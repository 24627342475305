import { gettext } from '@eventbrite/i18n';
import React, { useContext, useState } from 'react';
import {
    EventBasicInformation,
    EventBasicInformationContext,
} from '../../../contexts/EventContext';
import { CollectionCards } from '../../_shared/CollectionCards';
import { LoadMoreCollections } from '../../_shared/LoadMoreCollections';
import { Modal } from '../../_shared/Modal';
import { useEventCollections } from '../../_shared/useEventCollections';
import { COLLECTIONS_PAGE_SIZE, MODAL_LAYOUT_COLUMNS } from './constants';
import { CreatorCollectionsLink } from './CreatorCollectionsLink';

const filterCollections = (collections: any[], collectionAmount: number) => {
    return collections.slice(0, collectionAmount);
};

export const CreatorCollections = () => {
    const { id: eventId } = useContext(
        EventBasicInformationContext,
    ) as EventBasicInformation;

    const [opened, setOpened] = useState(false);

    const {
        collections,
        count,
        loadNextPage,
        hasMoreCollections,
        areCollectionsLoading,
    } = useEventCollections(eventId, COLLECTIONS_PAGE_SIZE);

    const collectionsLinkProps = {
        count,
        collection: count === 1 ? filterCollections(collections, 1) : undefined,
        viewMoreCollections: setOpened,
    };

    const hasSeveralCreatorCollections = count > 1;

    return (
        <>
            <CreatorCollectionsLink {...collectionsLinkProps} />
            {hasSeveralCreatorCollections && (
                <Modal
                    title={gettext('View collections').toString()}
                    visible={opened}
                    noMinHeight={true}
                    onClose={() => setOpened(false)}
                >
                    <CollectionCards
                        collections={collections}
                        columns={MODAL_LAYOUT_COLUMNS}
                    />
                    <LoadMoreCollections
                        areCollectionsLoading={areCollectionsLoading}
                        loadNextPage={loadNextPage}
                        hasMoreCollections={hasMoreCollections}
                    />
                </Modal>
            )}
        </>
    );
};
