import React from 'react';

import Image from './Image';

const ImageContainer = ({
    imageAlt,
    imageUrl,
    isLazyImage,
    linkUrl,
    onClick,
    imageStyle,
    placeholderBgColor,
    isTargetBlank,
    isNoFollow,
}) => (
    <aside
        className="eds-event-card-content__image-container"
        aria-hidden="true"
    >
        <Image
            imageUrl={imageUrl}
            onClick={onClick}
            linkUrl={linkUrl}
            isLazyImage={isLazyImage}
            imageAlt={imageAlt}
            imageStyle={imageStyle}
            hasNoTabIndex={true}
            placeholderBgColor={placeholderBgColor}
            isTargetBlank={isTargetBlank}
            isNoFollow={isNoFollow}
        />
    </aside>
);

export default ImageContainer;
