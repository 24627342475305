import React from 'react';

const getElementsFromDocument = (querySelector: string): Element[] => {
    const nodeList = document.querySelectorAll(querySelector);
    const elementArray = Array.from(nodeList);

    return elementArray;
};

export const useAddHandlersToNodes = ({
    enabled = true,
    callback,
    querySelector,
    delaySelector = false,
    retry = false,
}: {
    enabled?: boolean;
    callback: () => void;
    querySelector: string;
    delaySelector?: boolean;
    retry?: boolean;
}) => {
    const [elements, setElements] = React.useState<Element[]>([]);
    const handlersAdded = React.useRef(false);
    const retried = React.useRef(false);

    //Grab elements off the document on
    //initial mount and store in state
    React.useEffect(() => {
        if (enabled) {
            const getElements = () => {
                const elementArray = getElementsFromDocument(querySelector);

                if (elementArray.length === 0 && retry && !retried.current) {
                    setTimeout(() => {
                        retried.current = true;

                        getElements();
                    }, 200);
                }

                setElements(elementArray);
            };

            if (delaySelector) {
                setTimeout(() => {
                    getElements();
                }, 0);
            } else {
                getElements();
            }
        }
    }, [delaySelector, enabled, querySelector, retry]);

    //Once elements in state assign the handler to each.
    React.useEffect(() => {
        if (elements.length > 0 && !handlersAdded.current) {
            elements.forEach((element) => {
                element.addEventListener('click', (event) => {
                    event.preventDefault();

                    callback();
                });
            });

            handlersAdded.current = true;
        }
    }, [callback, elements]);
};
