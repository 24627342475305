import type { CoreEnv } from '@eventbrite/context-gen';
import { track } from '@eventbrite/datalayer-library';
import { Button } from '@eventbrite/eds-button';
import { DiscoveryEvent } from '@eventbrite/event-renderer';
import { RenderGlobalNavOrUndefined } from '@eventbrite/global-nav';
import {
    getLocaleOverride,
    gettext,
    setLanguage,
    setup as setupI18n,
} from '@eventbrite/i18n';
import { SiteStructure } from '@eventbrite/site-structure';
import { logEvent } from '@eventbrite/statsig';
import { UrgencySignalsProps } from '@eventbrite/urgency-signals';
import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import sanitizeHTML from 'sanitize-html';
import { App } from './app';
import { CreatorBanner } from './components/CreatorBanner/CreatorBanner';
import { ActionsPanel } from './components/EventDetails/ActionsPanel/ActionsPanel';
import { ConversionBarContainer } from './components/EventDetails/ConversionBarContainer/ConversionBarContainer';
import { COLLECTIONS_PAGE_SIZE } from './components/EventDetails/CreatorCollections/constants';
import { CreatorCollections } from './components/EventDetails/CreatorCollections/CreatorCollections';
import {
    AboutThisEvent,
    OldDescriptionBeforeStructuredContent,
    type EventDescriptionProps,
} from './components/EventDetails/EventDescription';
import {
    EventDetails,
    EventDetailsMainInner,
    EventDetailsSectionTitle,
    EventDetailsWrapper,
} from './components/EventDetails/EventDetails';
import { EventHero } from './components/EventDetails/EventHero';
import { MusicEventsInfo } from './components/EventDetails/MusicEventsInfo/MusicEventsInfo';
import { Performers } from './components/EventDetails/Performers/Performers';
import { RefundPolicy } from './components/EventDetails/RefundPolicy/RefundPolicy';
import { SalesEndedMessage } from './components/EventDetails/SalesEndedMessage/SalesEndedMessage';
import StartDate from './components/EventDetails/StartDate';
import { Summary } from './components/EventDetails/Summary/Summary';
import { DateAndTime } from './components/EventDetails/WhenAndWhere/DateAndTime/DateAndTime';
import { WhenAndWhere } from './components/EventDetails/WhenAndWhere/WhenAndWhere';
import { Agenda, AgendaWidget } from './components/EventDetails/Widgets/Agenda';
import { Faq, FaqWidget } from './components/EventDetails/Widgets/Faq';
import { parseWidgets } from './components/EventDetails/Widgets/utils';
import { EventManagerBanner } from './components/EventManagerBanner/EventManagerBanner';
import { EnhancedExpiredEventsBadge } from './components/ExpiredEvents/EnhancedExpiredEventsBadge';
import { EnhancedExpiredEventsList } from './components/ExpiredEvents/EnhancedExpiredEventsList';
import { ExpiredEventsPage } from './components/ExpiredEvents/ExpiredEventsPage';
import type { FooterLinks } from './components/Footer/Footer';
import { Footer } from './components/Footer/Footer';
import { GoodToKnow } from './components/GoodToKnow/GoodToKnow';
import { HighlightsProps } from './components/Highlights';
import { Layout, LayoutModule, Modules } from './components/Layout';
import MaintenanceBanner from './components/MaintenanceBanner/MaintenanceBanner';
import type { NotificationBarSsrProps } from './components/NotificationBar/NotificationBarSsr';
import { NotificationBarSsr } from './components/NotificationBar/NotificationBarSsr';
import type { OrganizerPanelLayoutProps } from './components/OrganizerPanel/OrganizerPanelLayout';
import { OrganizerPanelLayout } from './components/OrganizerPanel/OrganizerPanelLayout';
import { PageTitle } from './components/PageTitle/PageTitle';
import {
    getCreatorBadgeSignal,
    getCreatorQualitySignal,
} from './components/RelatedEvents/api';
import type {
    CreatorBadgeResponse,
    CreatorSignalQualityResponse,
} from './components/RelatedEvents/types/api';
import {
    getShareComponentProps,
    ShareProps,
} from './components/ShareEvent/ShareEventRoot';
import type { Tag } from './components/Tags/Tags';
import {
    TagsForExpiredEvents,
    TagsForLiveEvents,
} from './components/Tags/Tags';
import { Tickets } from './components/Tickets';
import { UrgencySignals } from './components/UrgencySignals';
import { ConnectedErrorBanner } from './components/_shared/ErrorBanner/ConnectedErrorBanner';
import {
    FollowButtonGDPRModal,
    FollowButtonLoginModal,
    FollowButtonProvider,
} from './components/_shared/FollowButton';
import {
    getCreatorBadgeText,
    getSignalTypeText,
    getTrustSignalText,
} from './components/_shared/OrganizerInfo/helperFunctions';
import OrganiserListingPage from './components/_shared/OrganizerInfo/OrganiserListingPage/OrganiserListingPage';
import { useEventCollections } from './components/_shared/useEventCollections';
import { LISTING_HEAP_PAGE_AREA_IDS } from './constants';
import { useEventBasicInformationContext } from './contexts';
import { useListingTimedEntryExperimentContext } from './contexts/ListingTimedEntryExperimentContext';
import {
    isDetachInterestsExperimentEnabled,
    isTESessionSelectorExperimentEnabled,
    STATSIG_EVENT_NAMES,
    useG2KExperiment,
} from './experimentation';
import { ListingsProviders } from './providers';
import type {
    AppProps,
    CheckoutWidgetProps,
    Discount,
    Event,
    EventDetailsProps,
    EventHeroScopedProps,
    EventListingsFeatureFlags,
    EventMapScopedProps,
    FollowOrganizerScopedProps,
    LegacyConversionBarProps,
    NativeVideoProps,
    Organizer,
    RemindMeScopedProps,
    Request,
    Settings,
    StatsigProps,
    TicketsProps,
    TimedEntryEventProps,
    User,
} from './types';
import { AffiliateCodes, SALES_STATUS } from './types';
import { isRepeatingEvent } from './utils';
import {
    getDiscountFromEventListings,
    hasBogoTickets,
    hasEarlyBirdTickets,
} from './utils/discounts';

const FEATURE_FLAGS = {
    launchCreatorCreditsSectionAttendee: true,
};

export interface ServerAppProps {
    checkoutWidget: CheckoutWidgetProps;
    conversionBar: LegacyConversionBarProps;
    user: User;
    event: Event;
    env: CoreEnv;
    request: Request;
    footerLinks: FooterLinks;
    eventDescription: EventDescriptionProps;
    eventDetails: EventDetailsProps;
    organizer: Organizer;
    tags: Array<Tag>;
    settings: Settings;
    organizerPanelLayout: OrganizerPanelLayoutProps;
    eventMapLayout: EventMapScopedProps;
    eventHero: EventHeroScopedProps;
    notificationBar: NotificationBarSsrProps;
    shareComponentProps: ShareProps;
    expiredEventsPage: {
        relatedEvents: DiscoveryEvent[];
    };
    urgencySignals: UrgencySignalsProps;
    highlights: HighlightsProps;
    followOrganizer: FollowOrganizerScopedProps;
    contactOrganizer: {
        captchaKey: string;
    };
    remindMe: RemindMeScopedProps;
    gaCategory: string;
    gaSettings: any;
    salesStatus: string;
    appProps: AppProps;
    statsig: StatsigProps;
    tickets: TicketsProps;
    featureFlags: EventListingsFeatureFlags;
    eventSessionResponse: Array<TimedEntryEventProps>;
    nativeVideo?: NativeVideoProps;
    parentEventId?: string;
    discount?: Discount;
}

export const ServerApp = ({
    checkoutWidget,
    conversionBar,
    user,
    event,
    env,
    request,
    footerLinks,
    eventDescription,
    eventDetails,
    organizer,
    tags,
    settings,
    organizerPanelLayout,
    eventMapLayout,
    eventHero,
    notificationBar = {
        message: null,
        type: null,
    },
    shareComponentProps,
    expiredEventsPage,
    urgencySignals,
    highlights,
    followOrganizer,
    contactOrganizer: { captchaKey },
    remindMe,
    gaCategory,
    gaSettings,
    appProps,
    statsig,
    tickets,
    featureFlags,
    eventSessionResponse,
    nativeVideo,
    parentEventId,
    discount,
    salesStatus,
}: ServerAppProps) => {
    const {
        ageRestriction,
        hasCroppedLogo,
        hasPresenterOrAgeRestriction,
        organizerSalesEndedMessage,
        presenter,
        salesEndedMessage,
        shouldShowSalesEndedMessage,
        isOnlineEvent,
        hideStartDate,
    } = event;

    const {
        hasStructuredContent,
        structuredContent,
        summary,
        legacyDescription,
    } = eventDescription;

    const {
        id: organizerId,
        name: organizerName,
        profilePicture: organizerAvatar,
        url: organizerUrl,
        description: organizerDescription,
        orgFacebook: organizerFacebook,
        orgTwitter: organizerTwitter,
        orgWebsite: organizerWebsite,
    } = organizer;

    const {
        featureFlags: {
            enableIaGlobalNav = false,
            launchLinkToCreatorCollectionsOnListing = false,
            isOnlineEventMvpEnabled = false,
        } = {},
    } = settings;

    const {
        localeInfo: { locale },
    } = env;
    const enableTimedEntry = featureFlags?.enableTimedEntry ?? false;
    // https://eventbrite.atlassian.net/browse/EB-279367
    const onlyDefaultCheckoutForOnlineEvents =
        isOnlineEvent &&
        featureFlags?.shouldShowOnlyDefaultCheckoutOnlineEvents;

    const { performers, dates } = eventDetails;
    const hasHeadliners =
        Array.isArray(performers.headliners) &&
        performers.headliners.length > 0;
    const hasSupporters =
        Array.isArray(performers.supporters) &&
        performers.supporters.length > 0;
    const hasPerformers = hasHeadliners || hasSupporters;

    const isSalesEnded = salesStatus === SALES_STATUS.SALES_ENDED;
    const isSoldOut = salesStatus === SALES_STATUS.SOLD_OUT;

    const LiveEventMainSection = ({
        onViewAllDetailsClick,
        viewAllEventDetails,
    }: {
        onViewAllDetailsClick: Function;
        viewAllEventDetails: boolean;
    }) => {
        const { isListingTimedEntryExperimentExperimentActive } =
            useListingTimedEntryExperimentContext();
        const [creatorSignalData, setCreatorSignalData] = useState<
            CreatorSignalQualityResponse | undefined
        >(undefined);

        const [creatorBadgeData, setCreatorBadgeData] = useState<
            CreatorBadgeResponse | undefined
        >(undefined);

        async function fetchCreatorQualitySignal() {
            const id = Number(organizerId) / 1007;
            try {
                const response = await getCreatorQualitySignal(String(id));
                setCreatorSignalData(response);
            } catch (e) {
                console.log('cannot fetch signal', e);
            }
        }

        async function fetchCreatorBadgeData() {
            const id = Number(organizerId) / 1007;
            try {
                const creatorBadgeResponse = await getCreatorBadgeSignal(
                    String(id),
                );
                setCreatorBadgeData(creatorBadgeResponse);
            } catch (e) {
                console.log('cannot fetch creator badge signal', e);
            }
        }

        useEffect(() => {
            fetchCreatorQualitySignal();
        }, []);

        useEffect(() => {
            fetchCreatorBadgeData();
        }, []);

        useEffect(() => {
            if (isListingTimedEntryExperimentExperimentActive) {
                track({
                    eventName: 'listing_timed_entry_experiment_loaded',
                    eventData: {},
                });
            }
        }, [isListingTimedEntryExperimentExperimentActive]);

        const isG2KExperimentEnabled = useG2KExperiment();

        const trustSignalText = getTrustSignalText(creatorSignalData);
        const signalTypeText = getSignalTypeText(creatorSignalData);
        const isShowBadge = getCreatorBadgeText(creatorBadgeData);

        const eventBasicInfo = useEventBasicInformationContext();
        const { salesStatus: eventBasicInfoSalesStatus } = eventBasicInfo;

        const eventBasicInfoIsSalesEnded =
            eventBasicInfoSalesStatus === SALES_STATUS.SALES_ENDED;
        const eventBasicInfoIsSoldOut =
            eventBasicInfoSalesStatus === SALES_STATUS.SOLD_OUT;

        const { agenda: agendaWidget, faqs: faqWidget } = parseWidgets(
            structuredContent.widgets,
        );
        const { collections, count } = useEventCollections(
            event.id,
            COLLECTIONS_PAGE_SIZE,
        );

        return (
            <div
                className={`event-listing ${
                    hasCroppedLogo ? 'event-listing--has-image' : ''
                }`}
                role="article"
                data-testid="live-event-main-section"
            >
                {(!eventBasicInfoIsSalesEnded || !eventBasicInfoIsSoldOut) &&
                    notificationBar.message && (
                        <NotificationBarSsr {...notificationBar} />
                    )}
                {user.canManageEvent &&
                    !settings.featureFlags
                        ?.launchMobilePreviewInEventCreationFlow && (
                        <CreatorBanner />
                    )}
                <EventDetails
                    hasHeroSection={hasCroppedLogo || !!eventHero.featuredVideo}
                >
                    <ConnectedErrorBanner />

                    <EventHero
                        mainBodyBackground={eventHero.mainBodyBackground}
                        hasCroppedLogo={hasCroppedLogo}
                        items={eventHero.items}
                        eventName={event.name}
                        featuredVideo={eventHero.featuredVideo}
                        nativeVideo={nativeVideo}
                    />
                    <MaintenanceBanner />
                    <EventDetailsWrapper>
                        <ActionsPanel
                            isShareable={conversionBar.isShareable}
                            isEnded={conversionBar.isEnded}
                            isSalesEnded={eventBasicInfoIsSalesEnded}
                            isSoldOut={eventBasicInfoIsSoldOut}
                        />

                        <Layout
                            exploreMoreEvents={
                                (eventBasicInfoIsSalesEnded ||
                                    eventBasicInfoIsSoldOut) &&
                                !viewAllEventDetails
                            }
                        >
                            <LayoutModule name={Modules.mainContent}>
                                <LayoutModule
                                    name={Modules.enhancedExpiredEventsBadge}
                                >
                                    {(eventBasicInfoIsSalesEnded ||
                                        eventBasicInfoIsSoldOut) && (
                                        <EventDetailsMainInner>
                                            <EnhancedExpiredEventsBadge
                                                isEventDetailsPage={true}
                                                salesMessage={
                                                    event.salesStatus.message
                                                }
                                            />
                                        </EventDetailsMainInner>
                                    )}
                                </LayoutModule>

                                <LayoutModule name={Modules.salesEndedMessage}>
                                    {shouldShowSalesEndedMessage && (
                                        <EventDetailsMainInner>
                                            <SalesEndedMessage
                                                organizerSalesEndedMessage={
                                                    organizerSalesEndedMessage
                                                }
                                                salesEndedMessage={
                                                    salesEndedMessage
                                                }
                                            />
                                        </EventDetailsMainInner>
                                    )}
                                </LayoutModule>

                                <LayoutModule name={Modules.urgencySignals}>
                                    <EventDetailsMainInner>
                                        <UrgencySignals
                                            urgencySignals={urgencySignals}
                                        />
                                    </EventDetailsMainInner>
                                </LayoutModule>

                                <LayoutModule name={Modules.startDate}>
                                    <EventDetailsMainInner>
                                        <StartDate
                                            isOnlineEvent={isOnlineEvent}
                                            hideStartDate={hideStartDate}
                                            locale={locale}
                                        />
                                    </EventDetailsMainInner>
                                </LayoutModule>

                                <LayoutModule name={Modules.title}>
                                    <EventDetailsMainInner>
                                        <meta content={event.name} />
                                        <PageTitle
                                            pageTitle={event.name}
                                            hideStartDate={hideStartDate}
                                        />
                                    </EventDetailsMainInner>
                                </LayoutModule>

                                <LayoutModule name={Modules.musicEventInfo}>
                                    {hasPresenterOrAgeRestriction && (
                                        <EventDetailsMainInner>
                                            <MusicEventsInfo
                                                ageRestriction={ageRestriction}
                                                presenter={presenter}
                                            />
                                        </EventDetailsMainInner>
                                    )}
                                </LayoutModule>

                                <LayoutModule name={Modules.creatorCollections}>
                                    {count > 0 && collections && (
                                        <EventDetailsMainInner>
                                            <CreatorCollections />
                                        </EventDetailsMainInner>
                                    )}
                                </LayoutModule>

                                <LayoutModule name={Modules.summary}>
                                    <EventDetailsMainInner>
                                        <Summary summary={summary} />
                                    </EventDetailsMainInner>
                                </LayoutModule>

                                <LayoutModule name={Modules.organizerBrief}>
                                    {organizer.displayOrganizationName && (
                                        <EventDetailsMainInner>
                                            <OrganiserListingPage
                                                organizerName={organizerName}
                                                organizerAvatar={
                                                    organizerAvatar
                                                }
                                                organizerId={organizerId}
                                                pageArea={
                                                    LISTING_HEAP_PAGE_AREA_IDS.ORGANIZER_PANEL
                                                }
                                                showBadge={isShowBadge}
                                                trustSignal={trustSignalText}
                                                signalType={signalTypeText}
                                                featureFlags={
                                                    settings?.featureFlags
                                                }
                                                isMobile={env.isMobile}
                                                organizerDescription={
                                                    organizerDescription
                                                }
                                            />
                                        </EventDetailsMainInner>
                                    )}
                                </LayoutModule>

                                <LayoutModule name={Modules.dateAndTime}>
                                    <DateAndTime
                                        {...{
                                            ...eventDetails.dates,
                                            event: {
                                                start: event.start,
                                                end: event.end,
                                                hideEndDate: event.hideEndDate,
                                                hideStartDate:
                                                    event.hideStartDate,
                                                isOnlineEvent:
                                                    event.isOnlineEvent,
                                                childEvents: event.childEvents,
                                                listingDisplayFlags: {
                                                    shouldShowTimezone: true,
                                                },
                                            },
                                            locale,
                                            isTimedEntryEnabled:
                                                enableTimedEntry,
                                            isTESessionSelectorExperimentEnabled:
                                                isTESessionSelectorExperimentEnabled(
                                                    eventSessionResponse.length,
                                                    eventBasicInfoIsSalesEnded,
                                                    eventBasicInfoIsSoldOut,
                                                    event.shouldShowCompactCheckout,
                                                ),
                                        }}
                                    />
                                </LayoutModule>

                                <LayoutModule name={Modules.location}>
                                    <WhenAndWhere
                                        location={{
                                            ...eventDetails.location,
                                            isOnlineEvent: event.isOnlineEvent,
                                            onlineEventUrl:
                                                eventDetails.onlineEvent?.url,
                                        }}
                                        map={{
                                            location: eventMapLayout.location,
                                            gMapsApiKey:
                                                eventMapLayout.gMapsApiKey,
                                            shouldDisplayMap:
                                                eventMapLayout.shouldDisplayMap,
                                        }}
                                    />
                                </LayoutModule>

                                <LayoutModule name={Modules.performers}>
                                    {hasPerformers && (
                                        <EventDetailsMainInner>
                                            <Performers
                                                headliners={
                                                    eventDetails.performers
                                                        .headliners
                                                }
                                                supporters={
                                                    eventDetails.performers
                                                        .supporters
                                                }
                                            />
                                        </EventDetailsMainInner>
                                    )}
                                </LayoutModule>

                                <LayoutModule name={Modules.refundPolicy}>
                                    <RefundPolicy
                                        isPaidEvent={
                                            eventDetails.refund.isPaidEvent
                                        }
                                        isRefundRequestAllowed={
                                            eventDetails.refund
                                                .isRefundRequestAllowed
                                        }
                                        refundPolicyDescription={
                                            eventDetails.refund
                                                .refundPolicyDescription
                                        }
                                        refundPolicyIsNoRefunds={
                                            eventDetails.refund
                                                .refundPolicyIsNoRefunds
                                        }
                                    />
                                </LayoutModule>

                                <LayoutModule name={Modules.agenda}>
                                    {!!agendaWidget && (
                                        <EventDetailsMainInner>
                                            <div className="structured-content-agenda-widget">
                                                <EventDetailsSectionTitle>
                                                    <h2>{gettext('Agenda')}</h2>
                                                </EventDetailsSectionTitle>
                                                <Agenda
                                                    agenda={
                                                        agendaWidget as AgendaWidget
                                                    }
                                                />
                                            </div>
                                        </EventDetailsMainInner>
                                    )}
                                </LayoutModule>
                                <LayoutModule name={Modules.goodToKnow}>
                                    <EventDetailsMainInner>
                                        <GoodToKnow
                                            highlights={highlights}
                                            isOnline={event.isOnlineEvent}
                                            doorTime={dates.doorTimeLineThree}
                                            ageRestriction={
                                                event.ageRestriction
                                            }
                                            refundPolicy={
                                                eventDetails.refund
                                                    .isRefundRequestAllowed &&
                                                eventDetails.refund
                                                    .isPaidEvent &&
                                                eventDetails.refund
                                                    .refundPolicyDescription
                                                    ? sanitizeHTML(
                                                          eventDetails.refund
                                                              .refundPolicyDescription,
                                                          {
                                                              allowedTags: [],
                                                              allowedAttributes:
                                                                  {},
                                                          },
                                                      )
                                                    : ''
                                            }
                                            faqs={
                                                (faqWidget as FaqWidget)
                                                    ?.faqs || []
                                            }
                                        />
                                    </EventDetailsMainInner>
                                </LayoutModule>
                                <LayoutModule name={Modules.aboutThisEvent}>
                                    {hasStructuredContent ||
                                    !legacyDescription ? (
                                        <EventDetailsMainInner>
                                            <AboutThisEvent
                                                hasAutogeneratedContent={
                                                    structuredContent.hasAutogeneratedContent
                                                }
                                                modules={
                                                    structuredContent.modules
                                                }
                                                highlights={highlights}
                                                summary={summary}
                                                category={event.category}
                                                subcategory={event.subcategory}
                                            />
                                        </EventDetailsMainInner>
                                    ) : (
                                        <EventDetailsMainInner>
                                            <OldDescriptionBeforeStructuredContent
                                                legacyDescription={
                                                    legacyDescription
                                                }
                                                highlights={highlights}
                                            />
                                        </EventDetailsMainInner>
                                    )}
                                </LayoutModule>

                                <LayoutModule name={Modules.tickets}>
                                    <Tickets
                                        onlyDefaultCheckoutForOnlineEvents={
                                            onlyDefaultCheckoutForOnlineEvents
                                        }
                                    />
                                </LayoutModule>
                                <LayoutModule name={Modules.faq}>
                                    {!!faqWidget && !isG2KExperimentEnabled && (
                                        <EventDetailsMainInner>
                                            <div className="structured-content-agenda-widget">
                                                <EventDetailsSectionTitle>
                                                    <h2>
                                                        {gettext(
                                                            'Frequently asked questions',
                                                        )}
                                                    </h2>
                                                </EventDetailsSectionTitle>
                                                <Faq
                                                    data={
                                                        faqWidget as FaqWidget
                                                    }
                                                />
                                            </div>
                                        </EventDetailsMainInner>
                                    )}
                                </LayoutModule>
                                <LayoutModule name={Modules.tags}>
                                    {
                                        <EventDetailsMainInner>
                                            <TagsForLiveEvents
                                                tags={tags}
                                                isSoldOut={
                                                    eventBasicInfoIsSoldOut
                                                }
                                                isSalesEnded={
                                                    eventBasicInfoIsSalesEnded
                                                }
                                            />
                                        </EventDetailsMainInner>
                                    }
                                </LayoutModule>

                                <LayoutModule name={Modules.organizerPanel}>
                                    <EventDetailsMainInner>
                                        <OrganizerPanelLayout
                                            {...{
                                                ...organizerPanelLayout,
                                                captchaKey: captchaKey,
                                                organizerAvatar:
                                                    organizerAvatar,
                                                organizerDescription:
                                                    organizerDescription,
                                                organizerId: organizerId,
                                                organizerName: organizerName,
                                                organizerUrl: organizerUrl,
                                                organizerFacebook:
                                                    organizerFacebook,
                                                organizerTwitter:
                                                    organizerTwitter,
                                                organizerWebsite:
                                                    organizerWebsite,
                                                isAuthenticated:
                                                    user.isAuthenticated,

                                                eventName: event.name,
                                                gaCategory: gaCategory,
                                                isSalesEnded,
                                                eventBasicInfoIsSoldOut,
                                                eventId: event.id,
                                                trustSignal: trustSignalText,
                                                country: event?.venue?.country,
                                            }}
                                        />
                                    </EventDetailsMainInner>
                                </LayoutModule>

                                <LayoutModule name={Modules.tags}>
                                    {
                                        <EventDetailsMainInner>
                                            <TagsForExpiredEvents
                                                tags={tags}
                                                isSoldOut={
                                                    eventBasicInfoIsSoldOut
                                                }
                                                isSalesEnded={
                                                    eventBasicInfoIsSalesEnded
                                                }
                                            />
                                        </EventDetailsMainInner>
                                    }
                                </LayoutModule>

                                <LayoutModule
                                    name={Modules.enhancedExpiredEvents}
                                >
                                    {(eventBasicInfoIsSalesEnded ||
                                        eventBasicInfoIsSoldOut) && (
                                        <>
                                            {!viewAllEventDetails && (
                                                <EventDetailsMainInner>
                                                    <div className="event-listing__view-more-details-btn-container">
                                                        <Button
                                                            data-testid="view-event-details-button"
                                                            style="neutral"
                                                            type="button"
                                                            onClick={
                                                                onViewAllDetailsClick
                                                            }
                                                        >
                                                            View all event
                                                            details
                                                        </Button>
                                                    </div>
                                                </EventDetailsMainInner>
                                            )}

                                            <EnhancedExpiredEventsList
                                                {...{
                                                    isAuthenticated:
                                                        user.isAuthenticated,
                                                    locale,
                                                    affCode:
                                                        AffiliateCodes.AFF_RELATED_EVENTS_EXPIRED,
                                                    organizer,
                                                    launchLinkToCreatorCollectionsOnListing,
                                                    isExpiredEvent:
                                                        event.isExpiredEvent,
                                                    forceTwoColumnLayout: true,
                                                    eventBasicInfoIsSoldOut,
                                                    eventBasicInfoIsSalesEnded,
                                                }}
                                            />
                                        </>
                                    )}
                                </LayoutModule>
                            </LayoutModule>
                            <LayoutModule name={Modules.aside}>
                                <ConversionBarContainer
                                    {...{
                                        ...conversionBar,
                                        ...checkoutWidget,
                                        externalTickets: {
                                            url: conversionBar.externalTicketsUrl,
                                            text: conversionBar.externalTicketsButtonText,
                                            available:
                                                conversionBar.externalTicketsAreAvailable,
                                            shouldShowExternalTicketingProvider:
                                                conversionBar.shouldShowExternalTicketingProvider,
                                            externalTicketingProviderLabelText:
                                                conversionBar.externalTicketingProviderLabelText,
                                        },
                                        childEvents: event.childEvents,
                                        isExpiredEvent: event.isExpiredEvent,
                                        isPreview: event.isPreview,
                                        eventName: event.name,
                                        eventId: event.id,
                                        ticketsInfo: event.ticketsInfo,
                                        compactCheckoutDisqualifications:
                                            event.compactCheckoutDisqualifications,
                                        salesStatus:
                                            event.salesStatus.sales_status,
                                        gaCategory,
                                        gaSettings,
                                        isAuthenticated: user.isAuthenticated,
                                        isGDPRCountry:
                                            followOrganizer.isGDPRCountry,
                                        isRemindedEvent:
                                            remindMe?.isRemindedEvent,
                                        organizerId,
                                        organizerData: {},
                                        organizers: {},
                                        userId: user.id,
                                        organizerName: organizer.name,
                                        organizerAvatar:
                                            organizer.profilePicture,
                                        isSalesEnded,
                                        isSoldOut: eventBasicInfoIsSoldOut,
                                        hasDonationTicketsAvailable:
                                            event?.hasDonationTicketsAvailable ??
                                            false,
                                        hasWaitlist:
                                            event
                                                .compactCheckoutDisqualifications
                                                .has_waitlist,
                                        hasAvailableHiddenTickets:
                                            tickets?.availability
                                                ?.hasAvailableHiddenTickets ??
                                            false,
                                        waitlistAvailable:
                                            tickets?.availability
                                                ?.waitlistAvailable,
                                        waitlistEnabled:
                                            tickets?.availability
                                                ?.waitlistEnabled,
                                        onlyDefaultCheckoutForOnlineEvents:
                                            onlyDefaultCheckoutForOnlineEvents,
                                        isOnlineEventMvpEnabled:
                                            isOnlineEventMvpEnabled,
                                        isTESessionSelectorExperimentEnabled:
                                            isTESessionSelectorExperimentEnabled(
                                                eventSessionResponse.length,
                                                eventBasicInfoIsSalesEnded,
                                                eventBasicInfoIsSoldOut,
                                                event.shouldShowCompactCheckout,
                                            ),
                                        eventStartDate: event.start,
                                        eventEndDate: event.end,
                                    }}
                                />
                            </LayoutModule>
                        </Layout>
                    </EventDetailsWrapper>
                </EventDetails>

                {shouldShowRelatedEventsLive &&
                    (!eventBasicInfoIsSalesEnded ||
                        !eventBasicInfoIsSoldOut) && (
                        <div id="listings-root__related-events"></div>
                    )}
                <EventManagerBanner canManage={user.canManageEvent || false} />
            </div>
        );
    };
    const MainContent = () => {
        const [shouldHideMainBody, setHideMainBody] = useState(
            !event.isPreview && event.isExpiredEvent,
        );
        const [viewAllEventDetails, setViewAllEventDetails] = useState(false);

        const showEventMainSection = () => {
            setHideMainBody(false);
        };

        const showAllEventDetails = () => {
            setHideMainBody(false);
            setViewAllEventDetails(true);
            logEvent(STATSIG_EVENT_NAMES.LISTING_ALL_EVENT_DETAILS_VIEW);
        };

        return (
            <ListingsProviders
                event={{
                    ...event,
                    ticketsInfo: {
                        ...event.ticketsInfo,
                        // TODO: This is a temporal solution, once we tackle EB-233327 we will simply take this field from SERVER_DATA
                        hasEarlyBirdTickets:
                            urgencySignals?.categories?.earlyBird ?? false,
                    },
                }}
                eventDescription={eventDescription}
                eventMapLayout={eventMapLayout}
                eventDetails={eventDetails}
                user={user}
                settings={settings}
                env={env}
                request={request}
                statsig={statsig}
                conversionBar={conversionBar}
                tickets={tickets}
                urgencySignals={urgencySignals}
                eventSessionResponse={eventSessionResponse}
                parentEventId={parentEventId}
                featureFlags={featureFlags}
                discount={discount}
            >
                {!shouldHideMainBody && (
                    <LiveEventMainSection
                        onViewAllDetailsClick={showAllEventDetails}
                        viewAllEventDetails={viewAllEventDetails}
                    />
                )}
                {shouldHideMainBody && (
                    <ExpiredEventsPage
                        relatedEvents={expiredEventsPage.relatedEvents}
                        eventName={event.name}
                        organizer={{
                            name: organizerName,
                            profilePicture: organizerAvatar,
                            id: organizerId,
                        }}
                        isAuthenticated={user.isAuthenticated}
                        shareComponentProps={shareComponentProps}
                        onViewDetailsClick={showEventMainSection}
                        locale={locale}
                        isSalesEnded={isSalesEnded}
                        isSoldOut={isSoldOut}
                        salesMessage={event.salesStatus.message}
                    />
                )}
                <App {...appProps} />
            </ListingsProviders>
        );
    };

    const shouldShowRelatedEventsLive = event.isLive;

    const cleanEsiCachePaths = (env: CoreEnv) => {
        const esiPath = user.isAuthenticated
            ? /%2Fesi_nocache/g
            : /%2Fesi_cache/g;
        const removeEsiPath = (string: string): string =>
            string ? string.replace(esiPath, '') : '';

        env.loginUrl = removeEsiPath(env.loginUrl);
        env.logoutUrl = removeEsiPath(env.logoutUrl);
        env.signinUrl = removeEsiPath(env.signinUrl);
        env.signupUrl = removeEsiPath(env.signupUrl);
    };

    cleanEsiCachePaths(env);

    const siteWrapperClasses = classNames({
        'conversion-bar-placeholder': !event.isExpiredEvent,
    });

    return (
        <ErrorBoundary fallback={<>{gettext('Error on Listings SSR')}</>}>
            <div className={siteWrapperClasses}>
                <SiteStructure
                    env={env}
                    request={request}
                    user={user}
                    preview={event.isPreview}
                    featureFlags={{
                        ...FEATURE_FLAGS,
                        isDetachInterestsEnabled:
                            isDetachInterestsExperimentEnabled(),
                    }}
                    fullScreenMain={true}
                    globalHeader={RenderGlobalNavOrUndefined({
                        config: { env, user },
                        featureFlag: enableIaGlobalNav,
                    })}
                    footer={
                        <Footer {...footerLinks} serverUrl={env.serverUrl} />
                    }
                    removeOverflowSettings={true}
                    focusDrawerOptions={{
                        content: '',
                        alwaysInclude: false,
                    }}
                >
                    <FollowButtonProvider
                        organizerId={organizerId}
                        userId={user.id}
                        isGDPRCountry={followOrganizer.isGDPRCountry}
                        isAuthenticated={user.isAuthenticated}
                        event={event}
                    >
                        <MainContent />
                        <FollowButtonGDPRModal />
                        <FollowButtonLoginModal
                            organizerId={organizerId}
                            organizerName={organizerName}
                            organizerAvatar={organizerAvatar}
                        />
                    </FollowButtonProvider>
                </SiteStructure>
            </div>
        </ErrorBoundary>
    );
};

export const render = (props: AppProps) => {
    const locale = getLocaleOverride() || props.env.localeInfo.locale;
    setupI18n(require.context('./i18n/translations', true, /\.json$/));
    setLanguage(locale);

    return renderToString(
        <ServerApp
            user={props.user}
            event={{
                ...props.event,
                isRepeating: isRepeatingEvent(props),
                ticketsInfo: {
                    ...props.event.ticketsInfo,
                    hasEarlyBirdTickets: hasEarlyBirdTickets(
                        props['event_listing_response'].tickets,
                    ),
                    hasBogoTickets: hasBogoTickets(
                        props['event_listing_response'].tickets,
                    ),
                },
            }}
            request={props.request}
            env={props.env}
            footerLinks={props.footerLinks}
            eventDescription={{ ...props.components.eventDescription }}
            eventDetails={{ ...props.components.eventDetails }}
            tags={props.components.tags}
            conversionBar={props.components.conversionBar}
            checkoutWidget={props.components.checkoutWidget}
            settings={props.settings}
            organizerPanelLayout={props.components.organizerPanel}
            eventMapLayout={props.components.eventMap}
            eventHero={props.components.eventHero}
            organizer={props.organizer}
            notificationBar={props.components.notificationBar}
            expiredEventsPage={props.components.relatedEventsExpiredPage}
            shareComponentProps={getShareComponentProps(props)}
            urgencySignals={props.urgencySignals}
            highlights={{
                ...props.highlights,
                ...props['event_listing_response'].highlights,
            }}
            gaCategory={props.gaSettings.category}
            gaSettings={props.gaSettings}
            followOrganizer={props.components.followOrganizer}
            contactOrganizer={props.components.contactOrganizer}
            remindMe={props.components.remindMe}
            salesStatus={props.salesStatus}
            appProps={props}
            statsig={props.statsig}
            tickets={props['event_listing_response'].tickets}
            featureFlags={props['event_listing_response'].featureFlags}
            eventSessionResponse={props['event_listing_response'].eventSessions}
            nativeVideo={props['event_listing_response'].video}
            parentEventId={props['event_listing_response']?.parentEventId}
            discount={getDiscountFromEventListings(
                props.env.localeInfo.locale,
                props['event_listing_response'].tickets,
            )}
        />,
    );
};

export default ServerApp;
