import { gettext } from '@eventbrite/i18n';
import React, { useRef, useState, type ReactNode } from 'react';

import { track } from '@eventbrite/datalayer-library';
import { logEvent } from '@eventbrite/statsig';
import {
    STATSIG_EVENT_NAMES,
    useG2KExperiment,
} from '../../../experimentation';
import { Highlights } from '../../Highlights';
import { useIntersecting } from '../../hooks/useIntersecting';
import { EventDetailsSectionTitle } from '../EventDetails';
import { useLazyImagePlaceholder } from './hooks';

import { LISTING_HEAP_IDS } from '../../../constants';
import { useTrackingContext } from '../../../contexts';
import { isSecondHierarchyLevel } from '../../../experimentation';
import { useTrackComponent } from '../../hooks/useTrackComponent';
import { ExpandingButtonLink } from '../../_shared/ButtonLink';
import { CategoryBadge } from './CategoryBadge';
import './EventDescription.scss';
import type {
    AboutThisEventProps,
    ImageContentProps,
    StructuredContentModule,
    TextContentProps,
    VideoContentProps,
} from './types';
import { StructuredContentModuleType, TextContentAlignment } from './types';

const getTextAlignment = (alignment: TextContentAlignment) => {
    switch (alignment) {
        case TextContentAlignment.LEFT:
            return 'eds-text--left';
        case TextContentAlignment.CENTER:
            return 'eds-text--center';
        case TextContentAlignment.RIGHT:
            return 'eds-text--right';
    }
};

const ContentWrapper = ({
    style,
    children,
}: {
    style?: string;
    children?: ReactNode;
}) => {
    const className = `eds-l-mar-vert-6 eds-l-sm-mar-vert-4 eds-text-bm ${style}`;

    return <div className={className}>{children}</div>;
};

const TextContent = ({ text, alignment }: TextContentProps) => (
    <ContentWrapper style="structured-content-rich-text">
        <div
            className={getTextAlignment(alignment)}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: text }}
        />
    </ContentWrapper>
);

const LazyContent: React.FC<{ fallback: ReactNode }> = ({
    children,
    fallback,
}) => {
    const LAZY_ELEMENT_ROOT_MARGIN = '100px';
    const lazyElememt = useRef(null);

    const [isIntersecting] = useIntersecting({
        ref: lazyElememt,
        rootMargin: LAZY_ELEMENT_ROOT_MARGIN,
    });

    return (
        <div ref={lazyElememt}>
            {isIntersecting ? (
                <div className="eds-fx--fade-in">{children}</div>
            ) : (
                fallback
            )}
        </div>
    );
};

const VideoContent = ({ url, altText }: VideoContentProps) => (
    <ContentWrapper style="structured-content-video">
        <iframe
            className="structured-content-video__iframe"
            src={url}
            frameBorder="0"
            allowFullScreen
            title={altText || gettext('Video content').toString()}
            loading="lazy"
        >
            {altText}
        </iframe>
    </ContentWrapper>
);

const ImageContent = ({
    url,
    altText,
    captionText,
    width = 0,
    height = 0,
}: ImageContentProps) => {
    const fallback = useLazyImagePlaceholder(url, 'event-description', {
        width,
        height,
    });

    return (
        <ContentWrapper>
            <LazyContent fallback={fallback}>
                <img
                    className="eds-max-img"
                    src={url}
                    alt={altText || ''}
                    loading="lazy"
                />
            </LazyContent>
            {captionText && (
                <span className="eds-text-bs eds-text-color--ui-600">
                    {captionText}
                </span>
            )}
        </ContentWrapper>
    );
};

const StructuredModuleRenderer = ({
    module,
    isPreview,
}: {
    module: StructuredContentModule;
    isPreview?: boolean;
}) => {
    switch (module.type) {
        case StructuredContentModuleType.TEXT:
            const textContent = module as TextContentProps;
            return (
                <TextContent
                    text={textContent.text}
                    alignment={textContent.alignment}
                />
            );
        case StructuredContentModuleType.IMAGE:
            const imageContent = module as ImageContentProps;
            return (
                <div
                    className={
                        isPreview ? 'structure-content-item--hidden' : ''
                    }
                    data-testid="image-content"
                >
                    <ImageContent
                        url={imageContent.url}
                        altText={imageContent.altText}
                        captionText={imageContent.captionText}
                        width={imageContent.width}
                        height={imageContent.height}
                    />
                </div>
            );
        case StructuredContentModuleType.VIDEO:
            const videoContent = module as VideoContentProps;
            return (
                <div
                    className={
                        isPreview ? 'structure-content-item--hidden' : ''
                    }
                    data-testid="video-content"
                >
                    <VideoContent
                        url={videoContent.url}
                        altText={videoContent.altText}
                    />
                </div>
            );
        default:
            return <></>;
    }
};

const ReadMoreButton = ({
    expanded,
    onClick,
}: {
    expanded: boolean;
    onClick: () => void;
}) => {
    const heapId = expanded
        ? LISTING_HEAP_IDS.ABOUT_THIS_EVENT_READ_LESS
        : LISTING_HEAP_IDS.ABOUT_THIS_EVENT_READ_MORE;

    const text = expanded ? gettext('Read less') : gettext('Read more');

    return (
        <ExpandingButtonLink
            aria-controls="event-description"
            heapId={heapId}
            onClick={onClick}
            isExpanded={expanded}
            text={text}
        />
    );
};

export const AboutThisEvent = ({
    modules,
    highlights,
    summary,
    category,
    subcategory,
}: AboutThisEventProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    const isG2KExperimentActive = useG2KExperiment();

    const hasHighlights =
        !isG2KExperimentActive &&
        highlights &&
        Object.values(highlights).includes(true);

    useTrackComponent({
        ref: ref,
        heap: {
            eventName: LISTING_HEAP_IDS.ABOUT_THIS_EVENT_VIEW,
            eventBucketLabel:
                'Views on the About this event section (top of the section)',
        },
        statsig: {
            eventName: STATSIG_EVENT_NAMES.ABOUT_THIS_EVENT_VIEW,
        },
        onTrack: () => {
            if (!hasHighlights) {
                return null;
            }
            track({
                eventName: LISTING_HEAP_IDS.HIGHLIGHTS_VIEW,
                eventData: {},
            });
            logEvent(STATSIG_EVENT_NAMES.HIGHLIGHTS_VIEW);
        },
    });

    const { isStaffUser } = useTrackingContext();

    if (modules.length === 0 && !hasHighlights && !summary) {
        return null;
    }

    const onReadMoreClick = () => {
        if (expanded && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
        setExpanded(!expanded);
    };

    const isHierarchySecondExperimentActive =
        !isStaffUser && isSecondHierarchyLevel();

    return (
        <div className="eds-l-mar-bot-12 structured-content">
            <EventDetailsSectionTitle>
                <h2 ref={ref}>{gettext('About this event')}</h2>
            </EventDetailsSectionTitle>
            <CategoryBadge category={category} subcategory={subcategory} />
            {hasHighlights && <Highlights highlights={highlights} />}
            <div className={`has-user-generated-content event-description`}>
                <div>
                    <div
                        className={`has-user-generated-content event-description__content ${
                            isHierarchySecondExperimentActive && !expanded
                                ? 'event-description__content--collapsed'
                                : 'event-description__content--expanded'
                        }`}
                        id="event-description"
                    >
                        {modules.map((module, index) => (
                            <StructuredModuleRenderer
                                module={module}
                                key={index}
                                isPreview={
                                    isHierarchySecondExperimentActive &&
                                    !expanded
                                }
                            />
                        ))}
                    </div>
                </div>
            </div>
            {isHierarchySecondExperimentActive && (
                <ReadMoreButton expanded={expanded} onClick={onReadMoreClick} />
            )}
        </div>
    );
};
