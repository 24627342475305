import { emitEventForExternalTab } from '@eventbrite/ads';
import {
    getLocaleOverride,
    getParentLocale,
    setupAsync,
} from '@eventbrite/i18n';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { AppTracking } from './appTracking';
import { getShareComponentProps } from './components/ShareEvent/ShareEventRoot';
import {
    getStableIdForInitialization,
    initializeStatsigSdk,
} from './experimentation';
import { ServerApp, type ServerAppProps } from './server';
import './styles/listings.scss';
import { initializeTracking } from './tracking/initialize';
import type { AppProps } from './types';
import { isRepeatingEvent, updateRecentViews } from './utils';
import {
    getDiscountFromEventListings,
    hasBogoTickets,
    hasEarlyBirdTickets,
} from './utils/discounts';

import('intersection-observer');

// retrieve data from server to hydrate on the client
const props: AppProps = window.__SERVER_DATA__ || {};

const parentLocale = getParentLocale(
    getLocaleOverride() || props.env.localeInfo.locale,
);

const main = async () => {
    initializeTracking({
        appName: props.app_name,
        appVersion: props.app_version,
        user: props.user,
        ebDomain: props.env.ebDomain,
        event: props.event,
        sentryDNS: props.settings.sentryDNS,
    });
    emitEventForExternalTab(props.event.id);

    const stableId = getStableIdForInitialization(props.stableId);
    const serverAppProps: ServerAppProps = {
        event: {
            ...props.event,
            isRepeating: isRepeatingEvent(props),
            ticketsInfo: {
                ...props.event.ticketsInfo,
                hasEarlyBirdTickets: hasEarlyBirdTickets(
                    props['event_listing_response'].tickets,
                ),
                hasBogoTickets: hasBogoTickets(
                    props['event_listing_response'].tickets,
                ),
            },
        },
        env: props.env,
        request: props.request,
        footerLinks: props.footerLinks,
        eventDescription: props.components.eventDescription,
        eventDetails: props.components.eventDetails,
        organizer: props.organizer,
        settings: props.settings,
        tags: props.components.tags,
        organizerPanelLayout: props.components.organizerPanel,
        eventMapLayout: props.components.eventMap,
        checkoutWidget: props.components.checkoutWidget,
        conversionBar: props.components.conversionBar,
        eventHero: props.components.eventHero,
        user: props.user,
        notificationBar: props.components.notificationBar,
        shareComponentProps: getShareComponentProps(props),
        expiredEventsPage: props.components.relatedEventsExpiredPage,
        remindMe: props.components.remindMe,
        statsig: {
            ...props.statsig,
            stableId,
        },
        urgencySignals: props.urgencySignals,
        highlights: {
            ...props.highlights,
            ...props['event_listing_response'].highlights,
        },
        gaCategory: props.gaSettings.category,
        followOrganizer: props.components.followOrganizer,
        contactOrganizer: props.components.contactOrganizer,
        gaSettings: props.gaSettings,
        salesStatus: props.event.salesStatus.sales_status,
        appProps: props,
        tickets: props['event_listing_response']?.tickets,
        featureFlags: props['event_listing_response']?.featureFlags,
        eventSessionResponse: props['event_listing_response']?.eventSessions,
        nativeVideo: props['event_listing_response']?.video,
        parentEventId: props['event_listing_response']?.parentEventId,
        discount: getDiscountFromEventListings(
            parentLocale,
            props['event_listing_response'].tickets,
        ),
    };

    updateRecentViews(props.event);

    try {
        if (parentLocale !== 'en_US') {
            try {
                await setupAsync({
                    parentLocale,
                    translations:
                        /* webpackChunkName: "listings-i18n-translation" */ import(
                            `./i18n/translations/${parentLocale}.json`
                        ),
                });
            } catch (e) {
                console.error(
                    'Failed to load parent locale translation. Falling back to en_US',
                    e,
                );
            }
        }

        await initializeStatsigSdk(props, stableId);
    } finally {
        const rootElement = document.getElementById('root');
        if (rootElement) {
            hydrate(
                <ServerApp {...serverAppProps} />,
                document.getElementById('root'),
            );
        }
        render(
            <AppTracking {...props} />,
            document.getElementById('listings-root'),
        );
    }
};
main();
