import { datadogRum } from '@datadog/browser-rum-slim';
import type { ContextConfig } from './types';

const SAMPLE_RATE = 0.15;

export const initDatadogRUM = ({
    app,
    user,
    env,
    sampleValue,
    featureFlags,
}: ContextConfig) =>
    import('@eventbrite/real-user-monitoring').then(
        ({ EBEnvsEnum, initializeRealUserMonitoring, setUser }) => {
            initializeRealUserMonitoring({
                sessionSampleRate: sampleValue || SAMPLE_RATE,
                service: app.name,
                version: app.version,
                env: env === EBEnvsEnum.PROD ? EBEnvsEnum.PROD : EBEnvsEnum.QA,
                enableExperimentalFeatures: ['feature_flags'],
            });

            if (user.email) {
                setUser({
                    id: user.publicId,
                    name: user.fullName,
                    email: user.email,
                });
            }

            if (featureFlags) {
                addFeatureFlagsToDatadogRum(featureFlags);
            }
        },
    );

export const addFeatureFlagsToDatadogRum = (
    featureFlags: Record<string, boolean>,
) => {
    Object.entries(featureFlags).forEach(([flagName, flagValue]) => {
        datadogRum.addFeatureFlagEvaluation(flagName, flagValue);
    });
};
