import { useEffect, useState } from 'react';

interface SwipeSettings {
    ref: React.RefObject<HTMLElement>;
    onSwipeLeft: () => void;
    onSwipeRight: () => void;
}

export const useSwipe = ({ ref, onSwipeLeft, onSwipeRight }: SwipeSettings) => {
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const minSwipeDistance = 50;

    useEffect(() => {
        const element = ref.current as HTMLElement;

        const handleTouchStart = (event: TouchEvent) => {
            setTouchEnd(0);
            setTouchStart(event.targetTouches[0].clientX);
        };

        const handleTouchMove = (event: TouchEvent) => {
            setTouchEnd(event.targetTouches[0].clientX);
        };

        const handleTouchEnd = () => {
            if (!touchStart || !touchEnd) return;
            const distance = touchStart - touchEnd;
            const isSwipeLeft = distance > minSwipeDistance;
            const isSwipeRight = distance < -minSwipeDistance;
            if (isSwipeLeft) {
                onSwipeLeft();
            }
            if (isSwipeRight) {
                onSwipeRight();
            }
        };

        element.addEventListener('touchstart', handleTouchStart);
        element.addEventListener('touchmove', handleTouchMove);
        element.addEventListener('touchend', handleTouchEnd);

        return () => {
            element.removeEventListener('touchstart', handleTouchStart);
            element.removeEventListener('touchmove', handleTouchMove);
            element.removeEventListener('touchend', handleTouchEnd);
        };
    });
};
