import React, { useContext, useEffect, useState } from 'react';
import { OverlayConfigContext, OverlayVisibilityContext } from '../context';
import './ContactOrganizerRenderer.scss';

interface ContactOrganizerRendererProps {
    domNodeId?: string;
    showOverlay?: Function;
    domNode?: Element;
}

interface RenderViaPortalProps {
    domNodeId?: string;
    domNode?: Element;
}

interface LazyLoadedLibs {
    ReactDOM: null | any;
    Modal: null | any;
}

const getMountingPoint = (
    domNodeId?: string,
    domNode?: Element,
): Element | boolean => {
    if (domNode) {
        return domNode;
    }
    if (domNodeId) {
        return document.getElementById(domNodeId) as Element;
    }

    return false;
};

const RenderViaPortal: React.FunctionComponent<RenderViaPortalProps> = (
    props,
) => {
    const { overlayConfig } = useContext(OverlayConfigContext);
    const { isVisible, setVisible } = useContext(OverlayVisibilityContext);
    const [mountPoint, setMountPoint] = useState({});

    const [{ ReactDOM, Modal }, setAsyncLibs] = useState<
        Partial<LazyLoadedLibs>
    >({
        ReactDOM: null,
        Modal: null,
    });

    const overlayConfigWithVisibility = {
        ...overlayConfig,
        isShown: isVisible,
        onClose: () => setVisible && setVisible({ value: false }),
    };

    //Only import necessary libs once module is being rendered
    useEffect(() => {
        if (isVisible && !ReactDOM && !Modal) {
            const reactDomPromise = import('react-dom');
            const modalPromise = import('@eventbrite/eds-modal');
            Promise.all([reactDomPromise, modalPromise]).then((values) => {
                setAsyncLibs({
                    ReactDOM: values[0].default,
                    Modal: values[1].Modal,
                });
            });
        }
    }, [isVisible, ReactDOM, Modal]);

    useEffect(() => {
        setMountPoint(getMountingPoint(props.domNodeId, props.domNode));
    }, [props.domNodeId, props.domNode]);

    if (ReactDOM === null || Modal === null || !mountPoint) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className="contact-org-wrapper">
            <Modal {...overlayConfigWithVisibility}>{props.children}</Modal>
        </div>,
        mountPoint as Element,
    );
};

/**
 * Controls the rendering and hiding of the overlay
 * component used to render the contact organizer
 * application.
 *
 * Passes modal config through and allows updates to
 * be made by leaves.
 *
 * @param props
 */
export const ContactOrganizerRenderer: React.FunctionComponent<
    ContactOrganizerRendererProps
> = (props) => {
    return (
        <RenderViaPortal domNodeId={props.domNodeId} domNode={props.domNode}>
            {props.children}
        </RenderViaPortal>
    );
};
