import { Button } from '@eventbrite/eds-button';
import { Icon } from '@eventbrite/eds-icon';
import { InfoChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import React, { useState } from 'react';
import { useEventBasicInformationContext } from '../../../../contexts';
import type {
    CheckoutWidgetProps,
    Event,
    Organizer,
    RemindMeProps,
} from '../../../../types';
import RemindMe from '../../../RemindMe/RemindMe';
import { RSVPLabel, RSVPOptions } from '../../../RSVP/RSVP';
import { CheckoutModalButton } from '../CheckoutModalButton/CheckoutModalButton';
import { ExploreSimilarEventsButton } from '../ExploreSimilarEventsButton/ExploreSimilarEventsButton';
import './ConversionBar.scss';
import './ConversionBarTeExperiment.scss';
import { ScrollToDateButton } from './ScrollToDateButton';

export type ConversionBarCommonProps = {
    statusIsUnavailable: boolean;
    shouldDisplayRsvp: boolean;
    shouldShowStatus: boolean;
    panelDisplayPrice: string;
    statusToDisplay: string;
    shouldShowCheckoutButton: boolean;
    ticketModalButton: any;
    shouldShowRemindMeButton: boolean;
    shouldShowTurnOffReminderButton: boolean;
    isExpiredEvent: boolean;
    salesStatus: string;
    isSalesEnded: boolean;
    isSoldOut: boolean;
    hasDonationTicketsAvailable: boolean;
    hasWaitlist: boolean;
    hasAvailableHiddenTickets: boolean;
    waitlistEnabled?: boolean;
    waitlistAvailable?: boolean;
    joinWaitlist?: boolean;
    enforceDateAndTimeVisualization?: boolean;
    onlyDefaultCheckoutForOnlineEvents?: boolean;
    useAllInPrice?: boolean;
    isOnlineEventMvpEnabled?: boolean;
    isTESessionSelectorExperimentEnabled?: boolean;
    panelDisplayMessage?: string;
};

export type ConversionBarComponentProps = ConversionBarCommonProps &
    CheckoutWidgetProps &
    RemindMeProps & {
        externalTickets?: {
            //TODO: build on the backend
            url: string;
            text: string;
            available: boolean;
            shouldShowExternalTicketingProvider: boolean;
            externalTicketingProviderLabelText?: string;
        };
    } & Pick<Event, 'isPreview' | 'childEvents'> & {
        eventName?: Event['name'];
        organizerName?: Organizer['name'];
        organizerAvatar?: Organizer['profilePicture'];
    };

export const ConversionBar = ({
    affiliateCode,
    campaign_token,
    promoCode,
    rsvpToken,
    waitlistToken,
    statusIsUnavailable,
    shouldDisplayRsvp,
    shouldShowStatus,
    statusToDisplay,
    panelDisplayPrice,
    externalTickets,
    ticketModalButton,
    isPreview,
    shouldShowRemindMeButton,
    shouldShowCheckoutButton,
    eventName,
    isRemindedEvent,
    organizerId,
    organizerName,
    organizerAvatar,
    isAuthenticated,
    isSalesEnded,
    isSoldOut,
    hasDonationTicketsAvailable,
    hasAvailableHiddenTickets,
    hasWaitlist,
    waitlistAvailable,
    waitlistEnabled,
    enforceDateAndTimeVisualization = false,
    onlyDefaultCheckoutForOnlineEvents = false,
    useAllInPrice = false,
    isOnlineEventMvpEnabled = false,
    isTESessionSelectorExperimentEnabled = false,
    panelDisplayMessage = '',
}: ConversionBarComponentProps) => {
    const {
        shouldShowExternalTicketingProvider,
        externalTicketingProviderLabelText,
    } = externalTickets ?? {};
    const { childEvents, isSeriesParent } = useEventBasicInformationContext();

    const ExternalTickets = () => (
        <Button
            href={externalTickets?.url}
            type="link"
            style="fill"
            size="block"
            target="_blank"
            rel="noreferrer nofollow"
        >
            {externalTickets?.text}
        </Button>
    );

    const showExploreSimilarEventsButton =
        !hasAvailableHiddenTickets &&
        !hasDonationTicketsAvailable &&
        !hasWaitlist &&
        (isSalesEnded || isSoldOut);

    const showExtraPriceInformation =
        onlyDefaultCheckoutForOnlineEvents &&
        isOnlineEventMvpEnabled &&
        useAllInPrice;
    const extraPriceInformation = gettext('+sales tax calculated at checkout');

    const CheckoutButton: React.FC = ({ children }) =>
        showExploreSimilarEventsButton ? (
            <ExploreSimilarEventsButton />
        ) : (
            <CheckoutModalButton
                {...{
                    affiliateCode,
                    campaign_token,
                    children,
                    isPreview,
                    promoCode,
                    rsvpToken,
                    shouldShowStatus,
                    ticketModalButton,
                    waitlistToken,
                    isSalesEnded,
                    isSoldOut,
                    childEvents,
                    joinWaitlist:
                        hasWaitlist && (waitlistAvailable || waitlistEnabled),
                }}
            />
        );

    const CheckoutArea = () => (
        <>
            {shouldShowRemindMeButton && (
                <RemindMe
                    eventName={eventName}
                    isRemindedEvent={isRemindedEvent}
                    organizerId={organizerId}
                    organizerName={organizerName}
                    organizerAvatar={organizerAvatar}
                    isAuthenticated={isAuthenticated}
                />
            )}
            {enforceDateAndTimeVisualization ? (
                <ScrollToDateButton />
            ) : (
                <CheckoutButton />
            )}
        </>
    );

    const SingleEvent = () => (
        <>
            {shouldDisplayRsvp ? (
                <RSVPOptions
                    {...{
                        affiliateCode,
                        campaign_token,
                        isPreview,
                        promoCode,
                        rsvpToken,
                        shouldShowStatus,
                        ticketModalButton,
                        waitlistToken,
                    }}
                />
            ) : (
                <>
                    {shouldShowCheckoutButton && <CheckoutArea />}
                    {externalTickets?.available && <ExternalTickets />}
                </>
            )}
        </>
    );

    const SeriesParent = () => {
        return enforceDateAndTimeVisualization ? (
            <ScrollToDateButton />
        ) : (
            <CheckoutButton />
        );
    };

    const PanelInfo = () => {
        const status = shouldShowStatus ? statusToDisplay : panelDisplayPrice;

        return (
            <div
                className={
                    isTESessionSelectorExperimentEnabled
                        ? 'conversion-bar-te__panel-info'
                        : 'conversion-bar__panel-info'
                }
                data-testid="panel-info"
            >
                {shouldDisplayRsvp ? (
                    <div id="listings-root__rsvp-label">
                        <RSVPLabel />
                    </div>
                ) : (
                    <>
                        {status}
                        {panelDisplayMessage &&
                        isTESessionSelectorExperimentEnabled ? (
                            <div className="conversion-bar-te__panel-message">
                                {panelDisplayMessage}
                            </div>
                        ) : null}
                        {shouldShowExternalTicketingProvider && (
                            <>
                                {' '}
                                <span className="conversion-bar__additional-info">
                                    {externalTicketingProviderLabelText}
                                </span>
                            </>
                        )}
                        {showExtraPriceInformation && <ShowMore />}
                    </>
                )}
            </div>
        );
    };

    const ShowMore = () => {
        const [showMoreOpen, setShowMoreOpen] = useState(false);

        return (
            <>
                <button
                    type="button"
                    className="conversion-bar__show-more__button"
                    onClick={() => setShowMoreOpen(!showMoreOpen)}
                    aria-label={gettext(
                        'Show price extra information',
                    ).toString()}
                >
                    <Icon
                        className="conversion-bar__show-more__icon"
                        type={<InfoChunky fill="#2D49BE" />}
                        width="24px"
                        height="24px"
                    />
                </button>
                {showMoreOpen && (
                    <p className="conversion-bar__show-more__content eds-text-bm eds-text-color--ui-600">
                        {extraPriceInformation}
                    </p>
                )}
            </>
        );
    };

    const shouldShowCTA =
        !statusIsUnavailable &&
        (shouldShowCheckoutButton ||
            externalTickets?.available ||
            shouldDisplayRsvp);

    return (
        <div
            className={
                isTESessionSelectorExperimentEnabled
                    ? 'conversion-bar conversion-bar-te__checkout-opener'
                    : 'conversion-bar conversion-bar--checkout-opener'
            }
            data-testid="conversion-bar"
        >
            <div
                className={
                    isTESessionSelectorExperimentEnabled
                        ? 'conversion-bar-te__body'
                        : 'conversion-bar__body'
                }
            >
                <PanelInfo />
            </div>
            {shouldShowCTA && (
                <div
                    className={
                        isTESessionSelectorExperimentEnabled
                            ? 'conversion-bar-te__cta-wrapper'
                            : 'conversion-bar__cta-wrapper'
                    }
                >
                    {isSeriesParent ? <SeriesParent /> : <SingleEvent />}
                </div>
            )}
        </div>
    );
};
