import {
    EventbriteEvent,
    getEventDatesFormat,
    getFormattedEventDate as getFormattedEventDateStandardized,
    TimezoneLocale,
} from '@eventbrite/event-datetime';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Event } from '../../../../types';
import { LoadingSkeleton } from '../../../_shared/LoadingSkeleton/LoadingSkeleton';
import { DoorTime } from './DoorTime';

const formattedEventDateStandardized = ({
    event,
    locale,
    isOnlineEvent,
}: {
    event: EventbriteEvent;
    locale: string;
    isOnlineEvent: boolean;
}) => {
    const getTimezoneFrom = isOnlineEvent
        ? TimezoneLocale.Attendee
        : TimezoneLocale.Creator;

    return getFormattedEventDateStandardized({
        event,
        locale,
        datesFormat: getEventDatesFormat({
            event,
            locale,
            useTimezoneFrom: getTimezoneFrom,
        }),
        useTimezoneFrom: getTimezoneFrom,
    });
};

interface DisplayDate {
    doorTime: string | null;
    locale: string;
    event: Pick<Event, 'start' | 'end' | 'isOnlineEvent'>;
}

export const DisplayDate: React.FC<DisplayDate> = ({
    doorTime,
    locale,
    event,
}) => {
    const { start, end, isOnlineEvent } = event;
    const [isLoadingUserLocalization, setIsLoadingUserLocalization] =
        useState(isOnlineEvent);
    const [formattedDateStandardized, setFormattedDateStandardized] = useState(
        () => {
            return formattedEventDateStandardized({
                event,
                locale,
                isOnlineEvent,
            });
        },
    );

    useEffect(() => {
        setFormattedDateStandardized(
            formattedEventDateStandardized({
                event,
                locale,
                isOnlineEvent,
            }),
        );
        if (isOnlineEvent) {
            setIsLoadingUserLocalization(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start, end, isOnlineEvent]);

    const dateTime = doorTime ? (
        <div>
            <>
                {formattedDateStandardized}.{' '}
                <DoorTime
                    dateAndTimezone={{
                        utc: doorTime as string,
                        timezone: event.start.timezone,
                    }}
                    locale={locale}
                    isOnlineEvent={isOnlineEvent}
                />
            </>
        </div>
    ) : (
        formattedDateStandardized
    );

    const dateTimeClasses = classNames('date-info__full-datetime', {
        'eds-is-hidden-accessible': isLoadingUserLocalization,
    });

    return (
        <div>
            {isLoadingUserLocalization && (
                <LoadingSkeleton width="100%" borderRadius="4px" />
            )}
            <span className={dateTimeClasses}>{dateTime}</span>
        </div>
    );
};
