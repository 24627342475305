import React from 'react';
import { string, func, bool } from 'prop-types';
import { gettext } from '@eventbrite/i18n';
import { formatUrl } from 'url-lib';
import { IconButton } from '@eventbrite/eds-icon-button';

import { UTM_OPTION_PROPTYPE } from './constants';
import { openUrl } from './utils';
import { LogoLinkedin } from '@eventbrite/eds-iconography';

export const STATIC_LINKEDIN_URL =
    '/organizations/marketing/share-on-social/integrations/linkedin';

export const STATIC_SETTINGS = {
    name: '_blank',
    noPopup: true,
};

const _getEventUrl = (eventUrl, utmOptions) =>
    formatUrl(eventUrl, {
        aff: 'esli',
        ...utmOptions,
    });

export default class LinkedinShare extends React.Component {
    static propTypes = {
        /**
         * Event's url
         */
        eventUrl: string.isRequired,

        /**
         * Function that onClick of one of the share options, passes
         * back an id of the clicked option
         */
        onClick: func,

        /**
         * Default is false. If true, LinkedIn button to will lead to <host>/STATIC_LINKEDIN_URL
         * otherwise, it generates a dynamic url for sharing specific content on LinkedIn.
         */
        staticUrl: bool,

        /**
         * utmOptions configured for this shareType
         */
        utmOptions: UTM_OPTION_PROPTYPE,
    };

    _handleButtonClick() {
        const { eventUrl, onClick, staticUrl = false, utmOptions } = this.props;

        if (onClick) {
            onClick();
        }

        if (staticUrl) {
            openUrl(STATIC_LINKEDIN_URL, STATIC_SETTINGS);
        } else {
            const url = formatUrl('https://www.linkedin.com/shareArticle', {
                url: _getEventUrl(eventUrl, utmOptions),
            });

            openUrl(url);
        }
    }

    render() {
        return (
            <span
                className="eds-l-pad-right-1"
                data-spec="linkedin-share-box-container"
                data-heap-id={this.props.dataHeapId}
                data-share-method={this.props.shareMethod}
                data-page-area={this.props.dataPageArea}
                data-event-id={this.props.eventId}
            >
                <IconButton
                    onClick={this._handleButtonClick.bind(this)}
                    iconType={<LogoLinkedin />}
                    title={gettext('Share on Linkedin')}
                />
            </span>
        );
    }
}
