import React from 'react';
import '../../styles/notification.scss';
import { ControlledProps } from '../../types';
import { dynaMount } from '../utils/mountComponent';
import Notification from './Notification';

const getNotificationOptions = (props: ControlledProps) => {
    const { notificationOptions } = props;

    return { options: notificationOptions };
};

const NotificationRoot = (
    props: ControlledProps,
): JSX.Element | React.ReactPortal => {
    const notificationProps = getNotificationOptions(props);

    return dynaMount(
        {
            Component: Notification,
            containerId: 'listings-root__notification',
        },
        notificationProps,
    );
};

export default NotificationRoot;
