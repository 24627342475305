import { sendNewQuantities } from './sendNewTicketQuantities';
import type { Communication, CommunicationAction } from './types';

export const reducer = (state: Communication, action: CommunicationAction) => {
    switch (action.type) {
        case 'sendQuantities':
            if (state.checkout?.contentWindow) {
                sendNewQuantities(state.checkout.contentWindow, action.payload);
            }
            return state;
        case 'setCheckoutIframe':
            return { ...state, checkout: action.payload };
        default:
            return state;
    }
};
