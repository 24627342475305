import {
    PromotedActionsMenuEventCard,
    PromotedActionsMenuEventCardProps,
} from '@eventbrite/ads-event-card-extra';
import {
    EventCardSaveAction,
    EventClient,
    EventClientProvider,
    FormattedEvent,
} from '@eventbrite/event-renderer';
import { logEvent } from '@eventbrite/statsig';
import classNames from 'classnames';
import React from 'react';
import { ListingsEventCard } from '../EventCard/ListingsEventCard';
import './EventsList.scss';

export type EventsListProps = {
    isAuthenticated: boolean;
    locale: string;
    affCode: string;
    title: string;
    events: Array<FormattedEvent>;
    forceTwoColumnLayout?: boolean;
    statsigEventMetrics?: {
        eventClick: string;
        eventLike: string;
    };
    promotedActionsProps?: Omit<PromotedActionsMenuEventCardProps, 'event'>;
};

export function EventsList({
    isAuthenticated,
    locale,
    affCode,
    title,
    events,
    forceTwoColumnLayout,
    statsigEventMetrics,
    promotedActionsProps,
}: EventsListProps) {
    const eventClient = new EventClient();

    const expiredEventsGridClasses = classNames('events-list__grid', {
        'events-list__grid--flex-columns-2up': forceTwoColumnLayout,
    });

    return (
        <EventClientProvider client={eventClient}>
            <div className="events-list__wrapper">
                <div className="events-list__container">
                    <h2 className="event-list__heading">{title}</h2>
                    <ul className={expiredEventsGridClasses}>
                        {events.map((event: FormattedEvent) => (
                            <li
                                key={
                                    event.promotedListingMetadata?.adId ||
                                    event.id
                                }
                                className="events-list__grid__item events-list__grid__item--fixed-width"
                            >
                                <ListingsEventCard
                                    bucketLabel={title}
                                    event={event}
                                    affCode={affCode}
                                    isLazyImage={false}
                                    isRaised={false}
                                    locale={locale || 'en_US'}
                                    // TODO: This should be moved to the statsigLocationString
                                    onClick={() =>
                                        statsigEventMetrics &&
                                        logEvent(statsigEventMetrics.eventClick)
                                    }
                                    actions={
                                        <>
                                            <EventCardSaveAction
                                                isAuthenticated={
                                                    !!isAuthenticated
                                                }
                                                savedByYou={event.savedByYou}
                                                id={event.id}
                                                eventName={event.name}
                                                imageUrl={event.imageUrl}
                                                actionStyle="outline"
                                                onClick={(saved) =>
                                                    statsigEventMetrics &&
                                                    saved &&
                                                    logEvent(
                                                        statsigEventMetrics.eventLike,
                                                    )
                                                }
                                            />
                                            {event.isPromoted &&
                                                promotedActionsProps && (
                                                    <PromotedActionsMenuEventCard
                                                        event={event}
                                                        actionStyle="outline"
                                                        {...promotedActionsProps}
                                                    />
                                                )}
                                        </>
                                    }
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </EventClientProvider>
    );
}
