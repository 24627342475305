import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import { gettext } from '@eventbrite/i18n';
import { Button } from '@eventbrite/marmalade';
import loadable from '@loadable/component';
import React, { useMemo, useState } from 'react';
import {
    useEventBasicInformationContext,
    useTicketContext,
} from '../../../../contexts';
import {
    LocalizedDateTime,
    SALES_STATUS,
    UNDETERMINED_SALES_STATUSES,
    type CheckoutWidgetProps,
    type ChildEventProps,
    type ServerEvent,
} from '../../../../types';
import {
    eventIsOnSale,
    eventIsSalesEnded,
    eventIsSoldOut,
} from '../../../../utils';
import { getFormattedDateCard } from '../../../utils/localizedDates';
import { useTimedEntryCalendarData } from '../../WhenAndWhere/DateAndTime/useTimedEntryCalendarData';
import './CheckAvailability.scss';

interface CheckAvailabilityProps
    extends CheckoutWidgetProps,
        Pick<ServerEvent, 'isPreview'> {
    eventStartDate?: LocalizedDateTime;
    eventEndDate?: LocalizedDateTime;
    onConfirm: () => void;
}

interface PanelDisplayMessageProps {
    locale: string;
    isSeriesParent: boolean;
    salesStatus: string;
    eventStartDate?: LocalizedDateTime;
    eventEndDate?: LocalizedDateTime;
}

const getValidChildEvents = (childEvents: Array<ChildEventProps>) => {
    return childEvents.filter(
        (event) =>
            eventIsOnSale(event) ||
            UNDETERMINED_SALES_STATUSES.includes(
                event?.sales_status as SALES_STATUS,
            ) ||
            eventIsSalesEnded(event) ||
            eventIsSoldOut(event),
    );
};

const filterUnavailableEventDates = (childEvents: Array<ChildEventProps>) => {
    return childEvents.filter((event) => {
        if (!event.availability) {
            return false;
        }
        return (
            event.availability.toLowerCase() === 'soldout' ||
            event.availability.toLowerCase() === 'salesended'
        );
    });
};

const getPanelDisplayMessage = ({
    locale,
    eventStartDate,
    eventEndDate,
    isSeriesParent,
    salesStatus,
}: PanelDisplayMessageProps) => {
    if (salesStatus === SALES_STATUS.SALES_ENDED) {
        return gettext('Sales Ended');
    }
    if (salesStatus === SALES_STATUS.SOLD_OUT) {
        return gettext('Sold Out');
    }
    if (!locale || !eventStartDate || !eventEndDate) {
        return '';
    }

    const eventStartDateInfo = getFormattedDateCard({
        date: eventStartDate,
        locale: locale,
    });
    const eventEndDateInfo = getFormattedDateCard({
        date: eventEndDate,
        locale: locale,
    });

    if (isSeriesParent) {
        return `${eventStartDateInfo.monthAbbr} ${eventStartDateInfo.day} to ${eventEndDateInfo.monthAbbr} ${eventEndDateInfo.day}`;
    } else {
        return `${eventStartDateInfo.monthAbbr} ${eventStartDateInfo.day}`;
    }
};

export const CheckAvailability: React.FC<CheckAvailabilityProps> = ({
    eventStartDate,
    eventEndDate,
    affiliateCode,
    isPreview,
    campaign_token,
    promoCode,
    rsvpToken,
    waitlistToken,
    onConfirm,
}) => {
    const basicEventInformation = useEventBasicInformationContext();
    const [moreOptionsModalOpened, setMoreOptionsModalOpened] = useState(false);
    const { ticketData, isFetchComplete } = useTicketContext();
    const { fetchTimedEntryCalendarData, loading } =
        useTimedEntryCalendarData();

    const LazyMoreOptionsModal = useMemo(
        () =>
            loadable(
                () =>
                    import(
                        '../../WhenAndWhere/ChildEventsSelection/MoreOptionsModal/MoreOptionsModal'
                    ),
            ),
        [],
    );

    if (!isFetchComplete) {
        return (
            <div className="express-checkout-progressbar">
                <ProgressIndicator style="gradient" />
            </div>
        );
    }

    const childEventsToShow =
        basicEventInformation.currentEventSessionsTimedEntry;

    const handleMoreOptionsClick = async () => {
        setMoreOptionsModalOpened(true);
        const startDateTime =
            basicEventInformation?.start?.local ??
            childEventsToShow?.[0]?.start?.local;

        if (startDateTime) {
            await fetchTimedEntryCalendarData(startDateTime);
        }
    };

    const validChildEvents = getValidChildEvents(
        basicEventInformation.childEvents ?? [],
    );

    const unavailableEvents = filterUnavailableEventDates(
        basicEventInformation.childEvents ?? [],
    );

    const panelDisplayMessage = getPanelDisplayMessage({
        locale: basicEventInformation.locale || '',
        isSeriesParent: basicEventInformation.isSeriesParent,
        salesStatus: basicEventInformation.salesStatus,
        eventStartDate: basicEventInformation.isSeriesParent
            ? eventStartDate
            : basicEventInformation.start,
        eventEndDate: basicEventInformation.isSeriesParent
            ? eventEndDate
            : basicEventInformation.end,
    });

    return (
        <section>
            <div className="check-availability-btn check-availability-btn--checkout-opener">
                <div className="check-availability-btn__body">
                    <div className="check-availability-btn__panel-info">
                        {ticketData?.panelDisplayPrice}
                        {panelDisplayMessage && (
                            <div className="check-availability-btn__panel-message">
                                {panelDisplayMessage}
                            </div>
                        )}
                    </div>
                </div>
                <div className="check-availability-btn__cta-wrapper">
                    <Button
                        onClick={handleMoreOptionsClick}
                        variant="primary"
                        className="check-availability-btn__button"
                    >
                        {gettext('Check Availability')}
                    </Button>
                </div>
            </div>
            {moreOptionsModalOpened && (
                <LazyMoreOptionsModal
                    onClose={() => setMoreOptionsModalOpened(false)}
                    onConfirm={onConfirm}
                    opened={moreOptionsModalOpened}
                    childEvents={validChildEvents}
                    currentEventStartDate={basicEventInformation?.start}
                    currentEventId={basicEventInformation.id}
                    isTimedEntryEnabled={true}
                    soldOutEvents={unavailableEvents}
                    isLoading={loading}
                    affiliateCode={affiliateCode}
                    isPreview={isPreview}
                    campaign_token={campaign_token}
                    promoCode={promoCode}
                    rsvpToken={rsvpToken}
                    waitlistToken={waitlistToken}
                />
            )}
        </section>
    );
};
