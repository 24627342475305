import styled from '@emotion/styled';

export const HostWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 8px;
    height: 40px;
    background: #ffffff;
    border-radius: 8px;
    width: 100%;

    @media all and (min-width: 640px) {
        width: fit-content;
    }
`;
HostWrapper.displayName = 'HostWrapper';

export const HostPicture = styled.img`
    margin-right: 8px;
    border-radius: 50%;
    overflow: hidden;
    width: 24px;
    height: 24px;
`;
HostPicture.displayName = 'HostPicture';

export const HostName = styled.p`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #39364f;
`;
HostName.displayName = 'HostName';
