import { gettext } from '@eventbrite/i18n';
import {
    ShareEventProps,
    ShareInline,
    ShareModal,
} from '@eventbrite/social-share';
import { keysCamelToSnake } from '@eventbrite/transformation-utils';
import React from 'react';
import { formatUrl } from 'url-lib';
import { useEventBasicInformationContext } from '../../contexts';
import { AppProps, GACodes } from '../../types';
import { dynaMount } from '../utils/mountComponent';

const LISTING_UTM_OPTIONS = {
    'utm-campaign': 'social',
    'utm-content': 'attendeeshare',
    'utm-medium': 'discovery',
    'utm-term': 'listing',
};

export type ShareProps = Pick<
    ShareEventProps,
    | 'facebookOptions'
    | 'isMobile'
    | 'twitterHandle'
    | 'utmOptions'
    | 'emailOptions'
    | 'serverUrl'
    | 'trackingCategory'
>;

export const getShareComponentProps = (props: AppProps): ShareProps => {
    const {
        components: { shareEvent },
        settings: { baseUrl: serverUrl, isMobile },
    } = props;

    return {
        ...shareEvent,
        serverUrl,
        isMobile,
        trackingCategory: GACodes.GA_CATEGORY_LISTING,
        utmOptions: LISTING_UTM_OPTIONS,
    };
};

export const getShareEventProps = (props: AppProps): ShareEventProps => {
    const {
        event: { name: eventName, id: eventId, url: eventUrl },
        components: { shareEvent },
        settings: { baseUrl: serverUrl, isMobile },
    } = props;

    return {
        ...shareEvent,
        eventName,
        eventId: `${eventId}`,
        eventUrl,
        serverUrl,
        isMobile,
        trackingCategory: GACodes.GA_CATEGORY_LISTING,
        utmOptions: LISTING_UTM_OPTIONS,
    };
};

export const ShareEventModal = (
    props: ShareEventProps & {
        modalContainer: React.MutableRefObject<HTMLDivElement | null>;
    },
) => {
    const dataWebShareApi = {
        text: props.eventName,
        title: props.eventName,
        url: formatUrl(
            props.eventUrl,
            keysCamelToSnake({
                ...LISTING_UTM_OPTIONS,
                'utm-source': 'wsa',
                aff: 'ebdsshwebmobile',
            }),
        ),
    };

    return (
        <ShareModal
            dataWebShareApi={dataWebShareApi}
            title={gettext('Share with friends').toString()}
            inputLabel={gettext('Event URL').toString()}
            url={formatUrl(
                props.eventUrl,
                keysCamelToSnake({
                    ...props.utmOptions,
                    'utm-source': 'cp',
                    aff: 'ebdsshcopyurl',
                }),
            )}
            tooltipTitle={gettext('Share this event')}
            tooltipText={gettext('Share Event')}
            trackingCategory={props.trackingCategory}
            isMobile={props.isMobile}
            container={props.modalContainer}
            useWebShareApi={true}
            eventId={props.eventId}
        >
            <ShareInline {...props} />
        </ShareModal>
    );
};

const ShareEventInline = (props: ShareEventProps) => (
    <div className="eds-l-mar-top-2 eds-text--left">
        <ShareInline {...props} />
    </div>
);

const TrackedShareEventRootMount = (props: ShareEventProps) =>
    dynaMount(
        {
            Component: ShareEventInline,
            containerId: 'listings-root__share-event',
        },
        props,
    );

const ShareEventModalRootMount = (
    props: ShareEventProps & {
        modalContainer: React.MutableRefObject<HTMLDivElement | null>;
    },
) =>
    dynaMount(
        {
            Component: ShareEventModal,
            containerId: 'listings-root__share-event-modal',
        },
        props,
    );

const ShareEventRoot = (
    props: AppProps & {
        modalContainer: React.MutableRefObject<HTMLDivElement | null>;
    },
) => {
    const { url: eventUrl, id: eventId } = useEventBasicInformationContext();
    const shareEventProps = {
        ...getShareEventProps(props),
        eventUrl: eventUrl as string,
        eventId,
    };
    return (
        <>
            <TrackedShareEventRootMount {...shareEventProps} />
            <ShareEventModalRootMount
                {...shareEventProps}
                modalContainer={props.modalContainer}
            />
        </>
    );
};

export default ShareEventRoot;
