import React from 'react';
import { useUserData } from '../hooks';

interface UserEmail {
    email: string;
}

export interface User {
    emails: UserEmail[];
    id: string;
    name: string;
    first_name: string;
    last_name: string;
    is_public: boolean;
    image_id: string | null;
}

export const UserContext = React.createContext<Partial<User> | null>(null);

interface UserProviderProps {
    isAuthenticated?: boolean;
}

export const UserProvider: React.FunctionComponent<UserProviderProps> = (
    props,
) => {
    const userData = useUserData(props.isAuthenticated);

    return (
        <UserContext.Provider value={userData}>
            {props.children}
        </UserContext.Provider>
    );
};
