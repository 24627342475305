import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { setWindowLocation } from '@eventbrite/http';
import { getUrlPath } from '@eventbrite/path-utils';
import Cookies, { expire } from 'cookies-js';
import { formatUrl } from 'url-lib';
import { ORGANIZER_COOKIE } from './constants';

export const updateFollowCounter = (
    followedByYou: boolean,
    followersCount: number,
) => (followedByYou ? followersCount + 1 : followersCount - 1);

export const hasChosenGDPR = (response: any) => {
    const explicitOptIn = 1;
    const explicitOptOut = 2;
    // Array with the values if the user has explicitly opted in or out for receiving notifications from an organizer
    const explicitChoices = [explicitOptIn, explicitOptOut];

    const hasChosen =
        explicitChoices.includes(
            response['preferences'][0]['global_preference'],
        ) &&
        explicitChoices.includes(
            response['preferences'][1]['global_preference'],
        );

    return hasChosen;
};

export const saveOrganizerInfoInCookie = (organizerId: string) => {
    Cookies.set(ORGANIZER_COOKIE, organizerId, {
        path: getUrlPath(),
        secure: true,
        expires: 300,
    });
};
export const getOrganizerIdFromCookie = () => Cookies.get(ORGANIZER_COOKIE);
export const expireOrganizerCookie = () =>
    expire(ORGANIZER_COOKIE, { path: getUrlPath() });

export const shouldFollowOrganizerFromCookie = ({
    organizerData,
    organizerIdFromCookie,
}: {
    organizerData?: { id: string; follow_status: { followed_by_you: boolean } };
    organizerIdFromCookie?: string;
}) => {
    return Boolean(
        organizerIdFromCookie &&
            organizerData &&
            !organizerData.follow_status.followed_by_you &&
            organizerIdFromCookie === organizerData.id,
    );
};

export const redirectToSignIn = () => {
    // then redirect to sign-in page, stld activates the "sticky TLD" feature
    // of the sign-in flow that will not redirect to the user's preferred TLD.
    const formattedUrl = formatUrl('/signin/', {
        referrer: getReferrer(),
        stld: 1,
    });

    return setWindowLocation(formattedUrl);
};

export const getReferrer = () => {
    if (!HAS_WINDOW) {
        return null;
    }
    const { pathname, search = '' } = window.location;
    return pathname + search;
};
