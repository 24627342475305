import { Icon } from '@eventbrite/eds-icon';
import {
    DurationChunky,
    EticketChunky,
    IdChunky,
    MoneyChunky,
    ParkingChunky,
} from '@eventbrite/eds-iconography';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { css, cx } from 'emotion';
import React from 'react';
import {
    HighlightDurationValue,
    HighlightsProps,
    HighlightsType,
} from './types';
import { formatDuration } from './utils';

type HighlightValue = boolean | string | HighlightDurationValue;

type HighlightConfig = {
    icon: JSX.Element;
    text: string | GenericLazyString;
};

const getHighlightConfig = (
    name: HighlightsType,
    value: HighlightValue,
): HighlightConfig => {
    const highlightConfig = {
        [HighlightsType.AcceptsRefunds]: {
            icon: <MoneyChunky />,
            text: gettext('Accepts refunds'),
        },
        [HighlightsType.AgeRestriction]: {
            icon: <IdChunky />,
            text: value as string,
        },
        [HighlightsType.Duration]: {
            icon: <DurationChunky />,
            text: formatDuration(value as HighlightDurationValue),
        },
        [HighlightsType.ETicket]: {
            icon: <EticketChunky />,
            text: gettext('Mobile eTicket'),
        },
        [HighlightsType.FreeParking]: {
            icon: <ParkingChunky />,
            text: gettext('Free venue parking'),
        },
        [HighlightsType.PaidParking]: {
            icon: <ParkingChunky />,
            text: gettext('Paid venue parking'),
        },
        [HighlightsType.ParkingNotAvailable]: {
            icon: <ParkingChunky />,
            text: gettext('No venue parking'),
        },
    };

    return highlightConfig[name];
};

type Props = {
    highlights: HighlightsProps;
};

const sortHighlights = (highlightsToSort: HighlightsProps) => {
    const sortPriority = [
        HighlightsType.Duration,
        HighlightsType.AgeRestriction,
        HighlightsType.ETicket,
        HighlightsType.AcceptsRefunds,
        HighlightsType.FreeParking,
        HighlightsType.PaidParking,
        HighlightsType.ParkingNotAvailable,
    ];
    const sortedHighlights: HighlightsProps = {};
    sortPriority.map((highlight: keyof HighlightsProps) => {
        if (highlightsToSort[highlight]) {
            sortedHighlights[highlight] = highlightsToSort[highlight];
        }
    });
    return sortedHighlights;
};

export const Highlights: React.FC<Props> = ({ highlights }) => {
    const sortedHighlights = sortHighlights(highlights);
    return (
        <ul className={styles.list} data-testid="highlights">
            {Object.entries(sortedHighlights).map(
                ([highlightName, highlightValue]) => {
                    if (!highlightValue) {
                        return;
                    }

                    // IMPORTANT: Due to legal concerns, we decided to temporarily hide the "accepts refunds" highlight until further notice.
                    if (highlightName === HighlightsType.AcceptsRefunds) {
                        return;
                    }

                    const { icon, text } = getHighlightConfig(
                        highlightName as HighlightsType,
                        highlightValue,
                    );

                    return (
                        <li
                            key={highlightName}
                            className={cx(
                                'eds-text-bm eds-text-weight--heavy',
                                styles.listitem,
                            )}
                        >
                            <span className={styles.iconbox}>
                                <Icon
                                    color="ui-blue--hover"
                                    size="small"
                                    type={icon}
                                />
                            </span>
                            {text}
                        </li>
                    );
                },
            )}
        </ul>
    );
};

const styles = {
    list: css`
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 15px;
        margin-bottom: 16px;
        margin-top: 16px;

        @media (min-width: 800px) {
            grid-template-columns: repeat(3, 1fr);
        }
    `,
    listitem: css`
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 8px;
    `,
    iconbox: css`
        align-items: center;
        background: #f8f7fa;
        border-radius: 8px;
        display: inline-flex;
        padding: 8px;
    `,
};
