import React, { FunctionComponent, useEffect } from 'react';
import {
    Statsig as StatsigReact,
    StatsigProvider as Provider,
    StatsigSynchronousProvider as SynchronousProvider,
    useExperimentWithExposureLoggingDisabled as useStatsigExperimentWithExposureLoggingDisabled,
    useGate as useStatsigGate,
    useLayer as useStatsigLayer,
} from 'statsig-react';
import { STATSIG_CLIENT_KEY } from '../constants/constants';
import {
    StatsigConfiguration,
    StatsigSyncConfiguration,
} from '../models/statsigConfiguration';
import { getDeviceData } from './device';
import {
    getExperimentValueToOverride,
    getOptions,
    getUser,
    shouldInitializeSDK,
} from './utils';

export {
    GateResult,
    StatsigContext,
    useConfig,
    useStatsigLogEffect,
} from 'statsig-react';
export { StatsigReact };

export const StatsigProvider: FunctionComponent<StatsigConfiguration> = (
    statsigConfig,
) => {
    const { children, clientKey, deactivateSdk, options, user, sampleRate } =
        statsigConfig || {};

    if (!shouldInitializeSDK(sampleRate, deactivateSdk)) {
        return <>{children}</>;
    }

    return (
        <Provider
            options={getOptions(options)}
            sdkKey={clientKey || STATSIG_CLIENT_KEY}
            user={getUser(user)}
            waitForInitialization={true}
        >
            {children}
        </Provider>
    );
};

export const StatsigSynchronousProvider: FunctionComponent<
    StatsigSyncConfiguration
> = (statsigConfig) => {
    const {
        children,
        clientKey,
        deactivateSdk,
        initializeValues = {},
        options,
        sampleRate,
        user,
        userFields,
    } = statsigConfig || {};

    if (!shouldInitializeSDK(sampleRate, deactivateSdk)) {
        return <>{children}</>;
    }

    return (
        <SynchronousProvider
            initializeValues={initializeValues}
            options={getOptions(options)}
            sdkKey={clientKey || STATSIG_CLIENT_KEY}
            user={getUser(user, userFields)}
        >
            {children}
        </SynchronousProvider>
    );
};

export function useExperiment<T>(
    experimentName: string,
    paramName: string,
    defaultValue: T,
    enabled = true,
) {
    const valueToOverride = getExperimentValueToOverride(experimentName);
    // Due to how react and hooks work, we disable logging so we can have a finer control over the exposure of the experiment with effects
    const { config, isLoading } =
        useStatsigExperimentWithExposureLoggingDisabled(experimentName);

    useEffect(() => {
        if (enabled && !isLoading) {
            StatsigReact.manuallyLogExperimentExposure(experimentName, false);
        }
    }, [enabled, isLoading, experimentName]);

    if (!enabled) {
        return {
            isLoading,
            value: defaultValue,
        };
    } else {
        return {
            isLoading,
            value: (valueToOverride ??
                config.get(paramName, defaultValue)) as T,
        };
    }
}

export const useLayer = <
    LayerParameterValue,
    ForcedReturnValue =
        | LayerParameterValue
        | NonNullable<ReturnType<typeof getExperimentValueToOverride>>,
>(
    layerName: string,
    paramName: string,
    defaultValue: LayerParameterValue,
) => {
    const valueToOverride = getExperimentValueToOverride(paramName);
    const { isLoading, layer } = useStatsigLayer(layerName);

    return {
        isLoading,
        value: (valueToOverride ??
            layer.get(paramName, defaultValue)) as unknown as ForcedReturnValue,
    };
};

export const useGate = (gateName: string) => {
    const valueToOverride = getExperimentValueToOverride(gateName);
    const { value, isLoading } = useStatsigGate(gateName);

    return {
        isLoading,
        value: valueToOverride ?? value,
    };
};

export const logReactEvent = (
    eventName: string,
    eventValue?: string | number | null,
    eventMetadata?: Record<string, string> | null,
) => {
    const deviceData = getDeviceData({ includeRawDeviceWidth: true });
    const metadata = Object.keys(deviceData).length
        ? {
              ...deviceData,
              ...eventMetadata,
          }
        : eventMetadata;

    // TODO: EB-188957 Validate event name with Heap's conventions
    // (https://eventbrite.atlassian.net/wiki/spaces/DEV/pages/15423275681/How+to+define+Heap+Events+-+Naming+Standard)
    StatsigReact.logEvent(eventName, eventValue, metadata);
};
