import React, { useRef, type FC } from 'react';

import { gettext } from '@eventbrite/i18n';

import { FollowButtonContext } from './context';

import { Button } from '@eventbrite/eds-button';

import { EventInteractions } from '@eventbrite/ads';
import { EVENT_INTERACTION } from '@eventbrite/event-renderer';
import { Event } from '../../../types';
import './FollowButton.scss';

type TFollowButtonProps = {
    pageArea: string;
    shouldPreventPropogation?: boolean;
};

const FOLLOW_TEXT = gettext('Follow');
const FOLLOWING_TEXT = gettext('Following');

export const useFollowButton = () => {
    const followButtonProps = React.useContext(FollowButtonContext);
    return {
        isFollowing: followButtonProps?.isFollowing || false,
        shouldShowLoginModal: followButtonProps?.shouldShowLoginModal || false,
        event: followButtonProps?.event,
        onFollowClick: followButtonProps?.onFollowClick,
    };
};

export const useFollowCount = () => {
    const followButtonProps = React.useContext(FollowButtonContext);
    return followButtonProps?.followersCount;
};

export const FollowButton: FC<TFollowButtonProps> = ({
    pageArea,
    shouldPreventPropogation,
}) => {
    const containerRef = useRef<HTMLSpanElement>(null);
    const { event, isFollowing, onFollowClick } = useFollowButton();
    const actionHandler = (action: PromotedActions) => {
        sendActionMetrics(action, containerRef, event);
    };
    return (
        <span ref={containerRef}>
            <Button
                style={'follow'}
                onClick={(e: MouseEvent) => {
                    onFollowClick?.(pageArea);
                    actionHandler(
                        isFollowing
                            ? PromotedActions.unfollow
                            : PromotedActions.follow,
                    );
                    if (shouldPreventPropogation) {
                        e.stopPropagation();
                    }
                }}
                isFollowing={isFollowing}
                __containerClassName="follow-button-listing-page"
            >
                {isFollowing ? FOLLOWING_TEXT : FOLLOW_TEXT}
            </Button>
        </span>
    );
};

export enum PromotedActions {
    follow = EventInteractions.Follow,
    unfollow = EventInteractions.Unfollow,
}

export function sendActionMetrics(
    action: PromotedActions,
    element: React.RefObject<HTMLSpanElement>,
    event?: Event,
) {
    const interactionEvent = new CustomEvent(EVENT_INTERACTION, {
        detail: {
            id: event?.id,
            action,
            event,
        },
        bubbles: true,
    });

    element.current?.dispatchEvent(interactionEvent);
}
