import styled from '@emotion/styled';
import React from 'react';
import { getFocalPoint } from '../../../utils/images';
import { CarouselItems } from '../HeroCarousel';

export type HeroImageProps = {
    alt?: string;
    fetchPriority?: FetchPriorityType;
    loading?: LoadingType;
    className?: string;
} & Pick<
    CarouselItems,
    'croppedLogoUrl480' | 'croppedLogoUrl600' | 'croppedLogoUrl940'
>;

export enum LoadingType {
    eager = 'eager',
    lazy = 'lazy',
}

export enum FetchPriorityType {
    high = 'high',
    low = 'low',
}

export const HeroImage = ({
    className,
    alt,
    croppedLogoUrl480,
    croppedLogoUrl600,
    croppedLogoUrl940,
    fetchPriority,
    loading,
}: HeroImageProps) => {
    const focalPoint = getFocalPoint(
        croppedLogoUrl480 || croppedLogoUrl600 || croppedLogoUrl940 || '',
    );

    return (
        <picture data-testid="hero-image">
            <source
                srcSet={`
                    ${croppedLogoUrl480} 480w,
                    ${croppedLogoUrl600} 600w,
                    ${croppedLogoUrl940} 940w
                `}
                sizes="
                    (max-width:480px) 480px,
                    (max-width:600px) 600px,
                    940px
                "
            />
            {focalPoint ? (
                <Image
                    className={className}
                    alt={alt}
                    src={croppedLogoUrl600}
                    loading={loading}
                    // fetchpriority does not exist on type 'DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>'
                    //eslint-disable-next-line
                    //@ts-ignore
                    fetchpriority={fetchPriority}
                    x={focalPoint.x}
                    y={focalPoint.y}
                    data-testid="hero-img"
                />
            ) : (
                <img
                    className={className}
                    width="600"
                    height="300"
                    alt={alt}
                    src={croppedLogoUrl600}
                    loading={loading}
                    // fetchpriority does not exist on type 'DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>'
                    //eslint-disable-next-line
                    //@ts-ignore
                    fetchpriority={fetchPriority}
                    data-testid="hero-img"
                />
            )}
        </picture>
    );
};

const Image = styled.img<{ x: number; y: number }>`
    user-select: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    object-position: ${({ x, y }) => `${x * 100}% ${y * 100}%`};
    aspect-ratio: 2/1;
`;
