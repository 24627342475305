import { ReactNode } from 'react';

export enum Shapes {
    CIRCLE = 'circle',
    SQUARE = 'square',
}
export interface IVerifiedBadgeProps {
    shape?: Shapes; //  will draw a ring (or any other shape based on shape prop,
    color?: string; //border color
    onClick?: () => void; // will call onClick on clicking,
    addOnClasses?: string; // class to override styles
    shouldTrack?: boolean; // should we track click event
    trackEventNameForClick?: string; // event name fror tracking click
    trackBadgeType?: string; // badge type for tracking click event,
    children?: ReactNode; // DOM over which the badge applied
}

export const defaultValues = {
    shape: Shapes.CIRCLE,
    color: '#f05537',
};
