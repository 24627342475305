import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { BREAKPOINTS } from '@eventbrite/eds-breakpoints-json';
import { COLORS_JSON, GREYS_JSON } from '@eventbrite/eds-color';

interface ControlButton extends React.ComponentPropsWithoutRef<'button'> {
    action: 'prev' | 'next';
}

interface SliderBarButton extends React.ComponentPropsWithoutRef<'button'> {
    active?: boolean;
}

interface CarouselWrapper extends React.ComponentPropsWithoutRef<'div'> {
    role?: string;
    ref: React.MutableRefObject<null>;
    tabIndex: number;
    onFocus: () => void;
    onBlur: (event: React.FocusEvent<HTMLDivElement>) => void;
}

interface ImageList extends React.ComponentPropsWithoutRef<'div'> {
    style: React.CSSProperties;
}

interface ImageListItem extends React.ComponentPropsWithoutRef<'div'> {
    'aria-hidden': boolean;
}

export const CarouselWrapper = styled.div<CarouselWrapper>`
    position: static;
`;

CarouselWrapper.displayName = 'CarouselWrapper';

export const CarouselViewport = styled.div`
    overflow: hidden;
    max-width: 940px;
    margin: auto;
`;
CarouselViewport.displayName = 'CarouselViewport';

export const ImageList = styled.div<ImageList>`
    margin: 0 auto;
    max-width: 940px;
    display: flex;
    width: 100%;
`;
ImageList.displayName = 'ImageList';

export const ImageListItem = styled.div<ImageListItem>`
    text-align: center;
    flex: 0 0 100%;

    img {
        max-width: 940px;
        width: 100%;
        height: auto;
    }
`;
ImageListItem.displayName = 'ImageListItem';

export const ControlButton = styled.button<ControlButton>`
    background-color: white;
    border: none;
    border-radius: 20px;
    color: ${COLORS_JSON['ui-blue--hover']};
    fill: ${COLORS_JSON['ui-blue--hover']};
    height: 48px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    z-index: 3;

    &:hover,
    &:focus {
        background-color: #ebeefc;

        @media (max-width: ${BREAKPOINTS['medium-narrow-width']}) {
            background-color: transparent;
        }
    }

    &:active {
        background-color: #c3cdf7;

        @media (max-width: ${BREAKPOINTS['medium-narrow-width']}) {
            background-color: transparent;
        }
    }

    &:focus-visible {
        border: solid 3px ${GREYS_JSON.white};
        background-clip: content-box;
        outline: none;
        padding: 3px;

        @media (max-width: ${BREAKPOINTS['medium-narrow-width']}) {
            background-color: transparent;
            border: none;
        }
    }

    @media (max-width: ${BREAKPOINTS['medium-narrow-width']}) {
        background-color: transparent;
        border-radius: 0;
        bottom: 0;
        height: 60%;
        width: 40%;
    }

    .eds-vector-image {
        width: 32px;
        height: 32px;

        @media (max-width: ${BREAKPOINTS['medium-narrow-width']}) {
            display: none;
        }
    }

    svg {
        height: auto;
        width: auto;
    }

    ${({ action }) =>
        action === 'prev' &&
        css`
            left: 16px;

            @media (max-width: ${BREAKPOINTS['medium-narrow-width']}) {
                left: 0;
            }
        `}

    ${({ action }) =>
        action === 'next' &&
        css`
            right: 16px;

            @media (max-width: ${BREAKPOINTS['medium-narrow-width']}) {
                right: 0;
            }
        `}
`;
ControlButton.displayName = 'ControlButton';

export const SliderBarOverlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0) 26.58%
    );
    display: flex;
    align-items: end;
    pointer-events: none;
`;
SliderBarOverlay.displayName = 'SliderBarOverlay';

export const SliderBarList = styled.ul`
    display: grid;
    gap: var(--base-unit);
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    width: 100%;
    padding: 0 8% 3%;
    max-width: 968px;
    margin: 0 auto;
`;
SliderBarList.displayName = 'SliderBarList';

export const SliderBarButton = styled.button<SliderBarButton>`
    background-color: ${GREYS_JSON.white};
    border: none;
    display: block;
    opacity: 0.3;
    padding: 2px;
    position: relative;
    width: 100%;

    &:before {
        content: '';
        bottom: -30px;
        left: 0;
        position: absolute;
        right: 0;
        top: -30px;

        @media (max-width: ${BREAKPOINTS['medium-narrow-width']}) {
            content: none;
        }
    }

    ${({ active }) =>
        active &&
        css`
            opacity: 1;
        `} @media(
    max-width: ${BREAKPOINTS['medium-narrow-width']}) {
        pointer-events: none;
    }
`;
SliderBarButton.displayName = 'SliderBarButton';

export const SliderBar = styled.div<React.ComponentPropsWithoutRef<'div'>>`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: end;
`;
SliderBar.displayName = 'SliderBar';

// TODO: Move these styles into a shared folder when it is going be reused from other component
export const AccessibleText = styled.span`
    position: absolute;
    left: -999999px;
`;
AccessibleText.displayName = 'AccessibleText';
