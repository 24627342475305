import { displayJourneyUsingMetatags } from '@eventbrite/branchio-utils';
import { sdkRequest } from '@eventbrite/http';
import { useEffect, useState } from 'react';
import { CollectionResponse, EventCollectionsResponse } from '../../types';

export const useCreatorCollections = (
    eventId: string,
    launchLinkToCreatorCollectionsOnListing: boolean,
    isExpiredEvent: boolean,
    isSalesEnded?: boolean,
    isSoldOut?: boolean,
) => {
    const [collections, setCollections] = useState<
        CollectionResponse[] | undefined
    >(undefined);

    useEffect(() => {
        let isMounted = true;

        displayJourneyUsingMetatags({
            name: 'DisplayBranchBanner',
            content: '1',
            extraData: {
                customActionParams: {
                    EventId: eventId,
                },
            },
        });

        if (isExpiredEvent && (!isSalesEnded || !isSoldOut)) {
            return;
        }

        if (launchLinkToCreatorCollectionsOnListing) {
            sdkRequest(`/events/${eventId}/collections/public/`).then(
                (storedCollections: EventCollectionsResponse) => {
                    if (storedCollections && isMounted)
                        return setCollections(storedCollections.collections);
                },
            );
        } else {
            return setCollections([]);
        }
        return () => {
            isMounted = false;
        };
    }, [
        eventId,
        launchLinkToCreatorCollectionsOnListing,
        isExpiredEvent,
        isSalesEnded,
        isSoldOut,
    ]);

    return collections;
};
