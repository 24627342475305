import React, {
    createContext,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Portal } from '../_shared/Portal/Portal';
import './ScrollableDrawer.scss';

export const IsTouchEnabledContext = createContext<{
    isTouchEnabled: boolean;
    setIsTouchEnabled: (value: boolean) => void;
    drawerState: string;
    setDrawerState: (value: string) => void;
}>({
    isTouchEnabled: true,
    setIsTouchEnabled: () => undefined,
    drawerState: 'half',
    setDrawerState: () => undefined,
});

const DraggerSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={69} height={5} fill="none" {...props}>
        <rect y={0.25} width={69} height={4} rx={2} fill="#DBDAE3" />
    </svg>
);

DraggerSvg.displayName = 'DraggerSvg';

export const ScrollableDrawer = ({
    children,
    onClickOutside = () => void 0,
    heightFixed,
    isScrollUpAllowed = true,
}: {
    children: React.ReactNode;
    onClickOutside?: () => void;
    heightFixed?: string;
    isScrollUpAllowed?: boolean;
}) => {
    const [isOpen, setIsOpen] = useState(true);

    // State to control touch interaction enabling/disabling
    const [isTouchEnabled, setIsTouchEnabled] = useState(true);

    // State to manage the state of the drawer (e.g., 'full', 'half')
    const [drawerState, setDrawerState] = useState('half');

    // Ref to store 'touchStartY' across renders
    const touchStartYRef = useRef<number>(0);

    //Ref to calculate the drawer height for css animation
    const drawerRef = useRef<HTMLDivElement | null>(null);

    // State to control whether the closing class should be applied
    const [applyClosingClass, setApplyClosingClass] = useState(false);

    // Function to apply the "closing" and "fade-out" class and then close the drawer with a delay
    const closeDrawerWithDelay = useCallback(() => {
        setApplyClosingClass(true);
        setTimeout(() => {
            setIsOpen(false);
            onClickOutside();
        }, 600);
    }, [onClickOutside]);

    const handleTouchStart = useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            // Allow users to minimize or close the drawer by swiping down from the header section
            if (!isTouchEnabled) {
                const headerElement =
                    document.getElementsByClassName('header')[0];
                if (headerElement && headerElement.contains(e.target as Node)) {
                    setIsTouchEnabled(true);
                }
            }
            // Begin capturing coordinates to enable swipe actions
            if (isTouchEnabled) {
                const touchStartY = e.touches[0].clientY;
                touchStartYRef.current = touchStartY;
            }
        },
        [isTouchEnabled],
    );

    const handleTouchEnd = useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            if (isTouchEnabled) {
                const touchEndY = e.changedTouches[0].clientY;
                const deltaY = touchEndY - touchStartYRef.current;

                // If scrolling up is not allowed and deltaY is negative
                if (!isScrollUpAllowed && deltaY < 0) {
                    return;
                }

                // If the user swipes down and the drawer is initially full, either make it half or close it
                if (deltaY > 20) {
                    if (drawerState === 'full') {
                        setDrawerState('half');
                    } else {
                        closeDrawerWithDelay();
                    }
                }
                // If the user swipes up and the drawer is initially half, make it full height
                else if (deltaY < -20 && drawerState === 'half') {
                    setDrawerState('full');
                }
            }
        },
        [isTouchEnabled, isScrollUpAllowed, drawerState, closeDrawerWithDelay],
    );

    // Control the body's overflow style based on the 'isOpen' state
    useEffect(() => {
        if (drawerRef.current)
            drawerRef.current.dataset.drawerHeight =
                drawerRef.current.clientHeight.toString() || '411';

        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen, drawerState]);

    if (!isOpen) return null;

    return (
        <IsTouchEnabledContext.Provider
            value={{
                isTouchEnabled,
                setIsTouchEnabled,
                drawerState,
                setDrawerState,
            }}
        >
            <Portal>
                <>
                    <div
                        className={`listings-backdrop ${
                            applyClosingClass ? 'fade-out' : ''
                        }`}
                        onClick={() => closeDrawerWithDelay()}
                    ></div>
                    <div
                        className={`drawer ${
                            applyClosingClass ? 'closing' : ''
                        } ${drawerState === 'full' ? 'fix-to-top' : ''} ${
                            drawerState === 'half' ? 'fix-to-half' : ''
                        }`}
                        ref={drawerRef}
                        style={
                            {
                                '--initial-height': `${
                                    heightFixed && drawerState === 'half'
                                        ? heightFixed
                                        : `${drawerRef.current?.dataset.drawerHeight}px`
                                }`,
                                overflowY: isScrollUpAllowed
                                    ? 'scroll'
                                    : 'hidden',
                            } as any
                        }
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                    >
                        {isScrollUpAllowed && (
                            <DraggerSvg className="dragger" />
                        )}
                        {children}
                    </div>
                </>
            </Portal>
        </IsTouchEnabledContext.Provider>
    );
};
