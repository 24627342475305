import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import React from 'react';
import {
    useEventBasicInformationContext,
    useTicketContext,
} from '../../../../contexts';
import { SALES_STATUS } from '../../../../types';
import { useIntersecting } from '../../../hooks/useIntersecting';
import { qualifiesForMultiticketSelection } from '../../../Tickets/multiTicketsSelection';
import { getFormattedDateCard } from '../../../utils/localizedDates';
import { ConversionBar } from '../ConversionBar/ConversionBar';
import { ConversionBarContainerProps } from '../ConversionBarContainer';
import '../ConversionBarContainer.scss';
import { isTicketSelectionExcludedInListings } from '../isTicketSelectionExcludedInListings';
import { TicketSelection } from '../TicketSelection/TicketSelection';

export const ConversionBarContent: React.FC<ConversionBarContainerProps> = ({
    ...props
}) => {
    let enforceDateAndTimeVisualization = false;
    const { ticketData, totalTicketsSelected, isFetchComplete } =
        useTicketContext();
    const {
        salesStatus,
        childEvents,
        ticketsInfo: { hasBogoTickets } = {},
        selectedDate = false,
        isRepeating = false,
        start,
        locale,
    } = useEventBasicInformationContext();
    const { compactCheckoutDisqualifications } = ticketData;

    const isTicketSelectionExcluded = isTicketSelectionExcludedInListings({
        soldOut: compactCheckoutDisqualifications?.sold_out,
        reservedSeating: compactCheckoutDisqualifications?.reserved_seating,
        moreThanOneTicket:
            compactCheckoutDisqualifications?.more_than_one_ticket,
        isSalesEnded: compactCheckoutDisqualifications?.is_sales_ended,
        isRsvp: compactCheckoutDisqualifications?.is_rsvp,
        isPostponed: compactCheckoutDisqualifications?.is_postponed,
        isCanceled: compactCheckoutDisqualifications?.is_canceled,
        hasWaitlist: compactCheckoutDisqualifications?.has_waitlist,
        hasWaitingRoom: compactCheckoutDisqualifications?.has_waiting_room,
        hasDiscounts: compactCheckoutDisqualifications?.has_discounts,
        hasAddons: compactCheckoutDisqualifications?.has_addons,
        discountCode: compactCheckoutDisqualifications?.discount_code,
        hasDonations: compactCheckoutDisqualifications?.has_donations,
        hasInventoryTiers:
            compactCheckoutDisqualifications?.has_inventory_tiers,
        externalTicketsAreAvailable: props.externalTicketsAreAvailable,
        salesStatus,
        onlyDefaultCheckoutForOnlineEvents:
            props.onlyDefaultCheckoutForOnlineEvents,
    });
    const qualifies = qualifiesForMultiticketSelection(
        {
            compactCheckoutDisqualifications,
            childEvents,
            salesStatus,
        },
        ticketData.ticketClasses,
        hasBogoTickets,
        props.onlyDefaultCheckoutForOnlineEvents,
    );
    const hasTicketsData = ticketData.ticketClasses.length > 0;
    const shouldShowTicketSelection =
        hasTicketsData && (!isTicketSelectionExcluded || qualifies);

    const [isDateAndTimeVisible] = useIntersecting({
        domNode: HAS_WINDOW
            ? document.getElementById('instance-selector')
            : null,
        ratio: 1,
        stayActive: !selectedDate,
        rootMargin: '0px',
    });

    if (
        isRepeating &&
        !isDateAndTimeVisible &&
        isFetchComplete &&
        salesStatus === SALES_STATUS.ON_SALE &&
        !props.isTESessionSelectorExperimentEnabled
    ) {
        enforceDateAndTimeVisualization = true;
    }

    if (!isFetchComplete) {
        return (
            <div className="express-checkout-progressbar">
                <ProgressIndicator style="gradient" />
            </div>
        );
    }

    if (isFetchComplete && shouldShowTicketSelection) {
        return (
            <TicketSelection
                eventName={props.eventName}
                affiliateCode={props.affiliateCode}
                isPreview={props.isPreview}
                promoCode={props.promoCode}
                waitlistToken={props.waitlistToken}
                rsvpToken={props.rsvpToken}
                campaignToken={props.campaign_token}
                isMultiTicketEnabled={qualifies}
                areTicketSelected={totalTicketsSelected > 0}
                enforceDateAndTimeVisualization={
                    enforceDateAndTimeVisualization
                }
            />
        );
    }

    let panelDisplayMessage = '';
    if (props.isTESessionSelectorExperimentEnabled && start && locale) {
        const eventStartDateInfo = getFormattedDateCard({
            date: start,
            locale: locale,
        });

        panelDisplayMessage = `${eventStartDateInfo.monthAbbr} ${eventStartDateInfo.day}・${eventStartDateInfo.time}`;
    }

    if (isFetchComplete) {
        return (
            <ConversionBar
                {...{
                    ...props,
                    panelDisplayPrice: ticketData.panelDisplayPrice,
                    panelDisplayMessage,
                    statusIsUnavailable:
                        salesStatus === SALES_STATUS.UNAVAILABLE,
                    salesStatus: salesStatus,
                    enforceDateAndTimeVisualization,
                    useAllInPrice: ticketData.ticketClasses?.some((tc) =>
                        tc.variants?.some((v) => v.useAllInPrice),
                    ),
                }}
            />
        );
    }

    return null;
};
