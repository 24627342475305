import { Icon } from '@eventbrite/eds-icon';
import { AlertChunky as AlertChunkySvg } from '@eventbrite/eds-iconography';
import { ErrorBoundary } from '@eventbrite/error-boundary';
import { gettext } from '@eventbrite/i18n';
import React from 'react';

const ViewFallBack: React.FunctionComponent = () => {
    return (
        <div
            className="eds-text--center eds-l-pad-top-6"
            style={{ flexDirection: 'column' }}
        >
            <Icon size="medium" type={<AlertChunkySvg />} />
            <div className="eds-text-bl eds-l-pad-top-6">
                {gettext('Uh oh.')}
            </div>
            <div className="eds-l-pad-top-3">
                {gettext('Looks like we have a problem.')}
            </div>
            <div className="eds-l-pad-top-1">
                {gettext(
                    'Our team has been notified and we are investigating.',
                )}
            </div>
        </div>
    );
};

export const ViewErrorBoundary: React.FunctionComponent = (props) => {
    return (
        <ErrorBoundary fallback={<ViewFallBack />}>
            {props.children}
        </ErrorBoundary>
    );
};

export const ModalErrorBoundary: React.FunctionComponent = (props) => {
    return <ErrorBoundary fallback={<div />}>{props.children}</ErrorBoundary>;
};
