import { Button } from '@eventbrite/eds-button';
import { getWindowObject } from '@eventbrite/feature-detection';
import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { logEvent } from '@eventbrite/statsig';
import { $FixMe } from '@eventbrite/ts-utils';
import classNames from 'classnames';
import escape from 'lodash/escape';
import React, { useEffect } from 'react';
import { LISTING_HEAP_IDS } from '../../../../constants';
import { useEventBasicInformationContext } from '../../../../contexts';
import { STATSIG_EVENT_NAMES } from '../../../../experimentation';
import {
    CheckoutWidgetProps,
    Event as EventProp,
    GACodes,
} from '../../../../types';
import { ConversionBarCommonProps } from '../ConversionBar/ConversionBar';

type CheckoutModalButtonProps = CheckoutWidgetProps &
    Pick<
        ConversionBarCommonProps,
        'shouldShowStatus' | 'ticketModalButton' | 'joinWaitlist'
    > &
    Pick<EventProp, 'isPreview'> & {
        handleClick?: () => void;
        size?: string;
        style?: string;
    };

declare global {
    interface Window {
        EBWidgets?: {
            createWidget: (params: $FixMe) => void;
        };
    }
}

const generateRandomHast = () => (Math.random() + 1).toString(36).substring(7);

export const CheckoutModalButton: React.FC<CheckoutModalButtonProps> = ({
    affiliateCode,
    campaign_token,
    children,
    handleClick,
    isPreview,
    promoCode,
    rsvpToken,
    shouldShowStatus,
    size,
    style,
    ticketModalButton,
    waitlistToken,
    joinWaitlist,
}) => {
    const { id: eventId, isSeriesParent } = useEventBasicInformationContext();
    const modalTriggerElementId = `eventbrite-widget-modal-trigger-${eventId}-${generateRandomHast()}`;
    const trackingLabel = isSeriesParent
        ? GACodes.GA_LABEL_SERIES_PARENT
        : ticketModalButton?.tracking_label;
    useEffect(() => {
        getWindowObject('EBWidgets')?.createWidget({
            widgetType: 'checkout',
            eventId,
            modal: true,
            modalTriggerElementId,
            ...(!isPreview ? { affiliateCode } : {}),
            ...(promoCode ? { promoCode: escape(promoCode) } : {}),
            ...(waitlistToken ? { waitlistToken } : {}),
            ...(rsvpToken ? { rsvpToken } : {}),
            ...(campaign_token ? { campaign_token } : {}),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    useEffect(() => {
        if (getWindowObject('location')?.hash === '#tickets') {
            document.getElementById(modalTriggerElementId)?.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Button
            id={modalTriggerElementId}
            data-testid="checkout-link"
            style={style ?? (shouldShowStatus ? 'neutral' : 'fill')}
            data-tracking-label={ticketModalButton?.tracking_label}
            __containerClassName={classNames({
                'js-embed-ticket-modal-btn': !isSeriesParent,
            })}
            size={size}
            onClick={() => {
                logEvent(STATSIG_EVENT_NAMES.LISTING_CONVERSION_BAR_CTA_CLICK);
                trackAnalyticsEvent({
                    action: GACodes.GA_ACTION_CHECKOUT,
                    category: GACodes.GA_CATEGORY_LISTING,
                    label: trackingLabel,
                });
                handleClick?.();
            }}
            data-heap-id={LISTING_HEAP_IDS.CHECKOUT_BUTTON}
        >
            {joinWaitlist
                ? gettext('Join waitlist')
                : children ?? ticketModalButton?.text}
        </Button>
    );
};
