import { Button } from '@eventbrite/eds-button';
import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import { gettext } from '@eventbrite/i18n';
import React from 'react';

interface Props {
    areCollectionsLoading: boolean;
    loadNextPage: () => void;
    hasMoreCollections: boolean;
}

export const LoadMoreCollections: React.FC<Props> = ({
    areCollectionsLoading,
    loadNextPage,
    hasMoreCollections,
}) => (
    <div className="eds-l-mar-top-6 eds-align--center">
        {areCollectionsLoading ? (
            <ProgressIndicator
                size="small-chunky"
                shape="circular"
                style="gradient"
            />
        ) : hasMoreCollections ? (
            <Button style="link" onClick={() => loadNextPage()}>
                {gettext('Show more collections')}
            </Button>
        ) : (
            <></>
        )}
    </div>
);
