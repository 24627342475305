import React, { useContext, useEffect } from 'react';
import { ScrollContext, ScrollContextDispatch } from './context';

export const useScrollContext = () => {
    return useContext(ScrollContext);
};

export const useScrollDispatch = () => {
    return useContext(ScrollContextDispatch);
};

export const useScrollOnCommand = (
    ref: React.RefObject<HTMLElement>,
    key: string,
) => {
    const { [key]: trigger } = useScrollContext();
    const dispatch = useScrollDispatch();

    useEffect(() => {
        if (trigger && ref && ref.current) {
            ref.current?.scrollIntoView({ behavior: 'smooth' });
            dispatch({ action: 'reset' });
        }
    }, [trigger, ref, dispatch]);
};
