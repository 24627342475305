import { Badge } from '@eventbrite/marmalade';
import React from 'react';
import { isThirdHierarchyLevel } from '../../../../experimentation/experiments';
import styles from './CategoryBadge.module.scss';
import { getCategoryString } from './utils';

interface CategoryBadgeProps {
    category?: string;
    subcategory?: string;
}

export const CategoryBadge = ({
    category,
    subcategory,
}: CategoryBadgeProps) => {
    const shouldShow = isThirdHierarchyLevel();
    const categoryString = getCategoryString(category, subcategory);

    if (!categoryString || !shouldShow) {
        return null;
    }

    return (
        <div className={styles.categoryBadge} data-testid="category-badge">
            <Badge variant="custom" bordered={false}>
                {categoryString}
            </Badge>
        </div>
    );
};
