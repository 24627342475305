import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ActionWrapper from './ActionWrapper';
import { STYLES_PROP_TYPE } from './constants';

export default class PrimaryContent extends PureComponent {
    static propTypes = {
        title: PropTypes.node.isRequired,
        subtitle: PropTypes.node,
        onClick: PropTypes.func,
        linkUrl: PropTypes.string,
        linkAlt: PropTypes.string,
        type: PropTypes.string,
        style: STYLES_PROP_TYPE,
        isTargetBlank: PropTypes.bool,
        isNoFollow: PropTypes.bool,
    };

    render() {
        const {
            onClick,
            linkUrl,
            linkAlt,
            title,
            subtitle,
            description,
            isTargetBlank,
            isNoFollow,
        } = this.props;
        const headingClassName =
            'eds-event-card-content__title eds-text-color--ui-800 eds-text-bl eds-text-weight--heavy';

        const subtitleClassName = classNames(
            'eds-event-card-content__sub-title',
            'eds-text-color--primary-brand',
            'eds-l-pad-bot-1',
            'eds-l-pad-top-2',
            'eds-text-weight--heavy',
            'eds-text-bm',
        );

        return (
            <div className="eds-event-card-content__primary-content">
                <ActionWrapper
                    onClick={onClick}
                    linkUrl={linkUrl}
                    isTargetBlank={isTargetBlank}
                    isNoFollow={isNoFollow}
                    linkAlt={linkAlt}
                >
                    <h3 className={headingClassName}>{title}</h3>
                </ActionWrapper>
                <div className={subtitleClassName}>{subtitle}</div>
                {description}
            </div>
        );
    }
}
