import React, { Suspense } from 'react';
import { useWindowSize } from 'react-use';
import loadable from '@loadable/component';

const CollectionMembershipDropdown = loadable(() =>
    import(
        /* webpackChunkName: "CollectionDropdown" */ './CollectionMembershipDropdown'
    ),
);
const CollectionMembershipModal = loadable(() =>
    import(
        /* webpackChunkName: "CollectionModal" */ './CollectionMembershipModal'
    ),
);

export const CollectionMembershipView = (props) => {
    const { width } = useWindowSize();

    const Component =
        width > 670 ? CollectionMembershipDropdown : CollectionMembershipModal;

    return <Component {...props} />;
};
