import { gettext, ngettext } from '@eventbrite/i18n';
import React from 'react';
import './CollectionSummary.scss';

interface UpcomingEventsProps {
    numUpcomingEvents?: number;
}

const UpcomingEvents: React.FunctionComponent<UpcomingEventsProps> = (
    props,
) => {
    if (
        props.numUpcomingEvents === null ||
        props.numUpcomingEvents === undefined
    ) {
        return null;
    }

    const { numUpcomingEvents } = props;

    //Remove when EB-173655 is resolved
    gettext('');

    return (
        <div
            className="creator-collection-card__upcoming-events"
            data-testid="collection-card--number-upcoming-events"
        >
            {props.numUpcomingEvents
                ? ngettext(
                      '%(numUpcomingEvents)s upcoming event',
                      '%(numUpcomingEvents)s upcoming events',
                      numUpcomingEvents,
                      { numUpcomingEvents },
                  )
                : gettext('No upcoming events')}
        </div>
    );
};

export const CollectionSummary: React.FunctionComponent<UpcomingEventsProps> = (
    props,
) => {
    if (
        !props.children &&
        (props.numUpcomingEvents === undefined ||
            props.numUpcomingEvents === null)
    ) {
        return null;
    }

    return (
        <div
            className="creator-collection-card__summary eds-text-bm"
            data-testid="collection-card--summary"
        >
            <UpcomingEvents numUpcomingEvents={props.numUpcomingEvents} />
            {props.children && (
                <div
                    className="creator-collection-card__summary--clamp"
                    data-testid="collection-card--summary-children"
                >
                    {props.children}
                </div>
            )}
        </div>
    );
};
