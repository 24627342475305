import classNames from 'classnames';
import React, { Suspense } from 'react';
import { ContentSkeleton } from './ContentSkeleton';
import './RelatedEventsSection.scss';

export type RelatedEventsSectionsProps = React.PropsWithChildren<{
    className?: string;
}>;

export function RelatedEventsSection({
    children,
    className,
    ...props
}: RelatedEventsSectionsProps) {
    const relatedEventsSectionClasses = classNames(
        className,
        'related-events-section',
    );
    return (
        <section className={relatedEventsSectionClasses} {...props}>
            <section>
                <Suspense fallback={<ContentSkeleton />}>{children}</Suspense>
            </section>
        </section>
    );
}
