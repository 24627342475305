import * as React from 'react';

const DefaultAbstractProfileTwoSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 40 40" fill="none" {...props}>
        <circle cx={20} cy={20} r={20} fill="#FAF9DC" />
        <path
            d="M37.203 30.208C33.716 36.07 27.317 40 20 40c-3.225 0-6.271-.763-8.969-2.119-1.8-5.19-2.632-9.653-1.544-11.154 3.162-4.364 9.826 7.094 10.03 7.446-.174-.624-7.38-26.6-4.278-28.047 3.973-1.855 13.447 17.447 13.447 17.447.844-.326.412-3.335-.028-6.398-.466-3.248-.941-6.558.088-6.797 1.323-.307 5.575 9.436 8.457 19.83z"
            fill="#A9A960"
        />
    </svg>
);

DefaultAbstractProfileTwoSvg.displayName = 'DefaultAbstractProfileTwoSvg';
export default DefaultAbstractProfileTwoSvg;
