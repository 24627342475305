import React from 'react';

export const Fire = (props: React.SVGProps<SVGSVGElement>) => {
    const randomId = Math.round(Math.random() * 100000);

    return (
        <svg viewBox="0 0 16 22" fill="none" {...props}>
            <path
                d="M.68 10.024a7.987 7.987 0 011.884-2.649l.661-.614a.183.183 0 01.295.076l.296.858c.184.538.523 1.087 1.002 1.628a.141.141 0 00.093.046.125.125 0 00.098-.035.136.136 0 00.046-.11c-.085-1.384.325-2.946 1.22-4.645.74-1.411 1.77-2.513 3.057-3.28l.938-.56c.123-.073.28.024.273.168l-.05 1.104c-.034.754.053 1.421.257 1.975.25.678.61 1.308 1.068 1.874.32.393.682.749 1.08 1.06a8.088 8.088 0 012.28 2.794 8.073 8.073 0 01.192 6.674 8.038 8.038 0 01-4.259 4.268 7.917 7.917 0 01-3.111.63 7.955 7.955 0 01-5.655-2.346A7.994 7.994 0 010 13.264c0-1.121.227-2.211.68-3.239z"
                fill={`url(#linear_gradient_id_${randomId})`}
            />
            <defs>
                <linearGradient
                    id={`linear_gradient_id_${randomId}`}
                    x1={8}
                    y1={0.714}
                    x2={8}
                    y2={21.286}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF1D1D" />
                    <stop offset={1} stopColor="#FFC267" />
                </linearGradient>
            </defs>
        </svg>
    );
};
