import { Avatar, AvatarSize } from '@eventbrite/eds-avatar';
import { User } from '@eventbrite/eds-iconography';
import { GenericLazyString } from '@eventbrite/i18n';
import React from 'react';
import './OrganizerInfo.scss';

export interface OrganizerInfoType {
    name: string | GenericLazyString;
    imageUrl?: string;
}

interface OrganizerInfoProps {
    organizer?: OrganizerInfoType;
}

export const OrganizerInfo: React.FunctionComponent<OrganizerInfoProps> = (
    props,
) => {
    const { organizer } = props;

    if (!props.organizer) {
        return null;
    }

    const organizerAvatarProps = organizer?.imageUrl
        ? { imageUrl: organizer?.imageUrl }
        : { iconType: <User /> };

    return (
        <div
            className="creator-collection-card__organizer-info"
            data-testid="collection-card--organizer-info"
        >
            <Avatar size={AvatarSize.xsmall} {...organizerAvatarProps} />
            <span className="organizer-collection-card__organizer-info__name eds-text-bm">
                {organizer?.name}
            </span>
        </div>
    );
};
