import * as React from 'react';

const MoneyChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
            d="M13.49 5.952c1.416.335 2.603 1.168 3.217 2.467l.207.437-2.558 1.238-.276-.527c-.379-.721-1.063-1.154-2.121-1.154-.973 0-1.554.51-1.554 1.065 0 .212.054.35.125.45.074.104.19.2.373.292.39.197.958.315 1.695.45l.002.001c.88.164 1.974.4 2.846.94.92.57 1.554 1.452 1.554 2.795 0 1.952-1.6 3.37-3.721 3.74V20h-2.958v-2c-1.405-.373-2.53-1.214-3.11-2.453L7 15.099l2.605-1.189.259.539c.343.715 1.088 1.138 2.289 1.138.59 0 1.057-.131 1.36-.332.286-.188.459-.456.459-.832 0-.227-.055-.37-.123-.47a.867.867 0 00-.343-.273c-.37-.185-.906-.286-1.635-.416h-.005c-.887-.165-1.998-.4-2.885-.938-.934-.567-1.587-1.45-1.587-2.798 0-1.716 1.321-3.077 3.139-3.563V4h2.958v1.952z"
            fill="#3659E3"
        />
    </svg>
);

MoneyChunkySvg.displayName = 'MoneyChunkySvg';
export default MoneyChunkySvg;
