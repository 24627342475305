import { VIDEO_STATES, type VideoMetrics } from './types';

export class VideoStateMetric {
    elapsedTime: number;
    count: number;

    constructor(elapsedTime = 0, count = 0) {
        this.elapsedTime = elapsedTime;
        this.count = count;
    }

    incrementCount() {
        this.count++;
        return this;
    }

    incrementTime(time: number) {
        this.elapsedTime += time;
        return this;
    }
}

export const getInitialStateMetrics = (): VideoMetrics => ({
    [VIDEO_STATES.UNINITIALIZED]: new VideoStateMetric(),
    [VIDEO_STATES.LOADING]: new VideoStateMetric(),
    [VIDEO_STATES.READY]: new VideoStateMetric(),
    [VIDEO_STATES.PLAYING]: new VideoStateMetric(),
    [VIDEO_STATES.PAUSED]: new VideoStateMetric(),
    [VIDEO_STATES.ENDED]: new VideoStateMetric(),
    [VIDEO_STATES.SEEKING]: new VideoStateMetric(),
    [VIDEO_STATES.STALLED]: new VideoStateMetric(),
    [VIDEO_STATES.ERROR]: new VideoStateMetric(),
});
