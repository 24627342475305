import { useContext } from 'react';
import { ErrorContext, ErrorContextDispatch } from './context';
import { ErrorType, type ErrorState } from './types';

export const useErrorContext = () => {
    return useContext(ErrorContext);
};

export const useErrorDispatch = () => {
    const dispatch = useContext(ErrorContextDispatch);
    return {
        clearError: () => dispatch({ action: 'clear' }),
        defaultError: () => dispatch({ action: ErrorType.default }),
        customError: (error: ErrorState) =>
            dispatch({ action: ErrorType.custom, error }),
    };
};
