import { Button } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { trackEvent } from '../analytics/analytics';
import {
    LISTING_AMPLITUDE_TRACKING_CATEGORY,
    LISTING_CLICK_PROMOTE,
    LISTING_CLICK_PROMOTE_ACTION,
    LISTING_SOURCE,
} from '../constants';

export const ListingTittle = gettext(
    'Put your event in front of more people',
).toString();
export const ListingDescription = gettext(
    'Creators sell an average of 63% more tickets after using Boost to promote their events.',
).toString();

const onPromoteClick = (promoteUrl: String) => {
    trackEvent({
        category: LISTING_AMPLITUDE_TRACKING_CATEGORY,
        action: LISTING_CLICK_PROMOTE_ACTION,
        source: LISTING_SOURCE,
    });
    window.open(promoteUrl.toString(), '_self')?.focus();
};

export interface Buttons {
    promoteUrl: String;
}

export const ListingButtons = (props: Buttons) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-evenly',
            }}
        >
            <Button
                style="eds-text-bm eds-text-weight--heavy marketing-hub-trial-banner-listing-button"
                onClick={() => onPromoteClick(props.promoteUrl)}
                data-heap-id={LISTING_CLICK_PROMOTE}
            >
                {gettext('Promote this event')}
            </Button>
        </div>
    );
};

export const ListingBannerIcon = (
    <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="26" cy="26" r="25.5" fill="white" stroke="#DBDAE3" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.9993 12L16 30.2084H26V40L36 21.8084L25.9993 21.8V12Z"
            fill="#3659E3"
        />
    </svg>
);
