import { EbAdsListingBanner } from '@eventbrite/footer-banner';
import React, { useContext, useEffect, useState } from 'react';
import {
    EventBasicInformation,
    EventBasicInformationContext,
} from '../../contexts';
import { getEventEBAdsEligibilityStatus } from './getEventEBAdsEligibilityStatus';

type EventManagerBannerProps = {
    canManage: boolean;
};

export const EventManagerBanner = ({ canManage }: EventManagerBannerProps) => {
    const { id: eventId } = useContext(
        EventBasicInformationContext,
    ) as EventBasicInformation;

    const [isServerSideRender, setIsServerSideRender] = useState(true);
    useEffect(() => {
        setIsServerSideRender(false);
    }, []);

    const [eventIsEbAdsEligible, setEventIsEbAdsEligible] = useState(false);
    useEffect(() => {
        const getAndSetEventEligibility = async () => {
            const eligibility = await getEventEBAdsEligibilityStatus(eventId);
            setEventIsEbAdsEligible(eligibility);
        };

        if (canManage) {
            getAndSetEventEligibility();
        }
    }, [canManage, eventId]);

    // This avoids a flicker in banners during hydration
    if (isServerSideRender) {
        return <></>;
    }

    if (canManage && eventIsEbAdsEligible) {
        return <EbAdsListingBanner eventId={eventId} />;
    } else {
        return <></>;
    }
};
