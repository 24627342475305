import React from 'react';

import { truncateString } from '@eventbrite/string-utils';

import { EVENT_TITLE_MAX_LENGTH } from './constants';

const FormattedEventName = ({ eventName }) => {
    return (
        <div data-spec="event-card__formatted-name">
            <div className="eds-is-hidden-accessible">{eventName}</div>

            <div
                className="eds-event-card__formatted-name--is-clamped eds-event-card__formatted-name--is-clamped-three eds-text-weight--heavy"
                aria-hidden="true"
                role="presentation"
                data-spec="event-card__formatted-name--content"
            >
                {truncateString(eventName, EVENT_TITLE_MAX_LENGTH)}
            </div>
        </div>
    );
};

export default FormattedEventName;
