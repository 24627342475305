import React from 'react';
import { Organizer } from './types';

type SettingsContext = {
    featureFlags: {
        launchLinkToCreatorCollectionsOnListing?: boolean;
    };
};
export const SettingsContext = React.createContext<Partial<SettingsContext>>(
    {},
);

type UserContext = {
    id: number;
    isAuthenticated: boolean;
    email: string;
};
export const UserContext = React.createContext<Partial<UserContext>>({});

export const OrganizerContext = React.createContext<Organizer>({
    id: '',
    name: '',
    profilePicture: '',
    url: '',
    nameTruncated: '',
    displayOrganizationName: '',
});
