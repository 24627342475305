import ClipboardJS from 'clipboard';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { renderIconButton } from './constants';

const COPY_TIMEOUT = 1000;

export default class CopyButton extends PureComponent {
    static propTypes = {
        /**
         * The text to copy
         */
        value: PropTypes.string.isRequired,
        /**
         * Callback function called if the user clicks on the copy button
         */
        onCopyClick: PropTypes.func,
        /**
         * Render function for the copy button contents (defaults to an Icon Button with copy icon)
         */
        render: PropTypes.func,
    };

    static defaultProps = {
        render: renderIconButton,
    };

    state = {
        hasCopied: false,
    };

    componentDidMount() {
        const { value } = this.props;

        this._createClipboard(value);
        this._isMounted = true;
    }

    UNSAFE_componentWillReceiveProps({ value }) {
        this._createClipboard(value);
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this._clipboard) {
            this._clipboard.destroy();
        }
    }

    _createClipboard = (value) => {
        // by using Clipboard library we have to pass a selector or DOM node
        // that is going to be used as trigger to listen the click event and
        // execute the 'text' callback in where we are going to pass the text
        // value passed in on the component's props
        if (this._componentNode) {
            this._clipboard = new ClipboardJS(this._componentNode, {
                container: this._componentNode,
                text: () => value,
            });
            this._clipboard.on(
                'success',
                this._handleCopyToClipboardSuccess.bind(this),
            );
        }
    };

    _handleCopyToClipboardSuccess() {
        const { onCopyClick } = this.props;

        this.setState({
            hasCopied: true,
        });

        setTimeout(() => {
            if (this._isMounted) {
                this.setState({
                    hasCopied: false,
                });
            }
        }, COPY_TIMEOUT);

        if (onCopyClick) {
            onCopyClick();
        }
    }

    _setRef = (componentNode) => {
        if (componentNode) {
            this._componentNode = componentNode;
        }
    };

    render() {
        const { render } = this.props;
        const { hasCopied } = this.state;

        return (
            <span className="eds-copy-button" ref={this._setRef}>
                {render(hasCopied)}
            </span>
        );
    }
}
