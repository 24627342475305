import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import React, { useEffect } from 'react';
import { Collection, GACodes } from '../../types';
import { MANY_COLLECTIONS_LIMIT } from './constants';
import './CreatorCollectionsLink.scss';

export interface Props {
    count: number;
    collection?: Collection[];
    viewMoreCollections?: (isOpen: boolean) => void;
}

export const CreatorCollectionsLink: React.FC<Props> = ({
    collection = [],
    count,
    viewMoreCollections,
}) => {
    const singleCollection = collection[0];
    const countToShow = Math.min(count, MANY_COLLECTIONS_LIMIT);

    const _trackCollectionLink = (action: string) => {
        trackAnalyticsEvent({
            category: GACodes.GA_CATEGORY_CREATOR_COLLECTIONS_LISTING,
            action,
            label: GACodes.GA_LABEL_EVENT_DETAILS,
        });
    };

    useEffect(() => {
        const link = document.getElementById('link-to-creator-collections');
        const _handleSingleCollectionClick = () => {
            _trackCollectionLink(GACodes.GA_ACTION_COLLECTION_PAGE_LINK_CLICK);
        };

        link?.addEventListener('click', _handleSingleCollectionClick);

        return () => {
            link?.removeEventListener('click', _handleSingleCollectionClick);
        };
    }, [singleCollection]);

    const _handleMultipleCollectionClick = () => {
        viewMoreCollections && viewMoreCollections(true);

        _trackCollectionLink(GACodes.GA_ACTION_COLLECTIONS_MODAL_LINK_CLICK);
    };

    if (count === 0) {
        return <></>;
    }

    if (count === 1) {
        const { name, url } = singleCollection;
        const gettextWithMarkup = {
            __html: gettext(
                'Part of the <a href=%(url)s target="_blank" rel="noopener noreferrer"}>%(name)s</a> collection',
                { url, name },
            ).toString(),
        };

        return (
            <div
                id="link-to-creator-collections"
                data-testid="link-to-creator-collections"
                className="eds-l-mar-top-1"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={gettextWithMarkup}
            />
        );
    }

    return (
        <div
            className="eds-text-color--ui-600 eds-text-bm eds-l-mar-top-2"
            data-testid="link-to-creator-collections"
        >
            <>
                {gettext('Featured in ')}
                <button
                    className="link-style"
                    onClick={_handleMultipleCollectionClick}
                >
                    {countToShow}
                    {count > MANY_COLLECTIONS_LIMIT ? '+' : ''}
                    {gettext(' collections')}
                </button>
            </>
        </div>
    );
};
