import {
    VIDEO_STATES,
    type VideoMetaData,
    type VideoMetrics,
} from '../../../_shared/Video/types';

type Percentage = number;

export const getStalledRatioOverPlaybackTime = (
    metrics: VideoMetrics,
): Percentage => {
    return (
        metrics[VIDEO_STATES.STALLED].elapsedTime /
        (metrics[VIDEO_STATES.PLAYING].elapsedTime || 0.01)
    );
};

export const getStalledRatioOverDuration = (
    metrics: VideoMetrics,
    metadata: VideoMetaData,
): Percentage => {
    return metrics[VIDEO_STATES.STALLED].elapsedTime / metadata.duration;
};

export const getTotalStalledTime = (metrics: VideoMetrics): number => {
    return metrics[VIDEO_STATES.STALLED].elapsedTime;
};

export const getTimesStalled = (metrics: VideoMetrics): number => {
    return metrics[VIDEO_STATES.STALLED].count;
};

export const getPlaybackTime = (metrics: VideoMetrics): number => {
    return metrics[VIDEO_STATES.PLAYING].elapsedTime;
};
