import { EventInteraction, useTracking } from '@eventbrite/ads';
import { track } from '@eventbrite/datalayer-library';
import { gettext } from '@eventbrite/i18n';
import React, { useContext, useEffect } from 'react';
import { LISTING_HEAP_PAGE_AREA_IDS } from '../../../../constants';
import { TrackingContext } from '../../../../contexts';
import { FeatureFlags } from '../../../../types';
import { FollowButton } from '../../FollowButton';
import { useFollowCount } from '../../FollowButton/FollowButton';
import {
    OrganizerStats,
    StyleTypes,
} from '../../OrganizerStats/OrganizerStats';
import type { OrganiserListingPageProps } from '../OrganiserListingPage/OrganiserListingPage';
import {
    aggregateContext,
    LISTINGS_EXPIRED_INTERFACE,
    LISTINGS_LIVE_INTERFACE,
    SIMPLE_ORGANIZER_SUBINTERFACE,
} from '../utils';
import { LSClickableAvatarWithBadge } from './ListingsAvatarWithBadge';
import './OrganizerTrustSignalInfo.scss';

interface OrganizerTrustSignalProps {
    style?: StyleTypes;
    signalType?: string;
    featureFlags?: FeatureFlags;
    showBadge?: boolean;
    isMobile?: boolean;
}

export type TOrganizerTrustSignalInfoProps = OrganizerTrustSignalProps &
    OrganiserListingPageProps;

export const OrganizerTrustSignalInfo = ({
    organizerName,
    organizerId,
    pageArea,
    style = StyleTypes.CONDENSED_FULL_WIDTH,
    trustSignal,
    organizerAvatar,
    signalType,
    featureFlags,
    showBadge,
    isMobile,
}: TOrganizerTrustSignalInfoProps) => {
    const followersCount = useFollowCount();

    const context = useContext(TrackingContext);
    const getTrackingContext = ({ id, event }: EventInteraction) =>
        aggregateContext({
            id,
            event: { ...event, id },
            context,
            listingsType: context.event?.isExpiredEvent
                ? LISTINGS_EXPIRED_INTERFACE
                : LISTINGS_LIVE_INTERFACE,
            subInterface: SIMPLE_ORGANIZER_SUBINTERFACE,
        });
    const { ref } = useTracking<HTMLDivElement>(getTrackingContext);

    useEffect(() => {
        track({
            eventName: 'ViewTrustSignal',
            eventData: {
                signalType: signalType,
            },
        });
    }, [signalType]);

    const renderFollowButtonSection = (dataTestId: string) => (
        <div
            className="organizer-listing-info-variant-b_follow-button"
            data-testid={dataTestId}
        >
            <FollowButton
                pageArea={
                    pageArea || LISTING_HEAP_PAGE_AREA_IDS.SIMPLIFIED_ORGANIZER
                }
            />
        </div>
    );

    const renderFollowerCount = (className: string, dataTestId: string) => (
        <div className={className} data-testid={dataTestId}>
            {renderOrganiserStat()}
        </div>
    );

    const renderTrustSignal = () => (
        <div className="organizer-listing-info-variant-b_total-events organizer-listing-info-variant-b_total-events-variantB">
            {trustSignal} 📈
        </div>
    );

    const renderOrganiserStat = () => (
        <OrganizerStats
            organizer={{ id: organizerId }}
            followersCount={followersCount || null}
            style={style}
        />
    );
    return (
        <section
            className="organizer-listing-info-variant-b"
            aria-label="Organizer profile"
            ref={ref}
        >
            <div
                className="organizer-listing-info-variant-b__profile
                    reverse-image"
            >
                <div className="organizer-listing-info-avatar-with-follower">
                    <LSClickableAvatarWithBadge
                        organizerId={organizerId}
                        organizerAvatar={organizerAvatar}
                        featureFlags={featureFlags}
                        showBadge={showBadge}
                        isMobile={isMobile}
                    />

                    {trustSignal &&
                        renderFollowerCount(
                            'organizer-listing-info-variant-b__followers-mobile-version',
                            'followers-count-mobile',
                        )}
                </div>

                <div
                    className="organizer-listing-info-variant-b__details"
                    data-testid="organizer-info-details"
                >
                    <div className="organizer-listing-info-variant-b__card-info">
                        {organizerName && (
                            <span className="organizer-listing-info-variant-b__name-by include-center">
                                {/* Translators: Word "By" followed by the organizer's name, ex: By My Cool Organizer Name */}
                                {gettext('By')}{' '}
                                <strong className="organizer-listing-info-variant-b__name-link">
                                    {organizerName}
                                </strong>
                            </span>
                        )}

                        {renderFollowerCount(
                            `organizer-listing-info-variant-b__desktop-version ${
                                followersCount && followersCount > 9
                                    ? 'organizer-listing-info-variant-b__dot'
                                    : null
                            }`,
                            'followers-count-desktop',
                        )}
                    </div>

                    <>
                        {trustSignal
                            ? renderTrustSignal()
                            : renderFollowerCount(
                                  'organizer-listing-info-variant-b__followers-mobile-version-inplaceof-signal',
                                  'followers-count-mobile',
                              )}
                        <div className="organizer-listing-info-variant-b__follow-button-mobile">
                            {renderFollowButtonSection('follow-button-mobile')}
                        </div>
                    </>
                </div>
            </div>
            <div className="organizer-listing-info-variant-b__follow-button-desktop">
                {renderFollowButtonSection('follow-button-desktop')}
            </div>
        </section>
    );
};
