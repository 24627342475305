import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { useTicketContext } from '../context';
import { formatDate } from './utils';

export const SalesEndStatus: React.FC = () => {
    const { displayFlags, onSaleStatus } = useTicketContext();
    const { showSalesEndDate } = displayFlags;
    const formattedDate = formatDate(
        onSaleStatus.endSalesWithTz.utc,
        onSaleStatus.endSalesWithTz.timezone,
    );

    if (!showSalesEndDate) {
        return null;
    }

    return (
        <Typography
            as="span"
            variant="body-sm"
            color="ui-800"
            data-testid="sales-end-message"
        >
            {gettext('Sales end on %(formattedDate)s', { formattedDate })}
        </Typography>
    );
};
