import { useCallback, useState } from 'react';
import type { TicketClass } from './types';

type TicketQuantity = Record<string, number>;

type onUpdateType = (newQuantities: TicketQuantity) => void;
type setTicketTotalsType = (newTotal: number) => void;

export const useTicketQuantityStore = (
    ticketsClasses: Array<TicketClass>,
    onUpdate: onUpdateType,
    setTicketTotals?: setTicketTotalsType,
) => {
    const [tickets, setTickets] = useState<TicketQuantity>(
        getDefaultTicketQuantity(ticketsClasses),
    );

    const changeQuantity = useCallback(
        (ticketId: string, quantity: number) => {
            setTickets((prevTickets) => {
                const newTicketQuantities = insertQuantity(prevTickets, {
                    ticketId,
                    quantity,
                });
                onUpdate(newTicketQuantities);
                setTicketTotals &&
                    setTicketTotals(getTotalTickets(newTicketQuantities));
                return newTicketQuantities;
            });
        },
        [setTickets, onUpdate, setTicketTotals],
    );

    const refreshQuantities = useCallback(() => {
        setTickets((prevTickets) => {
            onUpdate(prevTickets);
            return prevTickets;
        });
    }, [onUpdate, setTickets]);

    const resetQuantities = useCallback(() => {
        setTickets(getDefaultTicketQuantity(ticketsClasses));
    }, [setTickets, ticketsClasses]);

    return {
        changeQuantity,
        refreshQuantities,
        resetQuantities,
        ticketQuantities: tickets,
    };
};

const getTotalTickets = (ticketQuantities: TicketQuantity) => {
    return Object.values(ticketQuantities).reduce(
        (acc, quantity) => acc + quantity,
        0,
    );
};

const insertQuantity = (
    currentQuantity: TicketQuantity,
    {
        ticketId,
        quantity,
    }: {
        ticketId: string;
        quantity: number;
    },
) => {
    return {
        ...currentQuantity,
        [ticketId]: quantity,
    };
};

const getDefaultTicketQuantity = (
    tickets: Array<TicketClass>,
): TicketQuantity =>
    tickets.reduce<TicketQuantity>((acc, ticket) => {
        acc[ticket.id] = 0;
        return acc;
    }, {});
