import { HAS_WINDOW } from '@eventbrite/feature-detection';

const DEFAULT_WINDOW_SETTINGS = {
    name: 'popup',
    width: 550,
    height: 450,
};

const _buildWindowSettings = (width, height) =>
    `toolbar=yes,width=${width},height=${height}`;

export const openUrl = (url, settings = DEFAULT_WINDOW_SETTINGS) => {
    const { name, width, height } = settings;
    const usePopup = !settings.noPopup;

    if (!HAS_WINDOW) {
        return;
    }

    window.open(
        url,
        name,
        usePopup ? _buildWindowSettings(width, height) : null,
    );
};
