import { RefObject, useCallback, useEffect, useState } from 'react';

export const useIntersecting = ({
    domNode,
    ref,
    callback,
    rootMargin = '400px',
    ratio = 0,
    stayActive = false,
}: {
    domNode?: HTMLElement | null;
    ref?: RefObject<HTMLElement>;
    rootMargin?: string;
    callback?: () => HTMLElement | null;
    ratio?: number;
    stayActive?: boolean;
}) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const element = callback?.() || domNode || ref?.current;

    const handleIntersection: IntersectionObserverCallback = useCallback(
        (entries, observer) => {
            const [entry] = entries;
            const isEntryIntersecting =
                entry.intersectionRatio > (ratio == 1.0 ? 0.99 : ratio);

            setIsIntersecting(isEntryIntersecting);

            if (!stayActive && isEntryIntersecting) {
                observer.disconnect();
            }
        },
        [ratio, stayActive],
    );

    useEffect(() => {
        if (!element) return;

        const observer = new IntersectionObserver(handleIntersection, {
            rootMargin,
            threshold: ratio,
        });

        observer.observe(element);

        return () => observer.disconnect();
    }, [
        domNode,
        rootMargin,
        ratio,
        stayActive,
        callback,
        handleIntersection,
        element,
    ]);

    return [isIntersecting];
};
