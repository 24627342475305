import { EventInteraction, useTracking } from '@eventbrite/ads';
import { Avatar } from '@eventbrite/eds-avatar';
import { gettext } from '@eventbrite/i18n';
import React, { useContext } from 'react';
import { LISTING_HEAP_PAGE_AREA_IDS } from '../../../constants';
import { TrackingContext } from '../../../contexts';
import { FollowButton, useFollowCount } from '../FollowButton/FollowButton';
import { OrganizerStats, StyleTypes } from '../OrganizerStats/OrganizerStats';
import './SimplifiedOrganizerInfo.scss';
import {
    aggregateContext,
    LISTINGS_EXPIRED_INTERFACE,
    LISTINGS_LIVE_INTERFACE,
    MINIMAL_ORGANIZER_SUBINTERFACE,
} from './utils';

export interface SimplifiedOrganizerInfoProps {
    organizerName: string;
    organizerAvatar?: string;
    organizerId: string;
    style?: StyleTypes;
    pageArea?: string;
}

export const SimplifiedOrganizerInfo: React.FunctionComponent<
    SimplifiedOrganizerInfoProps
> = ({
    organizerName,
    organizerAvatar,
    organizerId,
    pageArea,
    style = StyleTypes.CONDENSED_FULL_WIDTH,
}) => {
    const followersCount = useFollowCount();

    const context = useContext(TrackingContext);
    const getTrackingContext = ({ id, event }: EventInteraction) =>
        aggregateContext({
            id,
            event: { ...event, id },
            context,
            listingsType: context.event?.isExpiredEvent
                ? LISTINGS_EXPIRED_INTERFACE
                : LISTINGS_LIVE_INTERFACE,
            subInterface: MINIMAL_ORGANIZER_SUBINTERFACE,
        });
    const { ref } = useTracking<HTMLDivElement>(getTrackingContext);

    return (
        <section
            className="simplified-organizer-info"
            data-testid="simplified-organizer-info"
            aria-label="Organizer profile"
            ref={ref}
        >
            <div className="simplified-organizer-info__profile">
                {organizerAvatar && (
                    <div className="simplified-organizer-info__avatar">
                        <Avatar imageUrl={organizerAvatar} />
                    </div>
                )}
                <div
                    className="simplified-organizer-info__details"
                    data-testid="organizer-info-details"
                >
                    {organizerName && (
                        <span className="simplified-organizer-info__name-by">
                            {/* Translators: Word "By" followed by the organizer's name, ex: By My Cool Organizer Name */}
                            {gettext('By')}{' '}
                            <strong className="simplified-organizer-info__name-link">
                                {organizerName}
                            </strong>
                        </span>
                    )}
                    <OrganizerStats
                        organizer={{ id: organizerId }}
                        followersCount={followersCount || null}
                        style={style}
                    />
                </div>
            </div>
            <div className="simplified-organizer-info__follow">
                <FollowButton
                    pageArea={
                        pageArea ||
                        LISTING_HEAP_PAGE_AREA_IDS.SIMPLIFIED_ORGANIZER
                    }
                />
            </div>
        </section>
    );
};
