import React from 'react';
import { OrganizerFollowersSignal } from './OrganizerFollowersSignal';
import { CollectionMembershipSignal } from './CollectionMembershipSignal';

const SIGNAL_MAP = {
    follow: OrganizerFollowersSignal,
    collection: CollectionMembershipSignal,
};

/**
 * @name SignalContent
 * @param {*} signal
 * @param {*} organizerName
 * @returns
 */
export const SignalContent = (props) => {
    const Signal = SIGNAL_MAP[props.signal.type];

    if (!Signal) {
        return null;
    }

    return <Signal {...props} />;
};
