import { getWindowObject } from '@eventbrite/feature-detection';

export const USE_LOCAL_TD = false; // process.env.NODE_ENV !== 'production';

const TD_UI_VERSION =
    getWindowObject('__SERVER_DATA__')?.request?.params?.uiVersion;

const eventbriteHostname =
    getWindowObject('location')?.hostname || 'www.eventbrite.com';

const boostSubdomain = eventbriteHostname
    .replace('eb.toneden.dev', 'boost.eventbrite.dev:4000')
    .replace('eb.toneden.io', 'boost.eventbrite.com')
    .replace('www', 'boost')
    .replace('evbdev', 'eventbrite')
    .replace('evbqa', 'eventbrite')
    .replace('evbstage', 'eventbrite')
    .replace('dev.', 'boost.');

export const TD_SERVER_URL = USE_LOCAL_TD
    ? 'https://boost.eventbrite.dev:4000'
    : `https://${boostSubdomain}`;
export const TD_API_URL = USE_LOCAL_TD
    ? 'https://boost.eventbrite.dev:4000/api/v1/'
    : `https://${boostSubdomain}/api/v1/`;
export const TD_CSS_URL = USE_LOCAL_TD
    ? 'https://www.toneden.dev:8081/stylesheets/build'
    : `https://st.toneden.io/${
          TD_UI_VERSION ? TD_UI_VERSION : 'production'
      }/stylesheets`;
export const TD_JS_URL = USE_LOCAL_TD
    ? 'https://www.toneden.dev:8081/javascripts/build'
    : `https://st.toneden.io/${
          TD_UI_VERSION ? TD_UI_VERSION : 'production'
      }/javascripts`;
