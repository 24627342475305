import type { TicketCardType } from '../types';
import type { SetExpandedAction, TicketAction } from './types';
import { TicketActionType } from './types';

export function ticketReducer(
    ticket: TicketCardType,
    action: TicketAction,
): TicketCardType {
    const reducer = reducersByType[action.type];

    if (reducer) {
        return reducer(ticket, action as any);
    }

    throw Error('Unknown ticket action');
}

const reducersByType = {
    [TicketActionType.setExpanded]: (
        ticket: TicketCardType,
        { payload: { expanded } }: SetExpandedAction,
    ): TicketCardType => ({
        ...ticket,
        expanded,
    }),
    [TicketActionType.updateQuantity]: (
        ticket: TicketCardType,
        { payload: { quantity } }: { payload: { quantity: number } },
    ): TicketCardType => ({
        ...ticket,
        quantity,
    }),
};
