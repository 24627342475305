import React from 'react';
import { ControlledProps, OverlayProps } from '../../types';
import { dynaMount } from '../utils/mountComponent';
import Overlay from './Overlay';

const getOverlayProps = (props: ControlledProps): OverlayProps | any => {
    const { overlayOptions } = props;

    return { options: overlayOptions };
};

const OverlayRoot = (
    props: ControlledProps,
): JSX.Element | React.ReactPortal => {
    const overlayProps = getOverlayProps(props);

    return dynaMount(
        {
            Component: Overlay,
            containerId: 'listings-root__overlay',
        },
        overlayProps,
    );
};

export default OverlayRoot;
