import { gettext } from '@eventbrite/i18n';

export const testIds = {
    modalContainer: 'modal-container',
    organizerAvatar: 'organizer-avatar',
    gotItButton: 'got-it-button',
    modalContent: 'modal-content',
    organizerName: 'organizer-name',
    defaultAvatar: 'default-avatar',
};

export const textContents = {
    headingString: gettext('This organizer is verified!'),
    contentString: gettext(
        "We've met these event organizers and confirmed their identity. They bring you unique things to do on Eventbrite.",
    ),
    buttonString: gettext('Got it'),
};
