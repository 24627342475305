import { gettext } from '@eventbrite/i18n';
import React, { useState } from 'react';
import { LISTING_HEAP_IDS } from '../../../../constants';
import { Modal } from '../../../_shared/Modal';

import { Play } from '../../../_shared/Icons';

import './FeaturedHeroVideo.scss';

interface FeaturedHeroVideo {
    alt?: string;
    url: string;
}

export const FeaturedHeroVideo: React.FC<FeaturedHeroVideo> = ({
    alt,
    url,
    children,
}) => {
    const [shouldShowVideo, setShouldShowVideo] = useState(false);

    return (
        <>
            <div className="video-wrapper">
                <div className="video-overlay">
                    <button
                        data-heap-id={LISTING_HEAP_IDS.HERO_VIDEO}
                        className="video-play-button"
                        aria-label="Play"
                        onClick={() => setShouldShowVideo(true)}
                    >
                        <div className="video-play-button__icon">
                            <Play />
                        </div>
                    </button>
                </div>
                {children}
            </div>
            {shouldShowVideo && (
                <Modal
                    visible={true}
                    type="simple"
                    noMinHeight={true}
                    noPadding={true}
                    onClose={() => setShouldShowVideo(false)}
                    __containerClassName="eds-modal--fit-content"
                >
                    <div className="modal-video-wrapper">
                        <iframe
                            src={`${url.split('?')[0]}?autoplay=1&mute=1`}
                            frameBorder="0"
                            allowFullScreen
                            allow="autoplay"
                            title={alt || gettext('Video content').toString()}
                            loading="lazy"
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};
