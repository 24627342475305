import { EventInteraction, useTracking } from '@eventbrite/ads';
import { FormattedEvent } from '@eventbrite/event-renderer';
import React, { useContext } from 'react';
import {
    EXPIRED_EVENTS_PLACEMENT_NAME,
    RELATED_EVENTS_PLACEMENT_NAME,
} from '../../constants';
import { TrackingContext } from '../../contexts';
import { aggregateContext } from '../RelatedEvents/tracking';

export type TrackableProps = React.PropsWithChildren<{
    events: FormattedEvent[];
    className?: string;
}>;

export function Trackable({ events, ...otherProps }: TrackableProps) {
    const hasPromotedEvents = events.find(({ isPromoted }) => isPromoted);
    const getTrackingContext = useExpiredEventsTrackingContextFn(events);
    const { ref } = useTracking<HTMLDivElement>(getTrackingContext);

    return <div ref={hasPromotedEvents ? ref : undefined} {...otherProps} />;
}

export function useExpiredEventsTrackingContextFn(events: FormattedEvent[]) {
    const context = useContext(TrackingContext);
    return ({ id, event }: EventInteraction) =>
        aggregateContext({
            id,
            context,
            events,
            event,
            page: { number: 0, size: 1 },
            interfaceName: RELATED_EVENTS_PLACEMENT_NAME,
            subInterfaceName: EXPIRED_EVENTS_PLACEMENT_NAME,
        });
}
