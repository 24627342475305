import { Icon } from '@eventbrite/eds-icon';
import { HelpChunky } from '@eventbrite/eds-iconography';
import { withTooltip } from '@eventbrite/eds-tooltip';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import './promotedLabel.scss';

export const PromotedLabel = () => {
    const promotedText = gettext('Promoted');
    const infoPromotedMessage = gettext(
        'Business owners and event organizers paid for these ads.',
    );
    const IconWithTooltip = withTooltip(Icon);
    return (
        <div className="eds-promoted-label--container">
            <span className="eds-text-bm--fixed">
                <span className="eds-text-bs eds-promoted-label--text eds-text-color--ui-600 eds-text-weight--heavy">
                    {promotedText}
                </span>
                <span style={{ paddingLeft: '5px' }}>
                    <IconWithTooltip
                        type={<HelpChunky fill="#6f7287" />}
                        size="xsmall"
                        tooltipId="tooltip-info"
                        tooltipStyle="phab"
                        tooltipText={infoPromotedMessage}
                        tooltipArrowLocation="left"
                        wrapperComponent="span"
                    />
                </span>
            </span>
        </div>
    );
};
