import React from 'react';
import { Host } from '../types';
import { HostName, HostPicture, HostWrapper } from './HostPreview.styles';

interface Props {
    host: Host;
}

export const HostPreview: React.FC<Props> = ({ host }) => {
    if (host.name) {
        return (
            <HostWrapper data-testid="host-preview__wrapper">
                {host.image?.url && (
                    <HostPicture
                        data-testid="host-preview__image"
                        src={host.image.url}
                        alt=""
                    />
                )}
                <HostName>{host.name}</HostName>
            </HostWrapper>
        );
    }
    return null;
};
