import * as React from 'react';

const PlaceholderSvg4Svg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 400 200" {...props}>
        <g>
            <path
                d="M0 100v100l86.85-.05c77.043-.044 86.85-.084 86.851-.35 0-.165.169-.75.374-1.3s.581-1.585.835-2.3l1.493-4.2c1.808-5.086 5.788-16.8 6.71-19.75.49-1.568 1.197-3.773 1.571-4.9a330.706 330.706 0 001.921-6.15 510.547 510.547 0 011.514-4.946c.15-.465.768-2.58 1.372-4.7a1313.8 1313.8 0 012.918-10.154 207.58 207.58 0 001.07-3.8c1.69-6.264 2.559-9.543 3.021-11.4.287-1.155.876-3.495 1.308-5.2s1.196-4.81 1.697-6.9l1.201-5c.373-1.546 1.856-8.127 2.096-9.3.101-.495.323-1.53.495-2.3 1.104-4.965 3.543-17.171 4.415-22.1a186.3 186.3 0 01.616-3.272c.372-1.857.738-1.182-3.214-5.929-3.307-3.972-8.574-10.734-10.814-13.882-.44-.619-1.415-1.956-2.167-2.971-5.515-7.446-14.154-21.545-18.961-30.946-3.401-6.65-7.88-16.535-8.066-17.799-.039-.267-9.218-.307-82.575-.351L0 0v100M348.94 3.05c-.118 3.115-.39 7.41-.762 12.05-.114 1.43-.291 3.68-.393 5s-.274 3.3-.382 4.4l-.512 5.2c-.469 4.777-1.12 10.407-1.907 16.5-.234 1.815-.474 3.941-.532 4.724l-.105 1.424 2.147 1.576c1.181.867 3.374 2.48 4.874 3.586 8.321 6.131 26.663 18.503 36.528 24.637 5.58 3.469 11.489 7.061 11.836 7.195.218.083.264-7.681.264-44.621V0h-50.94l-.116 3.05m-28.85 161.4c-.129.348-3.876 12.827-4.215 14.04-.153.544-.55 1.849-.883 2.9l-1.62 5.11c-.557 1.76-1.761 5.495-2.674 8.3-.914 2.805-1.64 5.123-1.614 5.151.025.027 17.049.027 37.829 0l37.783-.051-6.6-3.317c-3.63-1.824-7.339-3.736-8.243-4.25-.904-.513-1.68-.933-1.726-.933-.072 0-4.684-2.414-7.631-3.994a568.36 568.36 0 01-8.1-4.451l-5.531-3.111c-5.531-3.111-13.537-7.828-18.569-10.942-8.163-5.051-8.013-4.97-8.206-4.452"
                fill="#fbfcfc"
            />
            <path
                d="M165.09.37c.289 1.499 4.657 11.141 8.078 17.83 4.807 9.401 13.446 23.5 18.961 30.946a238.318 238.318 0 012.167 2.971c2.24 3.148 7.507 9.91 10.814 13.882 3.952 4.747 3.586 4.072 3.214 5.929a186.3 186.3 0 00-.616 3.272c-.872 4.929-3.311 17.135-4.415 22.1-.172.77-.394 1.805-.495 2.3-.24 1.173-1.723 7.754-2.096 9.3l-1.201 5c-.501 2.09-1.265 5.195-1.697 6.9s-1.021 4.045-1.308 5.2c-.462 1.857-1.331 5.136-3.021 11.4a207.58 207.58 0 01-1.07 3.8 1313.8 1313.8 0 00-2.918 10.154c-.604 2.12-1.222 4.235-1.372 4.7-.151.465-.832 2.691-1.514 4.946a330.706 330.706 0 01-1.921 6.15 363.444 363.444 0 00-1.571 4.9c-.836 2.676-5.275 15.78-6.013 17.75-.185.495-.683 1.89-1.106 3.1s-1.095 3.1-1.494 4.2-.757 2.203-.795 2.45l-.07.45h135.323l.795-2.25c.437-1.237 1.213-3.6 1.726-5.25s1.377-4.395 1.921-6.1 1.309-4.135 1.699-5.4c.391-1.265.92-2.975 1.177-3.8s1.222-4.054 2.145-7.175c.924-3.122 1.787-5.717 1.92-5.768.245-.094 1.22.475 7.959 4.645 5.032 3.114 13.038 7.831 18.569 10.942l5.531 3.111a568.36 568.36 0 008.1 4.451c2.947 1.58 7.559 3.994 7.631 3.994.046 0 .817.416 1.714.925s4.669 2.444 8.382 4.3l6.752 3.375h15.021l-.006-55.25-.006-55.25-2.944-1.755c-1.619-.965-4.159-2.512-5.644-3.438s-3.105-1.928-3.6-2.228c-7.365-4.454-28.209-18.513-36.428-24.569-1.5-1.106-3.693-2.719-4.874-3.586l-2.147-1.576.105-1.424c.058-.783.298-2.909.532-4.724.787-6.093 1.438-11.723 1.907-16.5l.512-5.2c.108-1.1.28-3.08.382-4.4s.279-3.57.393-5c.372-4.64.644-8.935.762-12.05l.116-3.05h-92.018c-89.407 0-92.017.01-91.948.37"
                fill="#ececf3"
            />
        </g>
    </svg>
);

PlaceholderSvg4Svg.displayName = 'PlaceholderSvg4Svg';
export default PlaceholderSvg4Svg;
