import type { GenericLazyString } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import './ButtonLink.scss';

export type ButtonLinkProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    heapId?: string;
    text: GenericLazyString;
    icon?: React.ReactNode;
};

export const ButtonLink = ({
    heapId,
    className,
    text,
    icon,
    ...props
}: ButtonLinkProps) => {
    return (
        <div className="button-link">
            <button
                className={`eds-btn eds-btn--link ${className || ''} ${
                    icon ? 'button-link--with-icon' : ''
                }`}
                data-heap-id={heapId}
                {...props}
            >
                <Typography variant="body-md-bold" color="blue-500">
                    {text}
                </Typography>
                {icon}
            </button>
        </div>
    );
};
