import { setLanguage } from '@eventbrite/i18n';
import React from 'react';
import BookmarkRoot from './components/Bookmark/BookmarkRoot';
import CreatorCollectionsRoot from './components/CreatorCollections/CreatorCollectionsRoot';
import { EnhancedListingComponents } from './components/EnhancedListingComponents/EnhancedListingComponents';
import { useCreatorCollections } from './components/hooks/useCreatorCollections';
import { useIsServerSideRendered } from './components/hooks/useIsServerSideRendered';
import { NotificationBar } from './components/NotificationBar/NotificationBar';
import { ReportThisEventRoot } from './components/ReportThisEvent/ReportThisEventRoot';
import ShareEventRoot from './components/ShareEvent/ShareEventRoot';
import { OrganizerContext, SettingsContext, UserContext } from './context';
import { NotificationsContextProvider } from './contexts';
import type { AppProps } from './types';

export const App: React.FC<AppProps> = (props: AppProps) => {
    const {
        env: {
            localeInfo: { locale },
        },
        event: { id: eventId, isExpiredEvent = false },
        settings: {
            featureFlags: {
                launchLinkToCreatorCollectionsOnListing = false,
            } = {},
        },
        components: { bookmark: { isBookmarkedEvent = false } = {} },
    } = props;

    setLanguage(locale);

    const modalContainer = React.useRef<HTMLDivElement | null>(null);
    const creatorCollections = useCreatorCollections(
        eventId,
        launchLinkToCreatorCollectionsOnListing,
        isExpiredEvent,
    );

    const { isServerSideRendered } = useIsServerSideRendered();

    if (isServerSideRendered) {
        return null;
    }

    return (
        <div data-spec="listing-page-app">
            <SettingsContext.Provider value={props.settings}>
                <UserContext.Provider value={props.user}>
                    <OrganizerContext.Provider value={props.organizer}>
                        <ShareEventRoot
                            {...props}
                            modalContainer={modalContainer}
                        />
                        <NotificationsContextProvider>
                            <NotificationBar />
                            <BookmarkRoot
                                isBookmarked={isBookmarkedEvent}
                                queryParams={location.search}
                            />
                        </NotificationsContextProvider>
                        <ReportThisEventRoot {...props} />
                        <CreatorCollectionsRoot {...props} />
                        <EnhancedListingComponents
                            {...props}
                            creatorCollections={creatorCollections}
                        />
                        <div
                            id="share-modal-wrapper"
                            ref={modalContainer}
                        ></div>
                        <div id="listings-root__overlay"></div>
                    </OrganizerContext.Provider>
                </UserContext.Provider>
            </SettingsContext.Provider>
        </div>
    );
};
