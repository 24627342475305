import React from 'react';
import { isListingTimedEntryExperimentEnabled } from '../../experimentation';

interface IListingTimedEntryExperimentContext {
    isListingTimedEntryExperimentExperimentActive: boolean;
}

interface IListingTimedEntryExperimentContextProviderProps {
    sessionsLength: number;
    shouldShowCompactCheckout: boolean;
}

const ListingTimedEntryExperimentContext =
    React.createContext<IListingTimedEntryExperimentContext>(
        {} as IListingTimedEntryExperimentContext,
    );

export const useListingTimedEntryExperimentContext = () => {
    const context = React.useContext(ListingTimedEntryExperimentContext);
    if (!context) {
        throw new Error(
            'useListingTimedEntryExperimentContext must be used within ListingTimedEntryExperimentContext',
        );
    }
    return context;
};

export const ListingTimedEntryExperimentContextProvider: React.FC<
    IListingTimedEntryExperimentContextProviderProps
> = ({ sessionsLength, shouldShowCompactCheckout, children }) => {
    const isExperimentActive = isListingTimedEntryExperimentEnabled(
        sessionsLength,
        shouldShowCompactCheckout,
    );

    return (
        <ListingTimedEntryExperimentContext.Provider
            value={{
                isListingTimedEntryExperimentExperimentActive:
                    isExperimentActive,
            }}
        >
            {children}
        </ListingTimedEntryExperimentContext.Provider>
    );
};
