import { ChoiceCard } from '@eventbrite/marmalade';
import React from 'react';
import styles from './SliderCard.module.scss';

interface Props {
    selected?: boolean;
    disabled?: boolean;
}

export const SliderCard: React.FC<Props> = ({
    children,
    selected,
    disabled,
}) => {
    // Noop function to give to the ChoiceCard card component the behavior of a clickable element
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const noop = () => undefined;
    return (
        <ChoiceCard
            className={styles.root}
            onClick={noop}
            selected={selected}
            disabled={disabled}
        >
            {children}
        </ChoiceCard>
    );
};
