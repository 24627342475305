import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { AnimationDirection } from './types';

interface ITab extends React.ComponentPropsWithoutRef<'div'> {
    active?: boolean;
}

interface IAgendaCollapsable extends React.ComponentPropsWithoutRef<'div'> {
    agendaIsCollapsed: boolean | null;
}

export const AgendaWrapper = styled.div<IAgendaCollapsable>`
    background: white;
    overflow: hidden;
    position: relative;

    ${({ agendaIsCollapsed }) =>
        agendaIsCollapsed &&
        css`
            max-height: 700px;
        `}
`;
AgendaWrapper.displayName = 'AgendaWrapper';

export const TabsArea = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
`;
TabsArea.displayName = 'TabsArea';

export const TabItem = styled.div<ITab>`
    box-sizing: border-box;
    padding: 8px 16px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin-right: 4px;
    background-color: transparent;
    color: #6f7287;
    transition: all ease-out 280ms;

    ${({ active }) =>
        active &&
        css`
            color: #fff;
            background-color: #3659e3;
            border-radius: 28px;
            transition: all ease-in 150ms;
        `}
`;
TabItem.displayName = 'TabItem';

const slideInFromTheLeft = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
`;

const slideInFromTheRight = keyframes`
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
`;

interface ISlotsWrapper extends React.ComponentPropsWithoutRef<'div'> {
    slotsAnimateFrom?: AnimationDirection;
}

export const SlotsWrapper = styled.div<ISlotsWrapper>`
    ${({ slotsAnimateFrom }) => {
        const animate = (keyframe: any) => css`
            animation: cubic-bezier(0.6, 0.7, 0.9, 1) 380ms 1 normal forwards
                ${keyframe};
        `;

        if (slotsAnimateFrom === AnimationDirection.right) {
            return animate(slideInFromTheRight);
        } else if (slotsAnimateFrom === AnimationDirection.left) {
            return animate(slideInFromTheLeft);
        }
    }}
`;
SlotsWrapper.displayName = 'SlotsWrapper';

export const ViewAllButtonArea = styled.div<IAgendaCollapsable>`
    height: 88px;
    width: 100%;
    padding-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    ${({ agendaIsCollapsed }) =>
        agendaIsCollapsed &&
        css`
            position: absolute;
            bottom: 0;
            background: linear-gradient(
                to top,
                white,
                white,
                white,
                rgba(255, 255, 255, 0.95),
                transparent
            );
            height: 200px;
        `}
`;
ViewAllButtonArea.displayName = 'ViewAllButtonArea';

export const ViewAllButton = styled.button`
    width: 163px;
    height: 44px;
    background: white;
    border: 2px solid #a9a8b3;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #1e0a3c;
`;
ViewAllButton.displayName = 'ViewAllButton';
