import * as Sentry from '@sentry/browser';
import {
    Dedupe as DedupeIntegration,
    ExtraErrorData as ExtraErrorDataIntegration,
} from '@sentry/integrations';
import { Event, Integration } from '@sentry/types';
import { buildServerErrorContext } from './buildServerErrorContext';

interface ErrorReportingContext {
    appName: string;
    appVersion: string;
    ignoreError?: (event: Event) => boolean;
    debug?: boolean;
    dsn?: string;
    integrations?: Integration[];
    environment: 'development' | 'qa' | 'production';
    sessionTrackingEnabled: boolean;
    shouldLogErrors: boolean;
    tracesSampleRate?: number;
    metaData: {
        tags: {
            [key: string]: string;
        };
        [key: string]: string | object;
    };
    user: object;
    beforeSend?: (event: Event) => Event;
    ignoreErrorPatterns?: string[];
}

const multiplier = Math.pow(10, 2);

function round(number: number) {
    return Math.round(number * multiplier) / multiplier;
}

function getLoadedResources() {
    try {
        return (
            performance.getEntriesByType(
                'resource',
            ) as PerformanceResourceTiming[]
        )
            .filter(function (resource) {
                return resource.initiatorType === 'script';
            })
            .map(function (resource) {
                return '(' + round(resource.duration) + 'ms) ' + resource.name;
            });
    } catch (ex) {
        // loaded resource is a nice to have, so if it fails just let it.
        return null;
    }
}

function addLoadedResources(event: Event) {
    // Get a list of loaded resources for the applications
    const loadedResources = getLoadedResources();
    // Grab the current application context from the event, falling back to an empty object
    const applicationContext = event.contexts?.application || {};
    // Create a merged event context that has the loaded resources in the application context
    const updatedEvent = {
        contexts: {
            ...(event.contexts || {}), // Add additional context objects
            ...{
                // Spread in our updated application context
                application: {
                    ...applicationContext,
                    ...loadedResources,
                },
            },
        },
    };
    return {
        ...event,
        ...updatedEvent,
    };
}

export function initializeErrorReporting(
    override?: Partial<ErrorReportingContext>,
    additionalTags = {},
    denyUrlsPatterns: string[] = [],
) {
    const errorContext = buildServerErrorContext(additionalTags);

    // Do not continue with initialization if we do not have an init context
    if (!errorContext && !override) return;

    // Do not continue with initialization if we already initialized Sentry
    if (Sentry.getCurrentHub().getClient()) {
        if (window.console) console.log('Sentry is already initialized.');
        return;
    }

    const errorContextObject = errorContext || {};
    const overrideObject = override || {};

    // Combine the errorContext with the override
    const context = {
        dsn: 'https://75bf6383c89a46629df1785ce77cf3c9@o375540.ingest.sentry.io/5970713',
        ...errorContextObject,
        ...overrideObject,
        ignoreErrors: [
            ...(overrideObject?.ignoreErrorPatterns || []),
            'TypeError: Load failed',
        ],
        metaData: {
            ...(errorContextObject.metaData || {}),
            ...(overrideObject.metaData || {}),
            tags: {
                ...(errorContextObject.metaData?.tags || {}),
                ...(overrideObject.metaData?.tags || {}),
            },
        },
    };

    const customTags = context.metaData?.tags || {};
    const defaultTags = {
        appName: context.appName,
        appVersion: `${context.appName}@${context.appVersion}`,
        environment: {
            node_env: process.env.NODE_ENV,
            api_host: process.env.API_HOST,
            ssr: process.env.SSR,
            react_app_target: process.env.REACT_APP_TARGET,
        },
        errorReportingTags: {
            react_version: process.env.REACT_VERSION,
        },
    };

    const customBeforeSend = (event: Event) => {
        const beforeSend = context.override?.beforeSend;
        const updatedEvent = addLoadedResources(event);

        if (beforeSend) {
            return beforeSend(updatedEvent);
        }

        return updatedEvent;
    };
    const beforeSend = (event: Event) =>
        context.ignoreError && context.ignoreError(event)
            ? null
            : customBeforeSend(event);

    Sentry.init({
        debug: context.debug,
        enabled: context.shouldLogErrors !== false,
        dsn: context.dsn,
        integrations: context.integrations || [
            new DedupeIntegration(),
            new ExtraErrorDataIntegration(),
        ],
        autoSessionTracking: context.sessionTrackingEnabled,
        initialScope: {
            user: context.user,
            contexts: {
                application: {
                    appVersion: `${context.appName}@${context.appVersion}`,
                },
            },
            tags: {
                ...customTags,
                ...defaultTags,
            },
        },
        environment: context.environment,
        beforeSend,
        release: defaultTags.appVersion,
        denyUrls: denyUrlsPatterns,
    });

    if (window.console) console.log('ebui: client error reporting initialized');
}
