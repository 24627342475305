import { Button } from '@eventbrite/eds-button';
import { sdkRequest } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import loadable from '@loadable/component';
import React, { useContext, useState } from 'react';
import {
    EventBasicInformation,
    EventBasicInformationContext,
} from '../../contexts/EventContext';
import { RemindMeProps } from '../../types';
import type { SimplifiedOrganizerInfoProps } from '../_shared/OrganizerInfo/SimplifiedOrganizerInfo';
import './RemindMe.scss';

const UNREMIND_ACTION = 'unremind';
const REMIND_ACTION = 'remind';

const redirectToSignIn = (eventUrl: string) => {
    window.location.href = `/signin/confirmation/?forward=${eventUrl}`;
};

const setReminderApi = (eventId: string, action: string) => {
    return sdkRequest(`/destination/events/${eventId}/${action}/`, {
        method: 'POST',
    });
};

const LazyRemindMeModal = loadable(() => import('./RemindMeModal'));

export const RemindMe = (
    props: RemindMeProps &
        Omit<SimplifiedOrganizerInfoProps, 'organizerName'> & {
            organizerName?: string;
        },
) => {
    const { id: eventId, url: eventUrl } = useContext(
        EventBasicInformationContext,
    ) as EventBasicInformation;

    const {
        isRemindedEvent,
        organizerId,
        isAuthenticated,
        organizerName,
        organizerAvatar,
    } = props;

    const [isReminded, setIsReminded] = useState(isRemindedEvent);
    const [opened, setOpened] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const setReminder = () => {
        const action = isReminded ? UNREMIND_ACTION : REMIND_ACTION;

        setIsFetching(true);

        setReminderApi(eventId, action)
            .then((response: { action_performed: boolean }) => {
                if (response.action_performed) {
                    if (!isReminded) {
                        setOpened(true);
                    }
                    setIsReminded(!isReminded);
                } else {
                }
            })
            .finally(() => setIsFetching(false));
    };

    const handleClick = () =>
        isAuthenticated
            ? setReminder()
            : redirectToSignIn(eventUrl ? eventUrl : '');

    return (
        <>
            <Button
                style={isReminded ? 'neutral' : 'fill'}
                isLoading={isFetching}
                onClick={handleClick}
                size="block"
                __containerClassName="remind-me__button"
            >
                {isReminded ? gettext('Remove reminder') : gettext('Remind me')}
            </Button>

            {opened && (
                <LazyRemindMeModal
                    onClose={() => setOpened(false)}
                    opened={opened}
                    organizerName={organizerName}
                    organizerAvatar={organizerAvatar}
                    organizerId={organizerId}
                />
            )}
        </>
    );
};

export default RemindMe;
