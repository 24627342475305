/**
 * Mimics the hash implementation done on Android and iOS for determining fall
 * back images, etc.
 * Two inputs with same contents but different order will return the same value.
 * If you care about collisions, use `hash` below :)
 *
 * @param  {string} input String to be hashed per android and ios implementation
 * @return {string} hash of string input
 */
export const simpleStringHash = (input: string): string => {
    const hash = 0;

    if (!input) {
        return hash.toString();
    }

    return input
        .split('')
        .reduce((memo, char) => memo + char.charCodeAt(0), hash)
        .toString();
};
