export const fetchTicketExpressCheckoutInfo = async (url: string) => {
    const headers = {
        'X-EB-App-Context': 'true',
    };

    const formatedUrl = `${
        new URL(url).origin + new URL(url).pathname
    }?keep_tld=1`;

    try {
        const data: any = await fetch(formatedUrl, {
            headers,
            credentials: 'omit',
        }).then((res) => res.json());

        return { data, error: null };
    } catch (exception) {
        console.error(`Error fetching event context: ${exception}`);
        return { data: null, error: exception as Error };
    }
};
