import {
    DestinationEvent,
    FormattedEvent,
    transformDestinationEvent,
} from '@eventbrite/event-renderer';
import { useEffect, useState } from 'react';
import { useEventBasicInformationContext } from '../../contexts/EventContext';
import { searchEventRelatedPromotedEvents } from '../_shared/searchEventRelatedPromotedEvents';

export function usePromotedEvents() {
    const eventBasicInformation = useEventBasicInformationContext();
    const [promotedEvents, setPromotedEvents] = useState<Array<FormattedEvent>>(
        [],
    );
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!eventBasicInformation) {
            return;
        }

        searchEventRelatedPromotedEvents(eventBasicInformation).then(
            (promotedEvents: Array<DestinationEvent>) => {
                setLoaded(true);
                setPromotedEvents(
                    promotedEvents.map(transformDestinationEvent),
                );
            },
        );
    }, [eventBasicInformation]);

    return { promotedEvents, loaded };
}
