import { Reducer } from 'react';
import { ScrollDispatchAction, ScrollState } from './types';

export const scrollReducer: Reducer<ScrollState, ScrollDispatchAction> = (
    _,
    payload,
): ScrollState => {
    if (payload.action === 'reset') {
        return {};
    }
    return { [payload.target]: true };
};
