import { captureException, captureMessage } from '@sentry/browser';
import {
    CaptureContext,
    Context,
    Primitive,
    SeverityLevel,
} from '@sentry/types';

const getContexts = (metaData?: Context | null): CaptureContext =>
    metaData
        ? {
              contexts: {
                  metaData,
              },
          }
        : {};

const getLevel = (level?: SeverityLevel): CaptureContext =>
    level ? { level } : {};

const getTags = (tags?: Record<string, Primitive>): CaptureContext =>
    tags
        ? {
              tags,
          }
        : {};

const getCompleteContext = ({
    metaData,
    level,
    tags,
}: {
    metaData?: Context | null;
    level?: SeverityLevel;
    tags?: Record<string, Primitive>;
}): CaptureContext => ({
    ...getContexts(metaData),
    ...getLevel(level),
    ...getTags(tags),
});

const error = (
    exception: any,
    metaData?: Context | null,
    tags?: Record<string, Primitive>,
) => {
    return captureException(exception, getCompleteContext({ metaData, tags }));
};

const warn = (
    exception: any,
    metaData: Context | null = {},
    tags?: Record<string, Primitive>,
) => {
    return captureMessage(
        exception,
        getCompleteContext({ metaData, tags, level: 'warning' }),
    );
};

const info = (
    exception: any,
    metaData: Context | null = {},
    tags?: Record<string, Primitive>,
) => {
    return captureMessage(
        exception,
        getCompleteContext({ metaData, tags, level: 'info' }),
    );
};

// Create a logger object that abstracts out error, warn, and info functions
// and supports the native Sentry functionality
export const logger = {
    error,
    warn,
    info,
};

export default logger;
