import React from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';

import {
    STYLE_PROP_TYPE as ICON_BUTTON_STYLE_PROPTYPE,
    ICON_PROP_TYPE as ICON_BUTTON_ICON_PROPTYPE,
} from '@eventbrite/eds-icon-button';

import { PlaceholderSvg1 } from '@eventbrite/eds-iconography';
import { PlaceholderSvg2 } from '@eventbrite/eds-iconography';
import { PlaceholderSvg3 } from '@eventbrite/eds-iconography';
import { PlaceholderSvg4 } from '@eventbrite/eds-iconography';

//eslint-disable-next-line consistent-return
const spacingClassProptype = (props, propName, componentName) => {
    let valid = true;

    //Ensure passed in prop starts with either `eds-l-pad` or `eds-l-mar`
    if (props[propName]) {
        valid = !props[propName]
            .split(' ')
            .find((className) => !/^eds-l-(pad|mar)-/.test(className));
    }

    if (!valid) {
        return new Error(
            `Ivalid prop ${propName} supplied to ${componentName}. Must be a spacing class
            of form eds-l-mar-* or eds-l-pad-*. Validation failed.`,
        );
    }
};

export const LIST_TYPE = 'list';
export const GRID_TYPE = 'grid';
export const TYPES = [LIST_TYPE, GRID_TYPE];
export const TYPES_PROP_TYPE = PropTypes.oneOf(TYPES);

export const STANDARD_STYLE = 'standard';
export const MINI_STYLE = 'mini';
export const STYLES = [STANDARD_STYLE, MINI_STYLE];
export const STYLES_PROP_TYPE = PropTypes.oneOf(STYLES);

export const IMAGE_STYLE_FIXED = 'fixed';
export const IMAGE_STYLE_FLUID = 'fluid';
export const IMAGE_STYLE_ROUNDED = 'rounded';
export const IMAGE_STYLE_SQUARE = 'square';
export const IMAGE_STYLE_SQUASHED_LANDSCAPE = 'squashed-landscape';
export const IMAGE_STYLES = [
    IMAGE_STYLE_FIXED,
    IMAGE_STYLE_FLUID,
    IMAGE_STYLE_ROUNDED,
    IMAGE_STYLE_SQUARE,
    IMAGE_STYLE_SQUASHED_LANDSCAPE,
];
export const IMAGE_STYLES_PROP_TYPE = PropTypes.oneOf(IMAGE_STYLES);

export const EVENT_CARD_PROPTYPES = {
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    description: PropTypes.node,
    style: STYLES_PROP_TYPE,
    imageStyle: IMAGE_STYLES_PROP_TYPE,
    imageUrl: PropTypes.string,
    isLazyImage: PropTypes.bool,
    imageAlt: PropTypes.oneOfType([PropTypes.string, translationPropType]),
    shouldHideImage: PropTypes.bool,
    placeholderBgColor: PropTypes.string,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    linkUrl: PropTypes.string,
    isLinkTargetBlank: PropTypes.bool,
    flag: PropTypes.node,
    shouldShowDivider: PropTypes.bool,
    subContentOne: PropTypes.node,
    subContentTwo: PropTypes.node,
    subContentThree: PropTypes.node,
    subContentFour: PropTypes.node,
    primaryIconType: ICON_BUTTON_ICON_PROPTYPE,
    primaryIconStyle: ICON_BUTTON_STYLE_PROPTYPE,
    primaryIconTitle: translationPropType,
    primaryIconOnClick: PropTypes.func,
    secondaryIconType: ICON_BUTTON_ICON_PROPTYPE,
    secondaryIconStyle: ICON_BUTTON_STYLE_PROPTYPE,
    secondaryIconTitle: translationPropType,
    secondaryIconOnClick: PropTypes.func,
    shouldShowIcons: PropTypes.bool,
    focused: PropTypes.bool,
    isTargetBlank: PropTypes.bool,
    isNoFollow: PropTypes.bool,
    /*
     * Only accepts classes of form eds-l-mar-* or
     * eds-l-pad-* to provide spacing around content
     */
    containerSpacingClasses: spacingClassProptype,
};

export const FALLBACK_IMAGE_LIST = [
    <PlaceholderSvg1 key="fallback-image-1" />,
    <PlaceholderSvg2 key="fallback-image-2" />,
    <PlaceholderSvg3 key="fallback-image-3" />,
    <PlaceholderSvg4 key="fallback-image-4" />,
];
