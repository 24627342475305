import isFunction from 'lodash/isFunction';

/**
 * Check if argument is thenable
 */
export const isThenable = (maybePromise: any): boolean =>
    !!(
        maybePromise &&
        isFunction(maybePromise.then) &&
        isFunction(maybePromise.catch)
    );
