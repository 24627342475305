import { CreatorCollectionCard } from '@eventbrite/creator-collection-card';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { css, cx } from 'emotion';
import React from 'react';
import { AffiliateCodes, Collection, GACodes } from '../../types';
import './CollectionCards.scss';

type Props = {
    collections?: Collection[];
    columns?: number;
};

export const AFFILIATE_CODE =
    AffiliateCodes.AFF_COLLECTIONS_FEATURING_THIS_EVENT;

export const CollectionCards: React.FC<Props> = ({ collections }) => {
    if (!collections || !collections.length) {
        return <></>;
    }

    return (
        <ul
            className={cx('cc-card-list', styles.list)}
            data-testid="collection-list"
        >
            {collections?.map((collection) => {
                const {
                    id,
                    name,
                    url,
                    summary,
                    imageUrl,
                    upcomingEventsCount,
                } = collection;

                return (
                    <li
                        key={id}
                        className={'cc-card-list__item'}
                        data-testid="collection-item"
                    >
                        <CreatorCollectionCard
                            name={name}
                            slug={`${url}?aff=${AFFILIATE_CODE}`}
                            summary={summary}
                            imageUrl={imageUrl}
                            numUpcomingEvents={upcomingEventsCount}
                            onClick={() =>
                                trackAnalyticsEvent({
                                    category:
                                        GACodes.GA_CATEGORY_CREATOR_COLLECTIONS_LISTING,
                                    action: 'CollectionView',
                                    label: id,
                                })
                            }
                        />
                    </li>
                );
            })}
        </ul>
    );
};

const styles = {
    list: css`
        margin: 0 auto;
    `,
};
