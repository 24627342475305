import { searchPromotedEvents } from '@eventbrite/ads';
import { EventBasicInformation } from '../../contexts/EventContext';

const RELATED_PROMOTED_EVENTS_RADIUS_IN_MILES = 50;

export function searchEventRelatedPromotedEvents(event: EventBasicInformation) {
    const { isOnline, latitude, longitude, placeId } = event.place;
    const geo_point =
        isOnline || !latitude || !longitude
            ? undefined
            : {
                  lat: latitude,
                  lon: longitude,
                  radius: RELATED_PROMOTED_EVENTS_RADIUS_IN_MILES,
              };

    return searchPromotedEvents({
        slots: 2,
        page: 1,
        interface: 'related_events',
        search: {
            dates: ['current_future'],
            related_event: {
                id: parseInt(event?.id),
                name: event?.name,
                summary: event?.summary,
            },
            place: {
                is_online: event.place.isOnline,
                geo_point,
                place_id: placeId,
            },
        },
    });
}
