import React, { useEffect, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum-slim';
import { track } from '@eventbrite/datalayer-library';
import { VIDEO_ACTIONS } from '../../../../tracking/DatadogRUM/actions';

import { LISTING_HEAP_IDS } from '../../../../constants';
import type { NativeVideoProps } from '../../../../types';
import { Play } from '../../../_shared/Icons';
import { Modal } from '../../../_shared/Modal';
import type { VideoMetaData, VideoMetrics } from '../../../_shared/Video/types';
import { Video } from '../../../_shared/Video/Video';
import { HeroImage } from '../HeroImage';

import './NativeVideo.scss';
import {
    getPlaybackTime,
    getStalledRatioOverDuration,
    getStalledRatioOverPlaybackTime,
    getTotalStalledTime,
} from './videoMetrics';

type NativeVideoComponentProps = NativeVideoProps & {
    alt?: string;
};

export const NativeVideo: React.FC<NativeVideoComponentProps> = ({
    alt,
    url,
    thumbnail,
}) => {
    const [shouldShowVideo, setShouldShowVideo] = useState(false);

    useEffect(() => {
        datadogRum.setGlobalContextProperty('event.has_video', true);
    }, []);

    const handleLoadedMetadata = (duration: number) => {
        if (duration) {
            track({
                eventName: LISTING_HEAP_IDS.VIDEO_DURATION,
                eventData: {
                    eventBucketLabel: `Video duration: ${duration} seconds`,
                },
            });
        }
    };

    const handleTimeUpdate = (currentTime: number, duration: number) => {
        if (duration) {
            const percentageOfVideoPlayed = Math.floor(
                (currentTime / duration) * 100,
            );
            if ([25, 50, 75, 100].includes(percentageOfVideoPlayed)) {
                track({
                    eventName: LISTING_HEAP_IDS.VIDEO_PLAYED,
                    eventData: {
                        percentageOfVideoPlayed,
                    },
                });
            }
        }
    };

    const handleError = (error?: MediaError) => {
        datadogRum.addAction(VIDEO_ACTIONS.playbackFailed, {
            error,
            location: 'fullscreen',
        });
    };

    const handleMetrics = (metrics: VideoMetrics, metadata: VideoMetaData) => {
        const playbackTime = getPlaybackTime(metrics);
        const stalledTime = getTotalStalledTime(metrics);
        const stalledRatioOverPlaybackTime =
            getStalledRatioOverPlaybackTime(metrics);
        const stalledRatioOverDuration = getStalledRatioOverDuration(
            metrics,
            metadata,
        );
        datadogRum.addAction(VIDEO_ACTIONS.playbackEnd, {
            playback_time: playbackTime,
            stalled_time: stalledTime,
            stalled_over_playback_time: stalledRatioOverPlaybackTime,
            stalled_over_video_duration: stalledRatioOverDuration,
            location: 'fullscreen',
        });
    };

    return (
        <>
            <div className="video-wrapper NativeVideo__thumbnail-wrapper">
                <div className="video-overlay NativeVideo__play-button-wrapper">
                    <button
                        data-heap-id={LISTING_HEAP_IDS.HERO_VIDEO}
                        className="video-play-button"
                        aria-label="Play"
                        onClick={() => setShouldShowVideo(true)}
                    >
                        <div className="video-play-button__icon">
                            <Play />
                        </div>
                    </button>
                </div>
                <HeroImage
                    alt={`${alt} thumbnail`}
                    croppedLogoUrl940={thumbnail}
                    data-testid="hero-img"
                    className="NativeVideo__thumbnail"
                />
            </div>
            {shouldShowVideo && (
                <Modal
                    visible={true}
                    type="simple"
                    noMinHeight={true}
                    noPadding={true}
                    onClose={() => setShouldShowVideo(false)}
                    __containerClassName="NativeVideo__modal"
                >
                    <div
                        className="NativeVideo__wrapper"
                        onClick={() => setShouldShowVideo(false)}
                        data-testid="native-video-content"
                    >
                        <Video
                            alt={alt}
                            url={url}
                            onLoadedMetadata={handleLoadedMetadata}
                            onTimeUpdate={handleTimeUpdate}
                            onError={handleError}
                            onEnded={handleMetrics}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};
