import { EventInteraction } from '@eventbrite/ads';
import {
    getUrgencySignalsForTracking,
    isPromoCodeActive,
} from '../../../utils';

export const LISTINGS_LIVE_INTERFACE = 'listings_page_live';
export const LISTINGS_EXPIRED_INTERFACE = 'listings_page_expired';
export const MINIMAL_ORGANIZER_SUBINTERFACE = 'min_simple_organizer';
export const SIMPLE_ORGANIZER_SUBINTERFACE = 'simple_organizer';
export const ABOUT_ORGANIZER_SUBINTERFACE = 'about_organizer';

export function aggregateContext({
    context,
    event,
    listingsType,
    subInterface,
}: Omit<EventInteraction, 'action'> & {
    context: any;
    listingsType: string;
    subInterface: string;
}) {
    const hasOpenPromotion = isPromoCodeActive(event.discount);
    const hasBOGOLabel = !!event.specialDiscounts?.hasBogoTickets;
    const urgencySignals = getUrgencySignalsForTracking(context.urgencySignals);
    return {
        user: {
            id: context.userId,
            locale: context.locale,
            guestId: context.guestId,
            sessionId: context.sessionId,
        },
        adId: event?.promotedListingMetadata?.adId,
        place: {
            name: listingsType,
            position: 0,
            absoluteRank: 0,
            page: 0,
            context: {
                related_event_id: context.event?.id,
                is_online: context.event?.isOnline,
                place_id: context.event?.localityPlaceId,
            },
            tabKey: '',
            subInterface: {
                name: subInterface,
            },
            placementId: event?.promotedListingMetadata?.placementId,
        },
        displayContext: {
            hasOpenPromotion,
            hasBOGOLabel,
            urgencySignals,
        },
        venueId: event?.venue?.id,
    };
}
