import type { ChildEventProps, LocalizedDateTime } from '../../types';

export enum EventActionType {
    setSelectedEvent = 'setSelectedEvent',
    setSeriesEvent = 'setSeriesEvent',
    setEventURL = 'setEventURL',
    setEarlyBird = 'setEarlyBird',
    setDiscounts = 'setDiscounts',
    updateChildEventForTimedEntry = 'setTimedEntryEvent',
    updateEventSessionForTimedEntry = 'updateEventSessionForTimedEntry',
}

type GenericEventAction<K extends EventActionType, T> = {
    payload: T;
    type: K;
};

export type SetSelectedEventAction = GenericEventAction<
    EventActionType.setSelectedEvent,
    {
        selectedDate?: boolean;
    }
>;

export type SetSeriesEventAction = GenericEventAction<
    EventActionType.setSeriesEvent,
    {
        id: string;
        start?: LocalizedDateTime;
        end?: LocalizedDateTime;
        url?: string;
        salesStatus: string;
        isSoldOut: boolean;
        selectedDate?: boolean;
    }
>;

export type SetEventURLAction = GenericEventAction<
    EventActionType.setEventURL,
    {
        url: string;
    }
>;

export type SetEarlyBirdAction = GenericEventAction<
    EventActionType.setEarlyBird,
    {
        hasEarlyBirdTicketsValue: boolean;
    }
>;

export type SetDiscountsAction = GenericEventAction<
    EventActionType.setDiscounts,
    {
        hasEarlyBirdTicketsValue: boolean;
        discountValue: any;
        hasBogoTicketsValue: boolean;
    }
>;

export type SetTimedEntryEventAction = GenericEventAction<
    EventActionType.updateChildEventForTimedEntry,
    {
        timedEntryEvents: Array<ChildEventProps>;
    }
>;

export type SetEventSessionTimedEntryAction = GenericEventAction<
    EventActionType.updateEventSessionForTimedEntry,
    {
        eventSessions: Array<ChildEventProps>;
    }
>;

export type EventAction =
    | SetSeriesEventAction
    | SetEventURLAction
    | SetEarlyBirdAction
    | SetTimedEntryEventAction
    | SetEventSessionTimedEntryAction
    | SetDiscountsAction
    | SetSelectedEventAction;
