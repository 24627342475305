import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { useTicketContext } from '../context';
import styles from '../TicketCard.module.scss';
import { TicketCost } from './types';
import { hasCost, renderFeeText, renderPrice, renderTaxText } from './utils';

export const TicketCosts: React.FC = () => {
    const { cost, total, allInclusivePrice, fee, tax, characteristics } =
        useTicketContext();

    const price = allInclusivePrice ? total : cost;

    const hasPrice = hasCost(price);
    const hasFee = hasCost(fee);
    const hasTax = hasCost(tax);

    return (
        <div className={styles.pricing}>
            <div>
                {characteristics.isFree && (
                    <Typography
                        as={'span'}
                        variant="body-md-bold"
                        className={styles.colorTextHighlight}
                    >
                        {gettext('FREE')}
                    </Typography>
                )}

                {hasPrice && (
                    <>
                        <Typography
                            as={'span'}
                            variant="body-md-bold"
                            className={styles.colorTextHighlight}
                        >
                            {renderPrice(price as TicketCost)}
                        </Typography>
                    </>
                )}
            </div>
            <div>
                {hasFee && (
                    <Typography
                        as={'span'}
                        variant="body-caption"
                        color="neutral-500"
                    >
                        {renderFeeText(fee as TicketCost, allInclusivePrice)}
                    </Typography>
                )}

                {hasFee && hasTax && (
                    <Typography
                        as={'span'}
                        variant="body-caption"
                        color="neutral-500"
                    >
                        {' / '}
                    </Typography>
                )}

                {hasTax && (
                    <Typography
                        as={'span'}
                        variant="body-caption"
                        color="neutral-500"
                    >
                        {renderTaxText(tax as TicketCost, allInclusivePrice)}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default TicketCosts;
