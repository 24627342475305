import React from 'react';
import {
    StartDateContainer,
    StartDateContainerProps,
} from './StartDateContainer';

type StartDateProps = {
    hideStartDate?: boolean;
} & StartDateContainerProps;

export const StartDate = ({
    hideStartDate = false,
    ...props
}: StartDateProps) => {
    if (hideStartDate) {
        return null;
    }

    return <StartDateContainer {...props} />;
};
