import React from 'react';
import { ngettext, gettext } from '@eventbrite/i18n';
import { Icon } from '@eventbrite/eds-icon';
import { UserChunky } from '@eventbrite/eds-iconography';

/**
 * @name getFollowSignalDisplayValue
 * @param {num} value numeric signal value
 * @returns formatted string representation of numeric signal param
 */
export const getFollowSignalDisplayValue = (value) => {
    if (value < 1000) {
        return `${value}`;
    }

    //returns: 2.8k
    return `${(value / 1000).toFixed(1)}k`.replace('.0', '');
};

/**
 * @name getOrganizerNameNode
 * @param {string} organizerName string representation of event organizer name
 * @returns event organizer component
 */
const getOrganizerNameNode = (organizerName) => {
    let component;

    if (organizerName) {
        component = (
            <div
                data-subcontent-key="organizerName"
                className="eds-event-card__sub-content--organizer eds-text-color--ui-800 eds-text-weight--heavy card-text--truncated__two"
            >
                {organizerName}
            </div>
        );
    }

    return component;
};

export const OrganizerFollowersSignal = ({ signal, organizerName }) => {
    if (!organizerName) {
        return null;
    }

    const signalDisplayValue = getFollowSignalDisplayValue(signal.value);

    //Translators: 1 follower
    //Translators: 2 followers
    const content = ngettext(
        '%(followCount)s follower',
        '%(followCount)s followers',
        signal.value,
        {
            followCount: signalDisplayValue,
        },
    );

    return (
        <div
            data-subcontent-key="follow"
            className="eds-event-card__sub-content--signal eds-text-color--ui-800 eds-text-weight--heavy"
        >
            {getOrganizerNameNode(organizerName)}
            {signal.value && signal.value > 10 ? (
                <>
                    <Icon type={<UserChunky />} size="xsmall" />
                    <span className="eds-l-pad-hor-1" />
                    {content}
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
