import { SetDiscountsAction } from '../actions';
import { EventBasicInformation } from '../types';

export const setDiscounts = (
    event: EventBasicInformation,
    {
        payload: {
            hasEarlyBirdTicketsValue,
            discountValue,
            hasBogoTicketsValue,
        },
    }: SetDiscountsAction,
): EventBasicInformation => {
    const updatedEvent = {
        ...event,
        discount: discountValue,
        ticketsInfo: {
            ...event.ticketsInfo,
            hasEarlyBirdTickets: hasEarlyBirdTicketsValue,
            hasBogoTickets: hasBogoTicketsValue,
        },
    };
    return updatedEvent;
};
