import {
    SHARE_ON_EMAIL,
    SHARE_ON_FACEBOOK,
    SHARE_ON_FACEBOOK_MESSENGER,
    SHARE_ON_TWITTER,
} from '@eventbrite/eds-share-box';

export const GA_SHARE_ACTION = 'Share';
export const GA_SHARE_ATTEMPT_ACTION = 'ShareAttempt';
export const GA_WILL_SHARE_EVENT = 'ShareEvent';
export const GA_WILL_DISMISS_EVENT = 'DismissEvent';
export const GA_EVENT_CARD_OVERFLOW_MENU_CLICKED =
    'EventCardOverflowMenuClicked';
export const CREATE_COLLECTION_VIEW_GA_ACTION = 'CreateCollectionAttempt';
export const SHOW_ADD_TO_COLLECTION_GA_ACTION = 'AddToCollectionListShown';
export const CREATE_COLLECTION_GA_ACTION = 'CreateCollection';
export const ADD_TO_COLLECTION_GA_ACTION = 'AddToCollection';
export const REMOVE_FROM_COLLECTION_GA_ACTION = 'RemoveFromCollection';

export const EVENTCARD_CATEGORY = 'eventcard';
export const VIEW_EVENT_GA_ACTION = 'ViewEvent';
export const BOOKMARK_GA_ACTION = 'Bookmark';
export const UNBOOKMARK_GA_ACTION = 'Unbookmark';
export const COLLECTION_SIGNAL_CLICKED = 'CollectionSignalClicked';
export const CREATOR_COLLECTION_CLICKED = 'CreatorCollectionClicked';

export const GA_TWITTER_IDENTIFIER = 'tw';
export const GA_FACEBOOK_IDENTIFIER = 'fb';
export const GA_FACEBOOK_MESSENGER_IDENTIFIER = 'fbm';
export const GA_EMAIL_IDENTIFIER = 'em';
export const GA_COPY_IDENTIFIER = 'cp';
export const SHARE_BY_COPY = 'copy';

export const RAW_GA_SHARETYPE_MAP = {
    [SHARE_ON_EMAIL]: GA_EMAIL_IDENTIFIER,
    [SHARE_ON_FACEBOOK]: GA_FACEBOOK_IDENTIFIER,
    [SHARE_ON_FACEBOOK_MESSENGER]: GA_FACEBOOK_MESSENGER_IDENTIFIER,
    [SHARE_ON_TWITTER]: GA_TWITTER_IDENTIFIER,
    [SHARE_BY_COPY]: GA_COPY_IDENTIFIER,
};

export const GA_SHARETYPE_MAP: Record<string, string> &
    typeof RAW_GA_SHARETYPE_MAP = RAW_GA_SHARETYPE_MAP;

export const CONSUMER_CARD_DEFAULTS = {
    isTargetBlank: true,
    shouldConvertToUserTimezone: true,
    isLazyImage: true,
    ticketsBy: 'Eventbrite',
};

export const HEAP_EVENT_CLICK = 'Click - Event(s)';
export const HEAP_EVENT_LIKE_CLICK = 'Click - Event Like(s)';

export const MORE_ACTIONS = {
    REMOVE_EVENT: 'removeEvent',
    SHARE_EVENT: 'shareEvent',
};

export const USER_VIEWS_HISTORY = 'user_recent_views';
