// This code was brought in from marketing-hub as js files and is not currently
// type safe so leaving as js for now
import { HAS_WINDOW } from '@eventbrite/feature-detection';

const dummy =
    (type = '') =>
    (...args) => {
        console.log(`Dummy ${type}`, ...args);
    };

// Main function to load Intercom.
export function loadIntercom() {
    if (process.env.NODE_ENV !== 'production') {
        window.Intercom = dummy('intercom');
    } else if (window.Intercom) {
        return;
    } else {
        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', {
                    app_id: 'xlku466w',
                    custom_launcher_selector: '.help-launcher',
                });
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                function l() {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/xlku466w';
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                }
                // THIS PART IS CUSTOMIZED, DO NOT REPLACE THE INTERCOM INSTALLATION TAG UNTIL YOU UNDERSTAND WHAT THIS DOES
                // In the marketing hub, this installation script runs after the window has loaded. I've added the first if
                // condition here to handle the case where the window has already loaded when the installation script runs.
                // Stolen from https://stackoverflow.com/questions/13364613/how-to-know-if-window-load-event-was-fired-already/28093606.
                if (
                    performance &&
                    performance
                        .getEntriesByType('navigation')
                        .every((e) => e.loadEventEnd)
                ) {
                    l();
                } else if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })();
    }
}

export function identifyIntercom() {
    const eventbriteUser = window.__SERVER_DATA__?.user;
    const intercomUserHash = eventbriteUser?.intercomUserHash;

    if (eventbriteUser && intercomUserHash) {
        const userParams = {
            app_id: 'xlku466w',
            name: eventbriteUser.fullName,
            email: eventbriteUser.email,
            user_hash: intercomUserHash,
            user_type: 'manager',
            admin_email: eventbriteUser.email,
            eventbrite_username: eventbriteUser.fullName,
            eventbrite_organization_id:
                eventbriteUser.activeOrganization &&
                eventbriteUser.activeOrganization.id,
            user_id: eventbriteUser.publicId,
            creation_platform: 'eventbrite',
            utm_source: window.utm_source,
            utm_campaign: window.utm_campaign,
            utm_medium: window.utm_medium,
        };

        if (window.__SERVER_DATA__?.featureFlags?.launchNewBillingSettings) {
            userParams.is_pricing_and_packaging = true;
        }

        window.Intercom('boot', userParams);
    }
}
function checkForIntercom() {
    if (!HAS_WINDOW || !window.Intercom) {
        console.log(`Attempted to call Intercom but it is not instantiated!`);
        return false;
    }
    return !!window.Intercom;
}
export function trackIntercomEvent(message, object) {
    checkForIntercom() && window.Intercom('trackEvent', message, object);
}
export function intercomUpdate(params) {
    checkForIntercom() && window.Intercom('update', params);
}
