import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { useTicketContext } from '../context';
import { SALES_ENDED, SALES_NOT_STARTED, UNAVAILABLE } from './constants';
import { SalesStatus } from './types';
import { formatDate } from './utils';

export const TicketSalesStatus: React.FC = () => {
    const { onSaleStatus, onSaleStatusEnum } = useTicketContext();
    const allowedStatus = new Set([
        SalesStatus.NotYetOnSale,
        SalesStatus.Unavailable,
    ]);

    if (!allowedStatus.has(onSaleStatusEnum)) {
        return null;
    }

    const displayStatus = () => {
        if (onSaleStatusEnum === SalesStatus.NotYetOnSale) {
            const formattedDate = formatDate(
                onSaleStatus.startSalesWithTz.utc,
                onSaleStatus.startSalesWithTz.timezone,
            );
            return onSaleStatus.endSales === onSaleStatus.startSales
                ? SALES_NOT_STARTED
                : gettext('Sales start on %(formattedDate)s', {
                      formattedDate,
                  });
        }

        if (onSaleStatusEnum === SalesStatus.Unavailable) {
            return onSaleStatus.ended ? SALES_ENDED : UNAVAILABLE;
        }
    };

    return (
        <Typography as="span" variant="body-md" color="ui-800">
            {displayStatus()}
        </Typography>
    );
};
