import { sdkRequest } from '@eventbrite/http';
import { useEffect, useState } from 'react';
import {
    Collection,
    CollectionResponse,
    EventCollectionsResponse,
} from '../../types';

export function useEventCollections(
    eventId: string,
    pageSize: number,
): {
    collections: Collection[];
    count: number;
    loadNextPage: () => void;
    hasMoreCollections: boolean;
    areCollectionsLoading: boolean;
} {
    const [collections, setCollections] = useState<{
        items: Collection[];
        count: number;
    }>({
        items: [],
        count: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMoreCollections, setHasMoreCollections] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        let mounted = true;
        sdkRequest(
            `/events/${eventId}/collections/public/?expand=image,event_count&page=${page}&page_size=${pageSize}`,
        )
            .then((storedCollections: EventCollectionsResponse) => {
                if (mounted) {
                    setCollections((collections) => ({
                        items: [
                            ...collections.items,
                            ...storedCollections?.collections?.map(
                                (collection: CollectionResponse) => ({
                                    id: collection.id,
                                    name: collection.name,
                                    summary: collection.summary,
                                    imageUrl: collection.image?.url,
                                    url: collection.relative_url,
                                    upcomingEventsCount:
                                        collection.event_count
                                            .current_future_published,
                                }),
                            ),
                        ],
                        count: storedCollections?.pagination?.object_count || 0,
                    }));
                    setHasMoreCollections(
                        storedCollections?.pagination?.has_more_items,
                    );
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
        return () => {
            mounted = false;
        };
    }, [eventId, page, setIsLoading, setHasMoreCollections, pageSize]);

    return {
        collections: collections.items,
        count: collections.count,
        loadNextPage: () => setPage(page + 1),
        hasMoreCollections,
        areCollectionsLoading: isLoading,
    };
}
