import { logEvent } from '@eventbrite/statsig';
import React, { useEffect } from 'react';
import {
    useErrorContext,
    useErrorDispatch,
} from '../../../contexts/ErrorContext';
import { STATSIG_EVENT_NAMES } from '../../../experimentation';
import { ErrorBanner } from './ErrorBanner';

const DEFAULT_AUTOCLOSE_TIME = 5000;

export const ConnectedErrorBanner = () => {
    const error = useErrorContext();
    const { clearError } = useErrorDispatch();

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;
        if (error?.autoClose) {
            timeoutId = setTimeout(() => {
                clearError();
            }, error.autoCloseTime || DEFAULT_AUTOCLOSE_TIME);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [error, clearError]);

    if (!error) {
        return null;
    }

    const onCloseClick = () => {
        clearError();
        logEvent(STATSIG_EVENT_NAMES.LISTING_ERROR_BANNER_CLOSE_CLICK);
    };

    const title = error.title ?? error.description;
    const description = title ? error.description : undefined;

    return (
        <ErrorBanner
            title={title}
            description={description}
            onCloseClick={error.closable ? onCloseClick : undefined}
        />
    );
};
