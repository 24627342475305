export enum TicketActionType {
    setExpanded = 'setExpanded',
    updateQuantity = 'updateQuantity',
}

export type SetExpandedAction = GenericTicketAction<
    TicketActionType.setExpanded,
    {
        expanded: boolean;
    }
>;

export type UpdateQuantityAction = GenericTicketAction<
    TicketActionType.updateQuantity,
    { quantity: number }
>;

export type TicketAction = SetExpandedAction | UpdateQuantityAction;

type GenericTicketAction<K extends TicketActionType, T> = {
    payload: T;
    type: K;
};
