export const getIconColor = (
    isDisabled: boolean,
    isAppRebrandActive?: boolean,
) => {
    if (isAppRebrandActive) {
        return 'fg-base';
    }

    return isDisabled ? 'grey-500' : 'white';
};
