export const sendNewQuantities = (
    comObject: Window,
    newQuantities: Record<string, number>,
) => {
    const quantities = Object.entries(newQuantities).reduce<
        Record<string, { selectedQuantity: number }>
    >((acc, [ticketId, quantity]) => {
        if (quantity > -1) {
            acc[ticketId] = { selectedQuantity: quantity };
        }
        return acc;
    }, {});

    comObject.postMessage({ type: 'setTickets', data: quantities }, '*');
};
