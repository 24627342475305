import React from 'react';
import PropTypes from 'prop-types';
import { gettext } from '@eventbrite/i18n';
import { formatUrl } from 'url-lib';
import { IconButton } from '@eventbrite/eds-icon-button';

import { UTM_OPTION_PROPTYPE } from './constants';
import { openUrl } from './utils';
import { LogoWhatsapp } from '@eventbrite/eds-iconography';

const _getEventUrl = (eventUrl, utmOptions) =>
    formatUrl(eventUrl, {
        ...utmOptions,
    });

export default class WhatsappShare extends React.Component {
    static propTypes = {
        /**
         * Event's name
         */
        eventName: PropTypes.string.isRequired,

        /**
         * Event's url
         */
        eventUrl: PropTypes.string.isRequired,

        /**
         * Function that onClick of one of the share options, passes
         * back an id of the clicked option
         */
        onClick: PropTypes.func,

        /**
         * utmOptions configured for this shareType
         */
        utmOptions: UTM_OPTION_PROPTYPE,
    };

    _handleButtonClick() {
        const { eventName, eventUrl, onClick, utmOptions } = this.props;
        const text = gettext('Check out "%(eventName)s" %(eventUrl)s', {
            eventName,
            eventUrl: _getEventUrl(eventUrl, utmOptions),
        });
        const url = formatUrl('whatsapp://send', { text });

        if (onClick) {
            onClick();
        }

        openUrl(url);
    }

    render() {
        return (
            <span
                className="eds-l-pad-right-1"
                data-spec="whatsapp-share-box-container"
            >
                <IconButton
                    onClick={this._handleButtonClick.bind(this)}
                    iconType={<LogoWhatsapp />}
                    title={gettext('Share on Whatsapp')}
                />
            </span>
        );
    }
}
