import { getWindowObject } from './get-window-object';

export const getUrlParams = (): URLSearchParams => {
    const location = getWindowObject('location');

    if (!location?.href) {
        return new URLSearchParams('');
    }

    const url = new URL(location.href);
    return new URLSearchParams(url?.search);
};

export const getUrlParam = (paramName: string) => getUrlParams().get(paramName);
