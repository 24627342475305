import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { useTicketContext } from './context';
import styles from './TicketCard.module.scss';

export const TicketHeading: React.FC = () => {
    const { name } = useTicketContext();

    return (
        <Typography
            as="h3"
            variant="body-md-bold"
            className={styles.colorTextHighlight}
        >
            {name}
        </Typography>
    );
};
