export enum HighlightsType {
    AcceptsRefunds = 'accepts_refunds',
    AgeRestriction = 'age_restriction',
    Duration = 'duration',
    ETicket = 'eticket',
    FreeParking = 'freeParking',
    PaidParking = 'paidParking',
    ParkingNotAvailable = 'notAvailableParking',
}

export type HighlightsProps = {
    [key: string]: boolean | string | HighlightDurationValue | undefined;
    accepts_refunds?: boolean;
    parking?: boolean; // This field should be removed once Core does not send it anymore (EB-263199)
    paidParking?: boolean;
    freeParking?: boolean;
    duration?: HighlightDurationValue;
    age_restriction?: string;
};

export type HighlightDurationValue = {
    totalSeconds: number;
    days: number;
    hours: number;
    minutes: number;
};
