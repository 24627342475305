import type { VideoStateMetric } from './VideoStateMetric';

export type Handlers = {
    onBuffering?: () => void;
    onError?: (error?: MediaError) => void;
    onLoadedMetadata?: (duration: number) => void;
    onPlay?: () => void;
    onTimeUpdate?: (currentTime: number, duration: number) => void;
    onEnded?: (metrics: VideoMetrics, metadata: VideoMetaData) => void;
};

export type EventToSubscribe = {
    event: string;
    handler: (event?: Event) => void;
};

export type MediaErrorEvent = Event & {
    target?: Event['target'] & { error?: MediaError };
};

export enum VIDEO_STATES {
    UNINITIALIZED = 'uninitialized', // load hasn't started
    LOADING = 'loading', // video initial load
    READY = 'ready', // video is ready to play but hasn't started yet
    PLAYING = 'playing',
    PAUSED = 'paused',
    ENDED = 'ended',
    SEEKING = 'seeking', // user started seeking by clicking somewhere in the progress bar
    STALLED = 'stalled', // video is stopped by other reason than user interaction (buffering or stalled)
    ERROR = 'error',
}

export type VideoMetaData = {
    duration: number;
};

export type VideoMetrics = Record<VIDEO_STATES, VideoStateMetric>;
