import { track } from '@eventbrite/datalayer-library';
import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import { HAS_DOCUMENT } from '@eventbrite/eds-utils';
import {
    getWindowObject,
    HAS_WINDOW_LISTENERS,
} from '@eventbrite/feature-detection';
import { gettext } from '@eventbrite/i18n';
import { logEvent } from '@eventbrite/statsig';
import classNames from 'classnames';
import escape from 'lodash/escape';
import React, { useEffect, useRef, useState } from 'react';
import { LISTING_HEAP_IDS } from '../../../../constants';
import {
    EventActionType,
    useEventBasicInformationContext,
    useEventDispatchContext,
} from '../../../../contexts';
import { useCommunicationDispatch } from '../../../../contexts/CommunicationContext';
import { useErrorDispatch } from '../../../../contexts/ErrorContext';
import { STATSIG_EVENT_NAMES } from '../../../../experimentation';
import { CheckoutType, CheckoutWidgetProps, Event } from '../../../../types';
import {
    CheckoutErrorName,
    CheckoutPostMessagesNames,
    useIncomingCheckoutMessages,
} from '../../../hooks/useIncomingCheckoutMessages';
import { useTrackComponent } from '../../../hooks/useTrackComponent';
import { GoToTicketsButton } from '../GoToTicketsButton';
import './TicketSelectionIframe.scss';

export type TicketSelectionIframeProps = CheckoutWidgetProps & {
    eventName: Event['name'];
    isPreview: Event['isPreview'];
    checkoutType?: CheckoutType;
    shouldShowGoToTickets?: boolean;
};

export const TICKET_SELECTION_CONTAINER_ID =
    'ticket-selection-iframe-container';

const setCheckoutIframe = (
    setCheckout: ((iframe: HTMLIFrameElement) => void) | undefined,
) => {
    const container = document.getElementById(TICKET_SELECTION_CONTAINER_ID);
    const iframe = container?.getElementsByTagName('iframe')[0];

    if (iframe && setCheckout) {
        setCheckout(iframe);
    }
};

export const TicketSelectionIframe = ({
    affiliateCode,
    campaign_token,
    eventName,
    isPreview,
    promoCode,
    rsvpToken,
    waitlistToken,
    checkoutType = CheckoutType.Compact,
    shouldShowGoToTickets = false,
}: TicketSelectionIframeProps) => {
    const dispatchEvent = useEventDispatchContext();
    const { id: eventId } = useEventBasicInformationContext();
    const { defaultError, customError } = useErrorDispatch();
    const ticketAnchorRef = React.useRef<HTMLDivElement>(null);
    const { setCheckout } = useCommunicationDispatch();

    const INITIAL_COMPONENT_HEIGHT =
        checkoutType === CheckoutType.OnlyCheckout ? 76 : 204;

    const [iframeWrapperHeight, setIframeWrapperHeight] = useState(
        INITIAL_COMPONENT_HEIGHT,
    );
    const [isTicketSelectionPage, setIsTicketSelectionPage] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const checkoutIframeContainer = useRef<HTMLDivElement | null>(null);

    const COMPACT_CHECKOUT_PARAMS = {
        name: 'ticketsPageSize',
        value: checkoutType,
    };

    useTrackComponent({
        ref: ticketAnchorRef,
        heap: {
            eventName: LISTING_HEAP_IDS.TICKETS_ANCHOR_BUTTON_VIEW,
            eventBucketLabel:
                'Views on "Get tickets“ button before selecting the ticket',
        },
    });

    useEffect(() => {
        const ebWidgets = getWindowObject('EBWidgets');
        if (!ebWidgets) return;

        setIsLoading(true);
        (checkoutIframeContainer.current as HTMLDivElement).innerHTML = '';

        ebWidgets.createWidget({
            widgetType: 'checkout',
            eventId,
            iframeContainerId: TICKET_SELECTION_CONTAINER_ID,
            extraParams: [COMPACT_CHECKOUT_PARAMS],
            preventResizing: true,
            iframeContainerHeight: iframeWrapperHeight,
            iframeTitle: gettext('Checkout for %(eventName)s', {
                eventName,
            }),
            ...(!isPreview ? { affiliateCode } : {}),
            ...(promoCode ? { promoCode: escape(promoCode) } : {}),
            ...(waitlistToken ? { waitlistToken } : {}),
            ...(rsvpToken ? { rsvpToken } : {}),
            ...(campaign_token ? { campaign_token } : {}),
        });

        HAS_WINDOW_LISTENERS &&
            window.addEventListener('message', _handlePostMessages);

        logEvent(STATSIG_EVENT_NAMES.LISTING_TICKET_SELECTION_VIEW);

        setCheckoutIframe(setCheckout);

        return () => {
            HAS_WINDOW_LISTENERS &&
                window.removeEventListener('message', _handlePostMessages);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    useEffect(() => {
        dispatchEvent({
            type: EventActionType.setSelectedEvent,
            payload: {
                selectedDate: true,
            },
        });
        const bodyTag = HAS_DOCUMENT && document.querySelector('body');

        if (bodyTag) {
            !isTicketSelectionPage
                ? bodyTag.classList.add('checkout-app-modal-opened')
                : bodyTag.classList.remove('checkout-app-modal-opened');
        }
    }, [isTicketSelectionPage, dispatchEvent]);

    const _handlePostMessages = useIncomingCheckoutMessages({
        handlers: {
            [CheckoutPostMessagesNames.widgetRenderComplete]: () =>
                setIsLoading(false),
            [CheckoutPostMessagesNames.ticketSelectionResize]: ({
                contentHeight,
            }) =>
                setIframeWrapperHeight(
                    contentHeight || INITIAL_COMPONENT_HEIGHT,
                ),
            [CheckoutPostMessagesNames.checkoutCaptchaErrorPageLoaded]: () =>
                setIsTicketSelectionPage(false),
            [CheckoutPostMessagesNames.checkoutFormLoaded]: () =>
                setIsTicketSelectionPage(false),
            [CheckoutPostMessagesNames.joinWaitlist]: () =>
                setIsTicketSelectionPage(false),
            [CheckoutPostMessagesNames.deliveryMethodFormLoaded]: () =>
                setIsTicketSelectionPage(false),
            [CheckoutPostMessagesNames.orderComplete]: () =>
                setIsTicketSelectionPage(false),
            [CheckoutPostMessagesNames.organizerQuestionsFormLoaded]: () =>
                setIsTicketSelectionPage(false),
            [CheckoutPostMessagesNames.ticketSelectionCompactLoaded]: () => {
                setIsTicketSelectionPage(true);
                setIframeWrapperHeight(INITIAL_COMPONENT_HEIGHT);
            },
            [CheckoutPostMessagesNames.onlyCheckoutLoaded]: () => {
                setIsTicketSelectionPage(true);
                setIframeWrapperHeight(INITIAL_COMPONENT_HEIGHT);
            },
            [CheckoutPostMessagesNames.ticketSelectionSubmit]: () =>
                logEvent(STATSIG_EVENT_NAMES.LISTING_TICKET_SELECTION_CLICK),
            [CheckoutPostMessagesNames.orderCreationError]: ({ error }) => {
                logEvent(
                    STATSIG_EVENT_NAMES.LISTING_MULTITICKET_ERROR_VIEW,
                    null,
                    {
                        errorName: error || 'unknown',
                    },
                );
                track({
                    eventName: LISTING_HEAP_IDS.MULTITICKET_ERROR_VIEW,
                    eventData: {
                        eventBucketLabel: 'View on Error (any) on listings',
                    },
                });

                if (error === CheckoutErrorName.INVENTORY_ERROR) {
                    return customError({
                        title: gettext(
                            'Sorry, your selected ticket is no longer available',
                        ).toString(),
                        description: gettext(
                            'To try again, please select another ticket.',
                        ).toString(),
                    });
                }
                defaultError();
                console.error(error);
            },
            [CheckoutPostMessagesNames.expressPaymentProcessingOrder]: () => {
                setIsTicketSelectionPage(false);
            },
            [CheckoutPostMessagesNames.expressPaymentCanceled]: () =>
                setIsTicketSelectionPage(true),
            [CheckoutPostMessagesNames.orderPlacingError]: () => defaultError(),
        },
    });

    const _iframeWrapperHeightUnits = isTicketSelectionPage
        ? `${iframeWrapperHeight}px`
        : '100%';

    const classes = classNames({
        'conversion-bar--ticket-selection': isTicketSelectionPage,
        'conversion-bar--full-size': !isTicketSelectionPage,
    });

    return (
        <div aria-live="polite" className="ticket-selection">
            {isLoading && !shouldShowGoToTickets && (
                <div className="ticket-selection-loader">
                    <ProgressIndicator style="gradient" />
                </div>
            )}
            <div
                ref={checkoutIframeContainer}
                id={TICKET_SELECTION_CONTAINER_ID}
                data-testid={TICKET_SELECTION_CONTAINER_ID}
                style={{
                    height: _iframeWrapperHeightUnits,
                    display: shouldShowGoToTickets ? 'none' : 'block',
                    minHeight: `${INITIAL_COMPONENT_HEIGHT}px`,
                }}
                className={classes}
            ></div>
            <div
                style={{ display: shouldShowGoToTickets ? 'block' : 'none' }}
                ref={ticketAnchorRef}
            >
                <GoToTicketsButton />
            </div>
        </div>
    );
};
