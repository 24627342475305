import { SubmissionError } from 'redux-form';

/**
 * Custom subclass of SubmissionError which allow us to pass extra information
 */
export class CustomSubmissionError extends SubmissionError {
    constructor(errors, extraData) {
        super(errors);
        this.extraData = extraData;
    }
}
