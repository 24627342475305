import { Button } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import { setFollowOrganizerStatus as setFollowOrganizerStatusApi } from '@eventbrite/redux-destination';
import React, { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import './FollowButton.scss';
import { useFollowButtonModal } from './hooks';

const GDPR_TITLE_TEXT = gettext('Get alerts for new events');
const GDPR_SUBTITLE_TEXT = gettext(
    "By clicking okay, you are agreeing to receive marketing emails and notifications about creators you follow so that you don't miss out.",
);

export const FollowButtonGDPRModal = () => {
    const { shouldShowGDPRModal, closeGDPRModal, userId } =
        useFollowButtonModal();

    const handleReject = () => {
        setFollowOrganizerStatusApi(userId, false);
        closeGDPRModal?.();
    };

    const handleAccept = () => {
        setFollowOrganizerStatusApi(userId, true);
        closeGDPRModal?.();
    };

    const [isClientSideRendered, setIsClientSideRendered] = useState(false);

    useEffect(() => setIsClientSideRendered(true), []);

    return isClientSideRendered ? (
        <Modal
            visible={shouldShowGDPRModal}
            type="simple"
            noMinHeight={true}
            onClose={closeGDPRModal}
        >
            <div>
                <h2 className="eds-dialog__title eds-l-mar-top-10 eds-l-pad-hor-5 eds-align--center eds-text--center eds-text-hs eds-text-weight--heavy">
                    {GDPR_TITLE_TEXT}
                </h2>
                <p className=" eds-dialog__message eds-l-mar-vert-5 eds-text-bm eds-text--center eds-text-color--ui-800 eds-l-pad-hor-10">
                    {GDPR_SUBTITLE_TEXT}
                </p>
            </div>

            <div className="eds-l-mar-top-5 eds-l-mar-bot-10">
                <div className="eds-dialog__button-bar eds-align--center">
                    <div className="eds-dialog__button-bar eds-align--center eds-text-bm eds-dialog__button-bar--with-title">
                        <div className="eds-dialog__secondary-button eds-l-pad-hor-2">
                            <Button onClick={handleReject} style="link">
                                {gettext('No Thanks')}
                            </Button>
                        </div>
                        <div className="eds-dialog__primary-button eds-l-pad-hor-2">
                            <Button onClick={handleAccept} style="link">
                                {gettext('Okay')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    ) : null;
};
