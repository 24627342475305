import { FormattedEvent } from '@eventbrite/event-renderer';
import { sdkRequest } from '@eventbrite/http';
import React, { useContext, useEffect, useState } from 'react';
import { EventBasicInformationContext } from '../../contexts/EventContext';
import { dynaMount } from '../utils/mountComponent';
import {
    filterEvents,
    getOrganizerRelatedEventsFromDestination,
    NUMBER_OF_MORE_EVENTS_TO_SHOW,
} from './api';
import { MORE_EVENTS_FROM_ID, RELATED_EVENTS_ID } from './constants';
import MoreEventsFrom from './MoreEventsFrom';
import {
    OtherEventsYouMayLike,
    OtherEventsYouMayLikeProps,
} from './OtherEventsYouMayLike';
import {
    EventsFromCollectionResponse,
    transformCollectionEvent,
} from './transform';
import { RelatedEventsProps } from './types';

export type RelatedEventsChildrenProps = Omit<
    RelatedEventsProps,
    'organizerId'
> & {
    isSingleCreatorCollection?: boolean;
    collectionUrl?: string;
};

export const MoreEventsFromMount = (
    props: RelatedEventsChildrenProps,
): JSX.Element | React.ReactPortal | null =>
    dynaMount(
        {
            Component: MoreEventsFrom,
            containerId: MORE_EVENTS_FROM_ID,
        },
        props,
    );

export const OtherEventsYouMayLikeMount = (
    props: OtherEventsYouMayLikeProps,
): JSX.Element | React.ReactPortal | null =>
    dynaMount(
        {
            Component: OtherEventsYouMayLike,
            containerId: RELATED_EVENTS_ID,
        },
        props,
    );

export const RelatedEventsWrapper = (
    props: RelatedEventsProps,
): JSX.Element | null => {
    const {
        organizerId,
        eventId,
        creatorCollections,
        isAuthenticated,
        shareComponentProps,
        areMetricsEnabled,
        locale,
    } = props;

    const isSingleCreatorCollection = creatorCollections?.length === 1;
    const collectionUrl =
        (creatorCollections && creatorCollections[0]?.relative_url) ||
        undefined;
    const collectionId = creatorCollections && creatorCollections[0]?.id;

    const relatedEvents = useRelatedEvents({
        organizerId,
        eventId,
        isAuthenticated,
        collectionsLength: creatorCollections?.length,
        collectionId,
    });

    return (
        <div data-testid="related-events-wrapper">
            {relatedEvents.eventsSameOrganizer && (
                <MoreEventsFromMount
                    {...props}
                    events={relatedEvents.eventsSameOrganizer || []}
                />
            )}
            {relatedEvents.eventsSameCollection && (
                <MoreEventsFromMount
                    {...props}
                    events={relatedEvents.eventsSameCollection || []}
                    isSingleCreatorCollection={isSingleCreatorCollection}
                    collectionUrl={collectionUrl}
                />
            )}

            <OtherEventsYouMayLikeMount
                eventId={eventId}
                isAuthenticated={isAuthenticated}
                shareComponentProps={shareComponentProps}
                areMetricsEnabled={areMetricsEnabled}
                locale={locale}
                eventsToRemove={[
                    ...(relatedEvents.eventsSameOrganizer || []),
                    ...(relatedEvents.eventsSameCollection || []),
                ]}
            />
        </div>
    );
};

export interface EventsOtherOrganizers {
    filteredEvents: FormattedEvent[];
    promotedEvents: FormattedEvent[];
}

export const useRelatedEvents = ({
    eventId,
    organizerId,
    isAuthenticated,
    collectionId,
    collectionsLength = 0,
}: {
    organizerId: string;
    eventId: string;
    isAuthenticated: boolean;
    collectionsLength?: number;
    collectionId?: string;
}) => {
    const [eventsSameOrganizer, setEventsSameOrganizer] =
        useState<FormattedEvent[]>();
    const [eventsSameCollection, setEventsSameCollection] =
        useState<FormattedEvent[]>();
    const [isLoading, setIsLoading] = useState<Boolean>(true);
    const eventBasicInformation = useContext(EventBasicInformationContext);

    useEffect(() => {
        if (!eventBasicInformation) {
            return;
        }

        const fetchEventsSameOrganizer = async () => {
            try {
                const response = await getOrganizerRelatedEventsFromDestination(
                    organizerId,
                    eventId,
                );
                const events = response?.events || [];
                setEventsSameOrganizer(events);
                setIsLoading(false);
                return events;
            } catch (error) {
                console.error(
                    `Error fetching events from organizer ${organizerId}: ${error}`,
                );
                setIsLoading(false);
            }
        };

        const fetchEventsSameCollection = () => {
            sdkRequest(
                `/collections/${collectionId}/events/public?time_filter=current_future`,
            ).then((response: EventsFromCollectionResponse) => {
                const filteredEvents = filterEvents(
                    NUMBER_OF_MORE_EVENTS_TO_SHOW,
                    response.events,
                    eventId,
                );
                const transformedEvents = filteredEvents.map(
                    transformCollectionEvent,
                );
                setEventsSameCollection(transformedEvents);
                return transformedEvents;
            });
        };

        if (collectionsLength === 0) {
            fetchEventsSameOrganizer();
        } else if (collectionsLength === 1) {
            fetchEventsSameCollection();
        }
    }, [
        collectionId,
        collectionsLength,
        eventBasicInformation,
        eventId,
        organizerId,
        isAuthenticated,
    ]);

    return { eventsSameOrganizer, eventsSameCollection, isLoading };
};
export default RelatedEventsWrapper;
