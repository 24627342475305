import { getWindowObject } from '@eventbrite/feature-detection';
import { useCallback, useEffect, useState } from 'react';

export const useIsTouchDevice = () => {
    const [hasTouched, setHasTouched] = useState<boolean>(false);
    const [isTouchDevice, setIsTouchDevice] = useState<boolean>(false);

    const updateHasTouched = useCallback(() => {
        setHasTouched(true);
    }, []);

    useEffect(() => {
        setIsTouchDevice(
            !!getWindowObject('ontouchstart') ||
                (getWindowObject('navigator')?.maxTouchPoints ?? 0) > 0,
        );
        window.addEventListener('touchstart', updateHasTouched, false);
        return () =>
            window.removeEventListener('touchstart', updateHasTouched, false);
    }, [updateHasTouched]);

    return hasTouched || isTouchDevice;
};
