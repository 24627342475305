import { Avatar } from '@eventbrite/eds-avatar';
import React from 'react';
import DefaultImageVariants from '../../DefaultImageVariants/DefaultImageVariants';
import { testIds } from './constants';
import styles from './ListingsAvatarBadge.module.scss';
import type { IListingsAvatarProps } from './types';

export const ListingsAvatar: React.FC<IListingsAvatarProps> = ({
    organizerAvatar,
    organizerId,
    featureFlags,
}) => {
    const enableCreatorDefaultAvatar =
        featureFlags?.enableCreatorDefaultAvatar || false;

    const defaultAvatarJSX =
        (enableCreatorDefaultAvatar && (
            <div
                className={styles.avatarImage}
                data-testid={testIds.defaultAvatar}
            >
                <DefaultImageVariants organizerId={organizerId} />
            </div>
        )) ||
        null;

    return organizerAvatar ? (
        <Avatar imageUrl={organizerAvatar} />
    ) : (
        defaultAvatarJSX
    );
};
