import { HAS_WINDOW } from '@eventbrite/feature-detection';
import React, { useEffect, useState } from 'react';
import { useEventBasicInformationContext } from '../../../contexts';
import { LocalizedDateTime } from '../../../types';
import { MultipleDatesView, StartDateView } from './StartDateView';
import { getFormattedStartDate } from './utils';

export type StartDateContainerProps = {
    isOnlineEvent: boolean;
    locale: string;
};

export const StartDateContainer = ({
    isOnlineEvent,
    locale,
}: StartDateContainerProps) => {
    const eventBasicInformation = useEventBasicInformationContext();
    const dateTime: LocalizedDateTime | null | undefined =
        eventBasicInformation?.start;
    const [formattedDates, setFormattedDates] = useState(
        !dateTime
            ? null
            : getFormattedStartDate({
                  eventStartDateTime: dateTime,
                  isOnlineEvent,
                  locale,
              }),
    );

    useEffect(() => {
        if (!!dateTime && isOnlineEvent) {
            setFormattedDates(
                getFormattedStartDate({
                    eventStartDateTime: dateTime,
                    isOnlineEvent,
                    locale,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!eventBasicInformation?.start) {
            return;
        }

        setFormattedDates(
            getFormattedStartDate({
                eventStartDateTime: eventBasicInformation.start,
                isOnlineEvent,
                locale,
            }),
        );
    }, [eventBasicInformation?.start, isOnlineEvent, locale]);

    if (HAS_WINDOW) {
        window.__i18n__ = { locale };
    }

    if (!formattedDates) {
        return <MultipleDatesView />;
    }

    return (
        <StartDateView
            dateTimeText={formattedDates.dateTime}
            dateText={formattedDates.formattedDateTime}
        />
    );
};
