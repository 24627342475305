import { NotificationBar } from '@eventbrite/eds-notification';
import {
    NOTIFICATION_POSITION_TOAST,
    NOTIFICATION_POSITION_TOP,
} from '@eventbrite/eds-structure';
import classNames from 'classnames';
import React from 'react';
import '../../styles/notification.scss';
import { NotificationProps } from '../../types';

const Notification = ({ options, fullWidth }: NotificationProps) => {
    let component = null;

    if (options) {
        /* Should stick comes from the AddMainControls HOC to determine
        if the notification component should stick to top of viewport */
        /* isClosing comes from the AddMainControls HOC and the addFocusDrawerControls HOC
        to determine if the notification component should animate out of view */
        const { position, shouldStick, isClosing, ...notificationOptions } =
            options;

        const isTop = !position || position === NOTIFICATION_POSITION_TOP;
        const isToast = position === NOTIFICATION_POSITION_TOAST;

        const classes = classNames('eds-structure__notification', {
            'eds-structure__notification--position-top': isTop,
            'eds-structure__notification--position-toast': isToast,
            'eds-structure__notification--sticky': shouldStick,
            'sticky-notification': shouldStick && isTop,
            'eds-structure__notification--is-closing': isClosing,
            'eds-g-cell-md-10-12': isTop && !fullWidth,
            'eds-g-offset-md-1-12': isTop && !fullWidth,
            'eds-g-cell-12-12': isTop && fullWidth,
        });

        component = (
            <div className={classes}>
                <NotificationBar {...notificationOptions} />
            </div>
        );
    }

    return component;
};

export default Notification;
