import UAParser from 'ua-parser-js';

type UADevices =
    | 'console'
    | 'mobile'
    | 'tablet'
    | 'smarttv'
    | 'wearable'
    | 'embedded'
    | 'desktop'
    | 'other';

/**
 * Possible values:
 * 'console' 'mobile' 'tablet' 'smarttv' 'wearable' 'embedded' 'desktop' 'other'
 */
export interface UADevice extends UAParser.IDevice {
    type: UADevices;
}
export type UADeviceType = Pick<UADevice, 'type'>;

/**
 * Helper method that pulls logic from the python user-agents
 * package to create a best guess at if the given user agent
 * is a PC/Desktop device.
 *
 * There isn't a 1:1 in available data between the two libs, but
 * this is a close proxy to align behaviors.
 *
 * `ua-parser-js` does not support providing inferred values and so
 * we must write the logic ourselves.
 * https://github.com/faisalman/ua-parser-js/tree/153831d2edb73db1f066c5638da733d302027a1e#-getdeviceidata
 *
 * Source: https://github.com/selwin/python-user-agents/blob/main/user_agents/parsers.py#L253-L268
 *
 * @param parsedUserAgent
 * @returns
 */
function guessIfDesktop(
    parsedUserAgent: UAParser.UAParserInstance,
): 'desktop' | 'other' {
    const { name } = parsedUserAgent.getOS();
    const uaString = parsedUserAgent.getUA();

    const isWindows = name && name.includes('Windows');
    const isMAC = name === 'Mac OS' && !uaString.includes('Silk');
    const isChromium = name === 'Chromium OS';
    const isLinux = uaString.includes('Linux') && uaString.includes('X11');

    const isDesktop = isWindows || isMAC || isChromium || isLinux;
    return isDesktop ? 'desktop' : 'other';
}

export function getDeviceType(userAgentString: string): UADeviceType {
    const result = new UAParser(userAgentString);
    const { type } = result.getDevice() as UADevice;
    let inferredDeviceType;

    if (type === 'mobile' || type === 'tablet') {
        return { type };
    }

    try {
        inferredDeviceType = guessIfDesktop(result);
    } catch (error) {}

    if (inferredDeviceType === 'desktop') {
        return { type: inferredDeviceType };
    }

    return type !== undefined ? { type } : { type: 'other' };
}
