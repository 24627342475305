export type ErrorState = {
    title?: string;
    description?: string;
    closable?: boolean;
    autoClose?: boolean;
    autoCloseTime?: number;
};

export enum ErrorType {
    default = 'default',
    custom = 'custom',
}

export type ErrorDispatchAction =
    | {
          action: 'clear';
      }
    | {
          action: ErrorType.default;
      }
    | {
          action: ErrorType.custom;
          error: ErrorState;
      };
