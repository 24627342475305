import { getWindowObject } from '@eventbrite/feature-detection';
import {
    closeJourney,
    logEvent,
    setBranchViewData,
    setIdentity,
    track,
} from 'branch-sdk';

const customActions: any = {
    FollowClicked: 'follow_organizer',
    SaveClicked: 'save_event',
    FollowCollectionClicked: 'follow_collection',
};

declare global {
    interface Window {
        branch?: {
            closeJourney: typeof closeJourney;
            logEvent: typeof logEvent;
            setBranchViewData: typeof setBranchViewData;
            setIdentity: typeof setIdentity;
            track: typeof track;
        };
    }
}

/**
 * displayJourneyUsingMetatags
 *
 * Add specific metatags to display a journey
 */
export const displayJourneyUsingMetatags = ({
    name,
    content,
    extraData = null,
    closeExistingJourney = true,
    timeout = 1000,
    keepLoginMetadata = true,
}: {
    name: string;
    content: string;
    extraData: any;
    closeExistingJourney?: Boolean;
    timeout?: number;
    keepLoginMetadata?: Boolean;
}) => {
    let isClosing = false;
    // Only if sdk is enabled in this page
    const branchSdk = getWindowObject('branch');
    if (branchSdk) {
        const metadata = {
            [name]: content,
            custom_action: customActions[name] ? customActions[name] : name,
        };

        //Keep userAuthenticated metadata
        if (
            keepLoginMetadata &&
            getWindowObject('__SERVER_DATA__')?.user?.isAuthenticated
        ) {
            metadata.userAuthenticated = '1';
        }
        //Adding metadata
        addMetadata({ extraData: metadata });

        //Close
        if (closeExistingJourney) isClosing = closeBranchJourney();

        //Add extra data
        extraData && addMetadata({ extraData });

        //Reopen
        if (isClosing) {
            // To be sure that previous journey has been closed, we need to use a timeout
            setTimeout(() => branchSdk.track('pageview'), timeout);
        } else {
            branchSdk.track('pageview');
        }
    }
};

/**
 * addEventToJourney
 *
 * Add event to a journey
 */
export const addEventToJourney = ({
    eventName,
    customData = {},
}: {
    eventName: string;
    customData: any;
}) => {
    const branchSdk = getWindowObject('branch');
    if (branchSdk) {
        branchSdk.logEvent(eventName, customData);
    }
};

/**
 * sendExtraDataToJourney
 *
 * Add extra data in a journey
 */
export const sendExtraDataToJourney = ({
    extraData,
    closeExistingJourney = true,
    openJourney = true,
    timeout = 1000,
    keepLoginMetadata = true,
}: {
    extraData: any;
    closeExistingJourney?: Boolean;
    openJourney?: Boolean;
    timeout?: number;
    keepLoginMetadata?: Boolean;
}) => {
    let isClosing = false;
    // Only if sdk is enabled in this page
    const branchSdk = getWindowObject('branch');
    if (branchSdk) {
        if (
            keepLoginMetadata &&
            getWindowObject('__SERVER_DATA__')?.user?.isAuthenticated
        ) {
            extraData.userAuthenticated = '1';
        }
        //Close
        if (closeExistingJourney) isClosing = closeBranchJourney();

        //Add extra data
        branchSdk.setBranchViewData(extraData);

        //Reopen
        if (openJourney) {
            //Reopen
            if (isClosing) {
                // To be sure that previous journey has been closed, we need to use a timeout
                setTimeout(() => branchSdk.track('pageview'), timeout);
            } else {
                branchSdk.track('pageview');
            }
        }
    }
};

/**
 * setUserIdentity
 *
 * Set user identity to track it.
 */
export const setUserIdentity = ({ userIdentity }: { userIdentity: any }) => {
    const branchSdk = getWindowObject('branch');
    if (branchSdk) {
        branchSdk.setIdentity(userIdentity);
    }
};

/**
 * closeBranchJourney
 *
 * Close banner
 */
export const closeBranchJourney = (): boolean => {
    if (doesJourneyExistOnPage()) {
        const branchSdk = getWindowObject('branch');
        if (branchSdk) {
            branchSdk.closeJourney();
            return true;
        }
    }
    return false;
};

/**
 * doesJourneyExistOnPage
 *
 * function to check if a banner is displaying
 */
export const doesJourneyExistOnPage = () => {
    const branchBannerIframe = document.getElementById('branch-banner-iframe');
    if (branchBannerIframe) {
        return true;
    }
    return false;
};

/**
 * addMetadata
 *
 * Add metadata to the page
 */
export const addMetadata = ({ extraData }: { extraData: Object }) => {
    if (document) {
        Object.entries(extraData).forEach(([key, value]) => {
            const meta = document.createElement('meta');

            if (
                typeof value === 'object' &&
                !Array.isArray(value) &&
                value !== null
            ) {
                meta.content = JSON.stringify(value);
            } else {
                meta.content = value;
            }

            meta.name = 'branch:deeplink:' + key;
            document.head.appendChild(meta);
        });
    }
};
