import React from 'react';
import {
    EnabledUrgencySignals,
    UrgencyCategories,
    UrgencyCategoryType,
    UrgencyMessages,
    UrgencyMessageType,
    UrgencySignalsProps,
} from './types';

const UrgencySignalsContext = React.createContext<EnabledUrgencySignals | null>(
    null,
);

export const UrgencySignalsProvider: React.FC<{
    urgencySignals: UrgencySignalsProps;
}> = ({ urgencySignals, children }) => {
    return (
        <UrgencySignalsContext.Provider
            value={urgencySignalsToShow(urgencySignals)}
        >
            {children}
        </UrgencySignalsContext.Provider>
    );
};

export const hasUrgencySignalsEnabled = (
    urgencySignals?: EnabledUrgencySignals | null,
) => {
    if (!urgencySignals) {
        return false;
    }

    const hasUrgencyMessages = Object.values(urgencySignals.messages).includes(
        true,
    );
    const hasUrgencyCategories = Object.values(
        urgencySignals.categories,
    ).includes(true);

    return hasUrgencyMessages || hasUrgencyCategories;
};

export const useHasUrgencySignalsEnabled = () => {
    const urgencySignals = React.useContext(UrgencySignalsContext);

    return hasUrgencySignalsEnabled(urgencySignals);
};

export const useUrgencyCategories = () => {
    const urgencySignals = React.useContext(UrgencySignalsContext);

    if (urgencySignals === null) {
        return {};
    }

    return urgencySignals?.categories;
};

export const useUrgencyMessages = () => {
    const urgencySignals = React.useContext(UrgencySignalsContext);

    if (urgencySignals === null) {
        return {};
    }

    return urgencySignals?.messages;
};

export const urgencySignalsToShow = (
    urgencySignals: UrgencySignalsProps,
): EnabledUrgencySignals | null => {
    const MAX_URGENCY_SIGNALS_TO_SHOW = 2;

    if (!urgencySignals) {
        return null;
    }

    const activeUrgencyMessage = pickActiveUrgencyMessageByPriority(
        urgencySignals.messages,
    );

    const activeUrgencyCategories = pickActiveUrgencyCategoriesByPriority(
        urgencySignals.categories,
        MAX_URGENCY_SIGNALS_TO_SHOW - Object.keys(activeUrgencyMessage).length,
    );

    return {
        messages: activeUrgencyMessage,
        categories: activeUrgencyCategories,
    };
};

export const pickActiveUrgencyMessageByPriority = (
    messages: UrgencyMessages,
) => {
    const messagePriority = [
        UrgencyMessageType.GoingFast,
        UrgencyMessageType.FewTickets,
        UrgencyMessageType.SalesEndSoon,
    ];

    const activeUrgencyMessageByPriority: UrgencyMessageType | undefined =
        messagePriority.find((message) => messages[message]);

    return activeUrgencyMessageByPriority
        ? { [activeUrgencyMessageByPriority]: true }
        : {};
};

export const pickActiveUrgencyCategoriesByPriority = (
    categories: UrgencyCategories,
    slotsLeft: number,
) => {
    const result: Partial<Record<UrgencyCategoryType, boolean>> = {};

    if (slotsLeft === 0 || !categories) {
        return result;
    }

    const categoriesSortedByPriority = [
        UrgencyCategoryType.EarlyBird,
        UrgencyCategoryType.Popular,
        UrgencyCategoryType.New,
    ];

    for (const categoryName of categoriesSortedByPriority) {
        if (slotsLeft > 0 && categories[categoryName]) {
            result[categoryName] = true;
            slotsLeft--;
        }
    }

    return result;
};
