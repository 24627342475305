import { ContentSlider } from '@eventbrite/eds-content-slider';
import { gettext } from '@eventbrite/i18n';
import React, { useEffect, useRef, useState } from 'react';
import {
    AgendaWrapper,
    SlotsWrapper,
    TabItem,
    TabsArea,
    ViewAllButton,
    ViewAllButtonArea,
} from './Agenda.styles';
import { SlotPreview } from './SlotPreview';
import { AgendaWidget, AnimationDirection } from './types';

interface Props {
    agenda: AgendaWidget;
}

export const Agenda: React.FC<Props> = ({ agenda }) => {
    const tabs = agenda?.tabs || [];
    const shouldDisplayTabs = tabs.length > 1;
    const [currentTab, changeCurrentTab] = useState(0);
    const [slots, changeSlots] = useState(tabs?.[currentTab]?.slots || []);
    const [slotsAnimateFrom, changeSlotsAnimateFrom] =
        useState<AnimationDirection>();
    const [mayCollapseAgenda, updateMayCollapseAgenda] = useState(false);
    const [agendaIsCollapsed, toggleagendaIsCollapsed] = useState<
        boolean | null
    >(true);

    const agendaWrapperElement = useRef<HTMLDivElement>(null);

    const switchToTab = (index: number) => {
        const animationDirection =
            currentTab - index < 0
                ? AnimationDirection.right
                : AnimationDirection.left;

        changeSlotsAnimateFrom(animationDirection);
        changeCurrentTab(index);
        changeSlots(agenda?.tabs[index].slots);
    };

    useEffect(() => {
        if (
            agendaWrapperElement.current &&
            agendaWrapperElement.current.offsetHeight
        ) {
            if (agendaWrapperElement.current.offsetHeight >= 700) {
                updateMayCollapseAgenda(true);

                if (agendaIsCollapsed === null) {
                    toggleagendaIsCollapsed(true);
                }
            } else {
                updateMayCollapseAgenda(false);
            }
        }
    }, [agendaIsCollapsed, slots]);

    return slots.length ? (
        <AgendaWrapper
            data-testid="agenda-component"
            ref={agendaWrapperElement}
            agendaIsCollapsed={agendaIsCollapsed}
        >
            {shouldDisplayTabs && (
                <ContentSlider>
                    <TabsArea>
                        {tabs.map((tab, index) => (
                            <TabItem
                                key={index}
                                active={index === currentTab}
                                onClick={() => switchToTab(index)}
                            >
                                {tab.name}
                            </TabItem>
                        ))}
                    </TabsArea>
                </ContentSlider>
            )}
            <SlotsWrapper key={currentTab} slotsAnimateFrom={slotsAnimateFrom}>
                {slots.map((slot, slotIndex) => (
                    <SlotPreview
                        key={slotIndex}
                        slot={slot}
                        slotIndex={slotIndex}
                    />
                ))}
            </SlotsWrapper>
            {mayCollapseAgenda && (
                <ViewAllButtonArea agendaIsCollapsed={agendaIsCollapsed}>
                    <ViewAllButton
                        onClick={() => {
                            toggleagendaIsCollapsed(!agendaIsCollapsed);
                        }}
                    >
                        {agendaIsCollapsed
                            ? gettext('View full agenda')
                            : gettext('Collapse agenda')}
                    </ViewAllButton>
                </ViewAllButtonArea>
            )}
        </AgendaWrapper>
    ) : null;
};
