import classNames from 'classnames';
import React from 'react';
import './EventDetails.scss';

export type EventDetailsSectionProps = {
    ariaLabelledBy?: string;
};

type EventDetails = {
    hasHeroSection: boolean;
};

export const EventDetails: React.FC<EventDetails> = ({
    children,
    hasHeroSection,
}) => {
    const classes = classNames('event-details', {
        'event-details--has-hero-section': hasHeroSection,
    });

    return (
        <div className={classes} data-testid="event-details">
            {children}
        </div>
    );
};

export const EventDetailsWrapper: React.FC = ({ children }) => {
    return <div className="event-details__wrapper">{children}</div>;
};

export const EventDetailsMainInner: React.FC = ({ children }) => {
    return <div className="event-details__main-inner">{children}</div>;
};

export const EventDetailsSection: React.FC<EventDetailsSectionProps> = ({
    children,
    ariaLabelledBy,
}) => {
    return (
        <section
            aria-labelledby={ariaLabelledBy}
            className="event-details__section"
        >
            {children}
        </section>
    );
};

export const EventDetailsSectionTitle: React.FC = ({ children }) => {
    return <div className="event-details__section-title">{children}</div>;
};
