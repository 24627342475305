import { Reducer } from 'react';
import type { ErrorDispatchAction, ErrorState } from './types';

export const errorReducer: Reducer<ErrorState | null, ErrorDispatchAction> = (
    _,
    payload,
): ErrorState | null => {
    if (payload.action === 'clear') {
        return null;
    }
    if (payload.action === 'default') {
        return {
            autoClose: false,
            closable: true,
        };
    }
    return {
        ...payload.error,
        closable: payload.error.closable || !payload.error.autoClose,
    };
};
