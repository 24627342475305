import { LoadingOverlay } from '@eventbrite/eds-loading-overlay';
import React, { useEffect, useRef, useState } from 'react';

/**
 * Displays loading Overlay after 300 miliseconds
 */
export const Loading: React.FunctionComponent = () => {
    const [isShown, setIsShown] = useState(false);
    const mounted = useRef(false);

    setTimeout(() => {
        if (mounted.current) {
            setIsShown(true);
        }
    }, 300);

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    if (!isShown) {
        return null;
    }

    return (
        <div data-testid="progress-indicator">
            <LoadingOverlay size="large-thin" isShown={true} />
        </div>
    );
};
