import { getFormattedTime, StartOrEnd } from '@eventbrite/event-datetime';
import { gettext } from '@eventbrite/i18n';
import React, { useEffect, useState } from 'react';
import { useIsServerSideRendered } from '../../../hooks/useIsServerSideRendered';

type DoorTimeProps = {
    dateAndTimezone: StartOrEnd;
    locale: string;
    isOnlineEvent: boolean;
};

const getFormattedDoorTime = ({
    dateAndTimezone,
    locale,
    isOnlineEvent,
}: DoorTimeProps) => {
    // Translators: This is showing a door time. For example "Doors at 8pm"
    return gettext('Doors at %(formattedTime)s', {
        formattedTime: getFormattedTime({
            dateAndTimezone,
            locale,
            isOnlineEvent,
        }),
    });
};

export const DoorTime = ({
    dateAndTimezone,
    locale,
    isOnlineEvent,
}: DoorTimeProps) => {
    const [doorTime, setDoorTime] = useState(() =>
        getFormattedDoorTime({
            dateAndTimezone,
            locale,
            isOnlineEvent: shouldUseTimezoneFromAttendee,
        }),
    );

    const { isServerSideRendered } = useIsServerSideRendered();
    useEffect(() => {
        if (isOnlineEvent) {
            setDoorTime(
                getFormattedDoorTime({
                    dateAndTimezone,
                    locale,
                    isOnlineEvent,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isServerSideRendered]);

    const shouldUseTimezoneFromAttendee =
        isOnlineEvent && !isServerSideRendered;

    return <time dateTime={dateAndTimezone.utc}>{doorTime}</time>;
};
