// copied from https://github.com/eventbrite/eb-ui/blob/main/packages/attendee/event-renderer/src/components/EventCardPrice/utils.ts
interface GetLocalizedFormattedPriceStringConfig {
    locale: string;
    currency: string;
    value: number;
}

export const getLocalizedFormattedPriceString = ({
    locale,
    currency,
    value,
}: GetLocalizedFormattedPriceStringConfig) => {
    const formattedLocale = locale.replace('_', '-');

    //try to use narrow symbol but some versions of Safari
    //do not support it so fallback to symbol.
    const numberFormatConfig = {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency,
    };

    try {
        return new Intl.NumberFormat(
            formattedLocale,
            numberFormatConfig,
        ).format(value / 100);
    } catch (error: unknown) {
        if (error instanceof RangeError || error instanceof TypeError) {
            return new Intl.NumberFormat(formattedLocale, {
                ...numberFormatConfig,
                currencyDisplay: 'symbol',
            }).format(value / 100);
        }

        throw error;
    }
};
