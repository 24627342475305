import { GREYS_JSON } from '@eventbrite/eds-color';
import {
    PlaceholderSvg1,
    PlaceholderSvg2,
    PlaceholderSvg3,
    PlaceholderSvg4,
} from '@eventbrite/eds-iconography';
import { Illustration } from '@eventbrite/eds-illustration';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import './Image.scss';

export const FALLBACK_IMAGE_LIST = [
    <PlaceholderSvg1 key="fallback-image-1" />,
    <PlaceholderSvg2 key="fallback-image-2" />,
    <PlaceholderSvg3 key="fallback-image-3" />,
    <PlaceholderSvg4 key="fallback-image-4" />,
];

interface ImageProps {
    url?: string;
    backgroundColor?: string;
    name: string;
    id?: string;
}

export const Image: React.FunctionComponent<ImageProps> = (props) => {
    if (props.url) {
        return (
            <div
                data-testid="creator-collection-image"
                className="creator-collection-card__image--aspect-container"
                style={{
                    backgroundColor: props.backgroundColor
                        ? props.backgroundColor
                        : GREYS_JSON['grey-200'],
                }}
            >
                <div className="creator-collection-card__image--image-position">
                    <img
                        loading="lazy"
                        className="creator-collection-card__image"
                        src={props.url}
                        alt={gettext('Collection image for %(name)s', {
                            name: props.name,
                        }).toString()}
                    />
                </div>
            </div>
        );
    }

    const numberFromId = props.id ? parseInt(props.id, 10) % 4 : 0;
    const RandomImageSvg = FALLBACK_IMAGE_LIST[numberFromId];
    const fallbackImage = (
        <Illustration
            type={RandomImageSvg ? RandomImageSvg : FALLBACK_IMAGE_LIST[0]}
            width="100%"
            height="100%"
        />
    );

    return (
        <div
            data-testid="fallback-collection-card-image"
            className="creator-collection-card__image--aspect-container"
        >
            <div className="creator-collection-card__image--image-position">
                {fallbackImage}
            </div>
        </div>
    );
};
