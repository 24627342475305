import { CreatorCollection, FormattedCreatorCollection } from '../types';

export const transformCreatorCollection = (
    collection: CreatorCollection,
): FormattedCreatorCollection => {
    return {
        slug: collection.slug,
        name: collection.name,
        summary: collection.summary,
        id: collection.id,
        organizer: {
            name: collection?.organizer?.name,
            imageUrl: collection?.organizer?.logo?.url,
        },
        imageUrl: collection?.image?.url,
        collectionImageBackgroundColor: collection?.image?.edge_color,
        numUpcomingEvents: collection?.event_count?.current_future_published,
    };
};
