import PropTypes from 'prop-types';
import { gettext, translationPropType } from '@eventbrite/i18n';

import {
    TYPES_PROP_TYPE,
    STYLES_PROP_TYPE,
    IMAGE_STYLES_PROP_TYPE,
} from './_internal/constants';
import { SVG_PROP_TYPE } from '@eventbrite/eds-vector-image';

export const EVENT_CARD_SHARE_OPTIONS_PROPTYPE = PropTypes.shape({
    /**
     * Formatted url with utm term/campaign/content/source, etc included
     */
    eventUrl: PropTypes.string,
    /**
     * Affiliate tracking to be included in the share link
     */
    trackingCode: PropTypes.string,
    /*
     * Extra UTM options
     */
    utmOptions: PropTypes.object,
    /*
     * URL of the current server
     */
    serverUrl: PropTypes.string,
    /*
     * EB Twitter handle
     */
    twitterHandle: PropTypes.string,
    /*
     * If client is mobile
     */
    isMobile: PropTypes.bool,
    /*
     * Facebook specific share options
     */
    facebookOptions: PropTypes.shape({
        page: PropTypes.string,
        locale: PropTypes.string,
        appId: PropTypes.string,
        version: PropTypes.string,
        xfbml: PropTypes.bool,
    }),
});

export const EVENT_CARD_PROPTYPE = {
    /*
     * ID of the associated event
     */
    id: PropTypes.string.isRequired,
    /*
     * Name of the event. Will be truncated
     * past 3 lines or 75 characters
     */
    name: PropTypes.string.isRequired,
    /*
     * Description of the event.
     * This accepts any generic react element.
     */
    description: PropTypes.node,
    /*
     * Event listing UR
     */
    url: PropTypes.string.isRequired,

    /*
     * Func passed down from `withOverlayControls`
     * which is applied in `EnhancedEventCard`
     */
    showOverlay: PropTypes.func,

    /* Share props */

    /*
     * Configuration options necessary for instantiating the sharebox
     * and sharing of the given event. Including the proper share URL
     */
    shareOptions: EVENT_CARD_SHARE_OPTIONS_PROPTYPE,
    /*
     * Callback when the user clicks the share icon to display the list of share options
     * (mainly useful for analytics tracking)
     */
    onWillShareClick: PropTypes.func,
    /*
     * Callback when user selects a share option, passes
     * the selected option back as param
     */
    onDidShareClick: PropTypes.func,

    /* Save / Unsave Props */

    /*
     * Callback when user opts to save the event
     * Passes back event ID, and boolean representing
     * if the event _should_ be saved. ie, shouldSave=true
     *
     * This callback is also what is called when the user
     * selects "Continue" from the logged out experience
     * to sign in and save the event. The logic around
     * handling that is not included with this component.
     *
     * Check /search/actions/event for an example of this
     * being handled.
     */
    onSaveEvent: PropTypes.func,
    /*
     * If the event has been saved by the current user
     */
    savedByYou: PropTypes.bool,
    /*
     * If the current user is authenticated
     */
    isAuthenticated: PropTypes.bool,

    /* Collection props */

    /*
     * the users total list of collections
     * if event card does not include collections it's undefined
     * if user has no collections value is an empty array
     */
    userCollections: PropTypes.arrayOf(
        PropTypes.shape({
            eventCount: PropTypes.number,
            href: PropTypes.string,
            id: PropTypes.string,
            name: PropTypes.string,
        }),
    ),
    /*
     * array of collection IDs that the event is part of
     */
    eventCollections: PropTypes.arrayOf(PropTypes.string),
    /*
     * Callback that triggers action to add a new collection to state and call API
     * If passed a "thenable" function, it will render a notification linking the
     * user to the newly created element and also display server errors when they occur
     */
    onCreateCollection: PropTypes.func,
    /*
     * Callback that triggers action to add event to selected collection in state and API
     * If passed a "thenable" function, it will render a notification linking the
     * user to a given collection and also display server errors when they occur
     */
    onAddOrRemoveEventToCollection: PropTypes.func,

    /* Image props */

    /*
     * Image URL of the event
     */
    imageUrl: PropTypes.string,
    /*
     * Uses native "loading" attribute
     * supported by most browsers
     */
    isLazyImage: PropTypes.bool,
    /*
     * Edgecolor of the image to provide a better
     * loading experience
     */
    edgeColor: PropTypes.string,

    /* Event info Props */

    /*
     * YYYY-MM-DD string
     */
    startDate: PropTypes.string,
    /*
     * HH:MM:SS string
     */
    startTime: PropTypes.string,
    /*
     * String descriptor of the venue that will
     * appear in the card meta data
     */
    locationInfo: PropTypes.oneOfType([PropTypes.string, translationPropType]),
    /*
     * Ticket supplier
     */
    ticketsBy: PropTypes.string,
    /*
     * Raw priving data passed in and the card will handle generating
     * the associated formatted string based on the currency information
     * provided.
     */
    minPrice: PropTypes.shape({
        minPriceValue: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        currencyFormat: PropTypes.string.isRequired,
    }),
    /**
     * Pre-formatted string representing the
     * price of the event
     */
    formattedPriceString: PropTypes.string,
    /*
     * Should be passed in if event is a repeating event
     * The number of upcoming instances of a repeating
     * event to be rendered on the card
     */
    repeatingInstanceCount: PropTypes.number,

    /* Card Configuration Props */

    /*
     * Callback triggered when the eventCard title or
     * image is clicked. Navigating to the passed in url.
     * Useful for tracking purposes or to override behavior
     * onClick
     */
    onClick: PropTypes.func,
    /*
     * Spacing classes that can be applied between the Card
     * and EventCardContent components
     */
    containerSpacingClasses: PropTypes.string,
    /*
     * Type of card to display
     */
    type: TYPES_PROP_TYPE,
    /*
     * Style of card to display
     */
    style: STYLES_PROP_TYPE,
    /*
     * Image style the card should use
     */
    imageStyle: IMAGE_STYLES_PROP_TYPE,
    /*
     * If the click through link should have
     * no follow attribute for SEO concerns
     */
    isNoFollow: PropTypes.bool,
    /*
     * Defaults to true
     * if icons are hidden - set to false
     */
    shouldShowIcons: PropTypes.bool,
    /*
     * If the price description should not be displayed
     * This doesn't affect the 'Free' flag of the card
     */
    hidePriceDescription: PropTypes.bool,
    /*
     * Passed via withNotificationHoC for the eventcard
     *
     * Notification config object for the mobile
     * instance of the CollectionView
     */
    modalNotificationConfiguration: PropTypes.shape({
        children: PropTypes.node,
        type: PropTypes.string,
        iconType: SVG_PROP_TYPE,
        hasCloseButton: PropTypes.bool,
    }),
    /*
     * Passed via withNotificationHoC for the eventcard
     *
     * Callback triggered if the user clicks the close
     * button of the notification rendered in the mobile
     * CollectionView.
     */
    onModalNotificationClose: PropTypes.func,
    /*
     * Callback for when the addToCollection
     * dropdown or modal is rendered
     */
    onShowAddToCollectionView: PropTypes.func,
    /*
     * Callback for when the user switches add
     * collection view to the create collection view
     */
    onShowCreateCollectionView: PropTypes.func,

    /*
     * Boolean for whether or not url link on event card should open in new tab
     */
    isTargetBlank: PropTypes.bool,

    /*
     * If provided, will show the timezone of the event on the card. For now, only
     * shows timezone if the event is online.
     */
    timezone: PropTypes.string,

    /*
     * Boolean for whether or not the event takes place online, rather than
     * at a physical location
     */
    isOnlineEvent: PropTypes.bool,

    /*
     * If true, the Event Card will convert the event date/time to the user's timezone.
     * For now, only affects online events.
     */
    shouldConvertToUserTimezone: PropTypes.bool,

    /**
     * Means to programmatically control if the eventcard is "raised" (akin to hovering
     * on desktop). Focus also entails a similar visual state, but this includes no
     * manipulation of the browser focused state.
     */
    isRaised: PropTypes.bool,

    /*
     * Event sales status from the expansion event_sales_status
     */
    eventSalesStatus: PropTypes.shape({
        salesStatus: PropTypes.string,
        message: PropTypes.string,
        messageType: PropTypes.string,
        messageCode: PropTypes.string,
    }),

    affCode: PropTypes.string,
};

export const EVENTBRITE = 'Eventbrite';
export const SHARE_BY_COPY = 'copy';
export const FREE_FLAG_TEXT = gettext('Free');
export const SOLD_OUT_FLAG_TEXT = gettext('Sold Out');
export const EVENT_TITLE_MAX_LENGTH = 75;
export const HEAP_EVENT_CARD_CLICK = 'EventCardClick';

// Moment formatting for events in physical venues
export const MOMENT_DATE_AND_TIME_FORMAT_STRING = 'ddd, MMM D, LT';
export const MOMENT_DATE_FORMAT_STRING = 'ddd, MMM D';

// Moment formatting for online events
export const MOMENT_DATE_AND_TIME_FORMAT_STRING_ONLINE = 'llll z';
export const MOMENT_DATE_FORMAT_STRING_ONLINE = 'll';

// Moment formatting for online events that show the offset (e.g. -8:00 for Pacific)
export const MOMENT_DATE_AND_TIME_FORMAT_STRING_ONLINE_OFFSET = 'llll z (Z)';
