import React, { createContext, useContext, useState, type FC } from 'react';
import type { IRatingResponse } from '../../components/RelatedEvents/types/api';

export type TSurfacingExperimentVariant = 'control' | 'variant' | '';

export interface ISurfacingExperimentContext {
    ratingsData?: IRatingResponse['ratings'];
    variant: TSurfacingExperimentVariant;
    showOrganiserInfoLoader: boolean;
    setRatingsData: (ratingsData: IRatingResponse['ratings']) => void;
    setVariant: (variant: TSurfacingExperimentVariant) => void;
    setShowOrganiserInfoLoader: (flag: boolean) => void;
}

const SurfacingExperimentContext = createContext<ISurfacingExperimentContext>(
    {} as ISurfacingExperimentContext,
);

export const useSurfacingExperimentContext = () => {
    return useContext(SurfacingExperimentContext);
};

export const SurfacingExperimentProvider: FC = ({ children }) => {
    const [variant, setVariant] = useState<TSurfacingExperimentVariant>(''); // empty string due to dependency on ratings data for the variant version; default control value was rendering control variant. Once the rating data was fetched, it was replacing the component leading to poor UX
    const [ratingsData, setRatingsData] =
        useState<ISurfacingExperimentContext['ratingsData']>();
    const [showOrganiserInfoLoader, setShowOrganiserInfoLoader] =
        useState(true);

    return (
        <SurfacingExperimentContext.Provider
            value={{
                variant,
                ratingsData,
                showOrganiserInfoLoader,
                setVariant,
                setRatingsData,
                setShowOrganiserInfoLoader,
            }}
        >
            {children}
        </SurfacingExperimentContext.Provider>
    );
};
