import React from 'react';
import { pickDefaultAvatarIcon } from '../helperFunctions';

interface IDefaultImageProps {
    organizerId: string;
}

const DefaultImageVariants: React.FunctionComponent<IDefaultImageProps> = ({
    organizerId,
}) => {
    const selectedIcon = pickDefaultAvatarIcon(organizerId);

    return (
        <>
            {selectedIcon && (
                <div data-testid="variant-b-image">{selectedIcon}</div>
            )}
        </>
    );
};

export default DefaultImageVariants;
