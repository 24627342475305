import { AlertChunky, CheckChunky } from '@eventbrite/eds-iconography';
import {
    NotificationBar as EdsNotificationBar,
    TYPE_ERROR,
    TYPE_SUCCESS,
} from '@eventbrite/eds-notification';
import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useNotificationsContext } from '../../contexts';
import './NotificationBar.scss';

const HIDE_NOTIFICATION_DELAY = 5000;

const getIcon = (type: string | undefined) => {
    switch (type) {
        case TYPE_ERROR:
            return <AlertChunky />;
        case TYPE_SUCCESS:
            return <CheckChunky />;
        default:
            return undefined;
    }
};

export const NotificationBar = ({}) => {
    const NotificationBarDiv = useMemo(() => document.createElement('div'), []);
    const { notificationState, setNotificationState } =
        useNotificationsContext();
    const { isHidden, type, hasCloseButton, children } = notificationState;

    useEffect(() => {
        document.body.appendChild(NotificationBarDiv);
        NotificationBarDiv.classList.add('listings-notification');
    }, [NotificationBarDiv]);

    useEffect(() => {
        const { isHidden } = notificationState;
        let timer: any;

        if (!isHidden) {
            timer = setTimeout(() => {
                setNotificationState({
                    isHidden: true,
                });
            }, HIDE_NOTIFICATION_DELAY);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [notificationState, setNotificationState]);

    return ReactDOM.createPortal(
        <EdsNotificationBar
            iconType={getIcon(type)}
            type={type}
            hasCloseButton={hasCloseButton}
            isHidden={isHidden}
            onClose={() =>
                setNotificationState({
                    ...notificationState,
                    isHidden: true,
                })
            }
        >
            {children}
        </EdsNotificationBar>,
        NotificationBarDiv,
    );
};
