import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const dateFormat = 'MMM dd, yyyy';

export const formatDate = (
    UTCDate: string | Date,
    timeZone: string,
): string => {
    const zonedDate = utcToZonedTime(new Date(UTCDate), timeZone);
    return format(zonedDate, dateFormat);
};
