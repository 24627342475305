import { IconButton } from '@eventbrite/eds-icon-button';
import { CrossChunky } from '@eventbrite/eds-iconography';

import { HAS_WINDOW } from '@eventbrite/feature-detection';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { trackEvent } from '../analytics/analytics';
import {
    LISTING_AMPLITUDE_TRACKING_CATEGORY,
    LISTING_SOURCE,
    LISTING_VIEW_PROMOTE_BANNER_ACTION,
} from '../constants';
import {
    ListingBannerIcon,
    ListingButtons,
    ListingDescription,
    ListingTittle,
} from '../constants/EbAdsFooter';
import './MarketingHubListingBanner.scss';

export interface EBAdsListingBannerProps {
    eventId: string;
}

export function EbAdsListingBanner(props: EBAdsListingBannerProps) {
    let isAvailable = false;
    let domainUrl = 'https://www.eventbrite.com';

    if (HAS_WINDOW) {
        const ancestorOrigins =
            window.location != window.parent.location
                ? document.referrer
                : document.location.href;

        domainUrl = window.location.origin;
        isAvailable = !(
            ancestorOrigins.length > 0 &&
            ancestorOrigins[0].indexOf('//admin.') !== -1
        );
    }

    const listingButtons = (
        <ListingButtons
            promoteUrl={`${domainUrl}/organizations/marketing/eventbrite-ads/new?eId=${props.eventId}`}
        ></ListingButtons>
    );

    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        if (isAvailable) {
            trackEvent({
                category: LISTING_AMPLITUDE_TRACKING_CATEGORY,
                action: LISTING_VIEW_PROMOTE_BANNER_ACTION,
                source: LISTING_SOURCE,
                feature: LISTING_SOURCE,
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            {isAvailable && isOpen && (
                <div
                    className={cx(
                        'eds-show-up-md eds-layout eds-layout--has-large-max-width eds-layout--has-horizontal-gutters',
                        'marketing-hub-trial-banner-wrap',
                    )}
                >
                    <div
                        className={cx(
                            'eds-l-pad-top-8 eds-l-pad-bot-8 eds-l-pad-left-12 eds-l-pad-right-12 eds-bg-color-control eds-g-grid',
                            'marketing-hub-trial-banner marketing-hub-trial-banner-listing',
                        )}
                    >
                        <div
                            className={cx(
                                'eds-g-group eds-align--center-vertical',
                                'marketing-hub-trial-banner-content',
                            )}
                        >
                            <div className="eds-g-cell eds-g-cell-9-12">
                                <div className="eds-g-grid">
                                    <div className="eds-g-group eds-align--center-vertical">
                                        <div
                                            data-testid="icon-banner"
                                            className={cx(
                                                'eds-g-cell eds-text--center eds-l-pad-right-4 eds-g-cell--align-center',
                                                'marketing-hub-trial-banner__icon',
                                            )}
                                        >
                                            {ListingBannerIcon}
                                        </div>
                                        <div className="eds-g-cell eds-g-cell--align-center">
                                            <div
                                                data-testid="title-banner"
                                                className={cx(
                                                    'title-footer-post-trial-banner',
                                                )}
                                            >
                                                {`${ListingTittle}`}
                                            </div>
                                            <div
                                                data-testid="description-banner"
                                                className="eds-text-bs eds-l-mar-top-2"
                                            >
                                                {`${ListingDescription}`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                data-testid="children-banner"
                                className="eds-g-cell eds-g-cell-4-12 eds-text--right eds-text-color--control"
                            >
                                {listingButtons}
                            </div>
                            <div>
                                <IconButton
                                    iconType={<CrossChunky />}
                                    title={'Clear'}
                                    onClick={() => setIsOpen(false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
