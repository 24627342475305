import { logEvent } from '@eventbrite/statsig';
import React, { useEffect } from 'react';
import { LISTING_HEAP_IDS } from '../../../../constants';
import {
    EventActionType,
    useEventBasicInformationContext,
    useEventDispatchContext,
    useTicketContext,
} from '../../../../contexts';
import { STATSIG_EVENT_NAMES } from '../../../../experimentation';
import { SALES_STATUS, SeriesDatesScopedProps } from '../../../../types';
import { getSalesStatus } from '../../../../utils';
import { getFormattedDateCard } from '../../../utils/localizedDates';
import './ChildEventsSlider.scss';
import { DateCard, MoreOptionsCard } from './SliderCard';

const CHILD_EVENTS_THRESHOLD = 5;

const filterEventDates = ({ childEvents, eventId }: SeriesDatesScopedProps) => {
    const eventDates = [];
    const slicedEventDates = childEvents.slice(0, CHILD_EVENTS_THRESHOLD);

    for (let i = 0; i < slicedEventDates.length; i++) {
        const item = childEvents[i];

        eventDates.push({
            eventId: item.id,
            url: item.url,
            display: getFormattedDateCard({
                date: item.start,
                locale: item.locale,
            }),
            start: item.start,
            end: item.end,
            selected: item.id === eventId,
            salesStatus: getSalesStatus(item),
        });
    }
    return eventDates;
};

export type ChildEventsSliderProps = Pick<
    SeriesDatesScopedProps,
    'childEvents'
> & {
    currentEventId: string;
    shouldShowMoreOptions?: boolean;
    onMoreOptionsClick: () => void;
    isTimedEntryEnabled: boolean;
};

export const ChildEventsSlider = ({
    childEvents,
    shouldShowMoreOptions,
    currentEventId,
    onMoreOptionsClick,
    isTimedEntryEnabled,
}: ChildEventsSliderProps) => {
    const dispatch = useEventDispatchContext();
    const {
        isSeriesParent,
        shouldDisplayCrawlableSeriesChildrenLinks,
        isSoldOut,
    } = useEventBasicInformationContext();
    const filteredEventDates = filterEventDates({
        childEvents,
        eventId: currentEventId,
    });

    const { fetchDataAndUpdateTicketData } = useTicketContext();

    const MoreOptionComponent = () => {
        if (isSoldOut && isTimedEntryEnabled) return null;
        return (
            <>
                {shouldShowMoreOptions && (
                    <li
                        className="child-event-dates-item"
                        data-heap-id={LISTING_HEAP_IDS.MORE_OPTIONS_CARD}
                    >
                        <MoreOptionsCard
                            onButtonClick={() => {
                                onMoreOptionsClick();
                                logEvent(STATSIG_EVENT_NAMES.MORE_OPTIONS_CARD);
                            }}
                        />
                    </li>
                )}
            </>
        );
    };

    useEffect(() => {
        const firstSeriesEvent = filteredEventDates[0];
        if (
            isSeriesParent &&
            firstSeriesEvent.salesStatus === SALES_STATUS.ON_SALE
        ) {
            fetchDataAndUpdateTicketData(firstSeriesEvent.url, dispatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="child-event-dates">
            <ul
                className="child-event-dates-list"
                data-testid="child-events-slider"
            >
                {filteredEventDates.map(
                    ({
                        eventId,
                        display,
                        url,
                        selected,
                        start,
                        end,
                        salesStatus,
                    }) => {
                        return (
                            <li
                                key={eventId}
                                className={`child-event-dates-item ${
                                    isTimedEntryEnabled &&
                                    isSoldOut &&
                                    'sold-out'
                                }`}
                            >
                                <DateCard
                                    isSoldOut={
                                        salesStatus === SALES_STATUS.SOLD_OUT
                                    }
                                    url={url}
                                    datesToDisplay={display}
                                    selected={selected}
                                    eventId={eventId}
                                    onClick={() => {
                                        dispatch({
                                            type: EventActionType.setSeriesEvent,
                                            payload: {
                                                id: eventId,
                                                start,
                                                end,
                                                url,
                                                salesStatus,
                                                isSoldOut:
                                                    salesStatus ===
                                                    SALES_STATUS.SOLD_OUT,
                                                selectedDate: true,
                                            },
                                        });
                                        dispatch({
                                            type: EventActionType.setEventURL,
                                            payload: {
                                                url,
                                            },
                                        });
                                        fetchDataAndUpdateTicketData(
                                            url,
                                            dispatch,
                                        );
                                    }}
                                    isTimedEntryEnabled={isTimedEntryEnabled}
                                />
                            </li>
                        );
                    },
                )}
                <MoreOptionComponent />
            </ul>
            {shouldDisplayCrawlableSeriesChildrenLinks && isSeriesParent && (
                <ul>
                    {childEvents.map(
                        ({
                            url,
                            formatted_start_date,
                        }: {
                            url: string;
                            formatted_start_date: string;
                        }) => (
                            <li key={url}>
                                <a href={url}>
                                    <time>{formatted_start_date}</time>
                                </a>
                            </li>
                        ),
                    )}
                </ul>
            )}
        </div>
    );
};
