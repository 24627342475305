import { Icon } from '@eventbrite/eds-icon';
import { ArrowLeftChunky, ArrowRightChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import React, { useCallback, useRef, useState } from 'react';
import { LISTING_HEAP_IDS } from '../../../../constants';
import type { FeaturedVideoProps, NativeVideoProps } from '../../../../types';
import { FetchPriorityType, HeroImage, LoadingType } from '../HeroImage';
import { HeroVideo } from '../HeroVideo';
import {
    AccessibleText,
    CarouselViewport,
    CarouselWrapper,
    ControlButton,
    ImageList,
    ImageListItem,
    SliderBar,
    SliderBarButton,
    SliderBarList,
    SliderBarOverlay,
} from './HeroCarousel.styles';
import type { CarouselItems } from './index';
import { useKeyPress } from './useKeyPress';
import { useSwipe } from './useSwipe';

interface HeroCarousel {
    eventName: string;
    items: Array<CarouselItems>;
    initialSlide?: number;
    enableAutoSlide?: boolean;
    featuredVideo?: FeaturedVideoProps | undefined;
    nativeVideo?: NativeVideoProps;
}

const TRANSLATION_UNIT_INIT = '0';

const getSlidesTranslationValue = (slidePosition: number) => {
    return slidePosition === 0 ? TRANSLATION_UNIT_INIT : `-${slidePosition}00`;
};

export const HeroCarousel: React.FC<HeroCarousel> = ({
    eventName,
    items,
    initialSlide = 0,
    featuredVideo,
    nativeVideo,
}) => {
    const shouldShowCarouselControls = items.length > 1;
    const firstSlide = 0;
    const lastSlide = items.length - 1;
    const [currentSlide, setCurrentSlide] = useState(initialSlide);
    const [translationUnit, setTranslationUnit] = useState(
        getSlidesTranslationValue(initialSlide),
    );
    const [isCarouselFocused, setIsCarouselFocused] = useState(false);
    const carousel = useRef(null);

    const goToSlide = useCallback((slideNumber: number) => {
        setCurrentSlide(slideNumber);
        setTranslationUnit(getSlidesTranslationValue(slideNumber));
    }, []);

    const nextSlide = useCallback(() => {
        const nextSlide =
            currentSlide === lastSlide ? firstSlide : currentSlide + 1;
        goToSlide(nextSlide);
    }, [currentSlide, lastSlide, goToSlide]);

    const prevSlide = useCallback(() => {
        const prevSlide =
            currentSlide === firstSlide ? lastSlide : currentSlide - 1;
        goToSlide(prevSlide);
    }, [currentSlide, lastSlide, goToSlide]);

    const handleOnFocus = () => {
        if (!isCarouselFocused) {
            setIsCarouselFocused(true);
        }
    };
    const handleOnBlur = (event: React.FocusEvent<HTMLDivElement>) => {
        const isCarouselBlurred = !event.currentTarget.contains(
            event.relatedTarget as Node,
        );
        if (isCarouselBlurred) {
            setIsCarouselFocused(false);
        }
    };

    useKeyPress({
        isCarouselFocused,
        onLeftKeyPressed: () => prevSlide(),
        onRightKeyPressed: () => nextSlide(),
    });

    useSwipe({
        ref: carousel,
        onSwipeLeft: () => nextSlide(),
        onSwipeRight: () => prevSlide(),
    });

    return (
        <CarouselWrapper
            ref={carousel}
            tabIndex={shouldShowCarouselControls ? 0 : -1}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            data-testid="hero-carousel"
        >
            {shouldShowCarouselControls && (
                <>
                    <ul>
                        <li>
                            <ControlButton
                                onClick={prevSlide}
                                action="prev"
                                data-heap-id={
                                    LISTING_HEAP_IDS.LEFT_ARROW_HERO_CAROUSEL
                                }
                            >
                                <Icon type={<ArrowLeftChunky />} />
                                <AccessibleText>
                                    {gettext('Previous slide')}
                                </AccessibleText>
                            </ControlButton>
                        </li>
                        <li>
                            <ControlButton
                                onClick={nextSlide}
                                action="next"
                                data-heap-id={
                                    LISTING_HEAP_IDS.RIGHT_ARROW_HERO_CAROUSEL
                                }
                            >
                                <Icon type={<ArrowRightChunky />} />
                                <AccessibleText>
                                    {gettext('Next slide')}
                                </AccessibleText>
                            </ControlButton>
                        </li>
                    </ul>
                    <SliderBarOverlay />
                    <SliderBar>
                        <SliderBarList>
                            {items.map((_, index: number) => {
                                const slideNumber = index + 1;

                                return (
                                    <li key={index} data-testid="slider-bar">
                                        <SliderBarButton
                                            onClick={() => {
                                                goToSlide(index);
                                            }}
                                            active={index === currentSlide}
                                            aria-current={
                                                index === currentSlide
                                            }
                                        >
                                            <AccessibleText>
                                                {gettext(
                                                    'Go to slide %(slideNumber)s',
                                                    { slideNumber },
                                                )}
                                            </AccessibleText>
                                        </SliderBarButton>
                                    </li>
                                );
                            })}
                        </SliderBarList>
                    </SliderBar>
                </>
            )}
            {items.length > 0 ? (
                <CarouselViewport>
                    <ImageList
                        style={{ transform: `translateX(${translationUnit}%)` }}
                        data-testid="slide-list"
                    >
                        {items.map((item, index: number) => {
                            const shouldShowHeroVideo =
                                index === 0 &&
                                (nativeVideo?.url || featuredVideo?.embedUrl);

                            return (
                                <ImageListItem
                                    data-testid="slide"
                                    aria-hidden={!(index === firstSlide)}
                                    key={`slide-${index}`}
                                >
                                    {shouldShowHeroVideo ? (
                                        <HeroVideo
                                            featuredVideo={featuredVideo}
                                            nativeVideo={nativeVideo}
                                            overrideThumbnail={item}
                                        />
                                    ) : (
                                        <HeroImage
                                            croppedLogoUrl480={
                                                item.croppedLogoUrl480
                                            }
                                            croppedLogoUrl600={
                                                item.croppedLogoUrl600
                                            }
                                            croppedLogoUrl940={
                                                item.croppedLogoUrl940
                                            }
                                            alt={index === 0 ? eventName : ''}
                                            loading={
                                                index !== 0
                                                    ? LoadingType.lazy
                                                    : undefined
                                            }
                                            fetchPriority={
                                                index === 0
                                                    ? FetchPriorityType.high
                                                    : FetchPriorityType.low
                                            }
                                        />
                                    )}
                                </ImageListItem>
                            );
                        })}
                    </ImageList>
                </CarouselViewport>
            ) : (
                <HeroVideo
                    nativeVideo={nativeVideo}
                    featuredVideo={featuredVideo}
                />
            )}
        </CarouselWrapper>
    );
};
