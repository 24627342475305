import { StatsigEnvironments } from '../models/statsigEnvironments';

export const STATSIG_CLIENT_KEY =
    'client-eQuZOzz7D7TWsrZtXEXGjLrsvFEvt7IJ3yQHf66gW3B';

export const STATSIG_OVERRIDE_PARAM = 'overrideStatsig';

// Floating number, range from 0 to 100
// By default, there won't be any sampling for clients.
export const DEFAULT_SAMPLE_RATE = 100;

export const STATSIG_ENV: Record<string, StatsigEnvironments> = {
    PROD: 'production',
    QA: 'staging',
    DEV: 'development',
};

export const PROD_ENV_URLS_REGEX = /^(https?:\/\/)?(www.)?(eventbrite)/;
export const QA_ENV_URLS_REGEX = /^(https?:\/\/)?(www.)?(evbqa(\d)*).\w{2,}$/;
export const DEV_ENV_URL_REGEX =
    /^(https?:\/\/)?(dev.evbqa|www.evbdev|evbdev).com(:(\d)*)?\/?/;
