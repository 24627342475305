import * as React from 'react';

const ParkingChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 6h12v12H6V6zM4 20V4h16v16H4zM9 8v8.376h1.824v-3.024h2.136a2.76 2.76 0 001.056-.192c.312-.136.576-.32.792-.552.224-.232.396-.512.516-.84.12-.328.18-.692.18-1.092 0-.392-.06-.752-.18-1.08a2.34 2.34 0 00-.516-.852 2.213 2.213 0 00-.792-.54A2.617 2.617 0 0012.96 8H9zm3.744 3.768h-1.92V9.584h1.92c.272 0 .484.072.636.216.16.136.24.34.24.612v.528c0 .272-.08.48-.24.624-.152.136-.364.204-.636.204z"
            fill="#010101"
        />
    </svg>
);

ParkingChunkySvg.displayName = 'ParkingChunkySvg';
export default ParkingChunkySvg;
