import { getExperiment, useExperiment } from '@eventbrite/statsig';
import { useEventBasicInformationContext } from '../contexts';
import { CheckoutDisqualifications, CheckoutType } from '../types';
import {
    DETACH_INTERESTS_FROM_USER_MENU,
    G2K_EXPERIMENT,
    LISTING_SECOND_HIERARCHY_LEVEL_EXPERIMENT,
    LISTING_TEST_AA_SSR_EXPERIMENT,
    LISTING_THIRD_HIERARCHY_LEVEL_EXPERIMENT,
    LISTING_TIMED_ENTRY_EXPERIMENT,
    LISTING_VIDEO_AUTOPLAY_EXPERIMENT,
    ONE_TICKET_TYPE_EXPERIMENT,
    TIMED_ENTRY_SESSION_SELECTOR_EXPERIMENT,
} from './constants';

type ExperimentDef = {
    experimentName: string;
    param: string;
    defaultValue: string | boolean;
};

export const genericGetRawVariant = <T>(experiment: ExperimentDef): T =>
    getExperiment(
        experiment.experimentName,
        experiment.param,
        experiment.defaultValue,
    );

export const genericGetBooleanExperiment = (
    experiment: ExperimentDef,
): boolean => {
    const value = genericGetRawVariant<string | boolean>(experiment);
    return value === true || value === 'true';
};

export const isSecondHierarchyLevel = () =>
    genericGetRawVariant<boolean>(LISTING_SECOND_HIERARCHY_LEVEL_EXPERIMENT);

export const isThirdHierarchyLevel = () =>
    genericGetRawVariant<boolean>(LISTING_THIRD_HIERARCHY_LEVEL_EXPERIMENT);

export const isAutoplayVideoExperimentVariant = () =>
    genericGetRawVariant(LISTING_VIDEO_AUTOPLAY_EXPERIMENT);

export const useTestAAExperiment = () => {
    const { value } = useExperiment<boolean | null>(
        LISTING_TEST_AA_SSR_EXPERIMENT.experimentName,
        LISTING_TEST_AA_SSR_EXPERIMENT.param,
        LISTING_TEST_AA_SSR_EXPERIMENT.defaultValue,
    );

    return value === true || (value as unknown as string) === 'true';
};

export const isDetachInterestsExperimentEnabled = () =>
    genericGetBooleanExperiment(DETACH_INTERESTS_FROM_USER_MENU);

export const isTESessionSelectorExperimentEnabled = (
    sessionsLength: number,
    isSalesEnded: boolean,
    isSoldOut: boolean,
    shouldShowCompactCheckout: boolean,
) => {
    if (
        sessionsLength < 5 ||
        isSalesEnded ||
        isSoldOut ||
        shouldShowCompactCheckout
    ) {
        return false;
    }

    return genericGetBooleanExperiment(TIMED_ENTRY_SESSION_SELECTOR_EXPERIMENT);
};

export const isListingTimedEntryExperimentEnabled = (
    sessionsLength: number,
    shouldShowCompactCheckout: boolean,
) => {
    if (sessionsLength < 5 || shouldShowCompactCheckout) {
        return false;
    }

    return genericGetBooleanExperiment(LISTING_TIMED_ENTRY_EXPERIMENT);
};

export const getCheckoutType = (
    isMultiTicketEnabled: boolean,
    compactCheckoutDisqualifications: CheckoutDisqualifications,
) => {
    if (!isMultiTicketEnabled) return CheckoutType.Compact;
    if (compactCheckoutDisqualifications.more_than_one_ticket)
        return CheckoutType.OnlyCheckout;
    return genericGetRawVariant<CheckoutType>(ONE_TICKET_TYPE_EXPERIMENT);
};

export const useG2KExperiment = () => {
    const { tld } = useEventBasicInformationContext();
    if (tld != '.com') {
        return false;
    }
    return genericGetRawVariant<boolean>(G2K_EXPERIMENT);
};
