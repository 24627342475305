import { trackAnalyticsEvent } from '@eventbrite/site-analytics';

const GA_ACTION_SHARE_EVENT = 'Share';

export enum ShareType {
    Facebook = 'facebook',
    LinkedIn = 'linkedin',
    WhatsApp = 'whatsapp',
    FacebookMessenger = 'facebookMessenger',
    Twitter = 'twitter',
    Email = 'email',
}

const shareMapping = {
    [ShareType.Facebook]: 'fb',
    [ShareType.LinkedIn]: 'li',
    [ShareType.WhatsApp]: 'wa',
    [ShareType.FacebookMessenger]: 'fbm',
    [ShareType.Twitter]: 'tw',
    [ShareType.Email]: 'strongmail',
};

export const getLabelFromShareType = (type: ShareType): string =>
    shareMapping[type];

export const trackShareAction = ({
    category,
    label,
    dimensions = {},
}: {
    label: string;
    category: string;
    dimensions?: object;
}) =>
    trackAnalyticsEvent({
        action: GA_ACTION_SHARE_EVENT,
        category: category,
        dimensions,
        label,
    });
