import { getWindowObject } from '@eventbrite/feature-detection';
import { $FixMe } from '@eventbrite/ts-utils';
import i18next from 'i18next';
import sanitizeHtml from 'sanitize-html';
import { languageInheritanceMap, ParentLanguageCode } from './constants';
import { GenericLazyString } from './genericLazyString';

export interface RequireContext {
    keys(): string[];
    (id: string): any;
    <T>(id: string): T;
    resolve(id: string): string;
}

const LOCALE_FROM_FILENAME_REGEX = /^\.\/(.*)\.json$/;

export const i18n = i18next.createInstance();
const I18N_NEXT_INITIALIZED = { current: false };

/**
 * I18n initialization
 *  - Uses all available translations downloaded from Transifex at build time.
 *  - If a locale is provided like 'es-ES' is not found, then an 'es' translation will be looked up.
 *  - If no 'es' translation is found, then an 'en' translation will be looked up
 *  - If no 'en' translation is found, then the provided key will be returned as is.
 */
const initializeI18n = () => {
    if (!I18N_NEXT_INITIALIZED.current) {
        i18n.init({
            lng: 'en',
            fallbackLng: 'en',
            returnEmptyString: false,
            keySeparator: false,
            nsSeparator: false,
            pluralSeparator: void 0,
            contextSeparator: void 0,
            initImmediate: false, // This one prevents a setTimeout(fn, 0)
            interpolation: {
                escapeValue: false,
                prefix: '%(',
                suffix: ')s',
            },
        });
        I18N_NEXT_INITIALIZED.current = true;
    }
};

const SANITIZE_CONFIG = {
    // This is to completely remove iframes from text
    // Without this filter, an empty iframe will be left behind
    exclusiveFilter: (frame: any) => frame.tag === 'iframe',
};

/**
 * Locale files generated by transifex tooling always use '_' as the region separator,
 * but i18next expects them to be to be separated by '-'.
 * @param {string} locale the locale string to normalize
 */
const normalizeLocale = (locale: string) => locale.replace('_', '-');

/**
 * isPlural returns true if the passed in count should be expressed as a plural in the current active
 * language
 *
 * @param {number} count a quantity to check if it should be expressed as plural.
 */
export const isPlural = (count: number) => {
    initializeI18n();

    return (
        i18n.services.pluralResolver.getSuffix(i18n.language, count) ===
        'plural'
    );
};

/**
 * gettext is the main translation function.
 *
 * @param key the key, string or template string of the message to translate
 * @param interpolation an object used for interpolation in the template string.
 * @returns a GenericLazyString instance that will resolve to the active language when used.
 */
export const gettext = (
    key: string,
    interpolation?: Record<string, $FixMe>,
) => {
    initializeI18n();

    return new GenericLazyString(() => {
        /*
         * When no interpolation object is provided, we need to preserve the placeholders
         * This is for backward compatibility with existing code relying on this behavior.
         */
        let interp: any = {
            interpolation: { suffix: '####', prefix: '####' },
        };

        if (interpolation) {
            interp = Object.fromEntries(
                Object.entries(interpolation).map(([key, value]) =>
                    value instanceof GenericLazyString
                        ? [key, value.toString()]
                        : [key, value],
                ),
            );
        }
        const text = i18n.t(key, interp);

        return text;
    });
};

/**
 * dangerousGettext is a clone of gettext meant to be leveraged when
 * the translated string has embedded HTML and will be included on the
 * page via `dangerouslySetInnerHtml`. If this does not apply, use `gettext`
 *
 * @param key the key, string or template string of the message to translate
 * @param interpolation an object used for interpolation in the template string.
 * @returns a GenericLazyString instance that will resolve to the active language when used.
 */
export const dangerousGettext = (
    key: string,
    interpolation?: Record<string, $FixMe>,
) => {
    initializeI18n();

    return new GenericLazyString(() => {
        /*
         * When no interpolation object is provided, we need to preserve the placeholders
         * This is for backward compatibility with existing code relying on this behavior.
         */
        let interp: any = {
            interpolation: { suffix: '####', prefix: '####' },
        };

        if (interpolation) {
            interp = Object.fromEntries(
                Object.entries(interpolation).map(([key, value]) =>
                    value instanceof GenericLazyString
                        ? [key, value.toString()]
                        : [key, value],
                ),
            );
        }
        const text = i18n.t(key, interp);

        return sanitizeHtml(text, SANITIZE_CONFIG);
    });
};

/**
 * ngettext returns singularString is the amount passed in 'number' qualifies as singular
 * given the set locale, returns pluralString otherwise.
 *
 * NB: ngettext DOES NOT send strings to Transifex for translation. In order to have strings
 * translated, you'll have to wrap them in gettext
 *
 * This inconsistency should be tackled as a part of https://eventbrite.atlassian.net/browse/EB-162607
 *
 * @param singularString gettext call to display if count is singular.
 * @param pluralString gettext call to display if count is plural.
 * @param count a quantity to check if it should be expressed as plural.
 * @param _interpolation an optional interpolation object for translation strings, more docs https://github.com/eventbrite/eb-ui/blob/main/docs/i18n.md
 */
export const ngettext = (
    singularString: string | GenericLazyString,
    pluralString: string | GenericLazyString,
    count: number,
    _interpolation?: Record<string, string | number>,
) => (isPlural(count) ? pluralString : singularString);

/**
 * Changes the active language for all subsequent calls to gettext()
 *
 * @param {string} language the new desired language for translations
 */
export const setLanguage = (language: string) => {
    initializeI18n();
    i18n.changeLanguage(normalizeLocale(language));
};

type TranslationsForLanguageCode = Record<string, string>;

/**
 * Load translations files pulled from transifex into i18next.
 *
 * @param context webpack context of the `{package}/src/i18n/translations` directory
 */
export const setup = (context: RequireContext) => {
    initializeI18n();

    const loadLocales = (context: RequireContext) => {
        const translations: {
            [hyphenSeparatedLanguageCode: string]: TranslationsForLanguageCode;
        } = {};

        context.keys().forEach((parentCountryCodeTranslationFilePath) => {
            const [, localeName] =
                (parentCountryCodeTranslationFilePath.match(
                    LOCALE_FROM_FILENAME_REGEX,
                    // This cast is ok because we're acting on a static set of files we generate in the pull script
                ) as [any, ParentLanguageCode]) || [];

            const inheritedLanguages = languageInheritanceMap[localeName];

            const translationsForLocale = context(
                parentCountryCodeTranslationFilePath,
            );

            if (inheritedLanguages) {
                inheritedLanguages.forEach((underscoreSeparatedLocaleKey) => {
                    translations[
                        normalizeLocale(underscoreSeparatedLocaleKey)
                    ] = translationsForLocale;
                });
            }

            const normalizedLocaleName = normalizeLocale(localeName);

            translations[normalizedLocaleName] = translationsForLocale;
        });

        return translations;
    };
    const translations = loadLocales(context);

    Object.keys(translations).forEach((countryCode) => {
        i18n.addResourceBundle(
            countryCode,
            'translation',
            translations[countryCode],
        );
    });
};

export const getLocaleSearchParam = (): string | null => {
    const location = getWindowObject('location');

    return new URLSearchParams(location?.search).get('locale');
};

export const getLocale = ({
    eventLocale,
    appLocale,
}: {
    eventLocale: string;
    appLocale: string;
}): string => getLocaleSearchParam() || eventLocale || appLocale;

/**
 * Setup translations async. An easy way to only add a singular translation file to the webpack bundle.
 * @param options.locale The string of the locale, e.g. es_ES
 * @param options.translations A dynamic import pointing to the translation file
 * @example
 * setupAsync({
 *   locale,
 *   translations: import(`./i18n/translations/${locale}.json`)
 * })
 */
export const setupAsync = async ({
    parentLocale,
    translations,
}: {
    parentLocale: ParentLanguageCode;
    translations: Promise<Record<string, string>>;
}) => {
    initializeI18n();

    setLanguage(parentLocale);

    const translation = await translations;

    i18n.addResourceBundle(
        normalizeLocale(parentLocale),
        'translation',
        translation,
    );
};

export const addTranslationsAddedListener = (
    callback: (lng: string, ns: string) => void,
) => {
    initializeI18n();
    i18n.store.on('added', callback);
};

export const removeTranslationsAddedListener = (
    callback: (lng: string, ns: string) => void,
) => {
    initializeI18n();
    i18n.store.off('added', callback);
};
