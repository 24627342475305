import { datadogRum } from '@datadog/browser-rum-slim';
import { EvaluationCallback } from 'statsig-js/dist/StatsigSDKOptions';
import type { AppProps } from '../types';
import { StatsigType } from './types';

export const evaluationCallback: EvaluationCallback = (args) => {
    if (args.type === StatsigType.EXPERIMENT) {
        const key = args.config.getName();
        const value = args.config.getGroupName();

        if (key && value) {
            datadogRum.addFeatureFlagEvaluation(
                `statsig_experiment_${key}`,
                value,
            );
        }
    }
};

export const getIsOnlineEventAndShouldShowDefaultCheckoutOnlineEvents = (
    app: AppProps,
) => {
    return (
        (app.event?.isOnlineEvent &&
            app.event_listing_response.featureFlags
                ?.shouldShowOnlyDefaultCheckoutOnlineEvents) ??
        false
    );
};
