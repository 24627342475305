/**
 * TRACKING CONSTANTS
 */

export const AMPLITUDE_GA_TRACKING_CATEGORY = 'Banner Trial Subscription';
export const STRIP_TRIAL_SUBSCRIPTION = 'Strip Trial Subscription';

export const POST_TRIAL_SUBSCRIPTION_BANNER_FEATURE = 'Post trial';
export const ON_TRIAL_SUBSCRIPTION_BANNER_FEATURE = 'On trial';

export const VIEW_SUBSCRIPTION_BANNER_ACTION = 'Viewed Subscription Banner';
export const CLICK_SUBSCRIPTION_BANNER_SUBSCRIPTION_ACTION =
    'Clicked Subscription Banner Subscription';
export const CLICK_SUBSCRIPTION_BANNER_DISMISS_ACTION =
    'Clicked Subscription Banner Dismissed';

// LISTING TRACKING CONSTANTS

export const LISTING_AMPLITUDE_TRACKING_CATEGORY = 'Promote Event Banner';
export const LISTING_VIEW_PROMOTE_BANNER_ACTION = 'Viewed Promote Event Banner';
export const LISTING_SOURCE = 'Listing Page';
export const LISTING_CLICK_PROMOTE_ACTION = 'Clicked Promote Event Banner';
export const LISTING_AMPLITUDE_TRACKING_CATEGORY_EB_ADS = 'Eb Ads Banner';
export const LISTING_VIEW_EB_ADS_BANNER_ACTION = 'Viewed Eb Ads Banner';
export const LISTING_CLICK_EB_ADS_ACTION = 'Clicked Eb Ads Banner';
export const LISTING_CLICK_PROMOTE = 'Creator Bottom Banner - Promote';

// ACTIVATION MODAL CONSTANTS

export const ACTIVATION_MODAL_CATEGORY = 'Trial activation';

export const ACTIVATION_MODAL_SOURCE_FIRST_STEP = 'trial modal - activation';
export const ACTIVATION_MODAL_SOURCE_SECOND_STEP = 'trial modal - confirmation';

export const ACTIVATION_MODAL_CLICKED_START_TRIAL = 'Clicked start trial';
export const ACTIVATION_MODAL_CLICKED_NO_THANKS = 'Clicked no thanks';
export const ACTIVATION_MODAL_CLICKED_LEARN_MORE = 'Clicked learn more';
export const ACTIVATION_MODAL_VIEW_TRIAL_MODAL = 'Viewed trial modal';
export const ACTIVATION_MODAL_CLICKED_GET_STARTED = 'Clicked get started';
export const ACTIVATION_MODAL_CLICKED_PRICING = 'Clicked pricing';
export const ACTIVATION_MODAL_CLICKED_CLOSE = 'Clicked close modal';
export const ACTIVATION_MODAL_OPEN = 'Open trial from ';

// STATSIG EVENT NAMES
export const MARKETING_ACTIVATION_TRIAL_MODAL_CLICK_GET_STARTED =
    'Marketing - Activation Trial Modal - Click - Get Started';
export const MARKETING_ACTIVATION_TRIAL_MODAL_VIEW =
    'Marketing - Activation Trial Modal - View';
export const MARKETING_ACTIVATION_TRIAL_MODAL_CLICK_CLOSE =
    'Marketing - Activation Trial Modal - Click - Close';
export const MARKETING_ACTIVATION_TRIAL_MODAL_CLICK_START_TRIAL =
    'Marketing - Activation Trial Modal - Click - Start Trial';
export const MARKETING_ACTIVATION_TRIAL_MODAL_CLICK_LEARN_MORE =
    'Marketing - Activation Trial Modal - Click - Learn More';
export const MARKETING_ACTIVATION_TRIAL_MODAL_CLICK_PRICING =
    'Marketing - Activation Trial Modal - Click - Pricing';
export const MARKETING_ACTIVATION_TRIAL_MODAL_CLICK_NO_THANKS =
    'Marketing - Activation Trial Modal - Click - No Thanks';
