import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { useTicketContext } from './context';
import styles from './TicketCard.module.scss';

export const QuantityRemaining: React.FC = () => {
    const { quantityRemaining, displayFlags } = useTicketContext();
    const { showRemaining } = displayFlags;

    if (!showRemaining) {
        return null;
    }

    return (
        <Typography
            as="span"
            color="ui-800"
            className={styles.quantityRemaining}
            data-testid="quantity-remaining"
            variant="body-sm-bold"
        >
            {quantityRemaining} {gettext('remaining')}
        </Typography>
    );
};
