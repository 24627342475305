import React from 'react';
import { parseUrl } from 'url-lib';
import { gettext } from '@eventbrite/i18n';

import PropTypes from 'prop-types';

import { Button } from '@eventbrite/eds-button';

const findIsEBDomain = (host) =>
    host.indexOf('eventbrite') > -1 ||
    host.indexOf('evbqa') > -1 ||
    host.indexOf('evbdev') > -1 ||
    host.indexOf('evbstage') > -1;

const shouldReferTargetBlank = (linkUrl) => {
    const isRelative = linkUrl.startsWith('/');
    const { host } = parseUrl(linkUrl);
    const isEBDomain = findIsEBDomain(host);

    return isRelative || isEBDomain;
};

const ActionWrapper = (props) => {
    const {
        onClick,
        linkUrl,
        children,
        hasNoTabIndex,
        isTargetBlank,
        isNoFollow,
        linkAlt,
    } = props;

    const tabIndex = hasNoTabIndex ? '-1' : '0';
    const ariaLabel = linkAlt
        ? gettext('See more of %(linkAlt)s', { linkAlt })
        : gettext('See more');
    let aProps = {
        onClick,
        tabIndex,
        href: linkUrl,
        className: 'eds-event-card-content__action-link',
        'aria-label': ariaLabel,
    };

    if (isTargetBlank) {
        aProps = {
            ...aProps,
            target: '_blank',
            rel: `noopener${
                shouldReferTargetBlank(linkUrl) ? '' : ' noreferrer'
            }`,
        };
    }

    if (isNoFollow) {
        const prevRelProps = aProps.rel || '';

        aProps = {
            ...aProps,
            rel: `${prevRelProps} nofollow`.trim(),
        };
    }

    let component = children;

    if (linkUrl) {
        component = <a {...aProps}>{children}</a>;
    } else if (onClick) {
        const buttonProps = {
            onClick,
            tabIndex,
            style: 'none',
            size: 'block',
        };

        component = <Button {...buttonProps}>{children}</Button>;
    }

    return component;
};

ActionWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    linkUrl: PropTypes.string,
    linkAlt: PropTypes.string,
    hasNoTabIndex: PropTypes.bool,
    isTargetBlank: PropTypes.bool,
    isNoFollow: PropTypes.bool,
};

export default ActionWrapper;
