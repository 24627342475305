import { TYPE_NEUTRAL } from '@eventbrite/eds-notification';
import React, { createContext, useContext, useState } from 'react';
import { NotificationBarProps } from '../types';

type NotificationsContextProviderProps = { children: React.ReactNode };
type NotificationsContextType = {
    notificationState: NotificationBarProps;
    setNotificationState: React.Dispatch<
        React.SetStateAction<NotificationBarProps>
    >;
};

export const NotificationsContext =
    createContext<NotificationsContextType | null>(null);
NotificationsContext.displayName = 'NotificationsContext';

export const useNotificationsContext = () => {
    const context = useContext(NotificationsContext);
    if (!context)
        throw new Error(
            'NotificationsContext has to be used within NotificationsContextProvider',
        );
    return context;
};

export const NotificationsContextProvider: React.FC<
    NotificationsContextProviderProps
> = ({ children }) => {
    const initialState: NotificationBarProps = {
        isHidden: true,
        type: TYPE_NEUTRAL,
        hasCloseButton: true,
        children: '',
    };
    const [notificationState, setNotificationState] = useState(initialState);
    const notificationsContext: NotificationsContextType = {
        notificationState,
        setNotificationState,
    };

    return (
        <NotificationsContext.Provider value={notificationsContext}>
            {children}
        </NotificationsContext.Provider>
    );
};
