import { useEffect } from 'react';

interface KeyPressSettings {
    isCarouselFocused: boolean;
    onLeftKeyPressed: () => void;
    onRightKeyPressed: () => void;
}

enum ArrowKeysCode {
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
}

export const useKeyPress = ({
    isCarouselFocused,
    onLeftKeyPressed,
    onRightKeyPressed,
}: KeyPressSettings) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const { key } = event;

            if (!isCarouselFocused) {
                return;
            }

            if (key === ArrowKeysCode.ArrowLeft) {
                onLeftKeyPressed();
            } else if (key === ArrowKeysCode.ArrowRight) {
                onRightKeyPressed();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isCarouselFocused, onLeftKeyPressed, onRightKeyPressed]);
};
