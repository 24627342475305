import * as React from 'react';

const MinusChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="minus-chunky_svg__eds-icon-minus-chunky"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <g>
            <path fill="#fff" d="M6.5 11.5h11v1h-11z" />
            <path d="M18 11H6v2h12v-2z" />
        </g>
    </svg>
);

MinusChunkySvg.displayName = 'MinusChunkySvg';
export default MinusChunkySvg;
