export type FollowOrganizerState = {
    isAuthenticated: boolean;
    isFollowing: boolean;
    followersCount: number;
    shouldShowLoginModal: boolean;
    shouldShowGDPRModal: boolean;
    notification?: {
        message: string;
        type: string;
    };
};

// Actions
export const SET_IS_FOLLOWING = 'setIsFollowing';
export const SET_FOLLOWERS_COUNT = 'setFollowersCount';
export const SET_SHOULD_SHOW_LOGIN_MODAL = 'setShouldShowLoginModal';
export const SET_SHOULD_SHOW_GDPR_MODAL = 'setShouldShowGDPRModal';

export type FollowOrganizerAction = {
    type: string;
    payload: any;
};

export const followOrganizerInitialState: FollowOrganizerState = {
    isAuthenticated: false,
    isFollowing: false,
    followersCount: 0,
    shouldShowLoginModal: false,
    shouldShowGDPRModal: false,
};

export const followOrganizerReducer = (
    state: FollowOrganizerState,
    action: FollowOrganizerAction,
) => {
    switch (action.type) {
        case SET_IS_FOLLOWING:
            return { ...state, isFollowing: action.payload };
        case SET_FOLLOWERS_COUNT:
            return { ...state, followersCount: action.payload };
        case SET_SHOULD_SHOW_LOGIN_MODAL:
            return { ...state, shouldShowLoginModal: action.payload };
        case SET_SHOULD_SHOW_GDPR_MODAL:
            return { ...state, shouldShowGDPRModal: action.payload };
        default:
            return state;
    }
};
