export enum CheckoutErrorName {
    ERROR_APPLE_PAY_CONFIG = 'ERROR_APPLE_PAY_CONFIG',
    ERROR_CONTACT_INFO_INCOMPLETE = 'ERROR_CONTACT_INFO_INCOMPLETE',
    ERROR_EXPRESS_CHECKOUT_ORDER = 'ERROR_EXPRESS_CHECKOUT_ORDER',
    GENERIC_ERROR = 'GENERIC_ERROR',
    INVENTORY_ERROR = 'INVENTORY_ERROR',
    UNITS_NOT_AVAILABLE_PROMO_CODE = 'UNITS_NOT_AVAILABLE_PROMO_CODE',
    UNKNOWN_INVENTORY_ERROR = 'UNKNOWN_INVENTORY_ERROR',
    TICKETS_CONSTRAINTS_MUTUALLY_EXCLUDED = 'TICKETS_CONSTRAINTS_MUTUALLY_EXCLUDED',
}
