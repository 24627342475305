import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { gettext, translationPropType } from '@eventbrite/i18n';
import CopyButton from './CopyButton';
import { withTooltip } from '@eventbrite/eds-tooltip';
import { VALID_DIRECTIONS } from '@eventbrite/eds-tooltip';
import { renderIconButton } from './constants';

const _getTooltipText = (hasCopied, tooltipText, tooltipSuccessText) => {
    if (hasCopied) {
        return tooltipSuccessText;
    }
    return tooltipText;
};

const CopyButtonWrapper = ({ children }) => children;
const CopyButtonWithTooltipComponent = withTooltip(CopyButtonWrapper);

export default class CopyButtonWithTooltip extends PureComponent {
    static propTypes = {
        /**
         * The text to copy
         */
        value: PropTypes.string.isRequired,
        /**
         * The id for the tooltip
         */
        tooltipId: PropTypes.string.isRequired,
        /**
         * Callback function called if the user clicks on the copy button
         */
        onCopyClick: PropTypes.func,
        /**
         * The direction that the tooltip should appear in relative to the icon
         */
        tooltipArrowLocation: PropTypes.oneOf(VALID_DIRECTIONS),
        /**
         * Render function for the copy button contents (defaults to an Icon Button with copy icon)
         */
        render: PropTypes.func,
        /**
         * Classes to be passed down to the tooltip container hoc to be applied to its container class.
         */
        __tooltipContainerClassName: PropTypes.string,
        /**
         * Text displayed in tooltip on initial hover
         */
        tooltipText: translationPropType,
        /**
         * Text displayed in tooltip when copy is successful
         */
        tooltipSuccessText: translationPropType,
    };

    static defaultProps = {
        tooltipArrowLocation: 'bottom',
        tooltipText: gettext('Copy'),
        tooltipSuccessText: gettext('Copied'),
        render: renderIconButton,
    };

    onRender = (hasCopied) => {
        const {
            tooltipText,
            tooltipSuccessText,
            tooltipArrowLocation,
            tooltipId,
            __tooltipContainerClassName,
            render,
        } = this.props;

        const tooltipTextToRender = _getTooltipText(
            hasCopied,
            tooltipText,
            tooltipSuccessText,
        );

        return (
            <CopyButtonWithTooltipComponent
                toolTipArrowLocation={tooltipArrowLocation}
                tooltipId={tooltipId}
                tooltipText={tooltipTextToRender}
                __containerClassName={__tooltipContainerClassName}
            >
                {render(hasCopied)}
            </CopyButtonWithTooltipComponent>
        );
    };

    render() {
        const { value, onCopyClick } = this.props;

        return (
            <CopyButton
                render={this.onRender}
                value={value}
                onCopyClick={onCopyClick}
            />
        );
    }
}
