import { gettext } from '@eventbrite/i18n';
import React from 'react';
import {
    useEventBasicInformationContext,
    useTicketContext,
} from '../../../../contexts';
import { DiscountType } from '../../../../types';
import { DiscountSignal } from '../DiscountSignal';

export const Discounts = () => {
    const {
        discount,
        ticketsInfo: { hasEarlyBirdTickets, hasBogoTickets } = {},
    } = useEventBasicInformationContext();

    const discountApplied = discount?.percentOff || discount?.amountOff;
    const hasOpenDiscount =
        discount?.discountType === DiscountType.open && discountApplied;

    const { isFetchComplete } = useTicketContext();

    if (!isFetchComplete) {
        return null;
    }

    return (
        <>
            {hasEarlyBirdTickets && (
                <DiscountSignal>
                    {gettext('Early bird discount').toString()}
                </DiscountSignal>
            )}
            {hasOpenDiscount && (
                <DiscountSignal>
                    {gettext('%(discountApplied)s off applied', {
                        discountApplied,
                    })}
                </DiscountSignal>
            )}
            {hasBogoTickets && (
                <DiscountSignal>{gettext('2 for 1 deal')}</DiscountSignal>
            )}
        </>
    );
};
