import { Button } from '@eventbrite/eds-button';
import { Link } from '@eventbrite/eds-link';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { trackEvent } from '../analytics/analytics';
import {
    AMPLITUDE_GA_TRACKING_CATEGORY,
    CLICK_SUBSCRIPTION_BANNER_DISMISS_ACTION,
    CLICK_SUBSCRIPTION_BANNER_SUBSCRIPTION_ACTION,
    POST_TRIAL_SUBSCRIPTION_BANNER_FEATURE,
} from '../constants';

export const PostTrialTitle = gettext(
    'Ready to subscribe to Boost?',
).toString();
export const PostTrialDescription = gettext(
    'Your free trial of Boost is over, so subscribe now to increase your ticket sales and keep your audience engaged.',
).toString();

const onSubscriptionClick = (history: RouteComponentProps['history']) => {
    trackEvent({
        category: AMPLITUDE_GA_TRACKING_CATEGORY,
        action: CLICK_SUBSCRIPTION_BANNER_SUBSCRIPTION_ACTION,
        path: history.location.pathname,
        feature: POST_TRIAL_SUBSCRIPTION_BANNER_FEATURE,
    });
    history.push('/settings/billing/subscribe?returnUrl=true');
};

const onDismissClick = (
    setIsDismiss: Function,
    history: RouteComponentProps['history'],
) => {
    trackEvent({
        category: AMPLITUDE_GA_TRACKING_CATEGORY,
        action: CLICK_SUBSCRIPTION_BANNER_DISMISS_ACTION,
        path: history.location.pathname,
        feature: POST_TRIAL_SUBSCRIPTION_BANNER_FEATURE,
    });
    setIsDismiss(true);
    return null;
};
export interface Buttons {
    history: RouteComponentProps['history'];
    setIsDismiss: Function;
}

export const PostTrialButtons = (props: Buttons) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-evenly',
            }}
        >
            <Button
                style="eds-text-bm eds-text-weight--heavy"
                onClick={() => onSubscriptionClick(props.history)}
            >
                {gettext('Subscribe now')}
            </Button>
            <div style={{ paddingTop: '10px', cursor: 'pointer' }}>
                <Link
                    style="neutral"
                    onClick={() =>
                        onDismissClick(props.setIsDismiss, props.history)
                    }
                    __containerClassName="body-medium--bold link-button-dismiss eds-text-bm eds-text-weight--heavy"
                >
                    {gettext('Dismiss')}
                </Link>
            </div>
        </div>
    );
};

export const PostTrialBannerIcon = (
    <svg
        width="53"
        height="53"
        viewBox="0 0 53 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="26.5" cy="26.5" r="26.5" fill="#3659E3" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.9993 14L17 32.2084H27V42L37 23.8084L26.9993 23.8V14Z"
            fill="white"
        />
    </svg>
);
