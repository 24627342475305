export type UrgencyMessageKeys = 'fewTickets' | 'goingFast' | 'salesEndSoon';

export type UrgencyCategoryKeys = 'earlyBird' | 'popular' | 'new';

export enum UrgencyMessageType {
    FewTickets = 'fewTickets',
    GoingFast = 'goingFast',
    SalesEndSoon = 'salesEndSoon',
}

export enum UrgencyCategoryType {
    EarlyBird = 'earlyBird',
    Popular = 'popular',
    New = 'new',
}

export type UrgencyMessages = Partial<Record<UrgencyMessageType, boolean>>;

export type UrgencyCategories = Partial<Record<UrgencyCategoryType, boolean>>;

export type UrgencySignalsProps =
    | {
          messages: UrgencyMessages;
          categories: UrgencyCategories;
      }
    | undefined;

export type EnabledUrgencySignals = {
    messages: Record<string, boolean | undefined>;
    categories: Record<string, boolean | undefined>;
};
