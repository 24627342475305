import { sdkRequest } from '@eventbrite/http';

const GET_PLACE_FROM_IP = '/api/v3/geo/place_from_request/';
export async function fetchEventUrgencySignals(eventID: string) {
    const apiUrl = `/destination/events/${eventID}/?expand=urgency_signals`;

    try {
        const response = await sdkRequest(apiUrl);

        if (response?.urgency_signals) {
            return {
                messages: {
                    fewTickets:
                        response.urgency_signals.messages.includes(
                            'fewTickets',
                        ),
                    goingFast:
                        response.urgency_signals.messages.includes('goingFast'),
                    salesEndSoon:
                        response.urgency_signals.messages.includes(
                            'salesEndSoon',
                        ),
                },
                categories: {
                    earlyBird:
                        response.urgency_signals.categories.includes(
                            'earlyBird',
                        ),
                    popular:
                        response.urgency_signals.categories.includes('popular'),
                    new: response.urgency_signals.categories.includes('new'),
                },
            };
        } else {
            // 'data' is empty or does not contain 'urgency_signals'
            return null;
        }
    } catch (error) {
        console.error('Error while doing API call:', error);
        throw error;
    }
}

export const getPlaceFromRequest = () => {
    return sdkRequest(GET_PLACE_FROM_IP);
};
