import React from 'react';
import PropTypes from 'prop-types';
import { gettext } from '@eventbrite/i18n';
import { formatUrl } from 'url-lib';
import { IconButton } from '@eventbrite/eds-icon-button';

import { openUrl } from './utils';
import { TWITTER_AFFILIATED_CODE, UTM_OPTION_PROPTYPE } from './constants';

import { LogoTwitter } from '@eventbrite/eds-iconography';

const TWITTER_URL = 'https://twitter.com/intent/tweet';

const _getEventUrl = (eventUrl, affiliateChannel, utmOptions) =>
    formatUrl(eventUrl, {
        aff:
            TWITTER_AFFILIATED_CODE[affiliateChannel] ||
            TWITTER_AFFILIATED_CODE['default'],
        ...utmOptions,
    });

export default class TwitterShare extends React.Component {
    static propTypes = {
        /**
         * Event's name
         */
        eventName: PropTypes.string.isRequired,

        /**
         * Event's url
         */
        eventUrl: PropTypes.string.isRequired,

        /**
         * Twitter handle
         */
        twitterHandle: PropTypes.string.isRequired,

        /**
         * Function that onClick of one of the share options, passes
         * back an id of the clicked option
         */
        onClick: PropTypes.func,

        /**
         * utmOptions configured for this shareType
         */
        utmOptions: UTM_OPTION_PROPTYPE,
    };

    _handleButtonClick() {
        const {
            affiliateChannel,
            eventName,
            eventUrl,
            onClick,
            twitterHandle,
            utmOptions,
        } = this.props;
        const text = gettext(
            'Check out "%(eventName)s" %(eventUrl)s @%(twitterHandle)s',
            {
                eventName,
                twitterHandle,
                eventUrl: _getEventUrl(eventUrl, affiliateChannel, utmOptions),
            },
        );
        const url = formatUrl(TWITTER_URL, { text });

        if (onClick) {
            onClick();
        }

        openUrl(url);
    }

    render() {
        return (
            <span
                className="eds-l-pad-right-1"
                data-spec="twitter-share-box-container"
                data-heap-id={this.props.dataHeapId}
                data-share-method={this.props.shareMethod}
                data-page-area={this.props.dataPageArea}
                data-event-id={this.props.eventId}
            >
                <IconButton
                    iconType={<LogoTwitter />}
                    title={gettext('Share on Twitter')}
                    onClick={this._handleButtonClick.bind(this)}
                />
            </span>
        );
    }
}
