import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import React from 'react';

import './ContentSkeleton.scss';

export type ContentSkeletonProps = React.PropsWithChildren<{
    className?: string;
}>;

export function ContentSkeleton(props: ContentSkeletonProps) {
    return (
        <section className="related-events-content-skeleton" {...props}>
            <ProgressIndicator shape="circular" style="gradient" />
        </section>
    );
}
