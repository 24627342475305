import { type Discount, type TicketsProps } from '../types';
import { getLocalizedFormattedPriceString } from './priceFormat';

const formatToPercentage = (value: string): string => {
    const integerPart = parseInt(value, 10);
    return `${integerPart}%`;
};

export const getDiscountFromEventListings = (
    locale: string,
    tickets?: TicketsProps,
): Discount | undefined => {
    const discountedTicket = tickets?.ticketClasses.find(
        (ticketClass) => !!ticketClass.discountId,
    );

    if (!discountedTicket || discountedTicket.variants.length === 0) {
        return undefined;
    }

    const variant = discountedTicket.variants[0];

    if (!variant.discountType) {
        return undefined;
    }

    return {
        discountType: variant.discountType,
        percentOff: variant.percentOff
            ? formatToPercentage(variant.percentOff)
            : undefined,
        amountOff:
            variant.amountOff && !variant.percentOff
                ? getLocalizedFormattedPriceString({
                      locale,
                      currency: variant.amountOff.currency,
                      value: variant.amountOff.value,
                  })
                : undefined,
        code: variant.code,
    };
};

export const hasEarlyBirdTickets = (tickets?: TicketsProps): boolean => {
    if (!tickets?.ticketClasses || tickets?.ticketClasses.length === 0) {
        return false;
    }

    return tickets.ticketClasses.some((ticketClass) => {
        return ticketClass.displayName.toLowerCase() === 'early bird';
    });
};

export const hasBogoTickets = (tickets?: TicketsProps): boolean =>
    tickets?.availability?.hasBogoTickets || false;
