import Stepper from '@eventbrite/eds-stepper';
import React, { useCallback } from 'react';
import { useTicketContext } from '../context';

export type TicketStepperProps = {
    minimumQuantity: number;
    maximumQuantityPerOrder: number;
};

export const TicketStepper = ({
    minimumQuantity,
    maximumQuantityPerOrder,
}: TicketStepperProps) => {
    const {
        onQuantityChange,
        id: ticketId,
        characteristics,
        quantity,
    } = useTicketContext();

    const onDecreaseOneStep = useCallback(() => {
        onQuantityChange(
            ticketId,
            quantity == minimumQuantity ? 0 : quantity - 1,
        );
    }, [quantity, minimumQuantity, onQuantityChange, ticketId]);

    const onIncreaseOneStep = useCallback(() => {
        if (characteristics.defaultQuantity > quantity) {
            return onQuantityChange(ticketId, characteristics.defaultQuantity);
        }

        if (quantity < minimumQuantity) {
            return onQuantityChange(ticketId, minimumQuantity);
        }

        onQuantityChange(ticketId, quantity + 1);
    }, [
        characteristics.defaultQuantity,
        quantity,
        minimumQuantity,
        onQuantityChange,
        ticketId,
    ]);

    const onSetQuantity = useCallback(
        (value: number) => {
            onQuantityChange(ticketId, value);
        },
        [onQuantityChange, ticketId],
    );

    return (
        <Stepper
            maxValue={maximumQuantityPerOrder}
            minQuantitySelectable={
                minimumQuantity || characteristics.defaultQuantity || 0
            }
            minValue={characteristics.defaultQuantity || 0}
            onDecreaseOneStep={onDecreaseOneStep}
            onIncreaseOneStep={onIncreaseOneStep}
            onSetQuantity={onSetQuantity}
            totalQuantity={quantity}
        />
    );
};
