import { track } from '@eventbrite/datalayer-library';
import React, { useEffect } from 'react';
import { LISTING_HEAP_IDS } from '../../../constants';
import { RelatedEventsSection } from '../RelatedEventsSection';
import { OtherEventsYouMayLikeContentProps } from '../types';
import { OtherEventsYouMayLikeSegmentedCarousel } from './OtherEventsYouMayLikeSegmentedCarousel';

export type OtherEventsYouMayLikeProps = OtherEventsYouMayLikeContentProps;

export function OtherEventsYouMayLike({
    eventId,
    shareComponentProps,
    isAuthenticated,
    areMetricsEnabled,
    eventsToRemove,
    locale,
}: OtherEventsYouMayLikeProps) {
    useEffect(() => {
        //see for multiticket
        track({
            eventName: LISTING_HEAP_IDS.RECCOMENDATIONS_VIEW,
            eventData: {
                eventBucketLabel: 'Other Events you may like',
            },
        });
    }, []);

    return (
        <RelatedEventsSection className="listings-root__related-events">
            <OtherEventsYouMayLikeSegmentedCarousel
                {...{
                    eventId,
                    shareComponentProps,
                    isAuthenticated,
                    areMetricsEnabled,
                    eventsToRemove,
                    locale,
                }}
            />
        </RelatedEventsSection>
    );
}
