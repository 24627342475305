import noop from 'lodash/noop';
import React from 'react';

import momentTZ from 'moment-timezone';
import { gettext } from '@eventbrite/i18n';

/* Foundational Imports */
import { ShareIosChunky } from '@eventbrite/eds-iconography';
import { HeartFillChunky } from '@eventbrite/eds-iconography';
import { HeartChunky } from '@eventbrite/eds-iconography';

/* Atomical Imports */
import { Card } from '@eventbrite/eds-card';
import { STYLE_BRAND, STYLE_DARK } from '@eventbrite/eds-icon-button';
import {
    reTrackHeapEvent,
    HEAP_SHARE_EVENT_METHOD,
    HEAP_SHARE_EVENT_ACTION,
} from '@eventbrite/eds-utils';

/* Molecular Imports */
import EventCardContent from './_internal/EventCardContent';
import {
    GRID_TYPE,
    STANDARD_STYLE,
    IMAGE_STYLE_FIXED,
} from './_internal/constants';
import { TYPE_SIMPLE, TYPE_COMPLEX } from '@eventbrite/eds-modal';
import { displayJourneyUsingMetatags } from '@eventbrite/branchio-utils';

/* Local Imports */
import { SaveModalContent } from './SaveEventModalContent';
import { ShareModalContent } from './ShareEventModalContent';
import { SignalContent } from './components/Signal/SignalContent';
import FormattedEventName from './FormattedEventName';
import { EventInteractions, EVENT_INTERACTION } from './EventInteractions';

import { getEventTimeString, getFormattedPriceString } from './utils';
import { track } from '@eventbrite/datalayer-library';

import {
    EVENTBRITE,
    EVENT_CARD_PROPTYPE,
    SOLD_OUT_FLAG_TEXT,
    HEAP_EVENT_CARD_CLICK,
} from './constants';

import './eventCard.scss';

require('intersection-observer');

const getLocationNode = (locationInfo) => {
    let component;

    if (locationInfo) {
        component = (
            <div
                data-subcontent-key="location"
                className="card-text--truncated__one"
            >
                {locationInfo}
            </div>
        );
    }

    return component;
};

const isNotOnSale = (eventSalesStatus) => {
    if (eventSalesStatus) {
        return eventSalesStatus.salesStatus !== 'on_sale';
    }
};

export const getFlagInfo = (isSoldOut, eventSalesStatus) => {
    let component;

    if (isSoldOut) {
        component = <span>{SOLD_OUT_FLAG_TEXT}</span>;
    }

    if (eventSalesStatus) {
        if (isNotOnSale(eventSalesStatus)) {
            component = (
                <span className="eds-text--center">
                    {eventSalesStatus.message}
                </span>
            );
        }
    }

    return component;
};

const Title = ({ TitleWrapper, eventName }) => {
    return TitleWrapper ? (
        <TitleWrapper>
            <FormattedEventName eventName={eventName} />
        </TitleWrapper>
    ) : (
        <FormattedEventName eventName={eventName} />
    );
};

const MemoizedTitle = React.memo(Title, (prevProps, nextProps) => {
    if (prevProps.eventName !== nextProps.eventName) {
        return false;
    }

    const castedWrappingComponent = Boolean(prevProps.TitleWrapper);
    const castedNewWrappingComponent = Boolean(nextProps.TitleWrapper);

    if (castedWrappingComponent !== castedNewWrappingComponent) {
        return false;
    }

    return true;
});

/*
This implementation of the EventCard utilizes data points and structure that
has been vetted and approved through multiple design iterations. This allows
us to keep a unified appearance of what data a user can expect to find on an
EventCard across our platform.

If you have special customizations or other things needed for your implementation
please feel free to roll your own version utilizing EventCardContent from EDS.
*/

export default class EventCard extends React.PureComponent {
    static propTypes = EVENT_CARD_PROPTYPE;
    domElement = React.createRef();

    static defaultProps = {
        style: STANDARD_STYLE,
        type: GRID_TYPE,
        imageStyle: IMAGE_STYLE_FIXED,
        isNoFollow: false,
        hideSaveButton: false,
        shouldShowIcons: true,
        isTargetBlank: false,
        isOnlineEvent: false,
        showLikesTooltip: false,
        showRemoveTooltip: false,
        showMoreActions: false,
        showFewerEventsIcon: false,
        isAuthenticated: false,
        promoCodeLabel: undefined,
        onEventCardMoreAction: () => {},
    };

    state = {
        userTimezone: false,
    };

    componentDidMount() {
        this.setState({
            // Currently we only need to convert timezone on the browser, and it's okay
            // if we don't do so on the initial server render
            userTimezone: momentTZ.tz.guess(),
        });

        this._dispatchImpressionWhenVisible();
    }

    _dispatchImpressionWhenVisible() {
        let impressed = false;
        this.observer = new IntersectionObserver(
            ([entry]) => {
                if (!impressed && entry.isIntersecting) {
                    this._dispatchInteraction(EventInteractions.Impression);
                    impressed = true;
                }
            },
            { threshold: 0.25 },
        );

        this.observer.observe(this.domElement.current);
    }

    _dispatchInteraction(interaction) {
        this.domElement.current?.dispatchEvent(
            new CustomEvent(EVENT_INTERACTION, {
                detail: {
                    id: this.props.id,
                    action: interaction,
                    event: {
                        isPromoted: Boolean(this.props.isPromoted),
                        isFree: Boolean(this.props.isFree),
                        isOnline: Boolean(this.props.isOnlineEvent),
                    },
                },
                bubbles: true,
            }),
        );
    }

    _onSaveClick = () => {
        const { id, onSaveEvent, savedByYou, isAuthenticated } = this.props;
        displayJourneyUsingMetatags({
            name: 'SaveClicked',
            content: '1',
            extraData: {
                customActionParams: {
                    EventId: id,
                },
            },
        }); // Add metatag to display branchIO journeys

        if (!isAuthenticated) {
            this._handleSaveModalShow();
        } else if (onSaveEvent) {
            onSaveEvent(id, !savedByYou);
        }
    };

    _handleSaveModalShow() {
        const {
            showOverlay,
            id,
            onSaveEvent,
            name,
            startDate,
            imageUrl,
            isLazyImage,
        } = this.props;

        if (showOverlay) {
            showOverlay('modal', {
                type: TYPE_COMPLEX,
                children: (
                    <SaveModalContent
                        onClick={onSaveEvent}
                        id={id}
                        name={<FormattedEventName eventName={name} />}
                        imageUrl={imageUrl}
                        isLazyImage={isLazyImage}
                        startDate={startDate}
                    />
                ),
                onClose: noop,
            });
        }
    }

    _handleShareClick = () => {
        const {
            id: eventId,
            name: eventName,
            showOverlay,
            shareOptions,
            onWillShareClick,
            onDidShareClick,
            affCode,
        } = this.props;
        if (onWillShareClick) {
            onWillShareClick();
        }
        if (shareOptions && showOverlay) {
            const updatedShareOptions = {
                ...shareOptions,
                eventId,
                eventName,
                affCode,
                onClick: (e) => {
                    reTrackHeapEvent({
                        eventId: eventId,
                        heapEventName: HEAP_SHARE_EVENT_METHOD,
                        shareMethod: e,
                        affCode: affCode,
                    });
                    onDidShareClick?.(e);
                },
            };

            showOverlay('modal', {
                type: TYPE_SIMPLE,
                title: gettext('Share with friends'),
                children: (
                    <ShareModalContent shareOptions={updatedShareOptions} />
                ),
                /* Due to a bug relating to safari, flexbox, and no set
                    height causing the modal to not appear, commenting out this
                    param until a fix is in place */
                // noMinHeight: true,
                onClose: noop,
            });

            reTrackHeapEvent({
                affCode,
                eventId,
                heapEventName: HEAP_SHARE_EVENT_ACTION,
            });
        }
    };

    handleEventCardMoreAction = (val) => {
        if (val === 'shareEvent') {
            this._handleShareClick();
        }
        this.props.onEventCardMoreAction(val);
    };

    render() {
        const {
            id,
            edgeColor,
            name,
            TitleWrapper,
            startDate,
            startTime,
            ticketsBy,
            url,
            isSoldOut,
            savedByYou,
            style,
            type,
            imageStyle,
            containerSpacingClasses,
            onClick,
            locationInfo,
            minPrice,
            formattedPriceString,
            shareOptions,
            repeatingInstanceCount,
            isNoFollow,
            focused,
            hideSaveButton,
            shouldShowIcons,
            imageUrl,
            isLazyImage,
            hidePriceDescription,
            isTargetBlank,
            timezone,
            isOnlineEvent,
            shouldConvertToUserTimezone,
            subtitle: subtitleProp,
            description,
            eventSalesStatus,
            organizerName,
            signal,
            isFree,
            isPromoted,
            onCollectionClick,
            onIsCollectionViewActive,
            showLikesTooltip,
            isRaised,
            showRemoveTooltip,
            showMoreActions,
            showFewerEventsIcon,
            locationSlug,
            paidStatus,
            category,
            bucketLabel,
            bucketKey,
            isAuthenticated,
            promoCodeLabel,
        } = this.props;
        let configProps = {};
        let formattedTimeAndDate = null;
        const { userTimezone } = this.state;

        /* Formatted event data strings and nodes to render as
        subContent on the eventCard */
        if (startDate) {
            formattedTimeAndDate = getEventTimeString(
                startDate,
                startTime,
                repeatingInstanceCount,
                isOnlineEvent,
                timezone,
                userTimezone,
                shouldConvertToUserTimezone,
            );
        }

        const formattedLocationNode = getLocationNode(locationInfo);

        /* Only render the share Icon if the shareOptions are passed through */
        if (shareOptions) {
            configProps = {
                ...configProps,
                secondaryIconType: <ShareIosChunky />,
                secondaryIconOnClick: this._handleShareClick,
                secondaryIconTitle: gettext(
                    'Share %(eventName)s with your friends.',
                    { eventName: name },
                ),
            };
        }

        const subtitle = formattedTimeAndDate
            ? formattedTimeAndDate
            : subtitleProp;
        const subContentOne = formattedLocationNode;
        let subContentTwo;
        let subContentThree;

        if (!hidePriceDescription) {
            const formattedPrice = formattedPriceString
                ? formattedPriceString
                : getFormattedPriceString(minPrice, isFree, ticketsBy);

            if (promoCodeLabel) {
                subContentTwo = (
                    <div
                        className="eds-event-card__price-container"
                        data-subcontent-key="event-card-price"
                    >
                        {formattedPrice}
                        {promoCodeLabel}
                    </div>
                );
            } else {
                subContentTwo = formattedPrice;
            }
        }

        if (signal) {
            if (subContentTwo) {
                subContentThree = (
                    <SignalContent
                        signal={signal}
                        onCollectionClick={onCollectionClick}
                        onIsCollectionViewActive={onIsCollectionViewActive}
                        organizerName={organizerName}
                        data-subcontent-key="signal"
                    />
                );
            } else {
                subContentTwo = (
                    <SignalContent
                        signal={signal}
                        onCollectionClick={onCollectionClick}
                        onIsCollectionViewActive={onIsCollectionViewActive}
                        organizerName={organizerName}
                        data-subcontent-key="signal"
                    />
                );
            }
        }

        let saveIconType = null;
        let saveIconStyle = null;
        let saveIconOnClick = null;
        let saveIconTitle = null;

        if (!hideSaveButton) {
            saveIconType = savedByYou ? <HeartFillChunky /> : <HeartChunky />;
            saveIconStyle = savedByYou ? STYLE_BRAND : STYLE_DARK;
            saveIconOnClick = this._onSaveClick;
            saveIconTitle = gettext('Save %(eventName)s to your collection.', {
                eventName: name,
            });
        }

        /* Necessary to add 1 grid unit of padding
        to ensure hover effect occurs as expected */
        return (
            <div
                ref={this.domElement}
                className="eds-event-card--consumer"
                data-event-id={id}
                data-event-location={locationSlug}
                data-event-paid-status={paidStatus}
                data-event-category={category}
                data-event-bucket-label={bucketLabel}
                data-event-bucket-key={bucketKey}
            >
                <Card style="neutral" isRaised={isRaised}>
                    <EventCardContent
                        {...configProps}
                        id={id}
                        style={style}
                        type={type}
                        imageStyle={imageStyle}
                        containerSpacingClasses={containerSpacingClasses}
                        isTargetBlank={
                            isTargetBlank || ticketsBy !== EVENTBRITE
                        }
                        isNoFollow={isNoFollow}
                        isPromoted={isPromoted}
                        onClick={(...params) => {
                            track({
                                eventName: HEAP_EVENT_CARD_CLICK,
                                eventData: {
                                    clickEventId: id,
                                    eventPaidStatus: paidStatus,
                                    eventLocation: locationSlug,
                                    eventBucketLabel: bucketLabel,
                                    eventCategory: category,
                                },
                            });
                            this._dispatchInteraction(EventInteractions.Click);
                            return onClick && onClick(...params);
                        }}
                        focused={focused}
                        title={
                            <MemoizedTitle
                                TitleWrapper={TitleWrapper}
                                eventName={name}
                            />
                        }
                        linkUrl={url}
                        flag={getFlagInfo(isSoldOut, eventSalesStatus)}
                        flagDark={isNotOnSale(eventSalesStatus)}
                        subtitle={subtitle}
                        description={description}
                        subContentOne={subContentOne}
                        subContentTwo={subContentTwo}
                        subContentThree={subContentThree}
                        imageUrl={imageUrl}
                        isLazyImage={isLazyImage}
                        imageAlt={name}
                        placeholderBgColor={edgeColor}
                        shouldShowIcons={shouldShowIcons}
                        primaryIconType={saveIconType}
                        primaryIconStyle={saveIconStyle}
                        primaryIconOnClick={saveIconOnClick}
                        primaryIconTitle={saveIconTitle}
                        showLikesTooltip={showLikesTooltip}
                        saved={savedByYou}
                        showRemoveTooltip={showRemoveTooltip}
                        showMoreActions={showMoreActions}
                        showFewerEventsIcon={showFewerEventsIcon}
                        onEventCardMoreAction={this.handleEventCardMoreAction}
                        bucketLabel={bucketLabel}
                        bucketKey={bucketKey}
                        isAuthenticated={isAuthenticated}
                    />
                </Card>
            </div>
        );
    }

    componentWillUnmount() {
        this.observer?.disconnect();
    }
}
