import { getWindowObject } from '@eventbrite/feature-detection';
import { useCallback, useEffect, useState } from 'react';

//TODO: consider using it from @eventbrite/feature-detection
export const useIsMobileDevice = () => {
    const [windowWidth, setWindowWidth] = useState<number | null>(null);
    const MOBILE_BREAKPOINT = 960;

    const updateDimensions = useCallback(() => {
        setWindowWidth(getWindowObject('innerWidth'));
    }, []);

    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, [updateDimensions]);

    const isMobile = (windowWidth || 0) <= MOBILE_BREAKPOINT;
    return isMobile;
};
