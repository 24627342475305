import { SALES_STATUS_ON_SALE } from '../../constants';
import type { CheckoutDisqualifications, Event } from '../../types';
import type { TicketClass } from './types';

const TICKET_LIMIT = 5;

const disqualificationKeys: Array<keyof CheckoutDisqualifications> = [
    'discount_code',
    'has_addons',
    'has_discounts',
    'has_donations',
    'has_inventory_tiers',
    'has_waiting_room',
    'has_waitlist',
    'is_canceled',
    'is_postponed',
    'is_rsvp',
    'is_sales_ended',
    'repeating_event',
    'reserved_seating',
    'sold_out',
];

type EventToCheck = Pick<
    Event,
    'compactCheckoutDisqualifications' | 'childEvents'
> & { salesStatus: string };

type EventDisqualificationFunction = (
    event: EventToCheck,
    ticketClasses?: Array<TicketClass>,
) => boolean;

type QualifierKey =
    | 'allFlagsFalse'
    | 'lessThanEqualToFiveTickets'
    | 'isNotRepeatedEvent'
    | 'isOnSale';

const QualificationCriteria: Record<
    QualifierKey,
    EventDisqualificationFunction
> = {
    allFlagsFalse: ({ compactCheckoutDisqualifications }) =>
        disqualificationKeys.every(
            (key) => !compactCheckoutDisqualifications[key],
        ),
    isOnSale: ({ salesStatus }) => salesStatus === SALES_STATUS_ON_SALE,
    lessThanEqualToFiveTickets: (_, ticketClasses = []) =>
        ticketClasses.length <= TICKET_LIMIT,
    isNotRepeatedEvent: ({ childEvents }) => {
        return !childEvents || childEvents.length === 0;
    },
};

export const qualifiesForMultiticketSelection = (
    event: EventToCheck,
    ticketClasses: Array<TicketClass>,
    hasBogoTickets?: boolean,
    onlyDefaultCheckoutForOnlineEvents?: boolean,
) => {
    const conditions = [
        QualificationCriteria.isOnSale,
        QualificationCriteria.lessThanEqualToFiveTickets,
        QualificationCriteria.allFlagsFalse,
        QualificationCriteria.isNotRepeatedEvent,
    ];
    return (
        !hasBogoTickets &&
        !onlyDefaultCheckoutForOnlineEvents &&
        conditions.every((condition) => condition(event, ticketClasses))
    );
};
