import { SetEarlyBirdAction } from '../actions';
import { EventBasicInformation } from '../types';

export const setEarlyBird = (
    event: EventBasicInformation,
    { payload: { hasEarlyBirdTicketsValue } }: SetEarlyBirdAction,
): EventBasicInformation => {
    const updatedEvent = {
        ...event,
        ticketsInfo: {
            ...event.ticketsInfo,
            hasEarlyBirdTickets: hasEarlyBirdTicketsValue,
        },
    };
    return updatedEvent;
};
