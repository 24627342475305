import React from 'react';

import { gettext } from '@eventbrite/i18n';
import InlineBanner from '../_shared/Banner/InlineBanner';

const MaintenanceBanner = () => {
    const maintenanceStartTime = Date.UTC(2024, 9, 20, 7, 15); // Oct 20, 2024, 07:15 AM in UTC (Oct 20, 2024, 12:15 AM PDT) (Oct 20, 2024, 09:15 AM CEST)
    const maintenanceEndTime = Date.UTC(2024, 9, 21, 7, 15); // Oct 21, 2024, 07:15 AM in UTC (Oct 21, 2024, 12:15 AM PDT) (Oct 21, 2024, 09:15 AM CEST)
    const now = Date.now();

    const shouldShowMaintenanceBanner =
        now >= maintenanceStartTime && now <= maintenanceEndTime;

    if (!shouldShowMaintenanceBanner) {
        return null;
    }

    const maintenanceText = gettext(
        'We’ll be performing routine site maintenance starting at 12:15 AM PDT on Monday October 21. We expect this to take 15 minutes, and eventbrite.com will be unavailable during that time. For the latest status updates, refer to ',
    );

    return (
        <InlineBanner variant="opportunity">
            <>
                {maintenanceText}
                <a
                    href="http://www.eventbritestatus.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    http://www.eventbritestatus.com
                </a>
                .
            </>
        </InlineBanner>
    );
};

export default MaintenanceBanner;
