import React from 'react';
import { READ_MORE_BUTTON_ID, READ_MORE_CONTENT_ID } from './constants';
import { useTicketContext } from './context';
import styles from './TicketCard.module.scss';

export const ReadMoreContent: React.FC = ({ children }) => {
    const { expanded, description, displayFlags, id = '' } = useTicketContext();
    const { showSalesEndDate, showRemaining } = displayFlags;
    const shouldShowReadMore = showSalesEndDate || showRemaining || description;

    if (shouldShowReadMore && expanded) {
        return (
            <div
                data-testid={READ_MORE_CONTENT_ID}
                className={styles.readMoreContent}
                id={`${READ_MORE_CONTENT_ID}-${id}`}
                aria-labelledby={`${READ_MORE_BUTTON_ID}-${id}`}
            >
                {children}
            </div>
        );
    }

    return null;
};
