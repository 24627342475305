import React from 'react';
import PropTypes from 'prop-types';
import { formatUrl } from 'url-lib';
import { IconButton } from '@eventbrite/eds-icon-button';
import { gettext, translationPropType } from '@eventbrite/i18n';

import { EMAIL_AFFILIATED_CODE, UTM_OPTION_PROPTYPE } from './constants';
import { openUrl } from './utils';

import { MailFillChunky } from '@eventbrite/eds-iconography';

const _getMailToUrl = (
    eventName,
    eventUrl,
    affiliateChannel,
    utmOptions,
    emailOptions = {},
) => {
    let subject = gettext("You're invited to %(eventName)s", {
        eventName,
    });

    let body = formatUrl(eventUrl, {
        aff:
            EMAIL_AFFILIATED_CODE[affiliateChannel] ||
            EMAIL_AFFILIATED_CODE['default'],
        ...utmOptions,
    });

    if (emailOptions.subject) {
        subject = emailOptions.subject;
    }

    if (emailOptions.body) {
        body = emailOptions.body;
    }

    return formatUrl('mailto:', {
        subject,
        body,
    });
};

export default class EmailShare extends React.Component {
    static propTypes = {
        /**
         * Event's id
         */
        eventId: PropTypes.string.isRequired,

        /**
         * Event's name
         */
        eventName: PropTypes.string.isRequired,

        /**
         * Event's url
         */
        eventUrl: PropTypes.string.isRequired,

        /**
         * Server's url
         */
        serverUrl: PropTypes.string.isRequired,

        /**
         * Boolean prop to know if we're on a mobile device or not
         */
        isMobile: PropTypes.bool,

        /**
         * Function that onClick of one of the share options, passes
         * back an id of the clicked option
         */
        onClick: PropTypes.func,

        /**
         * utmOptions configured for this shareType
         */
        utmOptions: UTM_OPTION_PROPTYPE,

        /**
         * affiliate_code configured for this shareType
         */
        trackingCode: PropTypes.string,

        /**
         * Configurable copy options
         * for prefilling the email
         */
        emailOptions: PropTypes.shape({
            //Defaults to the formatted url of the shared entity
            bodyCopy: translationPropType,
            //Defaults to "You're invited to {eventName}"
            subjectCopy: translationPropType,
        }),
    };

    _handleButtonClick() {
        const {
            affiliateChannel,
            emailOptions,
            eventName,
            eventUrl,
            onClick,
            utmOptions,
        } = this.props;
        const url = _getMailToUrl(
            eventName,
            eventUrl,
            affiliateChannel,
            utmOptions,
            emailOptions,
        );

        if (onClick) {
            onClick();
        }

        openUrl(url);
    }

    render() {
        return (
            <span
                className="eds-l-pad-right-1"
                data-spec="email-share-box-container"
                data-heap-id={this.props.dataHeapId}
                data-share-method={this.props.shareMethod}
                data-page-area={this.props.dataPageArea}
                data-event-id={this.props.eventId}
            >
                <IconButton
                    iconType={<MailFillChunky />}
                    title={gettext('Share by Email')}
                    onClick={this._handleButtonClick.bind(this)}
                />
            </span>
        );
    }
}
