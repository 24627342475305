import { gettext } from '@eventbrite/i18n';
import { CalendarIcon, Typography } from '@eventbrite/marmalade';
import React from 'react';
import styles from './MoreOptionsCard.module.scss';
import { SliderCard } from './SliderCard';

interface Props {
    onButtonClick: () => void;
}

const MORE_OPTIONS = gettext('More options');

export const MoreOptionsCard = ({ onButtonClick }: Props) => (
    <SliderCard>
        <button className={styles.root} onClick={onButtonClick}>
            <div>
                <CalendarIcon className="date-info__icon" />
            </div>
            <div>
                <Typography
                    as="span"
                    color="neutral-900"
                    variant="body-md-bold"
                >
                    {MORE_OPTIONS}
                </Typography>
            </div>
        </button>
    </SliderCard>
);
