import React, { Dispatch, Reducer, useReducer } from 'react';
import { errorReducer } from './reducer';
import type { ErrorDispatchAction, ErrorState } from './types';

export const ErrorContext = React.createContext<ErrorState | null>(null);
ErrorContext.displayName = 'ErrorContext';

export const ErrorContextDispatch = React.createContext<
    Dispatch<ErrorDispatchAction>
>((state) => state);
ErrorContextDispatch.displayName = 'ErrorContextDispatch';

type ErrorContextProviderProps = {
    error?: ErrorState;
};

export const ErrorContextProvider: React.FC<ErrorContextProviderProps> = ({
    children,
    error,
}) => {
    const [state, dispatch] = useReducer<
        Reducer<ErrorState | null, ErrorDispatchAction>
    >(errorReducer, error || null);

    return (
        <ErrorContext.Provider value={state}>
            <ErrorContextDispatch.Provider value={dispatch}>
                {children}
            </ErrorContextDispatch.Provider>
        </ErrorContext.Provider>
    );
};
