import { Icon, Typography } from '@eventbrite/marmalade';
import React from 'react';
import styles from './DiscountSignal.module.scss';
import { DiscountSignalIcon } from './DiscountSignalIcon';

export const DiscountSignal: React.FC = ({ children }) => {
    return (
        <div className={styles.discount}>
            <Icon>
                <DiscountSignalIcon />
            </Icon>
            <Typography as="span" color="neutral-1000" variant="body-md-bold">
                {children}
            </Typography>
        </div>
    );
};
