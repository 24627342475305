import React, { useEffect, useState, type ReactNode } from 'react';

type ImageDimensions = {
    width: number;
    height: number;
};

export const useLazyImagePlaceholder = (
    url: string,
    containerId: string,
    { width, height }: ImageDimensions,
): ReactNode | null => {
    const [placeholder, setPlaceholder] = useState<ReactNode | null>(null);

    useEffect(() => {
        if (!width || !height) {
            return;
        }

        const intrinsicAspectRatio = width / height;
        const container = document.getElementById(containerId);

        if (!container) {
            return;
        }

        const containerWidth = container.clientWidth;

        const renderedWidth = Math.min(containerWidth, width);
        const renderedHeight = renderedWidth / intrinsicAspectRatio;

        setPlaceholder(
            <div
                className="lazy_image_placeholder"
                style={{
                    width: `${renderedWidth}px`,
                    height: `${Math.round(renderedHeight)}px`,
                }}
            />,
        );
    }, [url, containerId, width, height]);

    return placeholder;
};
