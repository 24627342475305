import {
    ShareBox,
    SHARE_ON_EMAIL,
    SHARE_ON_FACEBOOK,
    SHARE_ON_FACEBOOK_MESSENGER,
    SHARE_ON_LINKEDIN,
    SHARE_ON_TWITTER,
    SHARE_ON_WHATSAPP,
} from '@eventbrite/eds-share-box';
import {
    HEAP_SHARE_EVENT_METHOD,
    reTrackHeapEvent,
} from '@eventbrite/eds-utils';
import React from 'react';
import {
    getLabelFromShareType,
    ShareType,
    trackShareAction,
} from '../../tracking';

const DESKTOP_SHARE_TYPES = [
    SHARE_ON_FACEBOOK,
    SHARE_ON_FACEBOOK_MESSENGER,
    SHARE_ON_LINKEDIN,
    SHARE_ON_TWITTER,
    SHARE_ON_EMAIL,
];

const MOBILE_SHARE_TYPES = [
    SHARE_ON_FACEBOOK,
    SHARE_ON_LINKEDIN,
    SHARE_ON_TWITTER,
    SHARE_ON_EMAIL,
    SHARE_ON_WHATSAPP,
];

export interface ShareProps {
    facebookOptions: {
        appId: string;
        locale: string;
        page: string;
        version: string;
        xfbml: boolean;
    };
    isMobile: boolean;
    serverUrl: string;
    twitterHandle: string;
    utmOptions?: {
        'utm-campaign': string;
        'utm-content': string;
        'utm-medium': string;
        'utm-source'?: string;
        'utm-term': string;
    };
    emailOptions?: {
        bodyCopy: string;
        subjectCopy: string;
    };
    trackingCategory: string;
    eventId?: string;
}

export interface ShareEventProps extends ShareProps {
    eventId: string;
    eventName: string;
    eventUrl: string;
}

export interface ShareCollectionProps extends ShareProps {
    collectionId: string;
    collectionName: string;
    collectionUrl: string;
}

const isShareEvent = (
    shareProps: ShareEventProps | ShareCollectionProps,
): shareProps is ShareEventProps => {
    return (shareProps as ShareEventProps).eventId !== undefined;
};

type Props = ShareProps &
    (
        | {
              eventId: string;
              eventName: string;
              eventUrl: string;
          }
        | {
              collectionId: string;
              collectionName: string;
              collectionUrl: string;
          }
    );

export const ShareInline = (props: Props) => {
    const shareTypes = props.isMobile
        ? MOBILE_SHARE_TYPES
        : DESKTOP_SHARE_TYPES;
    const onClick = (type: ShareType) => {
        reTrackHeapEvent({
            eventId: props?.eventId,
            heapEventName: HEAP_SHARE_EVENT_METHOD,
            shareMethod: type,
        });
        trackShareAction({
            label: getLabelFromShareType(type),
            category: props.trackingCategory,
        });
    };
    let shareProps;
    if (isShareEvent(props)) {
        shareProps = {
            onClick,
            ...props,
            shareTypes,
        };
    } else {
        shareProps = {
            onClick,
            eventId: props.collectionId,
            eventName: props.collectionName,
            eventUrl: props.collectionUrl,
            ...props,
            shareTypes,
        };
    }
    return <ShareBox {...shareProps} />;
};
