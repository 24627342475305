import { track } from '@eventbrite/datalayer-library';
import { gettext } from '@eventbrite/i18n';
import { CheckIcon } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React from 'react';
import { defaultValues, IVerifiedBadgeProps } from './type';
import styles from './VerifiedBadge.module.scss';
/// TODO: improve css for square shape

export const testIds = {
    badgeContainer: 'badge-container',
    badgeBorder: 'badge-border',
    tickIcon: 'tick-icon',
};

export const VerifiedBadge: React.FunctionComponent<IVerifiedBadgeProps> = ({
    shape = defaultValues.shape,
    color = defaultValues.color,
    onClick,
    addOnClasses,
    children,
    shouldTrack,
    trackEventNameForClick = 'ClickVerificationBadge',
    trackBadgeType = 'creatorBadge',
}: IVerifiedBadgeProps) => {
    const badgeClass = styles.badge;
    const tickIconClass = styles.tickIcon;

    React.useEffect(() => {
        shouldTrack &&
            track({
                eventName: 'ViewVerificationBadge',
                eventData: {
                    badgeType: trackBadgeType,
                },
            });
    }, [shouldTrack, trackBadgeType]);

    const tickIcon = (
        <div
            data-testid={testIds.tickIcon}
            className={classNames(tickIconClass, testIds.tickIcon)}
            style={{ backgroundColor: color }}
        >
            <CheckIcon size="small" color="white" />
        </div>
    );

    const badgeBorder = (
        <div
            id={testIds.badgeBorder}
            data-testid={testIds.badgeBorder}
            className={`${styles.shape} ${styles[`${shape}_badge`]}`}
            style={{ borderColor: color }}
        />
    );

    const trackBadgeClick = () => {
        track({
            eventName: trackEventNameForClick,
            eventData: {
                badgeType: trackBadgeType,
            },
        });
    };

    return (
        <button
            aria-label={gettext('Verified').toString()}
            className={classNames(addOnClasses, badgeClass)}
            data-testid={testIds.badgeContainer}
            disabled={!onClick}
            onClick={() => {
                onClick && onClick();
                shouldTrack && trackBadgeClick();
            }}
        >
            {children}
            {badgeBorder}
            {tickIcon}
        </button>
    );
};
