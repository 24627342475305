import type { EventDescriptionProps } from '../components/EventDetails/EventDescription';
import type { RefundPolicyProps } from '../components/EventDetails/RefundPolicy/RefundPolicy';
import { FaqItem } from '../components/EventDetails/Widgets/Faq';
import type { EventListingsResponse } from '../types';

export const getFaqsData = (
    eventDescription?: EventDescriptionProps,
): FaqItem[] => {
    const faqWidget = eventDescription?.structuredContent?.widgets.find(
        (widget) => widget.type === 'faqs',
    )?.data || { faqs: [] };
    return 'faqs' in faqWidget ? faqWidget?.faqs : [];
};

export const getRefundPolicy = (refund?: RefundPolicyProps) => {
    const { refundPolicyCode, refundPolicyIsNoRefunds } = refund || {};
    if (refundPolicyIsNoRefunds) return 'notAccepted';
    const acceptedPolicies = ['flexible', 'moderate', 'strict', 'custom'];
    if (refundPolicyCode && acceptedPolicies.includes(refundPolicyCode)) {
        return 'accepted';
    }
    return 'notDefined';
};

export const getParkingInfo = (
    highlights: EventListingsResponse['highlights'],
) => {
    if (highlights.freeParking) {
        return 'free';
    } else if (highlights.paidParking) {
        return 'paid';
    } else if (highlights.notAvailableParking) {
        return 'not_available';
    }
    return null;
};
