import React from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import { getWindowObject } from '@eventbrite/feature-detection';

import { compact } from 'lodash';
import { flow, map } from 'lodash/fp';

import EmailShare from './EmailShare';
import FacebookShare from './FacebookShare';
import FacebookMessengerShare from './FacebookMessengerShare';
import LinkedinShare from './LinkedinShare';
import TwitterShare from './TwitterShare';
import WhatsappShare from './WhatsappShare';
import {
    FACEBOOK_PROP_TYPE_SHAPE,
    UTM_OPTION_PROPTYPE,
    SHARE_ON_EMAIL,
    SHARE_ON_FACEBOOK,
    SHARE_ON_FACEBOOK_MESSENGER,
    SHARE_ON_LINKEDIN,
    SHARE_ON_TWITTER,
    SHARE_ON_WHATSAPP,
    DEFAULT_SHARE_TYPES,
    SHARE_TYPES,
    DEFAULT_UTM_OPTIONS,
    UTM_SHARE_TYPE_MAP,
    DATA_SHARE_METHOD_MAP,
    DATA_HEAP_ID,
} from './constants';
import { getHeapPageArea } from '@eventbrite/eds-utils';

const SHARE_TYPE_COMPONENTS_MAP = {
    [SHARE_ON_EMAIL]: EmailShare,
    [SHARE_ON_FACEBOOK]: FacebookShare,
    [SHARE_ON_FACEBOOK_MESSENGER]: FacebookMessengerShare,
    [SHARE_ON_TWITTER]: TwitterShare,
    [SHARE_ON_LINKEDIN]: LinkedinShare,
    [SHARE_ON_WHATSAPP]: WhatsappShare,
};

const _getShareComponent = (
    shareTypes,
    mergedUtmOptions,
    options,
    shareType,
    dataPageArea,
) => {
    let component = null;

    // if current share type is not contained on the wanted share types
    // we will skip it and not build it
    if (SHARE_TYPES.includes(shareType)) {
        let boundOnClick;
        const ShareComponent = SHARE_TYPE_COMPONENTS_MAP[shareType];
        const shareMethod = DATA_SHARE_METHOD_MAP[shareType];

        const { onClick, ...shareOptions } = options;

        const typeSpecificUtmOptions = {
            ...mergedUtmOptions,
            ...UTM_SHARE_TYPE_MAP[shareType],
        };

        if (onClick) {
            boundOnClick = onClick.bind(null, shareType);
        }

        if (shareType === SHARE_ON_LINKEDIN) {
            shareOptions.staticUrl = true;
        }

        component = (
            <ShareComponent
                key={shareType}
                onClick={boundOnClick}
                utmOptions={typeSpecificUtmOptions}
                dataHeapId={DATA_HEAP_ID}
                shareMethod={shareMethod}
                dataPageArea={dataPageArea}
                {...shareOptions}
            />
        );
    }

    return component;
};

const _generateShareComponents = (
    shareTypes,
    mergedUtmOptions,
    options,
    dataPageArea,
) =>
    flow(
        // transform the share type to a share component
        map((shareType) =>
            _getShareComponent(
                shareTypes,
                mergedUtmOptions,
                options,
                shareType,
                dataPageArea,
            ),
        ),
        // eliminate the null values
        compact,
    )(shareTypes ? shareTypes : DEFAULT_SHARE_TYPES);

export default class ShareBox extends React.Component {
    // set the state
    constructor(props) {
        super(props);
        this.state = {
            dataPageArea: getWindowObject('location')?.pathname,
        };
    }
    componentDidMount() {
        // Set the state with the current pathname when the component mounts
        this.setState({ dataPageArea: getHeapPageArea(this?.props?.affCode) });
    }

    static propTypes = {
        /**
         * Event's id
         */
        eventId: PropTypes.string.isRequired,

        /**
         * Event's name
         */
        eventName: PropTypes.string.isRequired,

        /**
         * Event's url
         */
        eventUrl: PropTypes.string.isRequired,

        /**
         * Facebook options
         */
        facebookOptions: FACEBOOK_PROP_TYPE_SHAPE.isRequired,

        /**
         * Server's url
         */
        serverUrl: PropTypes.string.isRequired,

        /**
         * Array containig all the shares type to show. If not provided, it will default to all
         */
        shareTypes: PropTypes.arrayOf(PropTypes.oneOf(SHARE_TYPES)),

        /**
         * Twitter handle
         */
        twitterHandle: PropTypes.string.isRequired,

        /**
         * Boolean prop to know if we're on a mobile device or not
         */
        isMobile: PropTypes.bool,

        /**
         * Function that onClick of one of the share options, passes
         * back an id of the clicked option
         */
        onClick: PropTypes.func,

        /**
         * Object that allows you to customize elements
         * of the UTM parameters passed via share
         */
        utmOptions: UTM_OPTION_PROPTYPE,

        /**
         * Configurable copy options
         * for prefilling the email
         */
        emailOptions: PropTypes.shape({
            //Defaults to the formatted url of the shared entity
            bodyCopy: translationPropType,
            //Defaults to "You're invited to {eventName}"
            subjectCopy: translationPropType,
        }),
    };

    render() {
        const { shareTypes, utmOptions, ...options } = this.props;

        const mergedUtmOptions = {
            ...DEFAULT_UTM_OPTIONS,
            ...utmOptions,
        };

        const shareComponents = _generateShareComponents(
            shareTypes,
            mergedUtmOptions,
            options,
            this.state.dataPageArea,
        );

        return <div data-spec="share-box-container">{shareComponents}</div>;
    }
}
