import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translationPropType, gettext } from '@eventbrite/i18n';
import { IconButton } from '@eventbrite/eds-icon-button';
import {
    STYLE_PROP_TYPE as ICON_BUTTON_STYLE_PROPTYPE,
    ICON_PROP_TYPE as ICON_BUTTON_ICON_PROPTYPE,
} from '@eventbrite/eds-icon-button';
import {
    JoyrideTourGuide,
    JOYRIDE_TOURGUIDE_STYLES_DEFAULT,
    JOYRIDE_TOURGUIDE_FLOATER_STYLES_DEFAULT,
} from '@eventbrite/marmalade';
import loadable from '@loadable/component';
import { ThumbsDownChunky } from '@eventbrite/eds-iconography';
import { getTooltipContent } from '../getToolTipContent';

const LazyJoyride = loadable.lib(() => import('react-joyride'));
const EventCardAction = ({
    id,
    iconType,
    style,
    title,
    onClick,
    showLikesTooltip,
    saved,
    showRemoveTooltip,
    showFewerEventsIcon,
    onEventCardMoreAction,
    isAuthenticated,
}) => {
    let component = null;

    const showFewerEventsClasName = classNames(
        `eds-event-card__actions-id__${id}__more-actions`,
        'eds-event-card-content__actions-container__action',
        'eds-event-card-content__actions-icon--more-actions',
        'eds-l-pad-all-2',
    );

    const removeEventClassName = classNames(
        `eds-event-card__actions-id__${id}__more-actions`,
        'eds-event-card-content__actions-container__action',
        'eds-event-card-content__actions-icon--more-actions',
        `eds-event-card-actions-id__${id}__remove-action`,
        'eds-l-pad-all-2',
    );

    const tooltipContent = getTooltipContent({ id, showLikesTooltip });

    if (iconType) {
        component = (
            <>
                {showLikesTooltip || (isAuthenticated && showRemoveTooltip) ? (
                    <LazyJoyride>
                        {({ default: Joyride }) => (
                            <Joyride
                                disableOverlay
                                disableScrolling
                                steps={[tooltipContent]}
                                tooltipComponent={JoyrideTourGuide}
                                locale={{
                                    back: '',
                                    last: showRemoveTooltip
                                        ? gettext('Got it').toString()
                                        : '',
                                }}
                                styles={JOYRIDE_TOURGUIDE_STYLES_DEFAULT}
                                floaterProps={{
                                    styles: JOYRIDE_TOURGUIDE_FLOATER_STYLES_DEFAULT,
                                }}
                            />
                        )}
                    </LazyJoyride>
                ) : null}

                <span className={showFewerEventsClasName}>
                    <IconButton
                        aria-pressed={saved}
                        title={title}
                        style={style}
                        iconType={iconType}
                        onClick={(e) => {
                            if (onEventCardMoreAction) {
                                onEventCardMoreAction('shareEvent');
                            } else {
                                onClick(e);
                            }
                        }}
                        data-event-id={id}
                        data-testid={onEventCardMoreAction && 'share-event'}
                    />
                </span>

                {showFewerEventsIcon && isAuthenticated ? (
                    <span className={removeEventClassName}>
                        <IconButton
                            title={title}
                            iconType={<ThumbsDownChunky />}
                            onClick={() => {
                                onEventCardMoreAction('removeEvent');
                            }}
                            data-testid="remove-event"
                        />
                    </span>
                ) : null}
            </>
        );
    }

    return component;
};

export default class EventCardActions extends PureComponent {
    static propTypes = {
        id: PropTypes.string,
        primaryIconType: ICON_BUTTON_ICON_PROPTYPE,
        primaryIconStyle: ICON_BUTTON_STYLE_PROPTYPE,
        primaryIconTitle: translationPropType,
        primaryIconOnClick: PropTypes.func,
        secondaryIconType: ICON_BUTTON_ICON_PROPTYPE,
        secondaryIconStyle: ICON_BUTTON_STYLE_PROPTYPE,
        secondaryIconTitle: translationPropType,
        secondaryIconOnClick: PropTypes.func,
        shouldShowIcons: PropTypes.bool,
        isListCard: PropTypes.bool,
        showLikesTooltip: PropTypes.bool,
        showRemoveTooltip: PropTypes.bool,
        showMoreActions: PropTypes.bool,
        showFewerEventsIcon: PropTypes.bool,
        onEventCardMoreAction: PropTypes.func,
    };

    render() {
        const {
            id,
            primaryIconType,
            primaryIconStyle,
            primaryIconTitle,
            primaryIconOnClick,
            secondaryIconType,
            secondaryIconStyle,
            secondaryIconTitle,
            secondaryIconOnClick,
            shouldShowIcons,
            showLikesTooltip,
            saved,
            showRemoveTooltip,
            showFewerEventsIcon,
            onEventCardMoreAction,
            isAuthenticated,
        } = this.props;
        const classes = classNames(
            'eds-event-card-content__actions-container eds-event-card-content__actions-container--consumer',
            {
                'eds-event-card-content__actions-container--always-visible eds-event-card-content__actions-container--display-flex':
                    shouldShowIcons,
            },
        );

        return (
            <div className={classes}>
                <>
                    <EventCardAction
                        id={id}
                        iconType={primaryIconType}
                        style={primaryIconStyle}
                        title={primaryIconTitle}
                        onClick={primaryIconOnClick}
                        saved={saved}
                        adjustPadding={showFewerEventsIcon}
                    />
                    <EventCardAction
                        id={id}
                        iconType={secondaryIconType}
                        style={secondaryIconStyle}
                        title={secondaryIconTitle}
                        onClick={secondaryIconOnClick}
                        secondary={secondaryIconType}
                        showLikesTooltip={showLikesTooltip}
                        showRemoveTooltip={showRemoveTooltip}
                        showFewerEventsIcon={showFewerEventsIcon}
                        onEventCardMoreAction={onEventCardMoreAction}
                        isAuthenticated={isAuthenticated}
                    />
                </>
            </div>
        );
    }
}
