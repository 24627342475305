import React from 'react';
import { gettext } from '@eventbrite/i18n';

import { Button } from '@eventbrite/eds-button';
import { STYLE_FILL, SIZE_BLOCK } from '@eventbrite/eds-button';

import EventCardContent from './_internal/EventCardContent';
import {
    LIST_TYPE,
    STANDARD_STYLE,
    IMAGE_STYLE_FIXED,
} from './_internal/constants';

import { parseDate } from '@eventbrite/datetime';

export class SaveModalContent extends React.Component {
    // TODO: Add prop-types

    render() {
        const {
            onClick,
            id,
            name: title,
            imageUrl,
            startDate,
            isLazyImage,
        } = this.props;
        const commonProps = {
            title,
            imageUrl,
            isLazyImage,
            style: STANDARD_STYLE,
            type: LIST_TYPE,
            imageStyle: IMAGE_STYLE_FIXED,
        };

        return (
            <div className="eds-g-cell eds-g-cell-md-6-12 eds-g-offset-md-3-12 eds-g-cell-sm-8-12 eds-g-offset-sm-2-12 eds-g-cell-1-1 save-interstitial">
                <div className="eds-text--center">
                    <h1 className="eds-text-hm eds-text-weight--light eds-text-color--ui-800">
                        {gettext('Sign in to save this event')}
                    </h1>
                </div>
                <div className="eds-l-mar-top-8 eds-align--center-horizontal">
                    <div className="eds-l-pad-vert-4">
                        <EventCardContent
                            subtitle={gettext(
                                '%(month)s %(day)s',
                                parseDate(startDate),
                            )}
                            {...commonProps}
                        />
                    </div>
                </div>
                <p className="eds-l-mar-top-10 eds-l-mar-hor-4 eds-text--center eds-text-color--ui-600">
                    {gettext(
                        "Save it, find it, and we'll show you other events like it",
                    )}
                </p>
                <div className="eds-l-mar-top-6 eds-align--center-horizontal">
                    <div className="save-interstitial__continue-button">
                        <Button
                            style={STYLE_FILL}
                            onClick={onClick.bind(null, id)}
                            size={SIZE_BLOCK}
                        >
                            {gettext('Continue')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
