import { Card } from '@eventbrite/eds-card';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { ActionWrapper } from '../ActionWrapper';
import { CollectionSummary } from '../CollectionSummary';
import { Image } from '../Image';
import { OrganizerInfo, OrganizerInfoType } from '../OrganizerInfo';
import './CreatorCollectionCard.scss';

export interface CreatorCollectionCardProps {
    /**
     * The URL path of the collection
     */
    slug: string;
    /**
     *  Name of the collection
     */
    name: string;
    /**
     * Summary of the collection. Will be line clamped to 4 lines on mobile and
     * 2 lines on desktop
     */
    summary?: string;
    /**
     * ID of the collection
     */
    id?: string;
    /**
     * Organizer information. If provided will render profile image
     * and name on the card
     */
    organizer?: OrganizerInfoType;
    /**
     * URL of the collection's image
     */
    imageUrl?: string;
    /**
     * Background color to render while the image is downloading. Ideally a
     * computed edge_color of the collection's image.
     */
    collectionImageBackgroundColor?: string;
    /**
     * Number of upcoming events within the collection.
     * If provided will render translated node
     */
    numUpcomingEvents?: number;
    /**
     * Callback for when a user interacts with the click targets of the card.
     * If provided and shouldOpenNewTab == false, it will programmatically
     * set window location to ensure callback is fired.
     */
    onClick?: (id: string | undefined) => void;
    /**
     * Determines if clicking on the click targets opens a new tab or navigates
     * on the current window. If true will apply noopener.
     */
    shouldOpenNewTab?: boolean;
    /**
     * Features to render into a container positioned in the right corner of the
     * image between the fold of the image and card content indicating a holistic
     * feature of the card rather than pertaining specifically to the image or content respectfully.
     */
    features?: ReactNode;
    /**
     * Prop to turn the box shadow off on the component
     */
    hideBoxShadow?: boolean;
    /**
     * Class that can be passed to the wrapping card
     * by consumers as necessary.
     */
    __containerClassName?: string;
}

const CardContent: React.FunctionComponent = (props) => {
    return (
        <div
            data-testid="creator-collection-card_content"
            className="creator-collection-card__card-content"
        >
            {props.children}
        </div>
    );
};

const Title: React.FunctionComponent = (props) => {
    return (
        <h2 className="creator-collection-card__title eds-text-bl">
            {props.children}
        </h2>
    );
};

const Features: React.FunctionComponent = (props) => (
    <div className="creator-collection-card__features">{props.children}</div>
);

export const CreatorCollectionCard: React.FunctionComponent<
    CreatorCollectionCardProps
> = (props) => {
    const classes = classNames(
        'eds-card__creator-collection-card creator-collection-card',
        {
            'eds-card__creator-collection-card--no-shadow': props.hideBoxShadow,
            [props.__containerClassName as string]:
                !!props.__containerClassName,
        },
    );

    return (
        <Card containerClassName={classes} style="neutral">
            <ActionWrapper
                path={props.slug}
                onClick={props.onClick}
                id={props.id}
                shouldOpenNewTab={props.shouldOpenNewTab}
            >
                <Image
                    url={props.imageUrl}
                    name={props.name}
                    backgroundColor={props.collectionImageBackgroundColor}
                    id={props.id}
                />
                <CardContent>
                    <Title>{props.name}</Title>
                    <OrganizerInfo organizer={props.organizer} />
                    <CollectionSummary
                        numUpcomingEvents={props.numUpcomingEvents}
                    >
                        {props.summary}
                    </CollectionSummary>
                    <Features>{props.features}</Features>
                </CardContent>
            </ActionWrapper>
        </Card>
    );
};

CreatorCollectionCard.defaultProps = {
    shouldOpenNewTab: true,
};
