import { UrgencySignalsProps } from '@eventbrite/urgency-signals';
import React from 'react';
import { AppProps } from '../types';

type TrackingContext = {
    userId?: number;
    isStaffUser?: boolean;
    locale?: string;
    event?: {
        id: string;
        isOnline: boolean;
        isExpiredEvent: boolean;
    };
    guestId?: string;
    sessionId?: string;
    urgencySignals?: UrgencySignalsProps;
};

export const TrackingContext = React.createContext<TrackingContext>({});
TrackingContext.displayName = 'TrackingContext';

type TrackingContextProps = Pick<
    AppProps,
    'user' | 'env' | 'event' | 'settings' | 'request' | 'urgencySignals'
> & {
    components?: Partial<AppProps['components']>;
};

export function getTrackingContextFrom(props: TrackingContextProps) {
    return {
        userId: props.user?.id || 0,
        isStaffUser: props.user?.isStaff || false,
        locale: props.env?.localeInfo?.locale || '',
        event: {
            id: props.event?.id || '',
            isOnline: props.event?.isOnlineEvent || false,
            localityPlaceId:
                props.components?.eventDetails?.location?.localityPlaceId,
            isExpiredEvent: props.event?.isExpiredEvent || false,
        },
        guestId: props.user?.guestId || '',
        sessionId: props.request?.session_id || '',
        urgencySignals: props.urgencySignals,
    };
}

export const useTrackingContext = () => {
    return React.useContext(TrackingContext);
};
