import { sdkRequest } from '@eventbrite/http';
import { formatUrl } from 'url-lib';
// import { GET_ORG_ERROR_NOTIFICATION } from './constants';
import { track } from '@eventbrite/datalayer-library';
import {
    LISTING_HEAP_IDS,
    LISTING_HEAP_PAGE_AREA_IDS,
} from '../../../constants';
import {
    FollowOrganizerAction,
    SET_FOLLOWERS_COUNT,
    SET_IS_FOLLOWING,
    SET_SHOULD_SHOW_GDPR_MODAL,
    SET_SHOULD_SHOW_LOGIN_MODAL,
} from './reducer';
import {
    expireOrganizerCookie,
    getOrganizerIdFromCookie,
    hasChosenGDPR,
    shouldFollowOrganizerFromCookie,
    updateFollowCounter,
} from './utils';

export const getFollowOrganizerChannelsGetURL = (userId: number) => {
    const followOrganizerEmailChannelId = '23';
    const followOrganizerNotificationChannelId = '10004';

    const followOrganizerSource = 'follow-organizer-gpdr-modal';
    const commSettingsRootURL = `/api/v3/message/users/${userId}/comm_settings/`;

    return `${commSettingsRootURL}?channel_ids=${followOrganizerEmailChannelId},${followOrganizerNotificationChannelId}&source=${followOrganizerSource}`;
};

// type Notification = {
//     message: string;
//     notificationType: string;
//     dispatch: React.Dispatch<FollowOrganizerAction>;
// };

// export const notificationHandler = ({
//     message,
//     notificationType,
//     dispatch,
// }: Notification) => {
//     dispatch({
//         type: SHOW_NOTIFICATION,
//         payload: { message, notificationType },
//     });
// };

type HydrateFollowStatus = {
    organizerId: string;
    userId?: number;
    isGDPRCountry: boolean;
    isFollowing: boolean;
    isAuthenticated: boolean;
    dispatch: React.Dispatch<FollowOrganizerAction>;
};

type FollowStatusResponse = {
    id: string;
    follow_status: { followed_by_you: boolean; num_followers: number };
};

export const hydrateFollowStatus = ({
    organizerId,
    userId,
    isGDPRCountry,
    isFollowing,
    isAuthenticated,
    dispatch,
}: HydrateFollowStatus) =>
    sdkRequest(
        formatUrl(`/api/v3/organizers/${organizerId}/`, {
            'expand.organizer': 'follow_status',
        }),
    )
        .then((response: FollowStatusResponse) => {
            if (response) {
                const { followed_by_you, num_followers } =
                    response.follow_status;
                const organizerIdFromCookie = getOrganizerIdFromCookie();

                if (
                    shouldFollowOrganizerFromCookie({
                        organizerIdFromCookie,
                        organizerData: response,
                    }) &&
                    isAuthenticated
                ) {
                    _followOrganizerApi({
                        isFollowing,
                        organizerId,
                        userId,
                        followersCount: num_followers,
                        isGDPRCountry,
                        pageArea:
                            LISTING_HEAP_PAGE_AREA_IDS.HYDRATE_FOLLOW_STATUS,
                        dispatch,
                    });
                    expireOrganizerCookie();
                } else {
                    updateFollowState({
                        followed_by_you,
                        followersUpdated: num_followers,
                        dispatch,
                    });
                }
            }
        })
        .catch(() => {
            //this._handleError.bind(null, '', ERROR_ORG_INFO)
        });

type UpdateFollowState = {
    followed_by_you?: boolean;
    followersUpdated: any;
    dispatch: React.Dispatch<FollowOrganizerAction>;
};
const updateFollowState = ({
    followed_by_you,
    followersUpdated,
    dispatch,
}: UpdateFollowState) => {
    dispatch({ type: SET_IS_FOLLOWING, payload: followed_by_you });
    dispatch({
        type: SET_FOLLOWERS_COUNT,
        payload: followersUpdated,
    });
};

type HandleGDPRParams = {
    userId?: number;
    dispatch: React.Dispatch<FollowOrganizerAction>;
};
export const handleGDPRModal = ({ userId, dispatch }: HandleGDPRParams) => {
    // checks if user has given explicit opt in or opt out
    // to show GDPR modal in case it hasn't
    sdkRequest(getFollowOrganizerChannelsGetURL(userId as number))
        .then((response: any) => {
            dispatch({
                type: SET_SHOULD_SHOW_GDPR_MODAL,
                payload: !hasChosenGDPR(response),
            });
        })
        .catch(() => {
            dispatch({ type: SET_SHOULD_SHOW_GDPR_MODAL, payload: true });
        });
};

type FollowUnfollowResponseHandler = {
    isFollowButtonClicked?: boolean;
    response: FollowFromDestinationResponse;
    userId?: number;
    followersCount: number;
    isGDPRCountry: boolean;
    dispatch: React.Dispatch<FollowOrganizerAction>;
};

type FollowFromDestinationResponse = {
    action_performed: boolean;
    followed_by_you: boolean;
};

export const followUnfollowResponseHandler = ({
    response,
    userId,
    followersCount,
    isGDPRCountry,
    dispatch,
}: FollowUnfollowResponseHandler) => {
    if (response) {
        const { followed_by_you, action_performed } = response;
        if (action_performed && followed_by_you !== undefined) {
            updateFollowState({
                followed_by_you,
                followersUpdated: updateFollowCounter(
                    followed_by_you as boolean,
                    followersCount,
                ),
                dispatch,
            });

            if (followed_by_you && userId && isGDPRCountry) {
                handleGDPRModal({ userId, dispatch });
            }
        }
    }
};

type FollowOrganizerApiParams = {
    isFollowing: boolean;
    isFollowButtonClicked?: boolean;
    organizerId: string;
    userId?: number;
    followersCount: number;
    isGDPRCountry: boolean;
    pageArea: string;
    dispatch: React.Dispatch<FollowOrganizerAction>;
};

const _followOrganizerApi = ({
    isFollowing,
    organizerId,
    userId,
    followersCount,
    isGDPRCountry,
    pageArea,
    dispatch,
}: FollowOrganizerApiParams) => {
    const followPath = isFollowing ? 'unfollow' : 'follow';

    sdkRequest(`/api/v3/destination/organizers/${organizerId}/${followPath}/`, {
        method: 'POST',
    })
        .then((response: FollowFromDestinationResponse) => {
            const followEvent = response.followed_by_you
                ? LISTING_HEAP_IDS.FOLLOW_ORGANIZER_SUCCESS
                : LISTING_HEAP_IDS.UNFOLLOW_ORGANIZER_SUCCESS;

            track({
                eventName: followEvent,
                eventData: {
                    organizerId: organizerId,
                    pageArea: pageArea,
                },
            });
            return followUnfollowResponseHandler({
                response,
                userId,
                followersCount,
                isGDPRCountry,
                dispatch,
            });
        })
        .catch(() => {
            // notificationHandler({
            //     message: GET_ORG_ERROR_NOTIFICATION.toString(),
            //     notificationType: 'error',
            //     dispatch,
            // });
        });
};

type HandleFollowClick = FollowOrganizerApiParams & {
    isAuthenticated: boolean;
};

export const handleFollowClick = ({
    isFollowing,
    organizerId,
    isAuthenticated,
    userId,
    followersCount,
    isGDPRCountry,
    pageArea,
    dispatch,
}: HandleFollowClick) => {
    if (!isAuthenticated) {
        dispatch({ type: SET_SHOULD_SHOW_LOGIN_MODAL, payload: true });
    } else {
        _followOrganizerApi({
            isFollowing,
            organizerId,
            userId,
            followersCount,
            isGDPRCountry,
            pageArea,
            dispatch,
        });
    }
};
