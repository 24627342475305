import * as React from 'react';

const UserSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="user_svg__eds-icon--list-view_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="user_svg__eds-icon--user_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.2 19.1c1-2.8 3.7-4.7 6.7-4.7s5.7 1.9 6.7 4.7c-4.1 2.5-9.3 2.5-13.4 0zm16.1-1.9c-.5.5-1.1 1-1.7 1.5a8.15 8.15 0 00-7.6-5.2c-3.3 0-6.3 2.1-7.6 5.1-.6-.4-1.1-.9-1.6-1.4l-.8.7C4.8 20.6 8.4 22 12 22s7.2-1.4 10-4.1l-.7-.7z"
        />
        <path
            id="user_svg__eds-icon--user_head"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C9.2 2 7 4.2 7 7s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 9c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z"
        />
    </svg>
);

UserSvg.displayName = 'UserSvg';
export default UserSvg;
