import logger from '@eventbrite/client-logger';
import { fetchEB } from '@eventbrite/http';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { useEffect } from 'react';
import { GACodes, TrackingProps } from '../../types';

type UserTrackingEvent = {
    selector: string;
    callback: (e: Event) => void;
};

export interface AnalyticsProps extends TrackingProps {
    eventId: string;
}

const handleCheckoutButtonClick = function (e: Event) {
    const targetEl = e.currentTarget as HTMLElement;
    const label = targetEl.getAttribute('data-tracking-label');

    trackAnalyticsEvent({
        action: GACodes.GA_ACTION_CHECKOUT,
        category: GACodes.GA_CATEGORY_LISTING,
        label,
    });
};

const userTrackingEventsMap = [
    {
        selector: '.js-embed-ticket-modal-btn',
        callback: handleCheckoutButtonClick,
    },
];

export const useAnalytics = ({
    debugging,
    enabled,
    environment,
    launchSupportMultipleFacebookPixels,
    shouldFireTrackingBeacon,
    shouldTrackEventView,
    staticEventCache,
    trackingBeaconsSerialized,
    eventId,
}: AnalyticsProps) => {
    const pixelsTracking = () => {
        import('@eventbrite/grylls').then((grylls) => {
            const options = {
                environment,
                enabled,
                debugging,
                launchSupportMultipleFacebookPixels,
            };

            grylls.load(trackingBeaconsSerialized, options);
            grylls.page(window.document.title);
            grylls.track('event_listing', {
                eventId: eventId,
            });
        });
    };

    const eventClickTracking = () => {
        const AJAX_EVENT_CLICK_URL = '/eventclick';

        fetchEB(AJAX_EVENT_CLICK_URL, {
            method: 'POST',
            body: { eid: eventId },
        }).catch((error: any) => {
            logger.error('Error calling eventclick', error);
        });
    };

    const gaTracking = () => {
        userTrackingEventsMap.forEach(
            (userTrackingEvent: UserTrackingEvent) => {
                document
                    .querySelector(userTrackingEvent.selector)
                    ?.addEventListener('click', userTrackingEvent.callback);
            },
        );
    };

    const gaTrackingRemove = () => {
        userTrackingEventsMap.forEach((userTrackingEvent) => {
            document
                .querySelector(userTrackingEvent.selector)
                ?.removeEventListener('click', userTrackingEvent.callback);
        });
    };

    useEffect(() => {
        gaTracking();

        if (shouldTrackEventView && !staticEventCache) {
            eventClickTracking();
        }
        if (shouldFireTrackingBeacon) {
            pixelsTracking();
        }

        return () => {
            gaTrackingRemove();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const Analytics = (props: AnalyticsProps): void => {
    useAnalytics(props);
};
