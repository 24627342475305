import { GenericLazyString } from '@eventbrite/i18n';
import { Button } from '@eventbrite/marmalade';
import React, { ReactNode } from 'react';
import { ListingsAvatarWithBadge } from '.';
import { ScrollableDrawer } from '../../../../ScrollableDrawer/ScrollableDrawer';
import { Modal } from '../../../Modal';
import { testIds, textContents } from './constants';
import styles from './ListingsAvatarBadge.module.scss';
import { IListingsVerifiedBadgeDialogProps } from './types';

export const ListingsVerifiedBadgeDialog: React.FC<
    IListingsVerifiedBadgeDialogProps
> = ({ isMobile, closeDialog, organizerId, organizerAvatar, featureFlags }) => {
    const creatorAvatarJSX = (
        <ListingsAvatarWithBadge
            organizerId={organizerId}
            organizerAvatar={organizerAvatar}
            showVerifiedBadge
            featureFlags={featureFlags}
        />
    );

    const headingString = textContents.headingString;
    const contentString = textContents.contentString;

    const badgeScrollableDrawerContentJSX = (
        <BadgeScrollableDrawerContent
            {...{
                onClickOutside: closeDialog,
                creatorAvatar: creatorAvatarJSX,
                heading: headingString,
                content: contentString,
            }}
        />
    );

    return (
        <BadgeDialog
            {...{
                isMobile,
                closeModal: closeDialog,
            }}
        >
            {badgeScrollableDrawerContentJSX}
        </BadgeDialog>
    );
};

const BadgeDialog = ({
    isMobile,
    children,
    closeModal,
}: {
    isMobile?: boolean;
    children: ReactNode;
    closeModal?: () => void;
}) => {
    return isMobile ? (
        <ScrollableDrawer
            onClickOutside={closeModal}
            heightFixed="307px"
            isScrollUpAllowed={false}
        >
            {children}
        </ScrollableDrawer>
    ) : (
        <Modal
            visible
            noPadding
            type="simple"
            __containerClassName={styles.organizerInfoAvatarBadgeModal}
            onClose={closeModal}
        >
            {children}
        </Modal>
    );
};

interface BadgeScrollableDrawerContentProps {
    onClickOutside: () => void;
    creatorAvatar?: JSX.Element;
    heading: GenericLazyString;
    content: GenericLazyString;
}

export const BadgeScrollableDrawerContent = ({
    onClickOutside,
    creatorAvatar,
    heading,
    content,
}: BadgeScrollableDrawerContentProps) => {
    return (
        <div
            className={styles.scrollableDrawerContent}
            data-testid={testIds.modalContent}
        >
            {creatorAvatar ? creatorAvatar : null}
            <div className={styles.creatorBadgeScrollHeading}>{heading}</div>
            <div className={styles.creatorBadgeScrollContent}>{content}</div>
            <Button
                className={styles.creatorBadgeScrollButton}
                onClick={onClickOutside}
            >
                {textContents.buttonString}
            </Button>
        </div>
    );
};
