import cookies from 'cookies-js';
/**
 * Checks the url for a lang search param and the `lang` cookie for a locale.
 * Returns null if it does not find a locale in either place or experiences any exceptions
 *
 * @returns the locale retrieved from the URL or the cookies | null
 */
export const getLocaleOverride = () => {
    try {
        return typeof window !== 'undefined'
            ? (function () {
                  const url = new URL(window.location.href);
                  const urlLang = url.searchParams.get('lang');

                  if (urlLang) {
                      return urlLang;
                  }

                  const lang = cookies.get('lang');
                  return lang || null;
              })()
            : null;
    } catch (e) {
        return null;
    }
};
