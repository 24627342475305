import { TYPE_LINK } from '@eventbrite/eds-button';
import { IconButton } from '@eventbrite/eds-icon-button';
import {
    GlobeChunky,
    LogoFacebook,
    LogoTwitter,
} from '@eventbrite/eds-iconography';
import { openInNewTab } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { css, cx } from 'emotion';
import React from 'react';
import { LISTING_HEAP_IDS } from '../../constants';
import { GACodes, SocialLinksProps, SocialLinksStyle } from '../../types';

const handleClick = (label: string, href: string) => {
    trackAnalyticsEvent({
        action: GACodes.GA_ACTION_SOCIAL_LINK_CLICK,
        category: GACodes.GA_CATEGORY_LISTING,
        label: label,
    });
    openInNewTab(href);
};

const SocialLinks: React.FunctionComponent<SocialLinksProps> = ({
    orgFacebook,
    orgTwitter,
    orgWebsite,
    style = SocialLinksStyle.sphere,
}) => {
    const hasSocialLinks = orgWebsite || orgTwitter || orgFacebook;
    const facebookHref = `https://www.facebook.com/${orgFacebook}`;
    const twitterHref = `https://www.twitter.com/${orgTwitter}`;
    const websiteHref = `${orgWebsite}`;

    if (!hasSocialLinks) {
        return null;
    }

    return (
        <div
            className={cx(
                styles.default,
                {
                    [styles.socialLinksSphere]:
                        style === SocialLinksStyle.sphere,
                },
                {
                    [styles.socialLinksRounded]:
                        style === SocialLinksStyle.rounded,
                },
            )}
            data-testid="socialLinks"
        >
            {orgFacebook && (
                <IconButton
                    type={TYPE_LINK}
                    title={gettext('Facebook profile')}
                    iconType={<LogoFacebook />}
                    onClick={() => {
                        handleClick(GACodes.GA_LABEL_FACEBOOK, facebookHref);
                    }}
                    href={facebookHref}
                    data-heap-id={LISTING_HEAP_IDS.ORGANIZER_FACEBOOK}
                />
            )}
            {orgTwitter && (
                <IconButton
                    type={TYPE_LINK}
                    title={gettext('Twitter profile')}
                    iconType={<LogoTwitter />}
                    onClick={() => {
                        handleClick(GACodes.GA_LABEL_TWITTER, twitterHref);
                    }}
                    href={twitterHref}
                    data-heap-id={LISTING_HEAP_IDS.ORGANIZER_TWITTER}
                />
            )}
            {orgWebsite && (
                <IconButton
                    type={TYPE_LINK}
                    title={gettext('Organizer website')}
                    iconType={<GlobeChunky />}
                    onClick={() => {
                        handleClick(GACodes.GA_LABEL_WEBSITE, websiteHref);
                    }}
                    href={websiteHref}
                    data-heap-id={LISTING_HEAP_IDS.ORGANIZER_WEBSITE}
                />
            )}
        </div>
    );
};

export default SocialLinks;

const styles = {
    default: css`
        display: flex;
        gap: 16px;
        justify-content: center;
        margin-top: 32px;
        @media (min-width: 480px) {
            gap: 32px;
        }
        .eds-btn--link {
            padding: 11px;
            display: block;
        }
    `,
    socialLinksSphere: css`
        margin-bottom: 40px;
        .eds-btn--link {
            background-color: #eeedf2;
            border-radius: 50%;
        }
    `,
    socialLinksRounded: css`
        .eds-btn--link {
            background-color: #f8f7fa;
            border-radius: 8px;
        }
        svg {
            fill: #3659e3;
        }
    `,
};
