import {
    getCorrectTzShortname,
    getDateFnsLocale,
} from '@eventbrite/datetime-fns';
import {
    getAttendeeTimezone,
    shouldShowDateInAttendeeTimezone,
} from '@eventbrite/event-datetime';
import { differenceInDays, format, startOfDay } from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';
import { LocalizedDateTime } from '../../types';

export const getLocalizedDate = (
    date: LocalizedDateTime,
    isOnlineEvent: boolean,
) => {
    const userTimezone = getAttendeeTimezone();

    if (shouldShowDateInAttendeeTimezone({ isOnlineEvent, userTimezone })) {
        return utcToZonedTime(date.utc, userTimezone ?? '');
    }

    return date.local;
};

export const getFormattedDate = ({
    date,
    format: formatString,
    isOnlineEvent = false,
    localizedFormat,
    locale,
}: {
    date: LocalizedDateTime;
    isOnlineEvent?: boolean;
    format: string;
    locale: string;
    localizedFormat?: string;
}) => {
    const attendeeTimezone = getAttendeeTimezone();
    const timeZone =
        shouldShowDateInAttendeeTimezone({
            isOnlineEvent,
            userTimezone: attendeeTimezone,
        }) && attendeeTimezone
            ? attendeeTimezone
            : date.timezone;
    const formatToApply = shouldShowDateInAttendeeTimezone({
        isOnlineEvent,
        userTimezone: attendeeTimezone,
    })
        ? localizedFormat ?? formatString
        : formatString;
    const dateToDisplay = utcToZonedTime(date.utc, timeZone);
    let formattedDatetime = format(dateToDisplay, formatToApply, {
        locale: getDateFnsLocale(locale),
    });
    const TZ_REGEX = /UTC|GMT([+-]\d+)?/;
    formattedDatetime = formattedDatetime.replace(
        TZ_REGEX,
        getCorrectTzShortname(timeZone, dateToDisplay, timeZone),
    );
    return formattedDatetime;
};

export const getFormattedDateCard = ({
    date,
    isOnlineEvent = false,
    locale,
}: {
    date: LocalizedDateTime;
    isOnlineEvent?: boolean;
    locale: string;
}) => {
    const attendeeTimezone = getAttendeeTimezone();
    const timeZone =
        shouldShowDateInAttendeeTimezone({
            isOnlineEvent,
            userTimezone: attendeeTimezone,
        }) && attendeeTimezone
            ? attendeeTimezone
            : undefined;
    const dateToFormat = new Date(getLocalizedDate(date, isOnlineEvent));

    return {
        day: formatTz(dateToFormat, 'd', {
            timeZone: timeZone,
            locale: getDateFnsLocale(locale),
        }),
        fullDate: formatTz(dateToFormat, 'eeee, MMMM d, yyyy · h:mm a', {
            timeZone: timeZone,
            locale: getDateFnsLocale(locale),
        }),
        month: formatTz(dateToFormat, 'MMMM', {
            timeZone: timeZone,
            locale: getDateFnsLocale(locale),
        }),
        monthAbbr: formatTz(dateToFormat, 'MMM', {
            timeZone: timeZone,
            locale: getDateFnsLocale(locale),
        }),
        time: formatTz(dateToFormat, 'h:mm a', {
            timeZone: timeZone,
            locale: getDateFnsLocale(locale),
        }),
        weekDay: formatTz(dateToFormat, 'eeee', {
            timeZone: timeZone,
            locale: getDateFnsLocale(locale),
        }),
        weekDayAbbr: formatTz(dateToFormat, 'eee', {
            timeZone: timeZone,
            locale: getDateFnsLocale(locale),
        }),
        year: formatTz(dateToFormat, 'yyyy', {
            timeZone: timeZone,
            locale: getDateFnsLocale(locale),
        }),
    };
};

export const isSameDay = ({
    end,
    isOnlineEvent = false,
    start,
}: {
    end: LocalizedDateTime;
    start: LocalizedDateTime;
    isOnlineEvent: boolean;
}) =>
    differenceInDays(
        startOfDay(new Date(getLocalizedDate(start, isOnlineEvent))),
        startOfDay(new Date(getLocalizedDate(end, isOnlineEvent))),
    ) === 0;
