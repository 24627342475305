import React from 'react';

export const Megaphone = (props: React.SVGProps<SVGSVGElement>) => {
    const randomId = Math.round(Math.random() * 100000);

    return (
        <svg viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.4994 22.9588L21.7452 22.1852C20.7879 21.763 19.102 21.1161 17.2148 20.5768C16.1917 20.2844 15.135 20.0312 14.1195 19.8596L16.0053 26.2493H12.6544L10.5457 19.5245C8.02495 19.0574 6.00085 17.0092 6.00085 14.3754C6.00085 11.3692 8.63792 9.12585 11.6406 9.12585C13.2944 9.12585 15.3126 8.71759 17.2148 8.17403C19.102 7.63475 20.7879 6.98784 21.7452 6.56568L23.4994 5.79199V11.6739C24.9659 11.7856 26 13.0731 26 14.4783C26 15.8834 24.9659 17.171 23.4994 17.2827V22.9588ZM23.5002 14.4783C23.5002 14.4875 23.5 14.4965 23.4994 14.5054V14.4512C23.4996 14.4548 23.4998 14.4584 23.4999 14.462C23.5001 14.4674 23.5002 14.4728 23.5002 14.4783Z"
                fill={`url(#linear_gradient_id_${randomId})`}
            />
            <defs>
                <linearGradient
                    id={`linear_gradient_id_${randomId}`}
                    x1={16.0004}
                    y1={5.79199}
                    x2={16.0004}
                    y2={26.2493}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5328D7" />
                    <stop offset={1} stopColor="#BDAEEA" />
                </linearGradient>
            </defs>
        </svg>
    );
};
