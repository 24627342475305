import { Dispatch } from 'react';
import {
    getDiscountFromEventListings,
    hasBogoTickets,
    hasEarlyBirdTickets,
} from '../../utils/discounts';
import { EventAction, EventActionType } from '../EventContext';
import { fetchTicketExpressCheckoutInfo } from './api';
import type { TicketContextType } from './types';

export const fetchDataAndUpdateTicketData =
    (
        setIsFetchComplete: (isComplete: boolean) => void,
        setTicketData: (ticketData: TicketContextType) => void,
    ) =>
    async (url: string, dispatch: Dispatch<EventAction>) => {
        setIsFetchComplete(false);
        try {
            const response = await fetchTicketExpressCheckoutInfo(url);
            const expressCheckoutResponse = {
                compactCheckoutDisqualifications:
                    response.data.event.compactCheckoutDisqualifications,
                panelDisplayPrice:
                    response.data.components.conversionBar.panelDisplayPrice,
                ticketClasses:
                    response.data.event_listing_response.tickets.ticketClasses,
            };
            setTicketData(expressCheckoutResponse);
            dispatch({
                type: EventActionType.setDiscounts,
                payload: {
                    hasEarlyBirdTicketsValue: hasEarlyBirdTickets(
                        response.data.event_listing_response.tickets,
                    ),
                    discountValue: getDiscountFromEventListings(
                        response.data.env.localeInfo.locale,
                        response.data.event_listing_response.tickets,
                    ),
                    hasBogoTicketsValue: hasBogoTickets(
                        response.data.event_listing_response.tickets,
                    ),
                },
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsFetchComplete(true);
        }
    };
