import { Dialog } from '@eventbrite/eds-dialog';
import { Modal } from '@eventbrite/eds-modal';
import React from 'react';
import { OverlayProps } from '../../types';

const OVERLAY_TYPE_MAP = {
    dialog: Dialog,
    modal: Modal,
};

const Overlay = (props: OverlayProps) => {
    const { options } = props;
    let component = null;

    if (options) {
        const { kind, ...overlayOptions } = options;
        const OverlayType = OVERLAY_TYPE_MAP[kind];

        component = <OverlayType {...overlayOptions} />;
    }

    return component;
};

export default Overlay;
