import { EventInteraction, useTracking } from '@eventbrite/ads';
import {
    SegmentedCarousel,
    useSegmentedCarouselController,
} from '@eventbrite/collection-carousel';
import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React, { useContext } from 'react';
import {
    LIVE_EVENTS_PLACEMENT_NAME,
    RELATED_EVENTS_PLACEMENT_NAME,
} from '../../../constants';
import { TrackingContext } from '../../../contexts';
import { aggregateContext } from '../tracking';
import { OtherEventsYouMayLikeContentProps } from '../types';
import { getEventCardsMap } from './getEventCardsMap';
import styles from './OtherEventsYouMayLike.module.scss';
import { useOtherEventsYouMayLike } from './OtherEventsYouMayLikeData';
import './SegmentedCarousel.scss';
export type OtherEventsYouMayLikeProps = OtherEventsYouMayLikeContentProps;

const CAROUSEL_HEADING = gettext('Other events you may like');

export const calculateTopOffset = () => {
    let isMobile = false;
    if (typeof window !== 'undefined') {
        isMobile = window?.innerWidth <= 960;
    }
    return isMobile;
};

export function OtherEventsYouMayLikeSegmentedCarousel({
    eventId,
    shareComponentProps,
    isAuthenticated,
    areMetricsEnabled,
    eventsToRemove,
    locale,
}: OtherEventsYouMayLikeProps) {
    const isMobile = calculateTopOffset();
    const segmentedCarouselControllerProps = useSegmentedCarouselController();

    // Get related events
    const { events: relatedFormattedEvents, removeEvent } =
        useOtherEventsYouMayLike({
            eventId,
            isAuthenticated,
            suspense: true,
            eventsToRemove,
        });
    const context = useContext(TrackingContext);
    const getTrackingContext = ({ id, event }: EventInteraction) =>
        aggregateContext({
            id,
            context,
            event: event,
            events: relatedFormattedEvents,
            page: {
                number: segmentedCarouselControllerProps.currentPage,
                size: 3,
            },
            interfaceName: RELATED_EVENTS_PLACEMENT_NAME,
            subInterfaceName: LIVE_EVENTS_PLACEMENT_NAME,
        });
    const { ref } = useTracking<HTMLDivElement>(getTrackingContext);

    if (relatedFormattedEvents.length === 0) {
        return null;
    }

    const relatedEvents = getEventCardsMap({
        events: relatedFormattedEvents,
        isAuthenticated,
        shareComponentProps,
        areMetricsEnabled,
        locale,
        bucketLabel: CAROUSEL_HEADING.toString(),
        getTrackingContext,
        onRemoveEvent: removeEvent,
    });

    return (
        <div
            ref={ref}
            className={styles.relatedEventsWrapper}
            data-testid="carousel"
        >
            <div className={styles.segmentedCarousel}>
                <SegmentedCarousel
                    {...{
                        ...segmentedCarouselControllerProps,
                        hideOverflow: !isMobile,
                        rootPaddingTop: 0,
                        rootPaddingBottom: 0,
                        rootPaddingLeft: isMobile ? 20 : 0,
                        rootPaddingRight: isMobile ? 20 : 0,
                        headerGap: 0,
                    }}
                >
                    <SegmentedCarousel.Header>
                        <Typography color="neutral-900" variant="heading-sm">
                            {CAROUSEL_HEADING.toString()}
                        </Typography>
                    </SegmentedCarousel.Header>
                    <SegmentedCarousel.Track
                        // This is an awful hack
                        // This carousel component doesn't seem to update if their children are updated (for example, by reporting and removing an ad)
                        // In order to force it to update we include this key with the events length
                        // Why would we want to it behave like a non standard React component?
                        // If performance is the issue, we should look into optimizing the component or have a correct keying system
                        // TODO: Refactor the segmented carousel to behave like a standard React component
                        key={`carousel-events-${relatedEvents.length}`}
                    >
                        {relatedEvents}
                    </SegmentedCarousel.Track>
                    <SegmentedCarousel.Footer />
                </SegmentedCarousel>
            </div>
        </div>
    );
}
