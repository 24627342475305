import { gettext } from '@eventbrite/i18n';
import React, { useRef } from 'react';
import { LISTING_HEAP_IDS } from '../../../constants';
import { useScrollOnCommand } from '../../../contexts';
import {
    STATSIG_EVENT_NAMES,
    useG2KExperiment,
} from '../../../experimentation';
import { useTrackComponent } from '../../hooks/useTrackComponent';
import {
    EventDetailsMainInner,
    EventDetailsSection,
    EventDetailsSectionTitle,
} from '../EventDetails';

export type RefundPolicyProps = {
    isPaidEvent: boolean;
    isRefundRequestAllowed: boolean;
    refundPolicyDescription: string;
    refundPolicyIsNoRefunds: boolean;
    refundPolicyCode?: string;
};

export const RefundPolicy = ({
    isPaidEvent,
    isRefundRequestAllowed,
    refundPolicyDescription,
}: RefundPolicyProps) => {
    const ref = useRef<HTMLDivElement>(null);
    useScrollOnCommand(ref, 'refundPolicy');
    useTrackComponent({
        ref,
        heap: {
            eventName: LISTING_HEAP_IDS.REFUND_POLICY_VIEW,
        },
        statsig: {
            eventName: STATSIG_EVENT_NAMES.REFUND_POLICY_VIEW,
        },
    });

    if (useG2KExperiment()) {
        return null;
    }

    if (!isPaidEvent) {
        return null;
    }
    const isRequiredContactOrganizer =
        !refundPolicyDescription && isRefundRequestAllowed;
    const isNonRefunds = !refundPolicyDescription && !isRefundRequestAllowed;
    const LABEL_ID = 'refund-policy-heading';

    if (
        !refundPolicyDescription &&
        !isRequiredContactOrganizer &&
        !isNonRefunds
    ) {
        return null;
    }

    return (
        <EventDetailsMainInner>
            <EventDetailsSection ariaLabelledBy={LABEL_ID}>
                <span ref={ref} />
                <EventDetailsSectionTitle>
                    <h2>{gettext('Refund Policy')}</h2>
                </EventDetailsSectionTitle>

                {refundPolicyDescription && (
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: refundPolicyDescription.toString(),
                        }}
                    />
                )}
                {isRequiredContactOrganizer && (
                    <div>
                        {gettext('Contact the organizer to request a refund.')}
                    </div>
                )}
                {isNonRefunds && <div>{gettext('No Refunds')}</div>}
            </EventDetailsSection>
        </EventDetailsMainInner>
    );
};
