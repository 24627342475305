import { GenericLazyString } from '@eventbrite/i18n';
import React from 'react';

interface ReadMoreContextShape {
    setExpanded?: (val: boolean) => void;
    isExpanded?: boolean;
    isClamped?: boolean;
    setIsClamped?: (val: boolean) => void;
    ariaLabel?: string | GenericLazyString;
}

export const ReadMoreContext = React.createContext<
    Partial<ReadMoreContextShape>
>({});

export const useReadMoreContext = () => {
    const value = React.useContext(ReadMoreContext);

    if (value === undefined) {
        throw new Error('useReadMoreContext must be used within ReadMore');
    }

    return value;
};

export const ReadMoreProvider: React.FunctionComponent<ReadMoreContextShape> = (
    props,
) => {
    const [isExpanded, setExpanded] = React.useState(props.isExpanded);
    const [isClamped, setIsClamped] = React.useState(false);

    return (
        <ReadMoreContext.Provider
            value={{
                isExpanded,
                setExpanded,
                isClamped,
                setIsClamped,
                ariaLabel: props.ariaLabel,
            }}
        >
            {props.children}
        </ReadMoreContext.Provider>
    );
};
