import { useEffect, useRef, useState } from 'react';
import { useUnmount } from 'react-use';
import {
    EventToSubscribe,
    Handlers,
    MediaErrorEvent,
    VIDEO_STATES,
    type VideoMetrics,
} from './types';
import { getInitialStateMetrics } from './VideoStateMetric';

export const useVideoEventHandlers = ({
    onError,
    onLoadedMetadata,
    onBuffering,
    onTimeUpdate,
    onPlay,
    onEnded,
}: Handlers) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [videoState, setVideoState] = useState<VIDEO_STATES>(
        VIDEO_STATES.UNINITIALIZED,
    );
    const [videoStateMetrics, setVideoStateMetrics] = useState<VideoMetrics>(
        getInitialStateMetrics(),
    );

    const currentStateTimeRef = useRef<number>(Date.now());
    const videoStateRef = useRef<VIDEO_STATES>(VIDEO_STATES.UNINITIALIZED);
    const metricsCollectionEnabled = useRef<boolean>(true);

    const transitionToState = (newState: VIDEO_STATES) => {
        if (newState === videoStateRef.current) {
            return;
        }
        if (!metricsCollectionEnabled.current) {
            videoStateRef.current = newState;
            setVideoState(newState);
            return;
        }
        const now = Date.now();
        const elapsedTime = now - currentStateTimeRef.current;
        setVideoStateMetrics((prevMetrics) => ({
            ...prevMetrics,
            [videoStateRef.current]:
                prevMetrics[videoStateRef.current].incrementTime(elapsedTime),
            [newState]: prevMetrics[newState].incrementCount(),
        }));
        currentStateTimeRef.current = now;
        videoStateRef.current = newState;
        setVideoState(newState);
    };

    const onLoadedMetadataHandler = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            onLoadedMetadata && onLoadedMetadata(videoElement.duration);
        }
        transitionToState(VIDEO_STATES.LOADING);
    };

    const onLoopHandler = () => {
        if (!metricsCollectionEnabled.current) {
            return;
        }
        videoStateMetrics[videoStateRef.current] = videoStateMetrics[
            videoStateRef.current
        ].incrementTime(Date.now() - currentStateTimeRef.current);
        if (onEnded) {
            onEnded(videoStateMetrics, {
                duration: videoRef.current?.duration || 0.01,
            });
        }
        metricsCollectionEnabled.current = false;
    };

    const onTimeUpdateHandler = () => {
        const videoElement = videoRef.current;
        if (videoElement && onTimeUpdate) {
            onTimeUpdate(videoElement.currentTime, videoElement.duration);
        }

        // Check if the video has reached the end and is looping
        if (
            videoElement &&
            videoElement.loop &&
            videoElement.currentTime === videoElement.duration
        ) {
            onLoopHandler();
        }
    };

    const onBufferingHandler = () => {
        const videoElement = videoRef.current;
        if (videoElement && onBuffering) {
            const statesToIgnore = [
                VIDEO_STATES.ENDED,
                VIDEO_STATES.LOADING,
                VIDEO_STATES.READY,
                VIDEO_STATES.SEEKING,
                VIDEO_STATES.STALLED,
            ];

            if (
                statesToIgnore.includes(videoStateRef.current) ||
                videoElement.seeking
            ) {
                return;
            }

            onBuffering();
        }
        transitionToState(VIDEO_STATES.STALLED);
    };

    const onErrorHandler = (event?: Event) => {
        if (onError) {
            onError((event as MediaErrorEvent)?.target?.error);
        }
        transitionToState(VIDEO_STATES.ERROR);
    };

    const onPlayHandler = () => {
        if (onPlay) {
            onPlay();
        }
        transitionToState(VIDEO_STATES.PLAYING);
    };

    const moveToPaused = () => {
        transitionToState(VIDEO_STATES.PAUSED);
    };
    const moveToEnded = () => {
        transitionToState(VIDEO_STATES.ENDED);
    };
    const moveToSeeking = () => {
        transitionToState(VIDEO_STATES.SEEKING);
    };
    const moveToLoading = () => {
        transitionToState(VIDEO_STATES.LOADING);
    };
    const moveToReady = () => {
        transitionToState(VIDEO_STATES.READY);
    };
    const moveToPlaying = () => {
        transitionToState(VIDEO_STATES.PLAYING);
    };

    const eventsToSubscribe: EventToSubscribe[] = [
        { event: 'loadstart', handler: moveToLoading },
        { event: 'loadedmetadata', handler: onLoadedMetadataHandler },
        { event: 'play', handler: onPlayHandler },
        { event: 'pause', handler: moveToPaused },
        { event: 'ended', handler: moveToEnded },
        { event: 'seeking', handler: moveToSeeking },
        { event: 'seeked', handler: moveToPlaying },
        { event: 'playing', handler: moveToPlaying },
        { event: 'canplay', handler: moveToReady },
        { event: 'waiting', handler: onBufferingHandler },
        { event: 'stalled', handler: onBufferingHandler },
        { event: 'timeupdate', handler: onTimeUpdateHandler },
        { event: 'error', handler: onErrorHandler },
    ];

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            eventsToSubscribe.forEach(
                ({ event, handler }: EventToSubscribe) => {
                    videoElement.addEventListener(event, handler);
                },
            );
        }
        return () => {
            if (videoElement) {
                eventsToSubscribe.forEach(
                    ({ event, handler }: EventToSubscribe) => {
                        videoElement.removeEventListener(event, handler);
                    },
                );
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoStateMetrics]);

    useUnmount(() => {
        onLoopHandler();
    });

    return {
        ref: videoRef,
        videoState,
        getMetrics: () => videoStateMetrics,
    };
};
