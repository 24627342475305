import statsig from 'statsig-js';
import { STATSIG_CLIENT_KEY } from '../constants/constants';
import { StatsigConfiguration } from '../models/statsigConfiguration';
import { getDeviceData } from './device';
import {
    ensureSDKIsInitialized,
    getExperimentValueToOverride,
    getOptions,
    getUser,
    shouldInitializeSDK,
} from './utils';

export {
    DeviceInfo,
    StatsigClient,
    StatsigOptions,
    StatsigUser,
} from 'statsig-js';

export const initializeSdk = async (statsigConfig?: StatsigConfiguration) => {
    const { clientKey, deactivateSdk, options, sampleRate, user } =
        statsigConfig || {};

    if (!shouldInitializeSDK(sampleRate, deactivateSdk)) {
        return null;
    }

    await statsig.initialize(
        clientKey || STATSIG_CLIENT_KEY,
        getUser(user),
        getOptions(options),
    );
};

export const updateUser = async (statsigConfig: StatsigConfiguration) => {
    const { user } = statsigConfig || {};

    await ensureSDKIsInitialized(() => statsig.updateUser(getUser(user)));
};

export const getExperiment = <
    ExperimentParameterValue,
    ForcedReturnValue =
        | ExperimentParameterValue
        | NonNullable<ReturnType<typeof getExperimentValueToOverride>>,
>(
    experimentName: string,
    paramName: string,
    defaultValue: ExperimentParameterValue,
) => {
    const valueToOverride = getExperimentValueToOverride(experimentName);

    return (valueToOverride ??
        ensureSDKIsInitialized(
            () =>
                statsig
                    .getExperiment(experimentName)
                    .get(paramName, defaultValue),
            defaultValue,
        )) as unknown as ForcedReturnValue;
};

export const checkGate = (gateName: string): boolean => {
    const valueToOverride = getExperimentValueToOverride(gateName);

    return valueToOverride !== null
        ? valueToOverride === 'true'
        : !!ensureSDKIsInitialized(() => statsig.checkGate(gateName));
};

export const getConfig = (configName: string) => {
    return ensureSDKIsInitialized(() => statsig.getConfig(configName));
};

export const logEvent = (
    eventName: string,
    eventValue?: string | number | null,
    eventMetadata?: Record<string, string> | null,
) => {
    // TODO: EB-188957 Validate event name with Heap's conventions
    // (https://eventbrite.atlassian.net/wiki/spaces/DEV/pages/15423275681/How+to+define+Heap+Events+-+Naming+Standard)
    const deviceData = getDeviceData({ includeRawDeviceWidth: true });
    const metadata = Object.keys(deviceData).length
        ? {
              ...deviceData,
              ...(eventMetadata ? eventMetadata : {}),
          }
        : eventMetadata;

    ensureSDKIsInitialized(() =>
        statsig.logEvent(eventName, eventValue, metadata),
    );
};

export const getLayer = <
    LayerParameterValue,
    ForcedReturnValue =
        | LayerParameterValue
        | NonNullable<ReturnType<typeof getExperimentValueToOverride>>,
>(
    layerName: string,
    paramName: string,
    defaultValue: LayerParameterValue,
) => {
    const valueToOverride = getExperimentValueToOverride(paramName);

    return (valueToOverride ??
        ensureSDKIsInitialized(
            () => statsig.getLayer(layerName).get(paramName, defaultValue),
            defaultValue,
        )) as unknown as ForcedReturnValue;
};

export const shutdown = () => {
    ensureSDKIsInitialized(statsig.shutdown);
};
