import { Button } from '@eventbrite/eds-button';
import { setWindowLocation } from '@eventbrite/http';
import React from 'react';

interface ActionWrapperProps {
    path: string;
    shouldOpenNewTab?: boolean;
    onClick?: (id: string | undefined) => void;
    id?: string;
}

const handleClick = ({
    path,
    onClick,
    id,
}: {
    path?: string;
    onClick?: (id: string | undefined) => void;
    id?: string;
}) => {
    onClick?.(id);

    if (path) setWindowLocation(path);
};

export const ActionWrapper: React.FunctionComponent<ActionWrapperProps> = (
    props,
) => {
    if (props.onClick && !props.shouldOpenNewTab) {
        return (
            <Button
                type="link"
                style="none"
                role="link"
                onClick={() => {
                    handleClick({
                        path: props.path,
                        onClick: props.onClick,
                        id: props.id,
                    });
                }}
            >
                {props.children}
            </Button>
        );
    }

    let aProps: Record<string, string | (() => void)> = { href: props.path };

    if (props.shouldOpenNewTab) {
        aProps = {
            ...aProps,
            target: '_blank',
            rel: 'noopener',
        };
    }

    if (props.onClick) {
        aProps = {
            ...aProps,
            onClick: () =>
                handleClick({ onClick: props.onClick, id: props.id }),
        };
    }

    return <a {...aProps}>{props.children}</a>;
};
