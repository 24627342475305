import { ChevronDownIcon, ChevronUpIcon } from '@eventbrite/marmalade';
import React from 'react';
import { ButtonLink, ButtonLinkProps } from './ButtonLink';

type ExpandingButtonLinkProps = ButtonLinkProps & {
    isExpanded?: boolean;
    // used to invert the direction of the chevron in case the button is placed below the expanding element
    inverted?: boolean;
};

const xor = (a: boolean, b: boolean) => (a ? !b : b);

export const ExpandingButtonLink = ({
    isExpanded = false,
    inverted = false,
    ...props
}: ExpandingButtonLinkProps) => {
    const iconType = xor(isExpanded, inverted) ? (
        <ChevronUpIcon />
    ) : (
        <ChevronDownIcon />
    );

    return <ButtonLink {...props} icon={iconType} />;
};
