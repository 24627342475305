import React from 'react';
import { ListingsAvatarWithBadge } from './ListingsAvatarWithBadge';
import { ListingsVerifiedBadgeDialog } from './ListingsVerifiedBadgeDialog';
import { ILSClickableAvatarWithBadgeProps } from './types';

export const LSClickableAvatarWithBadge: React.FC<
    ILSClickableAvatarWithBadgeProps
> = ({
    organizerAvatar,
    featureFlags,
    showBadge = false,
    isMobile,
    organizerName,
    organizerId,
}) => {
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    const enableCreatorBadge = featureFlags?.enableCreatorBadge || false;
    const showVerifiedBadge = enableCreatorBadge && showBadge;

    return (
        <>
            <ListingsAvatarWithBadge
                {...{
                    organizerId,
                    organizerAvatar,
                    featureFlags,
                    isMobile,
                    organizerName,
                    onClick: () => {
                        setDialogOpen(true);
                    },
                    showVerifiedBadge,
                }}
            />
            {showVerifiedBadge && isDialogOpen && (
                <ListingsVerifiedBadgeDialog
                    {...{
                        isMobile,
                        closeDialog: () => {
                            setDialogOpen(false);
                        },
                        organizerId,
                        organizerAvatar,
                        featureFlags,
                    }}
                />
            )}
        </>
    );
};
