import { Link } from '@eventbrite/eds-link';
import {
    CONSUMER_CARD_DEFAULTS,
    EventCard,
    FormattedEvent,
    ShareOptionsProps,
} from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import { STATSIG_EVENT_NAMES } from '../../experimentation';
import {
    label,
    ShareType,
    trackShareAction,
} from '../../tracking/trackShareAction';
import { AffiliateCodes, GACodes } from '../../types';
import {
    EventDetailsSection,
    EventDetailsSectionTitle,
} from '../EventDetails/EventDetails';
import { RelatedEventsChildrenProps } from './RelatedEvents';

const Heading = ({
    isSingleCreatorCollection,
}: {
    isSingleCreatorCollection?: boolean;
}): JSX.Element => (
    <EventDetailsSectionTitle>
        <h2>
            {isSingleCreatorCollection
                ? gettext('More events in this collection')
                : gettext('More events from this organizer')}
        </h2>
    </EventDetailsSectionTitle>
);

export const EventCardList = ({
    isAuthenticated,
    events,
    shareComponentProps,
    affCode,
    areMetricsEnabled,
}: RelatedEventsChildrenProps & { affCode: string }): JSX.Element => (
    <div data-testid="event-card-list-more-from">
        {events?.map((event: FormattedEvent) => {
            const shareEventProps: ShareOptionsProps = {
                eventId: event.id,
                eventName: event.name,
                eventUrl: event.url,
                ...shareComponentProps,
            };

            return (
                <EventCard
                    {...CONSUMER_CARD_DEFAULTS}
                    affCode={affCode}
                    eventData={event}
                    key={event.id}
                    gaCategory="related-events"
                    isAuthenticated={isAuthenticated}
                    type="list"
                    shareOptions={shareEventProps}
                    onDidShareClick={(id: ShareType) =>
                        trackShareAction(
                            label(id),
                            GACodes.GA_CATEGORY_EVENT_CARD,
                        )
                    }
                    onWillShareClick={() => {
                        trackShareAction(
                            GACodes.GA_LABEL_OPEN_MODAL,
                            GACodes.GA_CATEGORY_EVENT_CARD,
                        );
                        areMetricsEnabled &&
                            logEvent(
                                STATSIG_EVENT_NAMES.LISTING_MORE_ORGANIZER_EVENT_SHARE,
                            );
                    }}
                    onClick={() =>
                        areMetricsEnabled &&
                        logEvent(
                            STATSIG_EVENT_NAMES.LISTING_MORE_ORGANIZER_EVENT_CLICK,
                        )
                    }
                    onSave={(saved) =>
                        areMetricsEnabled &&
                        // bug exists where saved doesn't update before it is passed, so checking for opposite of true
                        !saved &&
                        logEvent(
                            STATSIG_EVENT_NAMES.LISTING_MORE_ORGANIZER_EVENT_LIKE,
                        )
                    }
                />
            );
        })}
    </div>
);

// TODO collectionUrl should be required at this point, revise typechecking
const ViewCreatorCollectionLink = ({
    collectionUrl,
}: {
    collectionUrl?: string;
}): JSX.Element => {
    return (
        <div className="eds-l-mar-vert-4 eds-align--center-horizontal">
            <Link
                to={collectionUrl}
                target="_blank"
                onClick={() =>
                    trackAnalyticsEvent({
                        category:
                            GACodes.GA_CATEGORY_CREATOR_COLLECTIONS_LISTING,
                        action: GACodes.GA_ACTION_COLLECTION_PAGE_LINK_CLICK,
                        label: GACodes.GA_LABEL_SHELF,
                    })
                }
            >
                {gettext('View collection')}
            </Link>
        </div>
    );
};

const MoreEventsFrom = (
    props: RelatedEventsChildrenProps,
): JSX.Element | null => {
    const {
        isAuthenticated,
        shareComponentProps,
        events,
        isSingleCreatorCollection,
        collectionUrl,
        eventId,
        areMetricsEnabled,
        locale,
    } = props;

    const affCode = isSingleCreatorCollection
        ? AffiliateCodes.AFF_RELATED_EVENTS_SAME_COLLECTION
        : AffiliateCodes.AFF_RELATED_EVENTS_SAME_ORG;

    if (!events || events.length <= 0) {
        return null;
    }

    return (
        <EventDetailsSection>
            <div className="eds-l-pad-top-10">
                <Heading
                    isSingleCreatorCollection={isSingleCreatorCollection}
                />
                <EventCardList
                    isAuthenticated={isAuthenticated}
                    events={events}
                    shareComponentProps={shareComponentProps}
                    affCode={affCode}
                    eventId={eventId}
                    areMetricsEnabled={areMetricsEnabled}
                    locale={locale}
                />
                {isSingleCreatorCollection && (
                    <ViewCreatorCollectionLink collectionUrl={collectionUrl} />
                )}
            </div>
        </EventDetailsSection>
    );
};

export default MoreEventsFrom;
