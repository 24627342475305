import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class SubContent extends PureComponent {
    static propTypes = {
        subContentOne: PropTypes.node,
        subContentTwo: PropTypes.node,
        subContentThree: PropTypes.node,
        subContentFour: PropTypes.node,
        subContentLast: PropTypes.node,
        hasTwoActionButtons: PropTypes.node,
    };

    render() {
        const {
            subContentOne,
            subContentTwo,
            subContentThree,
            subContentFour,
            subContentLast,
            hasTwoActionButtons,
        } = this.props;

        const consumerClassNames = classNames(
            'eds-event-card-content__sub',
            'eds-text-bm',
            'eds-text-color--ui-600',
            'eds-l-mar-top-1',
            {
                'eds-event-card-content__sub--cropped': hasTwoActionButtons,
            },
        );

        const subContent = [
            subContentOne,
            subContentTwo,
            subContentThree,
            subContentFour,
            subContentLast,
        ]
            .filter((x) => !!x)
            .map((content) => {
                const component = (
                    <div
                        key={
                            content?.props
                                ? content.props['data-subcontent-key']
                                : content
                        }
                        className={consumerClassNames}
                    >
                        {content}
                    </div>
                );

                return component;
            });

        return (
            <div className="eds-event-card-content__sub-content">
                {subContent}
            </div>
        );
    }
}
