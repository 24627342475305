import { sdkRequest } from '@eventbrite/http';

// This function is copied from packages/organizer/promoted-events-ui/src/utils/checkEbAdsEventEligibility.ts
const checkEbAdsEventEligibility = async (eventId: string) => {
    try {
        const isAuthed = window.__SERVER_DATA__?.user.isAuthenticated;
        if (!isAuthed) return;

        const url = `/api/v3/promoted_content/organizations/eb-ads-event-eligible/${eventId}/?`;

        return await sdkRequest(url);
    } catch (error) {
        return null;
    }
};

// This function is copied from packages/organizer/marketing-hub-ui/src/utils/getEventMarketingData.ts
export const getEventEBAdsEligibilityStatus = async (
    eventId?: string,
): Promise<boolean> => {
    if (!eventId) {
        return false;
    }

    const eligibilityInfo = await checkEbAdsEventEligibility(eventId);

    return eligibilityInfo?.is_eligible || false;
};
