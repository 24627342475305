import * as React from 'react';

const DefaultAbstractProfileOneSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 40 40" fill="none" {...props}>
        <circle cx={20} cy={20} r={20} fill="#BBC4DB" />
        <path
            d="M32.116 35.863C36.908 32.21 40 26.441 40 19.95 40 12.806 36.255 6.537 30.622 3 21.153 3.522 8 8.04 8 13.838c0 3.72 4.614 4.755 9.022 5.745 2.904.651 5.718 1.283 7.068 2.648 1.263 1.278.745 3.5.212 5.79-.902 3.87-1.848 7.928 5.698 7.928.713 0 1.419-.029 2.116-.086z"
            fill="#5F73A7"
        />
    </svg>
);

DefaultAbstractProfileOneSvg.displayName = 'DefaultAbstractProfileOneSvg';
export default DefaultAbstractProfileOneSvg;
