import React from 'react';
import type { FeaturedVideoProps, NativeVideoProps } from '../../../../types';
import { FeaturedHeroVideo } from '../FeaturedHeroVideo';
import type { CarouselItems } from '../HeroCarousel';
import { FetchPriorityType, HeroImage } from '../HeroImage';
import { NativeVideo } from '../NativeVideo';

type HeroVideoProps = {
    nativeVideo?: NativeVideoProps;
    featuredVideo?: FeaturedVideoProps;
    overrideThumbnail?: CarouselItems;
};

export const HeroVideo = ({
    nativeVideo,
    featuredVideo,
    overrideThumbnail,
}: HeroVideoProps) => {
    if (nativeVideo?.url) {
        return (
            <NativeVideo
                url={nativeVideo.url}
                thumbnail={
                    overrideThumbnail?.croppedLogoUrl940 ||
                    nativeVideo.thumbnail
                }
            />
        );
    }

    if (!featuredVideo?.embedUrl) {
        return null;
    }

    return (
        <FeaturedHeroVideo url={featuredVideo.embedUrl}>
            <HeroImage
                croppedLogoUrl480={
                    overrideThumbnail?.croppedLogoUrl480 ||
                    featuredVideo.thumbnail?.small?.url ||
                    ''
                }
                croppedLogoUrl600={
                    overrideThumbnail?.croppedLogoUrl600 ||
                    featuredVideo.thumbnail?.medium?.url ||
                    ''
                }
                croppedLogoUrl940={
                    overrideThumbnail?.croppedLogoUrl940 ||
                    featuredVideo.thumbnail?.large?.url ||
                    ''
                }
                alt={''}
                fetchPriority={FetchPriorityType.high}
            />
        </FeaturedHeroVideo>
    );
};
