import { gettext } from '@eventbrite/i18n';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import { LISTING_HEAP_IDS } from '../../../../constants';
import { STATSIG_EVENT_NAMES } from '../../../../experimentation';

type MoreOptions = {
    onButtonClick: () => void;
};

export const MoreOptionsButton = ({ onButtonClick }: MoreOptions) => {
    return (
        <div className="more-options-button">
            <button
                className="eds-btn eds-btn--link"
                data-heap-id={LISTING_HEAP_IDS.MORE_OPTIONS_BUTTON}
                data-testid="more-options-button-mobile"
                onClick={() => {
                    onButtonClick();
                    logEvent(STATSIG_EVENT_NAMES.MORE_OPTIONS_BUTTON);
                }}
            >
                {gettext('More options')}
            </button>
        </div>
    );
};
