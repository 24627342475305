import { SetTimedEntryEventAction } from '../actions';
import type { EventBasicInformation } from '../types';

export const setTimedEntryEvents = (
    event: EventBasicInformation,
    { payload: { timedEntryEvents } }: SetTimedEntryEventAction,
): EventBasicInformation => {
    const updatedEvent = {
        ...event,
        childEvents: timedEntryEvents,
    };
    return updatedEvent;
};
