import React from 'react';
import ReactDOM from 'react-dom';

type Importer<Props> = {
    Component: React.ComponentType<Props>;
    containerId: string;
};

export const dynaMount: <Props extends {}>(
    importer: Importer<Props>,
    props: Props,
) => JSX.Element | React.ReactPortal = ({ Component, containerId }, props) => {
    if (process.env.REACT_APP_TARGET === 'server' || !globalThis.document) {
        return <div key="rrs"></div>;
    }
    const element = globalThis.document?.getElementById(containerId);
    if (element) {
        return ReactDOM.createPortal(<Component {...props} />, element);
    }
    return <div></div>;
};

export const renderOnElement: <Props extends {}>(
    importer: Importer<Props>,
    props: Props,
) => void = ({ Component, containerId }, props) => {
    const element = globalThis.document?.getElementById(containerId);
    if (element) {
        ReactDOM.render(<Component {...props} />, element);
    }
};
