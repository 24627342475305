import { gettext } from '@eventbrite/i18n';
import { Badge } from '@eventbrite/marmalade';
import React from 'react';
import { useTicketContext } from '../context';
import styles from './TicketSalesStatus.module.scss';
import { SalesStatus } from './types';

export const SoldOutStatus: React.FC = () => {
    const { onSaleStatusEnum } = useTicketContext();
    const allowedStatus = new Set([SalesStatus.SoldOut]);

    if (!allowedStatus.has(onSaleStatusEnum)) {
        return null;
    }

    return (
        <div className={styles.soldOut}>
            <Badge variant="info" bordered={false}>
                {gettext('Sold out')}
            </Badge>
        </div>
    );
};
