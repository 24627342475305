import React from 'react';
import { useTicketContext } from '../../../../contexts';
import { getCheckoutType } from '../../../../experimentation';
import { CheckoutType } from '../../../../types';
import { ScrollToDateButton } from '../ConversionBar/ScrollToDateButton';
import { ConversionBarContainerProps } from '../ConversionBarContainer';
import { TicketSelectionIframe } from '../TicketSelectionIframe/TicketSelectionIframe';

type TicketSelectionProps = Pick<
    ConversionBarContainerProps,
    | 'eventName'
    | 'affiliateCode'
    | 'isPreview'
    | 'promoCode'
    | 'waitlistToken'
    | 'rsvpToken'
> & {
    campaignToken: ConversionBarContainerProps['campaign_token'];
    isMultiTicketEnabled: boolean;
    areTicketSelected: boolean;
    enforceDateAndTimeVisualization: boolean;
};

export const TicketSelection = ({
    affiliateCode,
    areTicketSelected,
    campaignToken,
    enforceDateAndTimeVisualization,
    eventName,
    isMultiTicketEnabled,
    isPreview,
    promoCode,
    rsvpToken,
    waitlistToken,
}: TicketSelectionProps) => {
    const { ticketData } = useTicketContext();
    const { compactCheckoutDisqualifications } = ticketData;
    const checkoutType = getCheckoutType(
        isMultiTicketEnabled,
        compactCheckoutDisqualifications,
    );

    if (enforceDateAndTimeVisualization) {
        return (
            <div style={{ padding: '24px' }}>
                <ScrollToDateButton />
            </div>
        );
    }

    return (
        <TicketSelectionIframe
            eventName={eventName}
            affiliateCode={affiliateCode}
            isPreview={isPreview}
            promoCode={promoCode}
            waitlistToken={waitlistToken}
            rsvpToken={rsvpToken}
            campaign_token={campaignToken}
            checkoutType={checkoutType}
            shouldShowGoToTickets={
                checkoutType === CheckoutType.OnlyCheckout && !areTicketSelected
            }
        />
    );
};
