import {
    FormattedEvent,
    FormattedEventResponse,
} from '@eventbrite/event-renderer';
import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';
import {
    EventBasicInformation,
    EventBasicInformationContext,
} from '../../../contexts';
import { getSortedEvents } from '../../_shared/getSortedEvents';
import { getRelatedEvents } from '../api';
import { RelatedEventsProps } from '../types';

export function getOtherEventsYouMayLikeQueryKey(eventId: string) {
    return ['related-events', 'other-organizers', eventId];
}

export const EVENT_DUPLICATES_QUERY_KEY = [
    'related-events',
    'events-to-filter',
];

const emptyRelatedEvents = {
    events: [] as FormattedEvent[],
    promotedEvents: [] as FormattedEvent[],
};

export type UseOtherEventsYouMayLikeProps = Pick<
    RelatedEventsProps,
    'isAuthenticated' | 'eventId'
> & { suspense?: boolean; eventsToRemove?: Pick<FormattedEvent, 'id'>[] };

export function useOtherEventsYouMayLike({
    isAuthenticated,
    eventId,
    eventsToRemove = [],
    suspense = false,
}: UseOtherEventsYouMayLikeProps) {
    const eventBasicInformation = useContext(EventBasicInformationContext);
    const queryKey = getOtherEventsYouMayLikeQueryKey(eventId);
    const { removeEvent } = useRemovePromotedEvent(queryKey);
    const { data = emptyRelatedEvents } = useQuery({
        queryKey,
        queryFn: async () =>
            getRelatedEvents(
                isAuthenticated,
                eventBasicInformation as EventBasicInformation,
            ),
        suspense,
        staleTime: Infinity,
        enabled: !!eventBasicInformation,
    });

    const organicEvents = useEventsWithoutDuplicates(
        data.events,
        eventsToRemove,
    );

    const sortedEvents = useMemo(
        () => getSortedEvents(organicEvents, data.promotedEvents || []),
        [organicEvents, data.promotedEvents],
    );

    return {
        events: sortedEvents,
        removeEvent,
    };
}

function useEventsWithoutDuplicates(
    events: FormattedEvent[],
    eventsToRemove: Pick<FormattedEvent, 'id'>[],
) {
    const idsToFilter = useMemo(
        () => new Set(eventsToRemove.map(({ id }) => id)),
        [eventsToRemove],
    );

    return useMemo(
        () => events.filter(({ id }) => !idsToFilter.has(id)),
        [events, idsToFilter],
    );
}

// Due to legal compliance, we need to be able to hide promoted events from the UI
export function useRemovePromotedEvent(queryKey: QueryKey) {
    const client = useQueryClient();

    return {
        removeEvent: (eventId: string) => {
            client.setQueryData<FormattedEventResponse>(
                queryKey,
                (apiResponse) => {
                    if (!apiResponse || !apiResponse.promotedEvents) {
                        return apiResponse;
                    }
                    return {
                        ...apiResponse,
                        promotedEvents: apiResponse.promotedEvents.filter(
                            ({ id }) => eventId !== id,
                        ),
                    };
                },
            );
        },
    };
}
