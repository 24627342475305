import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { gettext } from '@eventbrite/i18n';
import { isThenable } from '@eventbrite/feature-detection';

import { TYPE_NEUTRAL, TYPE_ERROR } from '@eventbrite/eds-notification';
import { isMediumDown } from '@eventbrite/eds-utils';
import { AlertChunky } from '@eventbrite/eds-iconography';
import { CheckChunky } from '@eventbrite/eds-iconography';

import find from 'lodash/find';

const withNotification = (Component) =>
    class WithNotificationWrapper extends PureComponent {
        static propTypes = {
            onSaveEvent: PropTypes.func,
            addMainNotification: PropTypes.func,
            addOverlayNotification: PropTypes.func,
            onAddOrRemoveEventToCollection: PropTypes.func,
            onCreateCollection: PropTypes.func,
            userCollections: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    id: PropTypes.string,
                    href: PropTypes.string,
                    eventCount: PropTypes.number,
                }),
            ),
        };

        _handleOnAddOrRemoveFromCollection = ({
            eventId,
            value,
            isChecked,
        }) => {
            const { onAddOrRemoveEventToCollection, userCollections } =
                this.props;

            if (onAddOrRemoveEventToCollection) {
                const promise = onAddOrRemoveEventToCollection({
                    value,
                    isChecked,
                    eventId,
                });

                if (isThenable(promise)) {
                    promise
                        .then(() => {
                            const { name, id } = find(userCollections, [
                                'id',
                                value,
                            ]);

                            this._displaySuccessNotification({
                                name,
                                href: `/c/${id}`,
                                isSaved: isChecked,
                            });
                        })
                        .catch((err) => {
                            let msg = isChecked
                                ? gettext('Unable to add event to collection')
                                : gettext(
                                      'Unable to remove event from collection',
                                  );

                            if (err === 'REACH_MAX_EVENTS') {
                                msg = gettext(
                                    'Unable to add any more events to this collection',
                                );
                            }

                            this._displayErrorNotification({ msg });
                        });
                }
            }
        };

        _handleOnCreateCollection = (name, eventIdArray) => {
            const { onCreateCollection } = this.props;

            if (onCreateCollection) {
                const promise = onCreateCollection(name, eventIdArray);

                if (isThenable(promise)) {
                    promise
                        .then((response) => {
                            const collectionId =
                                typeof response === 'string'
                                    ? response
                                    : response?.id;

                            this._displaySuccessNotification({
                                name,
                                href: `/c/${collectionId}`,
                                isSaved: true,
                            });
                        })
                        .catch(() =>
                            this._displayErrorNotification({
                                msg: gettext(
                                    'There was an error creating the collection',
                                ),
                            }),
                        );
                }
            }
        };

        _handleOnSaveEvent = (eventId, isSaved) => {
            const { onSaveEvent } = this.props;

            if (onSaveEvent) {
                const promise = onSaveEvent(eventId, isSaved);

                if (isThenable(promise)) {
                    promise
                        .then(() =>
                            this._displaySuccessNotification({
                                isSaved,
                                name: gettext('Likes'),
                                href: '/mytickets',
                            }),
                        )
                        .catch(() =>
                            this._displayErrorNotification({
                                msg: gettext(
                                    'There was a problem saving the event',
                                ),
                            }),
                        );
                }
            }
        };

        _displaySuccessNotification = ({ isSaved, name, href }) => {
            let markup = gettext(
                'Event added to <a href=%(href)s>%(name)s</a>.',
                { name, href },
            );

            if (!isSaved) {
                markup = gettext(
                    'Event removed from <a href=%(href)s>%(name)s</a>.',
                    { name, href },
                );
            }

            return this._handleShowNotification({
                children: (
                    //eslint-disable-next-line
                    <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: markup.toString() }}
                    />
                ),
                iconType: isSaved ? <CheckChunky /> : '',
                type: TYPE_NEUTRAL,
            });
        };

        _displayErrorNotification = ({ msg }) =>
            this._handleShowNotification({
                iconType: <AlertChunky />,
                children: msg,
                type: TYPE_ERROR,
            });

        _handleShowNotification = ({ children, type, iconType }) => {
            const {
                addMainNotification,
                userCollections,
                addOverlayNotification,
            } = this.props;

            // Necessary to check existence of userCollections
            // A truthy values determines rendering of
            // modal/dropdown when saving an event.
            if (isMediumDown() && userCollections && addOverlayNotification) {
                addOverlayNotification({
                    children,
                    type,
                    iconType,
                    hasCloseButton: true,
                    shouldFloatAboveContent: true,
                    shouldAnimate: true,
                    fullWidth: true,
                });
            } else if (addMainNotification) {
                addMainNotification({
                    children,
                    type,
                    iconType,
                    isNotificationSticky: true,
                    hasCloseButton: true,
                });
            }
        };

        render() {
            return (
                <Component
                    {...this.props}
                    onSaveEvent={this._handleOnSaveEvent}
                    onAddOrRemoveEventToCollection={
                        this._handleOnAddOrRemoveFromCollection
                    }
                    onCreateCollection={this._handleOnCreateCollection}
                />
            );
        }
    };

export default withNotification;
