import * as React from 'react';

const DurationChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.001 8h1.991v4h2.64v2h-4.63V8z"
            fill="#010101"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.676 10.053A6.011 6.011 0 0012 6c-3.308 0-6 2.692-6 6a6.007 6.007 0 006.888 5.934l1.055 1.828a8 8 0 115.879-9.45l-2.146-.26zm.323 2.053a5.963 5.963 0 01-.453 2.183l1.687 1.134a7.959 7.959 0 00.76-3.075l-1.994-.242zm-1.517 3.878l1.674 1.125a8.035 8.035 0 01-2.328 1.917l-1.002-1.734a6.04 6.04 0 001.656-1.308z"
            fill="#010101"
        />
    </svg>
);

DurationChunkySvg.displayName = 'DurationChunkySvg';
export default DurationChunkySvg;
