import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { gettext, translationPropType } from '@eventbrite/i18n';

import { GREYS_JSON } from '@eventbrite/eds-color';
import { Illustration } from '@eventbrite/eds-illustration';

import { IMAGE_STYLE_FIXED } from './constants';
import { getRandomFallbackImage } from '../utils';

import ActionWrapper from './ActionWrapper';

export default class Image extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        linkUrl: PropTypes.string,
        imageUrl: PropTypes.string,
        imageAlt: PropTypes.oneOfType([PropTypes.string, translationPropType]),
        imageStyle: PropTypes.string,
        hasNoTabIndex: PropTypes.bool,
        placeholderBgColor: PropTypes.string,
        isTargetBlank: PropTypes.bool,
        isNoFollow: PropTypes.bool,
        isLazyImage: PropTypes.bool,
    };

    static defaultProps = {
        placeholderBgColor: GREYS_JSON['grey-200'],
    };

    render() {
        const {
            onClick,
            linkUrl,
            imageUrl,
            imageAlt = gettext('Event image'),
            imageStyle,
            hasNoTabIndex,
            placeholderBgColor,
            isTargetBlank,
            isNoFollow,
            isLazyImage,
        } = this.props;
        const imageClassNames = classNames('eds-event-card-content__image', {
            'eds-max-img': imageStyle === IMAGE_STYLE_FIXED,
        });
        const imageWrapperClassNames = classNames(
            'eds-event-card-content__image-wrapper eds-event-card-content__image-wrapper--sharp-corners',
            {
                'eds-event-card-content__image-wrapper--with-width': !linkUrl,
            },
        );

        const imageContentClassNames =
            'eds-event-card-content__image-content eds-event-card-content__image-content--sharp-corners';

        const placeholderStyles = {
            backgroundColor: placeholderBgColor,
        };

        let image = (
            <div className="eds-event-card-content__image--svg">
                <Illustration
                    type={getRandomFallbackImage(imageAlt)}
                    width="100%"
                    height="100%"
                />
            </div>
        );

        if (imageUrl) {
            image = (
                <img
                    className={imageClassNames}
                    src={imageUrl}
                    alt={imageAlt}
                    loading={isLazyImage ? 'lazy' : 'eager'}
                    height={512}
                    width={256}
                />
            );
        }

        return (
            <ActionWrapper
                onClick={onClick}
                linkUrl={linkUrl}
                linkAlt={imageAlt}
                hasNoTabIndex={hasNoTabIndex}
                isTargetBlank={isTargetBlank}
                isNoFollow={isNoFollow}
            >
                <div className={imageWrapperClassNames}>
                    <div className={imageContentClassNames}>
                        <div
                            className="eds-event-card-content__image-placeholder"
                            style={placeholderStyles}
                        />
                        {image}
                    </div>
                </div>
            </ActionWrapper>
        );
    }
}
