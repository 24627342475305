import { track } from '@eventbrite/datalayer-library';
import { logEvent } from '@eventbrite/statsig';
import React, { useEffect, useReducer } from 'react';
import { LISTING_HEAP_IDS } from '../../../constants';
import { STATSIG_EVENT_NAMES } from '../../../experimentation';
import { handleFollowClick, hydrateFollowStatus } from './api';

import { Event } from '../../../types';
import './FollowButton.scss';
import {
    followOrganizerInitialState,
    followOrganizerReducer,
    SET_SHOULD_SHOW_GDPR_MODAL,
    SET_SHOULD_SHOW_LOGIN_MODAL,
} from './reducer';

type FollowButtonProviderProps = {
    isGDPRCountry: boolean;
    organizerId: string;
    userId?: number;
    isAuthenticated: boolean;
    event?: Event;
};

type FollowButtonContextProps = {
    closeGDPRModal: () => void;
    closeLoginModal: () => void;
    followersCount: number | null;
    isFollowing: boolean;
    onFollowClick: (pageArea: string) => void;
    shouldShowLoginModal: boolean;
    shouldShowGDPRModal: boolean;
    userId?: number;
    event?: Event;
};

export const FollowButtonContext =
    React.createContext<FollowButtonContextProps | null>(null);

export const FollowButtonProvider: React.FC<FollowButtonProviderProps> = ({
    children,
    organizerId,
    userId,
    isGDPRCountry,
    isAuthenticated,
    event,
}) => {
    const [state, dispatch] = useReducer(
        followOrganizerReducer,
        followOrganizerInitialState,
    );
    const {
        followersCount,
        isFollowing,
        shouldShowLoginModal,
        shouldShowGDPRModal,
    } = state;

    useEffect(() => {
        hydrateFollowStatus({
            organizerId,
            userId,
            isGDPRCountry,
            isFollowing,
            isAuthenticated,
            dispatch,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFollowClick = (pageArea: string) => {
        const followEventName = isFollowing
            ? LISTING_HEAP_IDS.UNFOLLOW_ORGANIZER
            : LISTING_HEAP_IDS.FOLLOW_ORGANIZER;

        if (isFollowing) {
            logEvent(STATSIG_EVENT_NAMES.LISTING_UNFOLLOW_CLICK);
        } else {
            logEvent(STATSIG_EVENT_NAMES.LISTING_FOLLOW_CLICK);
        }

        track({
            eventName: followEventName,
            eventData: {
                organizerId: organizerId,
                pageArea: pageArea,
            },
        });
        return handleFollowClick({
            isFollowing,
            organizerId,
            userId,
            isAuthenticated,
            followersCount,
            isGDPRCountry,
            pageArea,
            dispatch,
        });
    };

    return (
        <FollowButtonContext.Provider
            value={{
                followersCount,
                isFollowing,
                shouldShowLoginModal,
                shouldShowGDPRModal,
                userId,
                event,
                onFollowClick,
                closeLoginModal: () => {
                    dispatch({
                        type: SET_SHOULD_SHOW_LOGIN_MODAL,
                        payload: false,
                    });
                },
                closeGDPRModal: () => {
                    dispatch({
                        type: SET_SHOULD_SHOW_GDPR_MODAL,
                        payload: false,
                    });
                },
            }}
        >
            {children}
        </FollowButtonContext.Provider>
    );
};
