import React from 'react';

export const FooterLogo: React.FC = () => (
    <svg
        viewBox="0 0 615 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="footer-logo"
    >
        <g clipPath="url(#clip0_105_641)">
            <path
                d="M118.755 14.8955L112.294 33.3087H112.024L105.578 14.8955H94.271L105.309 44.5362H118.142L129.179 14.8955H118.755Z"
                fill="#6F7287"
            />
            <path
                d="M180.091 14.0869C175.634 14.0869 172.105 16.0779 170.519 19.3414V14.8953H159.751V44.551H170.519V29.9851C170.519 25.524 171.985 22.6049 175.634 22.6049C178.895 22.6049 180.032 24.7306 180.032 28.7875V44.536H190.8V25.9282C190.8 19.7456 188.198 14.0869 180.091 14.0869Z"
                fill="#6F7287"
            />
            <path
                d="M262.203 20.15V14.8955H251.434V44.5512H262.203V30.9884C262.203 26.0033 264.671 23.8476 268.649 23.8476C270.713 23.8476 272.971 24.2518 274.437 25.1201V14.9405C273.704 14.3417 272.104 13.8027 270.309 13.8027C266.391 13.8027 263.265 16.8267 262.203 20.15Z"
                fill="#6F7287"
            />
            <path
                d="M287.001 14.8955H276.232V44.5512H287.001V14.8955Z"
                fill="#6F7287"
            />
            <path
                d="M281.616 12.8145C284.978 12.8145 287.704 10.0867 287.704 6.72172C287.704 3.35675 284.978 0.628906 281.616 0.628906C278.254 0.628906 275.529 3.35675 275.529 6.72172C275.529 10.0867 278.254 12.8145 281.616 12.8145Z"
                fill="#6F7287"
            />
            <path
                d="M303.453 33.1586V22.3203H311.17V14.8802H303.453V6.70654H296.992C296.992 11.2275 293.327 14.8802 288.81 14.8802V22.3203H292.804V36.2724C292.804 43.2634 297.515 45.3143 302.511 45.3143C306.564 45.3143 309.151 44.3113 311.425 42.6497V35.6736C309.63 36.4071 308.164 36.6766 306.848 36.6766C304.649 36.6916 303.453 35.6886 303.453 33.1586Z"
                fill="#6F7287"
            />
            <path
                d="M209.69 36.6916C209.675 36.6916 209.675 36.6916 209.661 36.6916C207.477 36.6916 206.295 35.6886 206.295 33.1736V22.3353H214.013V14.8802H206.295V6.70654H199.834C199.834 11.2275 196.17 14.8802 191.653 14.8802C191.653 14.8802 191.653 14.8802 191.638 14.8802V22.3203H195.631V36.2724C195.631 43.2634 200.343 45.3143 205.338 45.3143C209.391 45.3143 211.979 44.3113 214.252 42.6497V35.6736C212.472 36.4071 211.007 36.6916 209.69 36.6916Z"
                fill="#6F7287"
            />
            <path
                d="M81.3485 14.1021C72.1204 14.1021 65.2104 20.8236 65.2104 30.1949C65.2104 39.641 71.9857 45.3595 81.4831 45.3595C90.113 45.3595 95.273 40.8535 96.4995 33.9823H87.6602C86.6581 36.3026 84.8035 37.3056 81.887 37.3056C78.6264 37.3056 75.9791 35.5841 75.7099 31.9164H96.4995V28.9224C96.4845 20.8835 91.2348 14.1021 81.3485 14.1021ZM75.8296 26.7368C76.2334 23.9374 77.759 21.8116 81.2139 21.8116C84.3997 21.8116 86.1346 24.0721 86.2692 26.7368H75.8296Z"
                fill="#6F7287"
            />
            <path
                d="M142.7 14.1021C133.472 14.1021 126.562 20.8236 126.562 30.1949C126.562 39.641 133.337 45.3595 142.835 45.3595C151.465 45.3595 156.625 40.8535 157.851 33.9823H149.012C148.01 36.3026 146.155 37.3056 143.239 37.3056C139.978 37.3056 137.331 35.5841 137.062 31.9164H157.851V28.9224C157.851 20.8835 152.601 14.1021 142.7 14.1021ZM137.181 26.7368C137.585 23.9374 139.111 21.8116 142.566 21.8116C145.751 21.8116 147.486 24.0721 147.621 26.7368H137.181Z"
                fill="#6F7287"
            />
            <path
                d="M344 28.9224C344 20.8835 338.75 14.1021 328.849 14.1021C319.621 14.1021 312.711 20.8236 312.711 30.1949C312.711 39.641 319.486 45.3595 328.984 45.3595C337.614 45.3595 342.774 40.8535 344 33.9823H335.161C334.159 36.3026 332.304 37.3056 329.387 37.3056C326.127 37.3056 323.48 35.5841 323.21 31.9164H344V28.9224ZM323.345 26.7368C323.749 23.9374 325.274 21.8116 328.729 21.8116C331.915 21.8116 333.65 24.0721 333.785 26.7368H323.345Z"
                fill="#6F7287"
            />
            <path
                d="M236.612 14.0421C231.632 14.0421 228.296 16.4373 226.831 19.9703V2.60498H216.062V44.5361H224.378L226.232 39.4164C227.893 43.0092 231.617 45.3445 236.403 45.3445C245.317 45.3445 249.774 38.4882 249.774 29.7008C249.774 20.9583 245.317 14.0421 236.612 14.0421ZM232.828 37.0062C229.164 37.0062 226.846 34.2068 226.846 29.8804V29.4912C226.846 25.2247 229.179 22.4403 232.828 22.4403C237.016 22.4403 238.945 25.4343 238.945 29.7008C238.945 34.0122 237.016 37.0062 232.828 37.0062Z"
                fill="#6F7287"
            />
            <path
                d="M45.2883 40.2695L25.441 24.7904C25.112 24.5359 25.456 24.0269 25.815 24.2365L33.5624 28.5778C37.0772 30.5539 41.5193 29.4012 43.6431 25.988C45.8866 22.3802 44.705 17.6347 41.0257 15.509L29.6588 8.93713C29.2998 8.72754 29.569 8.17365 29.9579 8.33832L35.1777 10.4341C35.1927 10.4341 35.3123 10.479 35.3572 10.494C35.9106 10.6886 36.5089 10.7934 37.1221 10.7934C39.9489 10.7934 42.2821 8.5479 42.4765 5.88323C42.7308 2.41018 40.0984 0 36.9426 0H12.8028C9.70678 0 7.08939 2.52994 7.13426 5.62874C7.14922 7.27545 7.89704 8.74251 9.0487 9.74551C9.91617 10.509 12.8477 12.8443 14.2386 13.9671C14.4929 14.1617 14.3433 14.5659 14.0292 14.5659H9.19826C4.11304 14.5958 0 18.7275 0 23.8174C0 26.3922 1.04696 28.7126 2.752 30.4042L30.7357 57.021C32.7099 58.8623 35.3722 60 38.2887 60C44.4209 60 49.3864 55.0299 49.3864 48.8922C49.3715 45.4192 47.7861 42.3054 45.2883 40.2695Z"
                fill="#6F7287"
            />
        </g>
        <path
            d="M365 58C364.457 58 364 56.4803 364 54.671V6.32894C364 4.51973 364.457 3 365 3C365.543 3 366 4.51973 366 6.32894V54.671C366 56.4803 365.565 58 365 58Z"
            fill="#6F7287"
        />
        <path
            d="M532.489 25.0284H527.054L529.89 17.2314H535.56C535.796 16.7589 536.033 16.5226 536.269 16.05C539.104 8.96186 545.956 4.23641 553.517 4.47269C560.605 4.23641 567.457 4.47269 574.545 4.47269C574.781 4.94523 574.545 5.41778 574.309 5.65405C570.529 16.05 566.985 26.6823 563.204 37.0783C562.968 37.7871 562.732 38.4959 562.968 39.2047C564.385 39.441 564.386 39.441 566.748 35.6607H571.237C570.292 39.2047 567.93 42.04 564.858 43.6939C562.495 44.8753 559.66 45.1116 556.825 44.8753C553.517 44.8753 550.682 42.04 550.682 38.4959C550.682 37.5508 550.918 36.6057 551.391 35.8969C553.281 30.2264 555.407 24.3196 557.297 18.649L560.369 9.43441C557.534 8.96186 554.935 9.19814 552.099 9.90695C551.39 10.1432 550.918 10.6158 550.682 11.0883C549.5 12.9785 548.319 14.8687 547.846 16.9951H554.462L551.627 24.7921H545.247C544.775 26.2098 544.302 27.6274 543.83 29.045C541.703 35.1881 540.049 41.3312 537.214 47.238C535.324 51.7272 532.016 55.7438 527.527 57.8703C523.274 59.9967 518.312 60.233 513.823 58.8154C512.878 58.5791 511.933 58.1065 510.988 57.3977C508.625 55.9801 507.916 52.9086 509.334 50.5458C510.515 48.1831 513.114 47.0017 515.713 47.7106C517.84 48.1831 519.494 50.3096 519.257 52.6723C519.257 53.6174 519.021 54.5625 520.203 54.7987C521.384 55.035 521.856 54.3262 522.565 53.6174C523.983 51.4909 524.928 49.1282 525.873 46.7655C528.472 39.6773 530.599 32.8254 533.198 25.7372C532.489 25.5009 532.489 25.2647 532.489 25.0284Z"
            fill="#6F7287"
        />
        <path
            d="M583.051 50.0731C583.287 42.5124 581.87 34.9517 579.034 28.0998C578.798 27.391 578.325 26.9184 578.089 26.2096C576.908 24.5557 575.726 24.5557 574.545 26.2096C574.073 26.9184 573.6 27.391 573.364 28.0998H568.875C569.82 24.0831 572.182 20.7753 575.726 18.4126C580.452 15.5773 585.177 15.8136 588.485 20.7753C590.848 24.3194 592.029 28.5723 592.502 32.8252C592.738 35.6605 592.974 38.2595 592.738 41.0948C595.573 37.5507 597.464 33.534 598.645 29.2811C598.172 29.0449 597.7 28.8086 597.227 28.8086C594.156 28.0998 592.502 25.0282 593.211 21.9567C593.447 21.2479 593.683 20.7753 593.919 20.3028C595.573 17.4675 598.881 16.2861 602.189 17.2312C605.261 18.4126 606.914 21.4841 606.206 24.5557C604.788 34.7154 599.59 42.5124 592.266 49.3643C588.485 53.1447 583.76 55.9799 578.562 57.1613C575.49 58.1064 572.182 57.8701 569.347 56.6887C566.276 55.7437 564.385 52.1996 565.33 49.128C565.567 48.6555 565.567 48.4192 565.803 47.9467C566.748 46.0565 568.875 44.8751 571.001 45.1114C573.127 45.1114 575.018 46.529 575.49 48.6555C575.49 49.128 575.726 49.6006 575.726 50.0731C575.726 51.727 576.199 52.1996 577.853 52.4358C579.507 52.6721 581.633 51.727 583.051 50.0731Z"
            fill="#6F7287"
        />
        <path
            d="M457.354 27.1553L462.552 17.4681H474.602L466.569 29.518L475.074 43.9306H463.024L457.354 32.3532H457.118V43.9306H447.194V12.0338C446.958 9.67108 448.612 7.30836 450.975 6.59954C452.865 5.89072 454.991 4.94563 457.118 4.23682V27.1553H457.354Z"
            fill="#6F7287"
        />
        <path
            d="M506.026 25.0283H502.718V17.2313H506.026V12.2695C505.79 9.43427 507.444 7.07154 510.043 6.36273L515.713 4V17.4675H524.455L521.62 25.0283H515.477C515.477 28.5723 515.477 31.8802 515.477 35.188C515.477 36.1331 516.186 36.8419 517.131 36.8419H517.367C518.312 36.8419 519.257 36.6056 520.202 36.6056V44.1663C516.422 45.3477 512.642 45.3477 508.861 44.1663C506.971 43.4575 505.79 41.5673 505.79 39.6771C505.554 36.8419 505.317 34.0066 505.554 31.1713C506.026 29.0449 506.026 27.1547 506.026 25.0283Z"
            fill="#6F7287"
        />
        <path
            d="M399.467 17.4681H406.083V25.0288H399.467C399.467 28.5729 399.467 31.8807 399.467 35.1885C399.467 36.1336 400.413 36.6061 401.358 36.6061C402.303 36.6061 403.248 36.3699 404.193 36.3699V43.9306C400.649 44.8757 396.868 45.112 393.088 43.9306C390.725 42.9855 389.071 40.6228 389.308 38.2601C389.071 34.4797 389.308 30.6993 389.308 26.6827V24.7925H386V17.4681H389.308C389.308 15.3416 389.308 13.4514 389.308 11.5613C389.071 9.67108 390.253 7.7809 391.907 7.07209C394.033 5.89072 396.396 5.18191 398.995 4.23682L399.467 17.4681Z"
            fill="#6F7287"
        />
        <path
            d="M443.178 17.468L442.233 25.7375C439.398 25.2649 436.562 24.3199 434.908 27.6277C433.491 29.9904 433.727 32.8257 435.381 34.9521C437.271 37.3148 439.87 36.3698 442.233 35.8972L442.942 43.9305C441.997 44.403 441.052 44.6393 440.106 44.6393C437.507 45.1118 434.908 45.1118 432.309 44.6393C428.529 43.9305 425.221 41.0952 424.276 37.3148C423.095 33.5345 423.331 29.7541 424.512 26.21C426.166 21.012 430.656 17.468 436.09 17.2317C438.689 16.7591 441.052 16.7591 443.178 17.468Z"
            fill="#6F7287"
        />
        <path
            d="M420.023 43.9303H410.336V17.4678H420.023V43.9303Z"
            fill="#6F7287"
        />
        <path
            d="M421.204 9.67054C421.204 12.9784 418.605 15.5774 415.297 15.5774C411.99 15.5774 409.391 12.9784 409.391 9.67054C409.627 6.599 412.226 4 415.297 4C418.369 4 420.968 6.599 421.204 9.67054Z"
            fill="#6F7287"
        />
        <path
            d="M489.015 16.7584C482.635 16.2859 476.728 20.5388 475.547 26.9181C475.074 29.5171 474.838 32.1161 475.311 34.4789C475.783 39.4406 479.564 43.4572 484.525 44.166C487.361 44.6386 490.432 44.6386 493.504 44.166C495.63 43.9298 497.757 43.4572 499.647 42.5121C499.411 40.1494 499.174 37.7867 498.702 35.424L497.048 35.8965C494.213 36.8416 491.141 37.0779 488.069 36.6053C486.416 36.369 485.234 35.1877 485.234 33.5338H500.356C500.356 32.3524 500.592 31.4073 500.592 30.226C500.828 23.3741 497.757 16.9947 489.015 16.7584ZM484.998 27.3907C484.762 25.5005 486.179 23.8466 487.833 23.6103C488.069 23.6103 488.069 23.6103 488.306 23.6103C490.196 23.6103 491.141 25.028 490.905 27.3907H484.998Z"
            fill="#6F7287"
        />
        <defs>
            <clipPath id="clip0_105_641">
                <rect width="344" height="60" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
