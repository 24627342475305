import { Button, STYLE_FILL } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import loadable from '@loadable/component';
import React, { useEffect, useState } from 'react';
import { LISTING_HEAP_IDS } from '../../constants';
import { useEventBasicInformationContext } from '../../contexts/EventContext';
import type { Event } from '../../types';
import { CheckoutWidgetProps, GACodes } from '../../types';
import { CheckoutModalButton } from '../EventDetails/ConversionBarContainer/CheckoutModalButton/CheckoutModalButton';
import { ConversionBarCommonProps } from '../EventDetails/ConversionBarContainer/ConversionBar/ConversionBar';
import './RSVP.scss';

export type RSVPOptionsProps = CheckoutWidgetProps &
    Pick<ConversionBarCommonProps, 'shouldShowStatus' | 'ticketModalButton'> &
    Pick<Event, 'isPreview'>;

const LazyRSVPModal = loadable(() => import('./RSVPModal'));

export const RSVPOptions = ({
    affiliateCode,
    campaign_token,
    isPreview,
    promoCode,
    rsvpToken,
    shouldShowStatus,
    ticketModalButton,
    waitlistToken,
}: RSVPOptionsProps) => {
    const { id: eventId, isSeriesParent } = useEventBasicInformationContext();

    const [modalOpened, setModalOpened] = useState(false);
    const [userResponse, setUserResponse] = useState('');

    useEffect(() => {
        trackAnalyticsEvent({
            action: GACodes.GA_ACTION_RSVP_LOADED,
            category: GACodes.GA_CATEGORY_LISTING,
        });
    }, []);

    const handleYesClick = () => {
        trackAnalyticsEvent({
            action: GACodes.GA_ACTION_RSVP_YES_INTENT,
            category: GACodes.GA_CATEGORY_LISTING,
        });
    };
    const openMaybeModalForm = () => {
        setUserResponse('maybe');
        setModalOpened(true);
    };
    const openNoModalForm = () => {
        setUserResponse('no');
        setModalOpened(true);
    };
    const handleClose = () => {
        setModalOpened(false);
    };

    const CheckoutButton: React.FC = ({ children }) => (
        <CheckoutModalButton
            {...{
                affiliateCode,
                campaign_token,
                children,
                handleClick: handleYesClick,
                isPreview,
                isSeriesParent,
                promoCode,
                rsvpToken,
                shouldShowStatus,
                style: STYLE_FILL,
                ticketModalButton,
                waitlistToken,
            }}
        />
    );

    return (
        <>
            {modalOpened && (
                <LazyRSVPModal
                    response={userResponse}
                    visible={modalOpened}
                    onClose={handleClose}
                    eventId={eventId}
                />
            )}
            <div className="rsvp-component__button-section">
                <CheckoutButton>{gettext('Yes')}</CheckoutButton>
                <Button
                    data-heap-id={LISTING_HEAP_IDS.CTA_RSVP_MAYBE}
                    onClick={openMaybeModalForm}
                >
                    {gettext('Maybe')}
                </Button>
                <Button
                    data-heap-id={LISTING_HEAP_IDS.CTA_RSVP_NO}
                    onClick={openNoModalForm}
                >
                    {gettext('No')}
                </Button>
            </div>
        </>
    );
};

export const RSVPLabel = () => {
    return (
        <h3 className="rsvp-label">
            {gettext('Are you going to this event?')}
        </h3>
    );
};
