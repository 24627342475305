import React from 'react';

type PlayProps = {
    fillColor?: string;
};

export const Play = ({ fillColor = 'white' }: PlayProps) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 29 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.04801 0.739296C1.17831 0.190008 0.0444336 0.814966 0.0444336 1.84361V34.1568C0.0444336 35.1854 1.17831 35.8104 2.04802 35.2611L27.6293 19.1045C28.4408 18.5919 28.4408 17.4084 27.6293 16.8959L2.04801 0.739296Z"
            fill={fillColor}
        />
    </svg>
);
