import { Icon } from '@eventbrite/eds-icon';
import { InfoChunky } from '@eventbrite/eds-iconography';
import React from 'react';
import './SalesEndedMessage.scss';

export interface SalesEndedMessageProps {
    organizerSalesEndedMessage: string | null;
    salesEndedMessage: string | null;
}

export const SalesEndedMessage = ({
    organizerSalesEndedMessage,
    salesEndedMessage,
}: SalesEndedMessageProps) => (
    <>
        {(organizerSalesEndedMessage || salesEndedMessage) && (
            <div className="sales-ended-message">
                <div className="sales-ended-message__icon">
                    <Icon type={<InfoChunky />} isBlockLevel={true} />
                </div>
                <div className="sales-ended-message__content">
                    {salesEndedMessage && (
                        <h2 className="sales-ended-message__title">
                            {salesEndedMessage}
                        </h2>
                    )}
                    {organizerSalesEndedMessage && (
                        <p className="sales-ended-message__text">
                            {organizerSalesEndedMessage}
                        </p>
                    )}
                </div>
            </div>
        )}
    </>
);
