import React from 'react';
import './LoadingSkeleton.scss';

interface LoadingSkeletonProps {
    width?: string;
    height?: string;
    opacity?: string;
    borderRadius?: string;
}

export const LoadingSkeleton = ({
    width,
    height,
    opacity,
    borderRadius,
}: LoadingSkeletonProps) => (
    <span
        style={{ width, height, opacity, borderRadius }}
        className="skeleton-loader"
    />
);
