import loadable from '@loadable/component';
import React, { useRef, useState } from 'react';
import { useScrollOnCommand } from '../../../contexts/ScrollContext';
import { EventMapScopedProps } from '../../../types';
import { DEFAULT_ZOOM } from '../../_shared/constants';
import type { LocationProps } from './Location/Location';
import { Location } from './Location/Location';

type WhenAndWhereProps = {
    location: LocationProps;
    map: Omit<EventMapScopedProps, 'venueName' | 'venueAddress'>;
};

export const WhenAndWhere = ({ location, map }: WhenAndWhereProps) => {
    const { shouldDisplayMap, location: mapLocation, gMapsApiKey } = map;
    const [isMapExpanded, setIsMapExpanded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useScrollOnCommand(ref, 'whenAndWhere');

    let lazyMap = null;

    if (isMapExpanded) {
        const Map = loadable(() => import('./Map/Map').then(({ Map }) => Map));
        lazyMap = (
            <Map
                location={mapLocation}
                gMapsApiKey={gMapsApiKey}
                mapZoom={DEFAULT_ZOOM}
            />
        );
    }

    return (
        <>
            <span ref={ref} />
            <Location
                {...{
                    ...location,
                    onToggleButtonMap: setIsMapExpanded,
                    isMapExpanded: isMapExpanded,
                }}
            />
            {shouldDisplayMap && lazyMap}
        </>
    );
};
