import { ContactOrganizer } from '@eventbrite/contact-organizer';
import { Button, STYLE_LINK } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
export const GA_ACTION_CONTACT_ORG_ATTEMPT = 'ContactOrgAttempt';

export const trackContactAction = (
    label: string | undefined,
    category: string,
) =>
    trackAnalyticsEvent({
        action: GA_ACTION_CONTACT_ORG_ATTEMPT,
        category,
        label,
    });

export interface ContactOrganizerProps {
    organizerId: string;
    organizerName?: string;
    isVisible: boolean;
    captchaKey: string;
    onClose?: Function;
    isAuthenticated?: boolean;
    domNodeId?: string;
    domNode?: Element;
    eventName?: string;
    eventId?: string; // Property required to render ContactOrganizer from Listings
    collectionId?: string;
    gaCategory: string;
    buttonStyle?: string;
    buttonSize?: string;
    onClickCallback?: () => void; // Callback to be executed when the button is clicked
}

export const ContactOrganizerButton: React.FunctionComponent<
    ContactOrganizerProps
> = (props) => {
    const [isVisible, setVisible] = useState(props.isVisible);

    const contactProps = {
        ...props,
        isVisible,
        onClose: () => setVisible(false),
    };

    const contactText = isEmpty(props.organizerName)
        ? gettext('Contact the organizer')
        : gettext('Contact');

    const _onClickHandler = () => {
        trackContactAction(props.eventName, props.gaCategory);
        if (props.onClickCallback) {
            props.onClickCallback();
        }
        setVisible(true);
    };

    return (
        <>
            <Button
                style={props.buttonStyle || STYLE_LINK}
                size={props.buttonSize || undefined}
                onClick={() => _onClickHandler()}
                data-testid="contact-organizer-button"
            >
                {contactText}
            </Button>
            {isVisible && <ContactOrganizer {...contactProps} />}
        </>
    );
};
