import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Loading } from '../components/Loading';
import {
    FORM_CONFIRMATION_VIEW,
    FORM_VIEW,
    GA_ACTION_FORM_CONFIRMATION_SHOWN,
    GA_ACTION_FORM_SHOWN,
    GA_ACTION_LANDING_SHOWN,
    GA_CONTACT_ORGANIZER_CATEGORY,
    LANDING_VIEW,
    SUCCESS_VIEW,
} from '../constants';
import { OverlayVisibilityContext } from '../context';
import { useOverlayConfig } from '../hooks';

export interface SharedViewProps {
    setView: Function;
}
const Landing = lazy(() => import('./Landing'));
const Form = lazy(() => import('./Form'));
const FormConfirmation = lazy(() => import('./FormConfirmation'));
const Success = lazy(() => import('./Success'));

export const VIEW_CONTAINER_CLASS = 'eds-modal__content__children';

const SuccessView: React.FunctionComponent<SharedViewProps> = (props) => {
    const { setVisible } = useContext(OverlayVisibilityContext);

    const landingViewConfig = {
        // No GA tracking for Success View modal dismiss
        onPrimaryAction: () => {
            setVisible && setVisible({ value: false, noAnalyticsTrack: true });
        },
        primaryText: gettext('Done'),
        primaryType: null,
        onSecondaryAction: null,
        secondaryText: null,
        secondaryType: null,
    };

    useOverlayConfig(landingViewConfig);

    return (
        <Suspense fallback={<Loading />}>
            <Success setView={props.setView} />
        </Suspense>
    );
};

const LandingView: React.FunctionComponent<SharedViewProps> = (props) => {
    const landingViewConfig = {
        onPrimaryAction: null,
        primaryText: null,
        primaryType: null,
        onSecondaryAction: null,
        secondaryText: null,
        secondaryType: null,
    };

    useOverlayConfig(landingViewConfig);

    useEffect(() => {
        trackAnalyticsEvent({
            action: GA_ACTION_LANDING_SHOWN,
            category: GA_CONTACT_ORGANIZER_CATEGORY,
        });
    }, []);

    return (
        <Suspense fallback={<Loading />}>
            <Landing setView={props.setView} />
        </Suspense>
    );
};

const FormView: React.FunctionComponent<SharedViewProps> = (props) => {
    useEffect(() => {
        trackAnalyticsEvent({
            action: GA_ACTION_FORM_SHOWN,
            category: GA_CONTACT_ORGANIZER_CATEGORY,
        });
    }, []);

    return (
        <Suspense fallback={<Loading />}>
            <Form setView={props.setView} />
        </Suspense>
    );
};

const FormConfirmationView: React.FunctionComponent<SharedViewProps> = (
    props,
) => {
    useEffect(() => {
        trackAnalyticsEvent({
            action: GA_ACTION_FORM_CONFIRMATION_SHOWN,
            category: GA_CONTACT_ORGANIZER_CATEGORY,
        });
    }, []);

    return (
        <Suspense fallback={<Loading />}>
            <FormConfirmation setView={props.setView} />
        </Suspense>
    );
};

const rawViewMap = {
    0: LandingView,
    1: FormView,
    2: FormConfirmationView,
    3: SuccessView,
};

const analyticsViewMap: Record<number, string> = {
    0: LANDING_VIEW,
    1: FORM_VIEW,
    2: FORM_CONFIRMATION_VIEW,
    3: SUCCESS_VIEW,
};

export const viewMap: Record<string, React.FunctionComponent<SharedViewProps>> &
    typeof rawViewMap = rawViewMap;

/**
 * Controls and manages the state of which view to
 * display in the contact organizer flow,
 *
 * Updates modal config accordingly to pass header
 * and ancillary action updates.
 *
 * @param props
 */
export const ViewController: React.FunctionComponent = (_props) => {
    const [currentView, setView] = useState(0);
    const View = viewMap[currentView];
    const { setCurrentView } = useContext(OverlayVisibilityContext);

    if (setCurrentView) {
        setCurrentView(analyticsViewMap[currentView]);
    }

    return <View setView={setView} />;
};
