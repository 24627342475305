import classNames from 'classnames';
import React, { useContext } from 'react';
import { ReadMoreContext } from './context';
import {
    PreviewContentLineConfig,
    PreviewContentPixelConfig,
    ReadMoreContentProps,
} from './types';

const getStyleDefs = (
    styleConfig: PreviewContentLineConfig | PreviewContentPixelConfig,
) => {
    const notObject =
        typeof styleConfig === 'number' || typeof styleConfig === 'string';
    return {
        '--mobile-val': notObject ? styleConfig : styleConfig.mobile,
        '--tablet-val': notObject ? styleConfig : styleConfig.tablet,
        '--desktop-val': notObject ? styleConfig : styleConfig.desktop,
    } as React.CSSProperties;
};

export const ReadMoreContent: React.FunctionComponent<ReadMoreContentProps> = (
    props,
) => {
    const previewType = props.previewContentType;
    const { isExpanded, setIsClamped, ariaLabel } = useContext(ReadMoreContext);

    const className = classNames('read-more--content', {
        'read-more--content__clamped': !isExpanded,
        'read-more--content__clamped--line-count': previewType === 'line',
        'read-more--content__clamped--pixel-count': previewType === 'pixel',
    });

    //Determine if the children are clamped so other
    //elements may respond to that data.
    const isClampedRef = React.useCallback(
        (element) => {
            if (element) {
                const elementIsClampingContent =
                    element.scrollHeight > element.clientHeight;
                setIsClamped?.(elementIsClampingContent);
            }
        },
        [setIsClamped],
    );

    const isLineCount = previewType === 'line';
    const styleConfig = isLineCount
        ? props.previewLineCount
        : props.previewPixelCount;

    const styleDefs = getStyleDefs(styleConfig);

    return (
        <div
            ref={isClampedRef}
            data-testid="read-more-contentx"
            className={className}
            aria-label={ariaLabel?.toString()}
            style={{ ...styleDefs }}
        >
            {props.children}
        </div>
    );
};
