import React, {
    createContext,
    Dispatch,
    useContext,
    useEffect,
    useReducer,
} from 'react';
import type { TicketCardType } from '../types';
import { ticketReducer } from './reducer';
import { TicketActionType, type TicketAction } from './types';

export const TicketContext = createContext<TicketCardType | null>(null);
TicketContext.displayName = 'MultiTicketContext';

export const TicketDispatchContext =
    createContext<Dispatch<TicketAction> | null>(null);

export const TicketContextProvider: React.FC<{
    value: TicketCardType;
}> = ({ children, value }) => {
    const [state, dispatch] = useReducer(ticketReducer, value);

    useEffect(() => {
        dispatch({
            type: TicketActionType.updateQuantity,
            payload: { quantity: value.quantity },
        });
    }, [value.quantity]);

    return (
        <TicketContext.Provider value={state}>
            <TicketDispatchContext.Provider value={dispatch}>
                {children}
            </TicketDispatchContext.Provider>
        </TicketContext.Provider>
    );
};

export const useTicketContext = () => {
    const value = useContext(TicketContext);

    if (!value) {
        throw new Error(
            'useEventBasicInformationContext must be used within EventBasicInformationContext',
        );
    }

    return value;
};

export const useTicketDispatchContext = () => {
    const dispatch = useContext(TicketDispatchContext);

    if (!dispatch) {
        throw new Error(
            'useEventDispatchContext must be used within EventDispatchContext',
        );
    }

    return dispatch;
};
