import logger from '@eventbrite/client-logger';
import { useContext, useEffect, useState } from 'react';
import { getUser as getUserApi } from '../api';
import { OverlayConfigContext } from '../context';

/**
 *
 * @param config Object to confgure the overlay settings
 *
 * Hook that upon the component mounting will take
 * the provided configuration and apply it to the mounted modal.
 */
export const useOverlayConfig = (config: any) => {
    const { overlayConfig, setOverlayConfig } =
        useContext(OverlayConfigContext);

    useEffect(() => {
        const newconfig = { ...overlayConfig, ...config };
        setOverlayConfig && setOverlayConfig(newconfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { overlayConfig };
};

/**
 *
 * @param className Classname of element to scroll to top of viewport
 */
export const useScrollTop = (className: string) => {
    useEffect(() => {
        const element = document.getElementsByClassName(className)[0];

        if (element) {
            if (element.scrollTo) {
                element.scrollTo(0, 0);
            } else {
                element.scroll(0, 0);
            }
        }
    }, [className]);
};

/**
 * If authenticated, gets the current user via
 * the API on mount and returns the user object.
 *
 * @param isAuthenticated Boolean determining if the user is authenticated
 */
export const useUserData = (isAuthenticated?: boolean | undefined) => {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (isAuthenticated) {
            const getUser = async () => {
                try {
                    const userData = await getUserApi();
                    setUserData(userData);
                } catch (error) {
                    logger.error('Error getting user object:', {
                        error: error.message ? error.message : error,
                    });
                }
            };

            getUser();
        }
    }, [isAuthenticated]);

    return userData;
};
