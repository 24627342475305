import React from 'react';
export const RebrandingGoingFast = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            d="M3.33333 8.66659L10.3333 2.33325L9 7.33325H12.6667L5.66667 13.6666L7 8.66659H3.33333Z"
            fill="black"
        />
    </svg>
);
