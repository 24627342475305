import logger from '@eventbrite/client-logger';
import {
    constants as datetimeConstants,
    getDateFnsLocale,
    getFormattedDateTime,
    getFormattedTimezone,
} from '@eventbrite/datetime-fns';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { addDays, format, getMinutes, getYear, isSameDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
// import momentTimezone from 'moment-timezone';

const { DEFAULT_TIMEZONE, DEFAULT_LANGUAGE } = datetimeConstants;

export const DEFAULT_TIME_FORMAT = 'p';
const DEFAULT_DATETIME_FORMAT = 'eee, PP p';
const BASE_EVENT_DATE_FORMAT = 'MMMM d';
const BASE_EVENT_TIME_FORMAT = 'h';
const BASE_EVENT_DATE_SEPARATOR = ' · ';
const US_LOCALE = 'en_US';

export interface StartOrEnd {
    utc: string;
    timezone: string;
}

export interface EventbriteEvent {
    start: {
        utc: string;
        timezone: string;
    };
    end: {
        utc: string;
    };
    hideStartDate?: boolean;
    hideEndDate?: boolean;
    listingDisplayFlags?: { shouldShowTimezone?: boolean };
}

export interface DatesFormat {
    startDate: string;
    endDate: string;
    startDateInRange: string;
    endDateInRange: string;
}

export enum TimezoneLocale {
    Attendee,
    Creator,
}

export const getEventDatesFormat = ({
    event,
    locale,
    useTimezoneFrom = TimezoneLocale.Creator,
}: {
    event: EventbriteEvent;
    locale: string;
    useTimezoneFrom?: TimezoneLocale;
}): DatesFormat | undefined => {
    if (locale !== US_LOCALE) {
        return undefined;
    }
    const timezone = getTimezone({ event, useTimezoneFrom });
    const startDate = utcToZonedTime(event.start.utc, timezone, {
        locale: getDateFnsLocale(locale),
    });
    const endDate = utcToZonedTime(event.end.utc, timezone, {
        locale: getDateFnsLocale(locale),
    });

    const startDateBaseOptions = {
        hideYear: isDateTimeCurrentYear(startDate),
        hideMinutes: isDateTimeTopHour(startDate),
    };

    const endDateBaseOptions = {
        hideYear: isDateTimeCurrentYear(endDate),
        hideMinutes: isDateTimeTopHour(endDate),
    };

    const { startDateInRangeOptions, endDateInRangeOptions } = isSameDay(
        startDate,
        endDate,
    )
        ? {
              startDateInRangeOptions: areDatetimesSameMeridian(
                  startDate,
                  endDate,
              )
                  ? { hideMeridian: true }
                  : null,
              endDateInRangeOptions: { onlyTime: true },
          }
        : {
              startDateInRangeOptions: { hideWeekday: true },
              endDateInRangeOptions: { hideWeekday: true },
          };

    return {
        startDate: getDatetimeFormatString(startDateBaseOptions),
        endDate: getDatetimeFormatString(endDateBaseOptions),
        startDateInRange: getDatetimeFormatString({
            ...startDateBaseOptions,
            ...startDateInRangeOptions,
        }),
        endDateInRange: getDatetimeFormatString({
            ...endDateBaseOptions,
            ...endDateInRangeOptions,
        }),
    };
};

/*
 * Returns true when the datetime belongs to the current year
 */
export const isDateTimeCurrentYear = (datetime: Date): boolean => {
    return getYear(datetime) == getYear(new Date());
};

/*
 * Returns `true` if the datetime is top of the hour (e.g: 13:00)
 */
export const isDateTimeTopHour = (datetime: Date): boolean => {
    return getMinutes(datetime) == 0;
};

/**
 * Returns `true` if passed datetimes are in the same meridian (AM or PM)
 */
export const areDatetimesSameMeridian = (
    datetime1: Date,
    datetime2: Date,
): boolean => {
    return format(datetime1, 'aa') == format(datetime2, 'aa');
};

/**
 * Composing a specific datetime format based on the params received.
 *
 * @param onlyTime              Show only the time part of the datetime (e.g: 12:45)
 * @param hideWeekday           Hide the weekday (e.g: Sunday)
 * @param hideMeridian          Hide the meridian (am or pm)
 * @param hideYear              Hide the year (eg. 2022)
 * @param hideMinutes           Hide the minutes (eg: 11:00pm instead of 11pm)
 * @returns a datetime format string
 */
export const getDatetimeFormatString = (options: {
    onlyTime?: boolean;
    hideWeekday?: boolean;
    hideMeridian?: boolean;
    hideYear?: boolean;
    hideMinutes?: boolean;
}): string => {
    const { baseDatePart, separator, weekdayPart, yearPart } = options.onlyTime
        ? {
              baseDatePart: '',
              separator: '',
              weekdayPart: '',
              yearPart: '',
          }
        : {
              baseDatePart: BASE_EVENT_DATE_FORMAT,
              separator: BASE_EVENT_DATE_SEPARATOR,
              weekdayPart: options.hideWeekday ? '' : 'eeee, ',
              yearPart: options.hideYear ? '' : ', yyyy',
          };
    const baseTimePart = BASE_EVENT_TIME_FORMAT;
    const minutesPart = options.hideMinutes ? '' : ':mm';
    const meridianPart = options.hideMeridian ? '' : 'aaa';

    return [
        weekdayPart,
        baseDatePart,
        yearPart,
        separator,
        baseTimePart,
        minutesPart,
        meridianPart,
    ].join('');
};

/**
 * getFormattedDateTimeRange
 *
 * Returns a string representing a datetime range formatted for a given
 * timezone. If showTimezone  is false, do not display the timezone as part of the range.
 * Note: In the future, instead of a default timezone it may be better to use
 * the browser's timezone. Moment Timezone 0.5.0 has a function momentTimezone.tz.guess(),
 * which we could use if we upgrade the library. (EB-43817)
 *
 * @return An event's datetime range
 */
export const getFormattedDateTimeRange = ({
    event,
    locale = DEFAULT_LANGUAGE,
    dateTimeFormat = DEFAULT_DATETIME_FORMAT,
    datesFormatOverride = undefined,
    useTimezoneFrom = TimezoneLocale.Creator,
}: {
    event: Pick<EventbriteEvent, 'start' | 'end' | 'listingDisplayFlags'>;
    locale?: string;
    dateTimeFormat?: string;
    datesFormatOverride?: DatesFormat;
    useTimezoneFrom?: TimezoneLocale;
}): string => {
    const startDateTime = get(event, 'start.utc');
    const endDateTime = get(event, 'end.utc');
    const timezone = getTimezone({ event, useTimezoneFrom });
    const showTimezone = get(
        event,
        'listingDisplayFlags.shouldShowTimezone',
        true,
    );

    const startDate = utcToZonedTime(startDateTime, timezone, {
        locale: getDateFnsLocale(locale),
    });
    const endDate = utcToZonedTime(endDateTime, timezone, {
        locale: getDateFnsLocale(locale),
    });

    // If the two dates are on the same day in the given timezone, the end datetime
    // should be formatted to include only the time to avoid printing the same
    // information twice
    const endDateTimeFormat =
        datesFormatOverride?.endDateInRange ??
        (isSameDay(startDate, endDate) ? DEFAULT_TIME_FORMAT : dateTimeFormat);

    dateTimeFormat = datesFormatOverride?.startDateInRange ?? dateTimeFormat;

    const formattedStartDateTime = format(startDate, dateTimeFormat, {
        locale: getDateFnsLocale(locale),
    });
    const formattedEndDateTime = format(endDate, endDateTimeFormat, {
        locale: getDateFnsLocale(locale),
    });

    const localizedLazyString = showTimezone
        ? // 'Tue, Feb 28, 2017 7:00 PM - Sat, Mar 4, 2017 7:00 PM EST'
          gettext(
              '%(formattedStartDateTime)s - %(formattedEndDateTime)s %(timezone)s',
              {
                  formattedStartDateTime,
                  formattedEndDateTime,
                  timezone: getFormattedTimezone(timezone, startDateTime),
              },
          )
        : // 'Tue, Feb 28, 2017 7:00 PM - Sat, Mar 4, 2017 7:00 PM'
          gettext('%(formattedStartDateTime)s - %(formattedEndDateTime)s', {
              formattedStartDateTime,
              formattedEndDateTime,
          });

    return localizedLazyString.toString().replace(/\.,/g, '.');
};

/*
 * Returns the timezone to use depending on the
 * useTimezoneFrom flag. When this flag is true it
 * will return the attendee's timezone (if defined).
 * Otherwise it will return the event timezone
 */
export const getTimezone = ({
    event,
    useTimezoneFrom,
}: {
    event: EventbriteEvent | undefined;
    useTimezoneFrom: TimezoneLocale;
}): string => {
    // If no timezone is provided, use the default timezone
    const creatorTimezone = event
        ? event.start.timezone || DEFAULT_TIMEZONE
        : DEFAULT_TIMEZONE;

    const timezone =
        useTimezoneFrom === TimezoneLocale.Attendee
            ? getAttendeeTimezone() ?? creatorTimezone
            : creatorTimezone;

    return timezone;
};

const _getStartDayAndTime = (
    startDayOfTheWeek: string,
    startTime: string,
    formattedTimezone: string,
) =>
    // 'Sun, 7:00 PM PDT' or
    // 'Sun, 7:00 PM'
    gettext('%(startDayOfTheWeek)s, %(startTime)s %(formattedTimezone)s', {
        startDayOfTheWeek,
        startTime,
        formattedTimezone,
    }).toString();

const _getDayAndTimeMultiDayRange = (
    startDayOfTheWeek: string,
    startTime: string,
    endDayOfTheWeek: string,
    endTime: string,
    formattedTimezone: string,
) =>
    // 'Fri, 9:00 PM - Sat, 2:00 AM PDT' or
    // 'Fri, 9:00 PM - Sat, 2:00 AM'
    gettext(
        '%(startDayOfTheWeek)s, %(startTime)s - %(endDayOfTheWeek)s, %(endTime)s %(formattedTimezone)s',
        {
            startDayOfTheWeek,
            startTime,
            endDayOfTheWeek,
            endTime,
            formattedTimezone,
        },
    ).toString();

const _getSingleDayAndTimeRange = (
    startDayOfTheWeek: string,
    startTime: string,
    endTime: string,
    formattedTimezone: string,
) =>
    // 'Sun, 7:00 PM - 10:00 PM PDT' or
    // 'Sun, 7:00 PM - 10:00 PM'
    gettext(
        '%(startDayOfTheWeek)s, %(startTime)s - %(endTime)s %(formattedTimezone)s',
        {
            startDayOfTheWeek,
            startTime,
            endTime,
            formattedTimezone,
        },
    );

/**
 * Returns a localized day of the week and time or time range to display
 * for each child event on the repeating event parent page.
 * For instance:
 * (may include timezone or not)
 * Tue, 7:00 PM PST
 * Tue, 7:00 PM - 9:00 PM PST
 * Fri, 9:00 PM - Sat, 2:00 AM PST
 * ven., 21:00 - sam., 02:00 CET
 */
export const getFormattedChildEventDayAndTime = ({
    event,
    locale,
    hideEndDate = false,
    showTimezone = true,
    shouldHideSeriesStartTime = false,
    useTimezoneFrom = TimezoneLocale.Creator,
}: {
    event?: EventbriteEvent;
    locale: string;
    hideEndDate?: boolean;
    showTimezone?: boolean;
    shouldHideSeriesStartTime?: boolean;
    useTimezoneFrom?: TimezoneLocale;
}): string => {
    if (!event) {
        return '';
    }

    let formattedDayAndTime: string | GenericLazyString = '';
    // get the timezone
    const timezone = getTimezone({ event, useTimezoneFrom });

    const startDate = utcToZonedTime(event.start.utc, timezone, {
        locale: getDateFnsLocale(locale),
    });
    const endDate = utcToZonedTime(event.end.utc, timezone, {
        locale: getDateFnsLocale(locale),
    });

    const isMultiDayEvent = !isSameDay(startDate, endDate);

    const startDayOfTheWeek = format(startDate, 'eee', {
        locale: getDateFnsLocale(locale),
    });
    const endDayOfTheWeek = isMultiDayEvent
        ? format(endDate, 'eee', {
              locale: getDateFnsLocale(locale),
          })
        : '';

    const startTime = format(startDate, DEFAULT_TIME_FORMAT, {
        locale: getDateFnsLocale(locale),
    });
    const endTime = format(endDate, DEFAULT_TIME_FORMAT, {
        locale: getDateFnsLocale(locale),
    });

    const formattedTimezone = showTimezone
        ? getFormattedTimezone(timezone, event?.start.utc)
        : '';

    if (shouldHideSeriesStartTime) {
        // only show the start day of the week, but use the full day rather
        // than the abbreviation, e.g. 'Tuesday'
        formattedDayAndTime = format(startDate, 'eeee', {
            locale: getDateFnsLocale(locale),
        });
    } else if (hideEndDate) {
        formattedDayAndTime = _getStartDayAndTime(
            startDayOfTheWeek,
            startTime,
            formattedTimezone,
        );
    } else if (isMultiDayEvent) {
        formattedDayAndTime = _getDayAndTimeMultiDayRange(
            startDayOfTheWeek,
            startTime,
            endDayOfTheWeek,
            endTime,
            formattedTimezone,
        );
    } else {
        formattedDayAndTime = _getSingleDayAndTimeRange(
            startDayOfTheWeek,
            startTime,
            endTime,
            formattedTimezone,
        );
    }

    // must trim to remove trailing space if !showTimezone
    return formattedDayAndTime.trimEnd();
};

/**
 * Returns the full event date range string to display in the subtitle on the modal,
 * purchase confirmation page and on the listing page
 */
export const getFormattedEventDate = ({
    event,
    locale,
    datesFormat = undefined,
    useTimezoneFrom = TimezoneLocale.Creator,
}: {
    event: EventbriteEvent | undefined;
    locale?: string;
    datesFormat?: DatesFormat;
    useTimezoneFrom?: TimezoneLocale;
}): string => {
    const startDateFormat = datesFormat?.startDate ?? DEFAULT_DATETIME_FORMAT;
    const endDateFormat = datesFormat?.endDate ?? DEFAULT_DATETIME_FORMAT;

    if (!event || isEmpty(event)) {
        return '';
    }

    const timezone = getTimezone({ event, useTimezoneFrom });

    const {
        listingDisplayFlags: { shouldShowTimezone } = {},
        hideStartDate,
        hideEndDate,
    } = event;

    if (hideStartDate && hideEndDate) {
        return shouldShowTimezone
            ? getFormattedTimezone(
                  event.start.timezone,
                  new Date().toISOString(),
              )
            : '';
    }

    if (hideStartDate) {
        // 'Ends on Sun, Apr 14, 2019 10:00 PM PDT'
        return gettext('Ends on %(datetime)s', {
            datetime: getFormattedDateTime(
                event.end.utc,
                shouldShowTimezone,
                timezone,
                locale,
                endDateFormat,
            ),
        }).toString();
    }

    if (hideEndDate) {
        // 'Starts on Sun, Apr 14, 2019 7:00 PM PDT'
        return gettext('Starts on %(datetime)s', {
            datetime: getFormattedDateTime(
                event.start.utc,
                shouldShowTimezone,
                timezone,
                locale,
                startDateFormat,
            ),
        }).toString();
    }

    // 'Sun, Apr 14, 2019 7:00 PM - 10:00 PM PDT'
    return getFormattedDateTimeRange({
        event,
        locale,
        dateTimeFormat: DEFAULT_DATETIME_FORMAT,
        datesFormatOverride: datesFormat,
        useTimezoneFrom,
    }).toString();
};

/**
 * Returns the event payment due date range string
 * to display on the cash based payment method's purchase confirmation page
 */
export const getFormattedDueDate = ({
    locale,
    deferredPaymentDueDays,
}: {
    locale: string;
    deferredPaymentDueDays: number;
}) => {
    const dueDateFormat = 'MMMM d, yyyy';
    const dueDate = addDays(new Date(), deferredPaymentDueDays).toISOString();

    return getFormattedDateTime(
        dueDate,
        false,
        undefined,
        locale,
        dueDateFormat,
    );
};

/*
 * Returns the timezone associated to the current user
 */
export const getAttendeeTimezone = () => {
    try {
        return new Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e: any) {
        logger.error('User timezone is not defined', e);
        return null;
    }
};

/*
 * Return if the date should be taken from the user.
 * Online events should show date & time related to the
 * attendee (when possible), instead of the creator ones
 */
export const shouldShowDateInAttendeeTimezone = ({
    isOnlineEvent,
    userTimezone,
}: {
    isOnlineEvent: boolean;
    userTimezone: string | null;
}) => isOnlineEvent && !!userTimezone;

/**
 * Return the date in the correct timezone, depending on if
 * the event is online (using the attendee timezone) or onsite
 * (using the event timezone)
 */
export const getDateForAttendee = ({
    isOnlineEvent,
    dateAndTimezone,
}: {
    isOnlineEvent: boolean;
    dateAndTimezone: StartOrEnd;
}) => {
    const useTimezoneFrom = isOnlineEvent
        ? TimezoneLocale.Attendee
        : TimezoneLocale.Creator;

    const timezone =
        useTimezoneFrom === TimezoneLocale.Attendee
            ? getAttendeeTimezone() ?? dateAndTimezone.timezone
            : dateAndTimezone.timezone;

    return utcToZonedTime(dateAndTimezone.utc, timezone);
};

/**
 * Returns a time format string in the correct timezone depending
 * on if the event is online or onsite (7:30pm)
 */
export const getFormattedTime = ({
    dateAndTimezone,
    locale,
    isOnlineEvent,
}: {
    dateAndTimezone: StartOrEnd;
    locale: string;
    isOnlineEvent: boolean;
}) => {
    const dateForAttendee = getDateForAttendee({
        isOnlineEvent,
        dateAndTimezone,
    });
    const dateFormat = isDateTimeTopHour(dateForAttendee) ? 'haaa' : 'h:maaa';

    return format(dateForAttendee, dateFormat, {
        locale: getDateFnsLocale(locale),
    });
};
