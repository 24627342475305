import React from 'react';
import type { MusicEventProps } from '../../../types';
import './MusicEventsInfo.scss';

type MusicEventsInfoProps = Pick<
    MusicEventProps,
    'ageRestriction' | 'presenter'
>;

export const MusicEventsInfo = (props: MusicEventsInfoProps) => {
    const itemList = Object.keys(props).map((itemKey, index) => {
        const item = props[itemKey as keyof MusicEventsInfoProps];

        if (!item) return;

        return <li key={index}>{item}</li>;
    });

    return <ul className="music-event-info">{itemList}</ul>;
};
