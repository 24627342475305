import { EnhancedEventCard } from '@eventbrite/eds-event-card';
import { logEvent } from '@eventbrite/statsig';
import React, { useEffect } from 'react';
import {
    EventInteractions,
    EVENT_INTERACTION,
} from '../../../components/EventCardTracking';
import {
    formatCategoryFromTags,
    formatPaidStatus,
    formatVenueToCityRegion,
} from '../../../components/EventCardTracking/utils';
import { FormattedEvent } from '../../../types';
import { useExpandCreatorCollections } from '../../hooks';
import {
    trackCollectionClick,
    trackCollectionSignalClick,
    trackEventClick,
    trackSaveEvent,
    trackUserViewedEvent,
} from './analytics';
import { HEAP_EVENT_CLICK, HEAP_EVENT_LIKE_CLICK } from './constants';
import './EventCard.scss';
import {
    useAffCode,
    useSaveEvent,
    useShouldSaveFromLoggedOutRedirect,
} from './hooks';
import { EventCardProps } from './types';
import { getSignal } from './utils';

const localStorageLikesTooltipViewed = 'search:eventCard:likes:tooltip:viewed';

export const EventCard: React.FunctionComponent<EventCardProps> = (props) => {
    //Prop Breakdown
    const {
        eventData,
        description,
        TitleWrapper,
        isLazyImage,
        currencyFormat,
        gaCategory,
        gaOnClickLabel,
        isAuthenticated,
        onClick,
        onCollectionClick,
        onCollectionViewActive,
        onSave,
        heapLocationString,
        onEventCardMoreAction,
        showRemoveTooltip,
        shareOptions,
        bucketLabel,
        ...otherProps
    } = props;
    const {
        isFree,
        isOnlineEvent,
        id: eventId,
        organizerFollowerCount,
        savedByYou,
        publicCollections,
        tags,
        venue,
    } = eventData;

    //Local state
    const [saved, setSaved] = React.useState(!!savedByYou);
    const [collectionViewShown, setCollectionViewShown] = React.useState(false);
    const [showLikesTooltip, setShowLikesTooltip] = React.useState(false);

    useEffect(() => {
        setSaved(savedByYou);

        return () => {
            // empty clean up
        };
    }, [savedByYou]);

    //Leverage expanded collections
    const { data: expandedCreatorCollections } = useExpandCreatorCollections(
        eventId,
        { enabled: collectionViewShown },
    );

    const paidStatus = formatPaidStatus(isFree);
    const locationSlug = formatVenueToCityRegion({
        venue,
        isOnlineEvent,
    });
    const category = formatCategoryFromTags(tags);

    //Action handlers and tracking helpers
    const handleSaveEvent = useSaveEvent({
        setSaved,
        onCompleted: () => {
            onSave?.(saved, eventId);

            gaCategory &&
                isAuthenticated &&
                !onSave &&
                trackSaveEvent(gaCategory, !saved, eventId);
        },
    });

    //Currently does not display success notification
    //However save is successful
    //TODO: Ensure notification fires
    useShouldSaveFromLoggedOutRedirect(
        handleSaveEvent,
        eventId,
        isAuthenticated,
    );

    const handleOnClick = () => {
        gaCategory &&
            !onClick &&
            trackEventClick(
                gaCategory,
                eventId,
                organizerFollowerCount,
                gaOnClickLabel,
            );
        onClick?.(eventId);
        trackUserViewedEvent(eventId);

        heapLocationString &&
            logEvent(
                `${heapLocationString} - ${HEAP_EVENT_CLICK}`,
                undefined,
                getEventMetadata(eventData),
            );
    };

    const handleOnCollectionClick = React.useCallback(
        (id: string) => {
            !onCollectionClick && trackCollectionClick(id);

            onCollectionClick?.(id);
        },
        [onCollectionClick],
    );

    const handleOnCollectionViewShown = React.useCallback(
        (isShown: boolean) => {
            setCollectionViewShown(true);

            !onCollectionViewActive && trackCollectionSignalClick(eventId);

            onCollectionViewActive?.(isShown);
        },
        [eventId, onCollectionViewActive],
    );

    const shouldTriggerTooltip = () => {
        if (!localStorage.getItem(localStorageLikesTooltipViewed)) {
            localStorage.setItem(localStorageLikesTooltipViewed, 'true');
            setShowLikesTooltip(true);
        }
    };

    // Final data massaging
    const signalData = getSignal({
        follow: organizerFollowerCount || 0,
        collection: expandedCreatorCollections || publicCollections,
    });

    if (eventData.minPrice && currencyFormat) {
        eventData.minPrice.currencyFormat = currencyFormat;
    }

    const url = useAffCode(eventData.url || '', props.affCode || '');

    // Emit mount event
    useEffect(() => {
        const cardElement = document.querySelector(
            `[data-event-id="${eventId}"]`,
        );
        if (!cardElement) {
            return;
        }

        cardElement.dispatchEvent(
            new CustomEvent(EVENT_INTERACTION, {
                detail: {
                    id: eventId,
                    action: EventInteractions.Mounted,
                    event: eventData,
                },
                bubbles: true,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    return (
        <EnhancedEventCard
            //Let passed in props overwrite
            //the signal info if necessary
            {...signalData}
            {...otherProps}
            {...eventData}
            url={url}
            description={description}
            TitleWrapper={TitleWrapper}
            id={eventId}
            isLazyImage={isLazyImage}
            isAuthenticated={isAuthenticated}
            onClick={handleOnClick}
            onCollectionClicked={handleOnCollectionClick}
            onIsCollectionViewActive={handleOnCollectionViewShown}
            onSaveEvent={() => {
                shouldTriggerTooltip();
                heapLocationString &&
                    logEvent(
                        `${heapLocationString} - ${HEAP_EVENT_LIKE_CLICK}`,
                    );

                return handleSaveEvent({
                    eventId,
                    isAuthenticated: !!isAuthenticated,
                    savedByYou: saved,
                });
            }}
            savedByYou={saved}
            showLikesTooltip={showLikesTooltip}
            showRemoveTooltip={showRemoveTooltip}
            showFewerEventsIcon={props.showFewerEventsIcon}
            onEventCardMoreAction={onEventCardMoreAction}
            shareOptions={shareOptions}
            locationSlug={locationSlug}
            paidStatus={paidStatus}
            category={category}
            bucketLabel={bucketLabel}
        />
    );
};

function getEventMetadata(
    eventData: Pick<FormattedEvent, 'isPromoted' | 'isFree' | 'isOnlineEvent'>,
) {
    return {
        isPromoted: `${Boolean(eventData.isPromoted)}`,
        isFree: `${Boolean(eventData.isFree)}`,
        isOnline: `${Boolean(eventData.isOnlineEvent)}`,
    };
}
