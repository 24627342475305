import { isAndroidWebView, isLinux } from '@eventbrite/feature-detection';
import { SALES_STATUS_ON_SALE } from '../../../constants';
import { ConversionBarContainerProps } from './ConversionBarContainer';

export type ConversionBarContainerExclusionProps = {
    soldOut: ConversionBarContainerProps['compactCheckoutDisqualifications']['sold_out'];
    reservedSeating: ConversionBarContainerProps['compactCheckoutDisqualifications']['reserved_seating'];
    isSalesEnded: ConversionBarContainerProps['compactCheckoutDisqualifications']['is_sales_ended'];
    isRsvp: ConversionBarContainerProps['compactCheckoutDisqualifications']['is_rsvp'];
    isPostponed: ConversionBarContainerProps['compactCheckoutDisqualifications']['is_postponed'];
    isCanceled: ConversionBarContainerProps['compactCheckoutDisqualifications']['is_canceled'];
    moreThanOneTicket: ConversionBarContainerProps['compactCheckoutDisqualifications']['more_than_one_ticket'];
    hasWaitlist: ConversionBarContainerProps['compactCheckoutDisqualifications']['has_waitlist'];
    hasWaitingRoom: ConversionBarContainerProps['compactCheckoutDisqualifications']['has_waiting_room'];
    hasDiscounts: ConversionBarContainerProps['compactCheckoutDisqualifications']['has_discounts'];
    hasAddons: ConversionBarContainerProps['compactCheckoutDisqualifications']['has_addons'];
    discountCode: ConversionBarContainerProps['compactCheckoutDisqualifications']['discount_code'];
    hasDonations: ConversionBarContainerProps['compactCheckoutDisqualifications']['has_donations'];
    externalTicketsAreAvailable: ConversionBarContainerProps['externalTicketsAreAvailable'];
    salesStatus: ConversionBarContainerProps['salesStatus'];
    hasInventoryTiers: ConversionBarContainerProps['compactCheckoutDisqualifications']['has_inventory_tiers'];
    onlyDefaultCheckoutForOnlineEvents: ConversionBarContainerProps['onlyDefaultCheckoutForOnlineEvents'];
};

export const isTicketSelectionExcludedInListings = (
    props: ConversionBarContainerExclusionProps,
) =>
    props.soldOut ||
    props.reservedSeating ||
    props.isSalesEnded ||
    props.isRsvp ||
    props.isPostponed ||
    props.isCanceled ||
    props.moreThanOneTicket ||
    props.hasWaitlist ||
    props.hasWaitingRoom ||
    props.hasDiscounts ||
    props.hasAddons ||
    props.discountCode ||
    props.hasDonations ||
    props.externalTicketsAreAvailable ||
    props.salesStatus !== SALES_STATUS_ON_SALE ||
    props.hasInventoryTiers ||
    props.onlyDefaultCheckoutForOnlineEvents ||
    (isLinux() && !isAndroidWebView());
