const getStableIdFromCookie = (): string | null => {
    const stableId = decodeURIComponent(document?.cookie)
        .split('; ')
        .find((row) => row.startsWith('stableId'));

    if (stableId) {
        return stableId.split('=')[1];
    }

    return null;
};

export const getStableIdForInitialization = (
    stableId?: string,
): string | undefined => {
    const queryParam = new URLSearchParams(window?.location?.search).get(
        'stableID',
    );

    if (queryParam) {
        return queryParam;
    }

    const stableIDCookieValue = getStableIdFromCookie();

    if (stableIDCookieValue) {
        return stableIDCookieValue;
    }

    return stableId;
};

export const normalizeCategory = (category?: string): string | undefined => {
    if (!category) {
        return;
    }

    return category.toLowerCase().split(' ').join('_');
};
