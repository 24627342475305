import React from 'react';
import PropTypes from 'prop-types';
import { gettext } from '@eventbrite/i18n';
import { formatUrl } from 'url-lib';
import { IconButton } from '@eventbrite/eds-icon-button';

import {
    FACEBOOK_AFFILIATED_CODE,
    FACEBOOK_PROP_TYPE_SHAPE,
    UTM_OPTION_PROPTYPE,
} from './constants';
import { openUrl } from './utils';

import { LogoFacebook } from '@eventbrite/eds-iconography';

const FACEBOOK_URL = 'https://www.facebook.com/dialog/share';

const _getEventUrl = (eventUrl, affiliateChannel, utmOptions) =>
    formatUrl(eventUrl, {
        aff:
            FACEBOOK_AFFILIATED_CODE[affiliateChannel] ||
            FACEBOOK_AFFILIATED_CODE['default'],
        ...utmOptions,
    });

export default class FacebookShare extends React.Component {
    static propTypes = {
        /**
         * Event's url
         */
        eventUrl: PropTypes.string.isRequired,

        /**
         * Facebook options
         */
        facebookOptions: FACEBOOK_PROP_TYPE_SHAPE.isRequired,

        /**
         * Function that onClick of one of the share options, passes
         * back an id of the clicked option
         */
        onClick: PropTypes.func,

        /**
         * utmOptions configured for this shareType
         */
        utmOptions: UTM_OPTION_PROPTYPE,
    };

    _handleButtonClick() {
        const {
            affiliateChannel,
            eventUrl,
            facebookOptions: { appId, locale },
            onClick,
            utmOptions,
        } = this.props;
        const url = formatUrl(FACEBOOK_URL, {
            locale,
            app_id: appId,
            display: 'popup',
            href: _getEventUrl(eventUrl, affiliateChannel, utmOptions),
        });

        if (onClick) {
            onClick();
        }

        openUrl(url);
    }

    render() {
        return (
            <span
                className="eds-l-pad-right-1"
                data-spec="facebook-share-box-container"
                data-heap-id={this.props.dataHeapId}
                data-share-method={this.props.shareMethod}
                data-page-area={this.props.dataPageArea}
                data-event-id={this.props.eventId}
            >
                <IconButton
                    iconType={<LogoFacebook />}
                    title={gettext('Share on Facebook')}
                    onClick={this._handleButtonClick.bind(this)}
                />
            </span>
        );
    }
}
