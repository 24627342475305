import React from 'react';
import { AppProps } from '../../types';
import { useAddHandlersToNodes } from '../hooks/useAddHandlersToNodes';
import { ReportThisEventController } from './ReportThisEventController';

export const ReportThisEventRoot = (props: AppProps) => {
    const [isReportThisEventVisible, setIsReportThisEventVisible] =
        React.useState(false);
    useAddHandlersToNodes({
        querySelector: '#report_this_link--react',
        callback: React.useCallback(() => {
            setIsReportThisEventVisible(!isReportThisEventVisible);
        }, [isReportThisEventVisible]),
        delaySelector: true,
        retry: true,
    });

    return (
        <>
            <ReportThisEventController
                {...props}
                isReportThisEventVisible={isReportThisEventVisible}
                setIsReportThisEventVisible={setIsReportThisEventVisible}
                country={props.event?.venue?.country}
            />
        </>
    );
};
