import { PromotedActionsMenuEventCard } from '@eventbrite/ads-event-card-extra';
import {
    EventCardSaveAction,
    EventCardShareAction,
    FormattedEvent,
    getShareActionProps,
} from '@eventbrite/event-renderer';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import { STATSIG_EVENT_NAMES } from '../../../experimentation';
import {
    label,
    ShareType,
    trackShareAction,
} from '../../../tracking/trackShareAction';
import { AffiliateCodes, GACodes } from '../../../types';
import { ListingsEventCard } from '../../_shared/EventCard/ListingsEventCard';
import { getEventCardsMapProps } from '../types';

export function getEventCardsMap({
    events,
    isAuthenticated,
    shareComponentProps,
    areMetricsEnabled,
    locale,
    bucketLabel,
    getTrackingContext,
    onRemoveEvent,
}: getEventCardsMapProps): JSX.Element[] {
    return events.map((event: FormattedEvent) => {
        const affCode = AffiliateCodes.AFF_RELATED_EVENTS_LIVE;
        const eventKey = event.promotedListingMetadata?.adId || event.id;
        const shareActionProps = getShareActionProps({
            name: event.name,
            id: event.id,
            url: event.url,
            affCode: affCode,
            utmOptions: shareComponentProps.utmOptions,
            onShareItemClick: (id: string) =>
                trackShareAction(
                    label(id as ShareType),
                    GACodes.GA_CATEGORY_EVENT_CARD,
                ),
            onClick: () => {
                trackShareAction(
                    GACodes.GA_LABEL_OPEN_MODAL,
                    GACodes.GA_CATEGORY_EVENT_CARD,
                );
                if (areMetricsEnabled) {
                    logEvent(STATSIG_EVENT_NAMES.LISTING_RELATED_EVENT_SHARE);
                }
            },
        });

        return (
            <ListingsEventCard
                key={eventKey}
                locale={locale || 'en-US'}
                event={event}
                statsigLocationString={
                    event.isPromoted
                        ? 'Related Events - Event Carousel - Promoted'
                        : undefined
                }
                affCode={affCode}
                onClick={() => {
                    if (areMetricsEnabled) {
                        logEvent(
                            STATSIG_EVENT_NAMES.LISTING_RELATED_EVENT_CLICK,
                            null,
                        );
                    }
                }}
                bucketLabel={bucketLabel}
                actions={
                    <>
                        <EventCardSaveAction
                            isAuthenticated={!!isAuthenticated}
                            savedByYou={event.savedByYou}
                            id={event.id}
                            eventName={event.name}
                            imageUrl={event.imageUrl}
                            actionStyle="outline"
                            onClick={(saved) =>
                                areMetricsEnabled &&
                                saved &&
                                logEvent(
                                    STATSIG_EVENT_NAMES.LISTING_RELATED_EVENT_LIKE,
                                )
                            }
                        />
                        <EventCardShareAction
                            actionStyle="outline"
                            {...shareActionProps}
                        />
                        {event.isPromoted && (
                            <PromotedActionsMenuEventCard
                                event={event}
                                getTrackingContext={getTrackingContext}
                                onRemoveEvent={onRemoveEvent}
                                actionStyle="outline"
                            />
                        )}
                    </>
                }
            />
        );
    });
}
