import { gettext } from '@eventbrite/i18n';
import React from 'react';

import './StartDateView.scss';

type StartDateViewProps = {
    dateTimeText: string;
    dateText: string;
};

export const StartDateView = ({
    dateTimeText,
    dateText,
}: StartDateViewProps) => {
    return (
        <time className="start-date" dateTime={dateTimeText}>
            {dateText}
        </time>
    );
};

export const MultipleDatesView = () => (
    <span className="start-date">{gettext('Multiple dates')}</span>
);
