import { Icon } from '@eventbrite/eds-icon';
import { ArrowLeftChunky, ArrowRightChunky } from '@eventbrite/eds-iconography';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    SliderContainer,
    SliderContent,
    SliderContentWrapper,
    SliderNav,
    SliderWrapper,
} from './ContentSlider.styles';

// Number of pixels to move the user in the slider upon nav click.
const OFFSET_FACTOR = 50;
const MINIMUM_OFFSET = 0;

export interface ContentSliderProps {
    offsetFactor?: number;
}

export const ContentSlider: React.FC<ContentSliderProps> = ({
    children,
    offsetFactor = OFFSET_FACTOR,
}) => {
    const [maximumOffset, setMaximumOffset] = useState(0);
    const [currentOffset, setCurrentOffset] = useState(0);
    const scrollableElement = useRef<HTMLDivElement>(null);
    const [shouldDisplayNavButtons, toggleShouldDisplayNavButtons] =
        useState(false);

    const shouldDisplayLeftNav = shouldDisplayNavButtons && currentOffset > 0;
    const shouldDisplayRightNav =
        shouldDisplayNavButtons && currentOffset < maximumOffset;

    const navigateLeft = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();

            setCurrentOffset((currentOffset) => {
                const newOffset = currentOffset - offsetFactor;
                return newOffset <= MINIMUM_OFFSET ? 0 : newOffset;
            });
        },
        [offsetFactor],
    );

    const navigateRight = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();

            setCurrentOffset((currentOffset) => {
                const newOffset = currentOffset + offsetFactor;
                return newOffset >= maximumOffset ? maximumOffset : newOffset;
            });
        },
        [maximumOffset, offsetFactor],
    );

    const calculateWidths = useCallback(() => {
        const scrollWidth = scrollableElement.current?.scrollWidth || 0;
        const width = scrollableElement.current?.offsetWidth || 0;
        const scrollableWidth = scrollWidth - width;
        setMaximumOffset(scrollableWidth);
        toggleShouldDisplayNavButtons(scrollableWidth > 0);
    }, [scrollableElement]);

    useEffect(() => {
        if (scrollableElement?.current) {
            scrollableElement.current.scrollLeft = currentOffset;
        }
    }, [currentOffset]);

    useEffect(() => {
        calculateWidths();

        // Need to copy the ref into another variable to overcome the issue
        // with react cleaning up the ref when unmounting the component.
        const scrollableElementCopy = scrollableElement.current;

        const onScroll = () => {
            setCurrentOffset(
                scrollableElementCopy?.scrollLeft || MINIMUM_OFFSET,
            );
        };

        const onResize = () => calculateWidths();

        scrollableElementCopy?.addEventListener('scroll', onScroll);
        window.addEventListener('resize', onResize);

        return () => {
            scrollableElementCopy?.removeEventListener('scroll', onScroll);
            window.removeEventListener('resize', onResize);
        };
    }, [calculateWidths, children]);

    return (
        <SliderContainer>
            <SliderWrapper>
                {shouldDisplayLeftNav && (
                    <SliderNav
                        data-testid="left-nav"
                        position="left"
                        onClick={navigateLeft}
                    >
                        <div>
                            <Icon
                                type={<ArrowLeftChunky />}
                                color="ui-blue--hover"
                            />
                        </div>
                    </SliderNav>
                )}
                <SliderContentWrapper
                    data-testid="content-wrapper"
                    ref={scrollableElement}
                >
                    <SliderContent>{children}</SliderContent>
                </SliderContentWrapper>

                {shouldDisplayRightNav && (
                    <SliderNav
                        data-testid="right-nav"
                        position="right"
                        onClick={navigateRight}
                    >
                        <div>
                            <Icon
                                type={<ArrowRightChunky />}
                                color="ui-blue--hover"
                            />
                        </div>
                    </SliderNav>
                )}
            </SliderWrapper>
        </SliderContainer>
    );
};
