import { Dialog } from '@eventbrite/eds-dialog';
import { withTooltip } from '@eventbrite/eds-tooltip';
import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useIsMobileDevice } from './hooks/useIsMobileDevice';
import { useIsTouchDevice } from './hooks/useIsTouchDevice';
import { useUrgencyCategories } from './provider';
import { UrgencyCategoryType } from './types';
import './UrgencyCategories.scss';

interface BaseUrgencyCategoryProps {
    category: string;
    label: string;
    description: string;
}

export const getUrgencyCategoriesContent: (labelOverrides?: {
    [key in UrgencyCategoryType]?: string;
}) => Record<
    UrgencyCategoryType,
    {
        label: string;
        description: string;
    }
> = (labelOverrides = {}) => ({
    [UrgencyCategoryType.EarlyBird]: {
        label:
            labelOverrides[UrgencyCategoryType.EarlyBird] ??
            gettext('Early bird discount').toString(),
        description: gettext(
            'Tickets may be available at a lower price for a limited time.',
        ).toString(),
    },
    [UrgencyCategoryType.Popular]: {
        label:
            labelOverrides[UrgencyCategoryType.Popular] ??
            gettext('Popular').toString(),
        description: gettext(
            "Most of this event's ticket are already sold! Get yours before they sell out.",
        ).toString(),
    },
    [UrgencyCategoryType.New]: {
        label:
            labelOverrides[UrgencyCategoryType.New] ??
            gettext('Just Added').toString(),
        description: gettext(
            'This event was added to Eventbrite recently. Be the first to get tickets.',
        ).toString(),
    },
});

export const UrgencyCategories: React.FC<{
    labelOverrides?: { [key in UrgencyCategoryType]?: string };
}> = ({ labelOverrides }) => {
    const categories = useUrgencyCategories();
    const isMobileDevice = useIsMobileDevice();
    const isTouchDevice = useIsTouchDevice();

    const urgencyCategoriesToShow = Object.keys(categories).filter(
        (categoryType) => categoryType,
    );

    const Component =
        isMobileDevice || isTouchDevice
            ? UrgencyCategoryWithDialog
            : UrgencyCategoryWithTooltip;

    return (
        <>
            {urgencyCategoriesToShow.map(
                (urgencyCategory: string, index: number) => {
                    const { label, description } =
                        getUrgencyCategoriesContent(labelOverrides)[
                            urgencyCategory as UrgencyCategoryType
                        ];

                    return (
                        <Component
                            key={index}
                            category={urgencyCategory}
                            label={label}
                            description={description}
                        />
                    );
                },
            )}
        </>
    );
};

const UrgencyCategoryTag: React.FunctionComponent<{
    category: string;
    label: string;
}> = ({ category, label }) => {
    const labelStyles = classNames(
        'urgencySignals-label',
        `urgencySignals-${category}_label`,
    );
    return <span className={labelStyles}>{label}</span>;
};

const UrgencyCategoryWithTooltip: React.FunctionComponent<
    BaseUrgencyCategoryProps
> = ({ category, label, description }) => {
    const BaseTooltipComponent = withTooltip(UrgencyCategoryTag);

    return (
        <BaseTooltipComponent
            category={category}
            label={label}
            tooltipId={label}
            tooltipText={
                <div className="urgencySignals-label_tooltip">
                    {description}
                </div>
            }
            tooltipStyle={'phab'}
            tooltipArrowLocation={'left'}
        />
    );
};

const UrgencyCategoryWithDialog: React.FunctionComponent<
    BaseUrgencyCategoryProps
> = ({ category, label, description }) => {
    const [isModalOpen, setIsOpen] = useState(false);

    const labelStyles = classNames(
        'urgencySignals-label',
        `urgencySignals-${category}_label`,
    );
    const dialogContent = (
        <>
            <UrgencyCategoryTag category={category} label={label} />
            <span className="urgencySignals-label_dialogDescription">
                {description}
            </span>
        </>
    );

    return (
        <div className="urgencySignals-label_modal">
            <div onClick={() => setIsOpen(true)} className={labelStyles}>
                {label}
            </div>

            <Dialog
                isShown={isModalOpen}
                isClosable={true}
                onClose={() => setIsOpen(false)}
                message={dialogContent}
            />
        </div>
    );
};
