import { CheckoutLifecycleEventPostMessageName } from '@eventbrite/checkout-postmessage-api';
import { useCallback } from 'react';

export { CheckoutErrorName } from '@eventbrite/checkout-postmessage-api';

export enum CheckoutPostMessagesNames {
    backToTicketSelection = CheckoutLifecycleEventPostMessageName.backToTicketSelection,
    checkoutCaptchaErrorPageLoaded = CheckoutLifecycleEventPostMessageName.captchaErrorPageLoaded,
    checkoutFormLoaded = CheckoutLifecycleEventPostMessageName.checkoutFormLoaded,
    deliveryMethodFormLoaded = CheckoutLifecycleEventPostMessageName.deliveryMethodFormLoaded,
    orderPlacingError = CheckoutLifecycleEventPostMessageName.orderPlacingError,
    expressPaymentCanceled = CheckoutLifecycleEventPostMessageName.expressPaymentCanceled,
    expressPaymentProcessingOrder = CheckoutLifecycleEventPostMessageName.expressPaymentProcessingOrder,
    joinWaitlist = CheckoutLifecycleEventPostMessageName.joinWaitlist,
    onlyCheckoutLoaded = CheckoutLifecycleEventPostMessageName.onlyCheckoutLoaded,
    orderComplete = CheckoutLifecycleEventPostMessageName.orderComplete,
    orderCreationError = CheckoutLifecycleEventPostMessageName.orderCreationError,
    organizerQuestionsFormLoaded = CheckoutLifecycleEventPostMessageName.organizerQuestionsFormLoaded,
    ticketSelectionCompactLoaded = CheckoutLifecycleEventPostMessageName.ticketSelectionCompactLoaded,
    ticketSelectionResize = CheckoutLifecycleEventPostMessageName.ticketSelectionResize,
    ticketSelectionSubmit = CheckoutLifecycleEventPostMessageName.ticketSelectionSubmit,
    widgetRenderComplete = CheckoutLifecycleEventPostMessageName.widgetRenderComplete,
}

type CallbackParams = {
    contentHeight?: number;
    error?: string;
};

type IncomingCheckoutMessagesConfig = {
    handlers: Partial<
        Record<CheckoutPostMessagesNames, (params: CallbackParams) => void>
    >;
};

type CheckoutPostMessage = {
    messageName: CheckoutPostMessagesNames;
} & CallbackParams;

export const useIncomingCheckoutMessages = (
    config: IncomingCheckoutMessagesConfig,
) => {
    return useCallback(
        (event: MessageEvent<CheckoutPostMessage>) => {
            const { data: { messageName, contentHeight, error } = {} } = event;

            return messageName && config.handlers[messageName]
                ? config.handlers[messageName]?.({ contentHeight, error })
                : null;
        },
        [config],
    );
};
