import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

export default class Flag extends PureComponent {
    static propTypes = {
        flag: PropTypes.node,
        flagDark: PropTypes.bool,
    };

    render() {
        const { flag } = this.props;

        if (!flag) {
            return null;
        }

        return (
            <div className="eds-event-card-content__flag eds-align--center eds-text-bs eds-text-weight--heavy eds-text-color--white">
                {flag}
            </div>
        );
    }
}
