import React from 'react';
import { LISTING_HEAP_IDS, LISTING_HEAP_PAGE_AREA_IDS } from '../../constants';
import { CountryProps } from '../../types';
import { useTrackComponent } from '../hooks/useTrackComponent';
import { ReportThisEventButton } from '../ReportThisEvent/ReportThisEventButton';
import { DescriptiveOrganizerInfoProps } from '../_shared/OrganizerInfo/DescriptiveOrganizerInfo';
import { DescriptiveOrganizerPanelView } from '../_shared/OrganizerInfo/DescriptiveOrganizerPanelView/DescriptiveOrganizerPanelView';
import './OrganizerPanel.scss';

export type OrganizerPanelLayoutProps = {
    shouldShowMoreEventsFromThisOrganizer?: boolean;
    isSalesEnded?: boolean;
    isSoldOut?: boolean;
    timeframeInMonths?: string;
    numberOfEvents?: string;
    trustSignal?: React.ReactNode;
    eventId?: string;
};

type TOrganizerPanelProps = OrganizerPanelLayoutProps &
    DescriptiveOrganizerInfoProps &
    CountryProps;

export const OrganizerPanelLayout = ({
    shouldShowMoreEventsFromThisOrganizer,
    captchaKey,
    eventName,
    gaCategory,
    isAuthenticated,
    organizerAvatar,
    organizerDescription,
    organizerId,
    organizerName,
    organizerUrl,
    organizerFacebook,
    organizerTwitter,
    organizerWebsite,
    isSalesEnded,
    isSoldOut,
    trustSignal,
    eventId,
    country,
}: TOrganizerPanelProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const isEventActive = !isSalesEnded || !isSoldOut;

    useTrackComponent({
        ref: ref,
        heap: {
            eventName: LISTING_HEAP_IDS.ORGANIZER_PANEL_INFO_VIEW,
            eventBucketLabel: 'Views on the Organizer panel section',
        },
    });

    return (
        <section className="listing-organizer eds-l-pad-bot-5" ref={ref}>
            <DescriptiveOrganizerPanelView
                captchaKey={captchaKey}
                organizerAvatar={organizerAvatar}
                organizerDescription={organizerDescription}
                organizerId={organizerId}
                organizerName={organizerName}
                organizerUrl={organizerUrl}
                organizerFacebook={organizerFacebook}
                organizerTwitter={organizerTwitter}
                organizerWebsite={organizerWebsite}
                isAuthenticated={isAuthenticated}
                eventName={eventName}
                gaCategory={gaCategory}
                pageArea={LISTING_HEAP_PAGE_AREA_IDS.ORGANIZER_PANEL}
                trustSignal={trustSignal}
                eventId={eventId}
            />
            <div className="eds-align--center eds-l-mar-bot-3">
                <ReportThisEventButton country={country} />
            </div>
            {shouldShowMoreEventsFromThisOrganizer && isEventActive && (
                <div id="listings-root__more-events-from-this-organizer"></div>
            )}
            {isEventActive && (
                <div id="listings-root__collections-related-to-this-event"></div>
            )}
        </section>
    );
};
