import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { trackIntercomEvent } from '@eventbrite/marketing-tools-common';
import { ACTIVATION_MODAL_CLICKED_START_TRIAL } from '../constants';

interface TrackEvent {
    category: string;
    action: string;
    path?: string;
    source?: string;
    feature?: string;
    extraItem?: { [key: string]: string };
}

export const trackEvent = (props: TrackEvent) => {
    if (props.action === ACTIVATION_MODAL_CLICKED_START_TRIAL && HAS_WINDOW) {
        trackIntercomEvent('started trial', {
            email: window.__SERVER_DATA__?.user?.email,
        });
    }
};
