import React from 'react';
import { ContactOrganizerRenderer } from './components/ContactOrganizerRenderer';
import {
    ModalErrorBoundary,
    ViewErrorBoundary,
} from './components/ErrorBoundary';
import {
    CollectionContext,
    EnvContext,
    EventContext,
    FormProvider,
    OrganizerContext,
    OverlayProvider,
    UserProvider,
} from './context';
import { ViewController } from './views/ViewController';

interface ContactOrganizerProps {
    /**
     * The ID of the organizer which
     * should be contacted via this widget
     */
    organizerId: string;
    /**
     * Boolean controlling the visibility
     * status of the overlay. The consuming
     * application will need to handle managing
     * this state.
     */
    isVisible: boolean;
    /**
     * Required val to handle rendering a captcha in the
     * circumstance it is deemed necessary.
     *
     * Should be passed from the backend template, to your
     * application, and to this library.
     */
    captchaKey: string;
    /**
     * Optional callback triggered
     * when the widget  is closed
     */
    onClose?: Function;
    /**
     * Optional but recommended boolean determining
     * if the user is currently logged in
     *
     * If true, will fetch the user information
     * and prefill the email and name fields of the
     * form.
     */
    isAuthenticated?: boolean;
    /**
     * DEFAULT: 'root'
     *
     * Id of the dom node that this widget should
     * mount onto.
     *
     * Since it is an overlay, it is recommended
     * to use something close to the root of the
     * DOM tree
     */
    domNodeId?: string;
    /**
     * DOM Element to mount the modal into.
     *
     * If passed in, will take precedence over
     * the domNodeId argument and render into the
     * element directly.
     *
     * Since it is an overlay, it is recommended
     * to use something close to the root of the
     * DOM tree
     */
    domNode?: Element;
    /**
     * Optional parameter with the event id.
     *
     * If it exists, the email will contain information
     * about the event.
     */
    eventId?: string;
    /**
     * Optional parameter with the collection id.
     *
     * If it exists, the email will contain information
     * about the collection.
     */
    collectionId?: string;
}

export const ContactOrganizer: React.FunctionComponent<
    ContactOrganizerProps
> = (props) => {
    const {
        organizerId,
        eventId,
        collectionId,
        domNode,
        isVisible,
        onClose,
        captchaKey,
        domNodeId = 'root',
    } = props;

    return (
        <ModalErrorBoundary>
            <OverlayProvider
                isVisible={isVisible}
                onClose={onClose as Function}
            >
                <ContactOrganizerRenderer
                    domNodeId={domNodeId}
                    domNode={domNode}
                >
                    <ViewErrorBoundary>
                        <OrganizerContext.Provider value={organizerId}>
                            <EventContext.Provider value={{ eventId }}>
                                <CollectionContext.Provider
                                    value={{ collectionId }}
                                >
                                    <EnvContext.Provider value={{ captchaKey }}>
                                        <UserProvider
                                            isAuthenticated={
                                                props.isAuthenticated
                                            }
                                        >
                                            <FormProvider>
                                                <ViewController />
                                            </FormProvider>
                                        </UserProvider>
                                    </EnvContext.Provider>
                                </CollectionContext.Provider>
                            </EventContext.Provider>
                        </OrganizerContext.Provider>
                    </ViewErrorBoundary>
                </ContactOrganizerRenderer>
            </OverlayProvider>
        </ModalErrorBoundary>
    );
};
