import { SetEventSessionTimedEntryAction } from '../actions';
import type { EventBasicInformation } from '../types';

export const setEventSessionTimedEntry = (
    event: EventBasicInformation,
    { payload: { eventSessions } }: SetEventSessionTimedEntryAction,
): EventBasicInformation => {
    const updatedEvent = {
        ...event,
        currentEventSessionsTimedEntry: eventSessions,
    };
    return updatedEvent;
};
