import * as React from 'react';

const LogoMessengerSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.7 14.5l5.2-5.8-4.6 2.9-2.7-2.9-5.2 5.8 4.8-2.7 2.5 2.7zM12 2c5.5 0 10 4.1 10 9.2s-4.5 9.2-10 9.2c-1 0-2.1-.1-3-.4l-3.3 2v-3.6C3.4 16.7 2 14.1 2 11.2 2 6.1 6.5 2 12 2z"
        />
    </svg>
);

LogoMessengerSvg.displayName = 'LogoMessengerSvg';
export default LogoMessengerSvg;
