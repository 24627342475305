import React, { Dispatch, Reducer, useReducer } from 'react';
import { scrollReducer } from './reducer';
import { ScrollDispatchAction, ScrollState } from './types';

export const ScrollContext = React.createContext<ScrollState>({});
ScrollContext.displayName = 'ScrollContext';
export const ScrollContextDispatch = React.createContext<
    Dispatch<ScrollDispatchAction>
>((state) => state);
ScrollContextDispatch.displayName = 'ScrollContextDispatch';

type ScrollContextProviderProps = {};

export const ScrollContextProvider: React.FC<ScrollContextProviderProps> = ({
    children,
}) => {
    const [state, dispatch] = useReducer<
        Reducer<ScrollState, ScrollDispatchAction>
    >(scrollReducer, {});

    return (
        <ScrollContext.Provider value={state}>
            <ScrollContextDispatch.Provider value={dispatch}>
                {children}
            </ScrollContextDispatch.Provider>
        </ScrollContext.Provider>
    );
};
