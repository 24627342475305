import {
    EVENTBRITE_CATEGORY_TO_REFINEMENT,
    FORMATTED_SUBCATEGORY_MAP,
    SEASONAL_EVENTBRITE_CATEGORY,
    SUBCATEGORY_REFINEMENT_TO_KEY,
} from '@eventbrite/categories';
import { gettext } from '@eventbrite/i18n';
import { SeasonalBannerProps } from './types';

// Heap ID's
export const LISTING_HEAP_IDS = {
    RELATED_EVENTS_STRING: 'Related Events',
    CAROUSEL_CARD: 'Hero Content - Carousel card - Change',
    CHILD_EVENTS_DROPDOWN: 'Listing - Date - Child Events Dropdown - Click',
    CHECKOUT_BUTTON: 'Conversion Bar - Checkout Button',
    CREATOR_TOP_BANNER_EDIT: 'Creator Top Banner - Edit',
    CTA_RSVP_MAYBE: 'Listing - Conversion Bar - RSVP Maybe - Click',
    CTA_RSVP_NO: 'Listing - Conversion Bar - RSVP No - Click',
    DESCRIPTIVE_ORGANIZER:
        'Listing - Descriptive Organizer Panel - Follow - Click',
    EXPLORE_SIMILAR_EVENTS:
        'Listing - Conversion Bar - Explore Similar Events - Click',
    FAQS_DROPDOWN: 'FAQs - Dropdown',
    FOLLOW_BUTTON: 'Listing - Follow - Button - Click',
    HERO_VIDEO: 'Promo Video - Play',
    HIDE_MAP: 'Listing - Hide Map - Button - Click',
    IMAGE_CATEGORY_CLICK:
        'Listing - Expired Event Page - Click - Category Browse',
    IMAGE_CATEGORY_VIEW:
        'Listing - Expired Event Page - View - Category Browse',
    LEFT_ARROW_HERO_CAROUSEL: 'Hero Content - Hero Carousel - Left',
    MAP_DRIVE: 'Listing - Map - Drive - Click',
    MAP_WALK: 'Listing - Map - Walk - Click',
    MAP_BUS: 'Listing - Map - Bus - Click',
    MAP_BIKE: 'Listing - Map - Bike - Click',
    MAP_ZOOM_CHANGE: 'Listing - Map - Zoom - Change',
    MORE_COLLECTIONS_THAT_FEATURE_EVENT:
        'More Collections That Feature This Event - View more collections Button',
    MORE_OPTIONS_BUTTON:
        'Hero Content - More options button in Repeating events - Click',
    MORE_OPTIONS_CARD:
        'Hero Content - More options last card in Repeating events - Click',
    MORE_OPTIONS_DATE:
        'Hero Content - Change date confirmation in Calendar - Click',
    ONLINE_EVENT_ACCESS_BUTTON:
        'Listing - Online Location - Access the Event - Click',
    ORGANIZER_FACEBOOK:
        'Listing - Organizer Panel - Organizer Facebook - Click',
    ORGANIZER_NAME: 'Listing - Organizer Panel - Organizer Name - Click',
    ORGANIZER_TWITTER: 'Listing - Organizer Panel - Organizer Twitter - Click',
    ORGANIZER_WEBSITE: 'Listing - Organizer Panel - Organizer Website - Click',
    PART_OF_A_COLLECTION:
        'Hero Content - Part of /Featured in Collection/s button',
    RECCOMENDATIONS_VIEW: 'PersonalizedRelatedEventsView',
    RIGHT_ARROW_HERO_CAROUSEL: 'Hero Content - Hero Carousel - Right',
    SHARE_EVENT: 'Listing - Hero - Share - Click',
    SHOW_MAP: 'Listing - Show Map - Button - Click',
    SIMPLIFIED_ORGANIZER:
        'Listing - Simplified Organizer Panel - Follow - Click',
    UNFOLLOW_BUTTON: 'Listing - Following - Button - Click',
    FOLLOW_ORGANIZER: 'FollowOrganizerClick',
    FOLLOW_ORGANIZER_SUCCESS: 'FollowOrganizerSuccessful',
    UNFOLLOW_ORGANIZER: 'UnfollowOrganizerClick',
    UNFOLLOW_ORGANIZER_SUCCESS: 'UnfollowOrganizerSuccessful',
    HIGHLIGHT_LOCATION_CLICK: 'Highlights - Location anchor - Click',
    HIGHLIGHT_REFUND_CLICK: 'Highlights - Refund Service Info anchor - Click',
    ABOUT_THIS_EVENT_READ_MORE: 'Listings - Description - Read more - Click',
    ABOUT_THIS_EVENT_READ_LESS: 'Listings - Description - Read less - Click',
    TICKETS_ANCHOR_BUTTON_VIEW: 'ListingWeb - Tickets Anchor button - View',
    TICKETS_ANCHOR_BUTTON_CLICK: 'ListingWeb - Tickets Anchor button - Click',
    MULTITICKET_ERROR_VIEW: 'ListingWeb - Multiticket Error - View',
    ABOUT_THIS_EVENT_VIEW: 'ListingWeb - Description - View',
    TICKET_SECTION_VIEW: 'ListingWeb - Ticket Section - View',
    TICKET_CONTROL_SECTION_VIEW: 'ListingWeb - Ticket Section - View Control',
    ORGANIZER_PANEL_INFO_VIEW: 'ListingWeb - Organizer panel section - View',
    VIDEO_DURATION: 'ListingWeb - Video Duration',
    VIDEO_PLAYED: 'ListingWeb - Video Played',
    VIEW_RELIEF_RIBBON: 'ListingWeb - View - Relief Ribbon - DANA',
    TIMED_ENTRY_ANCHOR_BUTTON_CLICK:
        'ListingWeb - Select Date Anchor button - Click',
    G2K_VIEW: 'ListingWeb - Good to know - ListingGoodToKnowView - View',
    G2K_VIEW_CARD:
        'ListingWeb - Good to know - ListingGoodToKnowViewCard - View',
    G2K_READ_MORE_CLICK:
        'ListingWeb - Good to know - ListingGoodToKnowReadMore - Click',
    G2K_VIEW_ALL_CLICK:
        'ListingWeb - Good to know - ListingGoodToKnowViewAll - Click',
    FAQ_VIEW: 'ListingWeb - Good to know - FAQ - View',
    HIGHLIGHTS_VIEW: 'ListingWeb - Good to know - Highlights - View',
    REFUND_POLICY_VIEW: 'ListingWeb - Good to know - RefundPolicy - View',
};

export const LISTING_HEAP_PAGE_AREA_IDS = {
    SIMPLIFIED_ORGANIZER: 'listing-simplified-organizer',
    DESCRIPTIVE_ORGANIZER: 'listing-descriptive-organizer',
    HERO_CONTENT: 'listing-hero-content',
    ORGANIZER_PANEL: 'listing-organizer-panel',
    REMIND_ME_MODAL: 'listing-remind-me-modal',
    EXPIRED_EVENTS_PAGE: 'listing-expired-events-page',
    HYDRATE_FOLLOW_STATUS: 'listing-hydrate-follow-status',
    TOP_SECTION_MODAL: 'listing-top-section-modal', // surfacing rating experiment
};

// Seasonal Banner constants
export const SEASONAL_BANNER_CONTENT: { [key: string]: SeasonalBannerProps } = {
    HALLOWEEN: {
        title: gettext('Trick or Treat Yourself'),
        subtitle: gettext(
            'Nothing like a little hocus pocus to get into the Halloween spirit.',
        ),
        ctaLink: `/b/local/${
            EVENTBRITE_CATEGORY_TO_REFINEMENT[SEASONAL_EVENTBRITE_CATEGORY]
        }/${
            FORMATTED_SUBCATEGORY_MAP[
                SUBCATEGORY_REFINEMENT_TO_KEY[SEASONAL_EVENTBRITE_CATEGORY][
                    'halloweenhaunt'
                ]
            ]['refinement']
        }/?utm_source=listing_halloween_banner_click`,
        ctaText: gettext("Let's Go"),
    },
};

export const SALES_STATUS_ON_SALE = 'on_sale';

// Interface names
export const RELATED_EVENTS_PLACEMENT_NAME = 'related_events';

// Sub-interface names
export const EXPIRED_EVENTS_PLACEMENT_NAME = 'event_expired_page';
export const LIVE_EVENTS_PLACEMENT_NAME = 'events_carousel';
