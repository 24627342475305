import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import React from 'react';
import { EventDetailsSectionTitle } from '../EventDetails/EventDetails';
import './Tags.scss';

export type Tag = {
    link: string;
    text: string;
    tracking_action: string;
    tracking_category: string;
};

function isCreatorGenerated(tagText: string) {
    return tagText.startsWith('#');
}

export const TagsForLiveEvents = ({
    tags,
    isSoldOut,
    isSalesEnded,
}: {
    tags: Array<Tag>;
    isSoldOut: boolean;
    isSalesEnded: boolean;
}) => {
    if ((isSalesEnded && tags.length > 0) || (isSoldOut && tags.length > 0)) {
        return null;
    }

    return <Tags tags={tags} />;
};

export const TagsForExpiredEvents = ({
    tags,
    isSoldOut,
    isSalesEnded,
}: {
    tags: Array<Tag>;
    isSoldOut: boolean;
    isSalesEnded: boolean;
}) => {
    if ((!isSalesEnded && tags.length > 0) || (!isSoldOut && tags.length > 0)) {
        return null;
    }
    return <Tags tags={tags} customHeading="Related to this event" />;
};

export const Tags = ({
    tags,
    customHeading,
}: {
    tags: Array<Tag>;
    customHeading?: string;
}) => {
    if (!tags?.length) {
        return null;
    }

    return (
        <section aria-labelledby="tags-heading">
            <div className="eds-l-mar-bot-12">
                <EventDetailsSectionTitle>
                    <h3>
                        {gettext('%(tagTitle)s', {
                            tagTitle: customHeading || 'Tags',
                        })}
                    </h3>
                </EventDetailsSectionTitle>
                <ul>
                    {tags
                        .filter((tag: Tag) => !!tag?.link)
                        .map((tag: Tag, index) => (
                            <li key={index} className="tags-item">
                                <a
                                    {...(isCreatorGenerated(tag.text) && {
                                        rel: 'nofollow',
                                    })}
                                    href={tag.link}
                                    className="tags-link listing-tag eds-l-mar-top-4 eds-text-bs eds-text--center"
                                    onClick={() =>
                                        trackAnalyticsEvent({
                                            category: tag.tracking_category,
                                            action: tag.tracking_action,
                                            label: tag.link,
                                        })
                                    }
                                >
                                    {tag.text}
                                </a>
                            </li>
                        ))}
                </ul>
            </div>
        </section>
    );
};
