import * as React from 'react';

const CopyChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="copy-chunky_svg__eds-icon--copy-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="copy-chunky_svg__eds-icon--copy-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 8v12h12V8H4zm10 10H6v-8h8v8z"
        />
        <path
            id="copy-chunky_svg__eds-icon--copy-chunky_corner"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 5v10h-2V6h-8V4h10v1z"
        />
    </svg>
);

CopyChunkySvg.displayName = 'CopyChunkySvg';
export default CopyChunkySvg;
