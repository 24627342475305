import { FormattedEvent } from '@eventbrite/event-renderer';

import type { PaginationResponse } from './types/api';

const getLocationInfo = (venue?: {
    name: string;
    address?: { localized_area_display: string };
}) => {
    if (venue?.name && venue?.address?.localized_area_display) {
        return `${venue?.name} \u2022 ${venue?.address?.localized_area_display}`;
    }

    return venue?.name || venue?.address?.localized_area_display || '';
};

export interface EventOriginType {
    id: string;
    logo?: {
        url: string;
    };
    is_free: boolean;
    online_event: boolean;
    start: {
        utc: string;
        timezone: string;
        local: string;
        date_header?: string;
        formatted_time?: string;
    };
    name: {
        text: string;
    };
    saves?: {
        saved_by_you: boolean;
    };
    hide_start_date: boolean;
    url: string;
    event_sales_status?: {
        default_message: null | string;
        sales_status: string;
        message: null | string;
        message_code: null | string;
        message_type: null | string;
    };
    series_id?: string | null;
    image?: {
        edge_color_set: boolean;
        edge_color: string | null;
        url: string;
        original: {
            url: string;
            width: number;
            height: number;
        };
        crop_mask: {
            width: number;
            height: number;
            top_left: {
                y: number;
                x: number;
            };
        };
        aspect_ratio: string;
        id: string;
    };
    price_range?: string;
    ticket_availability?: {
        is_sold_out: boolean;
        is_free: boolean;
        minimum_ticket_price: {
            currency: string;
            major_value: string;
            value: number;
            display: string;
        };
        has_available_tickets: boolean;
        maximum_ticket_price: {
            currency: string;
            major_value: string;
            value: number;
            display: string;
        };
    };
    subtitle?: string;
    tickets_by?: string;
    organizer_id: string;
    primary_organizer?: {
        name: string;
        num_followers: number;
    };
    num_children?: number;
    series?: {
        url: string;
        counts: { current_future: number };
        next_dates: { start: string }[];
    };
    venue?: {
        age_restriction: string;
        user_id: string;
        name: string;
        longitude: string;
        address: {
            city: string;
            country: string;
            region: string;
            longitude: string;
            localized_address_display: string;
            postal_code: string;
            address_1: string;
            latitude: string;
            localized_multi_line_address_display: string[];
            localized_area_display: string;
        };
        latitude: string;
        capacity: 500;
        organizer_id: string;
        google_place_id: string;
        id: string;
    };
}

export interface EventsFromCollectionResponse {
    pagination: PaginationResponse;
    events: EventOriginType[];
}

export const transformCollectionEvent = (
    event: EventOriginType,
): FormattedEvent => {
    const [startDate, startTime] = event.start.local.split('T');

    const fullInstanceCount =
        event?.series?.counts?.current_future || event?.num_children;
    const upcomingRepeatingInstanceCount = fullInstanceCount
        ? fullInstanceCount - 1
        : fullInstanceCount;

    return {
        id: event.id,
        imageUrl: event?.logo?.url || '',
        isFree: event.is_free,
        isOnlineEvent: event.online_event,
        locationInfo: getLocationInfo(event?.venue),
        name: event?.name.text,
        savedByYou: !!event.saves?.saved_by_you,
        startDate: event.hide_start_date ? undefined : startDate,
        startTime: startTime,
        timezone: event.start.timezone,
        url: event.url,
        eventSalesStatus: {
            salesStatus: event?.event_sales_status?.sales_status || 'on_sale',
            message: event?.event_sales_status?.message || '',
            messageType: event?.event_sales_status?.message_type || '',
            messageCode: event?.event_sales_status?.message_code || '',
        },
        isSoldOut:
            event?.event_sales_status?.sales_status === 'sold_out' &&
            !event?.series_id,
        edgeColor: event?.image?.edge_color || undefined,
        formattedPriceString: event.is_free ? '' : event.price_range,
        minPrice: {
            currency:
                event?.ticket_availability?.minimum_ticket_price?.currency ||
                '',
            currencyFormat: '¤#,##0.00',
            minPriceValue:
                event?.ticket_availability?.minimum_ticket_price?.value || 0,
        },
        repeatingInstanceCount: upcomingRepeatingInstanceCount,
        subtitle: event.subtitle,
        ticketsBy: event.tickets_by,
        organizerId: event.organizer_id,
        organizerName: event?.primary_organizer?.name || '',
        organizerFollowerCount:
            event?.primary_organizer?.num_followers || undefined,
    };
};
