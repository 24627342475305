import {
    addMainControls,
    addOverlayControls,
    WithMainControlsProps,
    WithOverlayControlsProps,
} from '@eventbrite/eds-structure';
import React from 'react';
import type { ControlledProps, EnhancedAppProps } from '../../types';
import NotificationRoot from '../Notification/NotificationRoot';
import OverlayRoot from '../Overlay/OverlayRoot';
import RelatedEventsRoot from '../RelatedEvents/RelatedEventsRoot';

const ControlledListingComponents = (
    props: ControlledProps & WithMainControlsProps & WithOverlayControlsProps,
) => {
    return (
        <>
            <NotificationRoot {...props} />
            <RelatedEventsRoot {...props} />
            <OverlayRoot {...props} />
        </>
    );
};

export const EnhancedListingComponents = addOverlayControls<EnhancedAppProps>(
    addMainControls(ControlledListingComponents),
);
