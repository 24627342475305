import React from 'react';
import './Summary.scss';

type SummaryProps = {
    summary: string;
};

export const Summary = ({ summary }: SummaryProps) => {
    if (!summary) {
        return null;
    }
    return (
        <p className="summary">
            {/* Summary comes from the summary field in the creation flow */}
            <strong>{summary}</strong>
        </p>
    );
};
