import { useQuery } from '@tanstack/react-query';
import { fetchExpandedCollections } from '../../api';
import { transformCreatorCollection } from '../../transforms';
import {
    FormattedCreatorCollection,
    PublicCollectionsCamel,
} from '../../types';

export const useExpandCreatorCollections = (
    eventId: string,
    options: { enabled: boolean },
) => {
    const query = useQuery<PublicCollectionsCamel>(
        [eventId, 'public-collection-expand'],
        async () => {
            const data = await fetchExpandedCollections(eventId);

            const formattedData: FormattedCreatorCollection[] =
                data.collections.map(transformCreatorCollection);

            return {
                creatorCollections: {
                    collections: formattedData,
                    objectCount: formattedData.length,
                },
            };
        },
        {
            enabled: options.enabled,
        },
    );

    return query;
};
