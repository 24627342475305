import React from 'react';

type Props = {
    textAlign?: 'center' | 'start';
};

export const Banner: React.FC<Props> = ({ children, textAlign = 'start' }) => {
    return (
        <div
            className="eds-notification-bar eds-notification-bar eds-notification-bar--neutral"
            style={{ textAlign }}
            role="alert"
        >
            <div className="eds-notification-bar__content">
                <div className="eds-notification-bar__content-child eds-g-cell">
                    {children}
                </div>
            </div>
        </div>
    );
};
