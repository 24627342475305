import { compact, reduce } from 'lodash';

import { HAS_WINDOW } from '@eventbrite/feature-detection';

/**
 * reducePaths
 *
 * Joins paths together and short curcuits for an absolute path
 *
 * @param {string} base path to join additional paths to
 * @param {string} path path to join to base
 */
const reducePaths = (base, path) => {
    if (path && path.match && path.match(/^\//)) {
        return path;
    }
    return compact([
        base && base.replace && base.replace(/\/$/, ''),
        path,
    ]).join('/');
};

/**
 * join parts of a path together, respects absolute paths
 *
 * @param  {...string} parts items in the path to join together
 */
export const joinPath = (...parts) => reduce(parts, reducePaths, '');

/**
 * getUrlPath
 *
 * Get url pathname
 *
 * @return string url pathname
 */
export const getUrlPath = () => (HAS_WINDOW ? window.location.pathname : null);
