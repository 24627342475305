import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { useTicketContext } from './context';
import styles from './TicketCard.module.scss';

export const TicketDescription: React.FC = () => {
    const { description } = useTicketContext();

    if (!description) {
        return null;
    }

    return (
        <Typography
            as="p"
            variant="body-sm"
            color="ui-800"
            className={styles.description}
            data-testid="ticket-description"
        >
            {description}
        </Typography>
    );
};
