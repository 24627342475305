import React, { type Dispatch } from 'react';
import { reducer } from './reducer';
import type { CommunicationAction } from './types';

const CommunicationDispatch =
    React.createContext<Dispatch<CommunicationAction> | null>(null);
CommunicationDispatch.displayName = 'CommunicationDispatch';

/*
 * For now this Provider is only used to dispatch actions
 * In the future we might want to provide information here as well, we should create another context for that
 */
export const CommunicationProvider: React.FC = ({ children }) => {
    const [, dispatch] = React.useReducer(reducer, {});

    return (
        <CommunicationDispatch.Provider value={dispatch}>
            {children}
        </CommunicationDispatch.Provider>
    );
};

export const useCommunicationDispatch = () => {
    const context = React.useContext(CommunicationDispatch);
    if (!context) {
        throw new Error(
            'useCommunicationDispatch must be used within a CommunicationProvider',
        );
    }
    return {
        setCheckout: (iframe: HTMLIFrameElement) => {
            context({ type: 'setCheckoutIframe', payload: iframe });
        },
        sendNewQuantities: (quantities: Record<string, number>) => {
            context({ type: 'sendQuantities', payload: quantities });
        },
    };
};
