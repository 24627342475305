import { constants as datetimeConstants } from '@eventbrite/datetime-fns';
import { gettext } from '@eventbrite/i18n';
import { parse } from 'date-fns';
import React, { useState } from 'react';
import { HostPreview } from '../HostPreview/HostPreview';
import { Host, Slot } from '../types';
import {
    DescriptionDisplay,
    Divider,
    HostsArea,
    InnerWrapper,
    PlusOneDay,
    PreviewWrapper,
    TimeDisplay,
    TitleDisplay,
    ViewMoreButton,
} from './SlotPreview.styles';

interface Props {
    slot: Slot;
    slotIndex: number;
}

export const CHAR_LIMIT_FOR_DESCRIPTION = 200;
const CHAR_LIMIT_SUM_FOR_BREAKING_LINE_ON_HOSTS = 78;

const convertTimeStringToNumber = (timeString: string) =>
    Number(timeString.replace(':', ''));

export const convertTimeStringToLocaleString = (timeString: string) => {
    const date = parse(
        timeString,
        datetimeConstants.TIME_FORMAT_24HS,
        new Date(),
    );
    return date.toLocaleString([], { hour: 'numeric', minute: 'numeric' });
};

const renderTime = (startTime: string, endTime: string) => {
    if (startTime && !endTime) {
        return <>{convertTimeStringToLocaleString(startTime)}</>;
    }

    if (startTime && endTime) {
        const numericStartTime = convertTimeStringToNumber(startTime);
        const numericEndTime = convertTimeStringToNumber(endTime);
        const localeStartTime = convertTimeStringToLocaleString(startTime);
        const localeEndTime = convertTimeStringToLocaleString(endTime);

        return (
            <>
                {localeStartTime} - {localeEndTime}{' '}
                {numericEndTime - numericStartTime < 0 ? (
                    <PlusOneDay>({gettext('+1 day')})</PlusOneDay>
                ) : null}
            </>
        );
    }
};

export const SlotPreview: React.FC<Props> = ({
    slot: { description, title, endTime, startTime, hosts },
    slotIndex,
}) => {
    const [showMore, toggleShowMore] = useState(false);

    const constrainedDescription = () => {
        if (description.length <= CHAR_LIMIT_FOR_DESCRIPTION) {
            return description;
        } else if (showMore) {
            return (
                <>
                    {description}
                    <br />
                    <ViewMoreButton
                        data-testid="slot-preview__view-less"
                        aria-label={gettext(
                            'Click here to hide the full description',
                        ).toString()}
                        onClick={() => toggleShowMore(false)}
                    >
                        <span aria-hidden={true}>{gettext('View Less')}</span>
                    </ViewMoreButton>
                </>
            );
        } else {
            return (
                <>
                    {description.substring(0, CHAR_LIMIT_FOR_DESCRIPTION - 1)}
                    ...
                    <br />
                    <ViewMoreButton
                        data-testid="slot-preview__view-more"
                        aria-label={gettext(
                            'Click here to read full description of this element',
                        ).toString()}
                        onClick={() => toggleShowMore(true)}
                    >
                        <span aria-hidden={true}>{gettext('View More')}</span>
                    </ViewMoreButton>
                </>
            );
        }
    };

    return (
        <PreviewWrapper data-testid="SlotPreview" slotIndex={slotIndex}>
            <InnerWrapper slotIndex={slotIndex}>
                {startTime && (
                    <TimeDisplay data-testid="preview-slot__time">
                        {renderTime(startTime, endTime)}
                    </TimeDisplay>
                )}
                <TitleDisplay>{title}</TitleDisplay>
                {hosts?.length > 0 && (
                    <HostsArea
                        breakLine={
                            hosts.reduce(
                                (acc, host) => acc + host.name.length,
                                0,
                            ) >= CHAR_LIMIT_SUM_FOR_BREAKING_LINE_ON_HOSTS
                        }
                        data-testid="preview-slot__hosts-area"
                    >
                        {hosts.map((host: Host) => (
                            <HostPreview key={host.name} host={host} />
                        ))}
                    </HostsArea>
                )}
                {description && (
                    <>
                        <Divider />
                        <DescriptionDisplay data-testid="preview-slot__description">
                            {constrainedDescription()}
                        </DescriptionDisplay>
                    </>
                )}
            </InnerWrapper>
        </PreviewWrapper>
    );
};
