import { VerifiedBadge } from '@eventbrite/verified-badge';
import React from 'react';
import { ListingsAvatar } from './ListingsAvatar';
import styles from './ListingsAvatarBadge.module.scss';
import { IListingsAvatarWithBadgeProps } from './types';

export const ListingsAvatarWithBadge: React.FC<
    IListingsAvatarWithBadgeProps
> = ({
    showVerifiedBadge,
    organizerAvatar,
    organizerId,
    featureFlags,
    onClick,
}) => {
    const avatarJSX = (
        <ListingsAvatar
            featureFlags={featureFlags}
            organizerId={organizerId}
            organizerAvatar={organizerAvatar}
        />
    );

    return showVerifiedBadge ? (
        <VerifiedBadge
            addOnClasses={styles.listingsAvatarWithBadgeContainer}
            onClick={onClick}
        >
            {avatarJSX}
        </VerifiedBadge>
    ) : (
        avatarJSX
    );
};
