import React from 'react';
import './Detail.scss';

export type DetailProps = {
    ariaLabelledBy?: string;
};

export const ICON_SIZE = 'small';
export const ICON_BLOCK = true;

export const DetailGrid: React.FC = ({ children }) => {
    return <div className="detail-grid">{children}</div>;
};

export const Detail: React.FC<DetailProps> = ({ children }) => {
    return <div className="detail">{children}</div>;
};

export const DetailIcon: React.FC = ({ children }) => {
    return <div className="detail__icon">{children}</div>;
};

export const DetailContent: React.FC = ({ children }) => {
    return <div className="detail__content">{children}</div>;
};

export const DetailInner: React.FC = ({ children }) => {
    return <div className="detail__inner">{children}</div>;
};

export const DetailHeading: React.FC = ({ children }) => {
    return <div className="detail__heading">{children}</div>;
};
