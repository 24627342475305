import isNumber from 'lodash/isNumber';
import pick from 'lodash/pick';
import extend from 'lodash/extend';

import * as constants from './constants';

function _replaceDecimalNotation(decimalMatches, thousandsMatches) {
    var decimalNotation = constants.DEFAULT_DECIMAL_NOTATION;

    if (decimalMatches && thousandsMatches) {
        decimalNotation = decimalMatches[0].replace(
            constants.ZERO_GLOBAL_REGEX,
            '',
        );
    }

    return decimalNotation;
}

function _replaceThousandsNotation(decimalMatches, thousandsMatches) {
    var decimalNotation = constants.DEFAULT_THOUSANDS_NOTATION;

    if (decimalMatches && thousandsMatches) {
        decimalNotation = thousandsMatches[0].replace(
            constants.THOUSANDS_GLOBAL_REGEX,
            '',
        );
    }

    return decimalNotation;
}

function _getCurrencyPrecisionFromMatch(decimalMatches, decimalSeparator) {
    var precision = 0;

    if (decimalMatches) {
        precision = decimalMatches[0].split(decimalSeparator)[1].length;
    }

    return precision;
}

function _getSymbolSpace(currencyFormat) {
    var symbolSpace = '';

    if (
        currencyFormat.indexOf(constants.PRECEDING_SPACE_PLACEHOLDER) >= 0 ||
        currencyFormat.indexOf(constants.FOLLOWING_SPACE_PLACEHOLDER) >= 0
    ) {
        symbolSpace = ' ';
    }

    return symbolSpace;
}

function _getCurrencyInfo(currencyCode) {
    var currencyInfo =
        constants.CURRENCY_MAP[currencyCode || constants.DEFAULT_CURRENCY];

    if (!currencyInfo) {
        throw new Error(`Currency not supported: ${currencyCode}`);
    }

    return currencyInfo;
}

/**
 * Parses Babel currency format strings (see get_currency_format() in ebapps/i18n/helpers.py)
 * and returns an Object with currency/number display properties. These currency format strings
 * change based on locale, e.g:
 *
 *     en_US: ¤ #,##0.00
 *     es_ES: ¤ #.##0,00
 *     fr_CA: # ##0,00 ¤
 *
 * @param {string} currencyFormat The currency format to parse
 * @return {Object} Localized currency and number display properties:
 *
 *     {Boolean} isSymbolSuffix True if the symbol displays after the value
 *     {Boolean} hasSymbolSpace True if there's a space between the symbol and the value
 *     {string} decimalSeparator The locale's decimal separator
 *     {string} thousandsSeparator The locale's thousandsSeparator
 */
function _getCurrencyFormatInfo(currencyFormat) {
    var decimalMatches,
        thousandsMatches,
        decimalSeparator,
        thousandsSeparator,
        currFormat = currencyFormat || constants.DEFAULT_CURRENCY_FORMAT;

    decimalMatches = constants.DECIMAL_REGEX.exec(currFormat);
    thousandsMatches = constants.THOUSANDS_REGEX.exec(currFormat);
    decimalSeparator = _replaceDecimalNotation(
        decimalMatches,
        thousandsMatches,
    );
    thousandsSeparator = _replaceThousandsNotation(
        decimalMatches,
        thousandsMatches,
    );

    return {
        isSymbolSuffix: currFormat.indexOf(constants.SYMBOL_PLACEHOLDER) > 0,
        symbolSpace: _getSymbolSpace(currFormat),
        // Because we want to keep the number format valid, if not localized, at all times,
        // if either decimal or thousands separator can't be parsed, we default both to en_US
        precision: _getCurrencyPrecisionFromMatch(
            decimalMatches,
            decimalSeparator,
        ),
        decimal: decimalSeparator,
        thousand: thousandsSeparator,
        currencyFormat: currFormat,
    };
}

/**
 * Check and normalise the value of precision (must be positive integer)
 */
function _checkPrecision(val, base) {
    var preicisionValue = Math.round(Math.abs(val));

    return isNaN(preicisionValue) ? base : preicisionValue;
}

/**
 * Unformats currency string value into number
 *
 *     unformat('$5,212.40', '¤ #,##0.00');
 *     5212.4
 *
 * @param {string|number} formattedValue String containing a formatted number
 * @param {string} [currencyFormat] Format of currency, e.g. '¤ #,##0.00'
 * @return {number} Unformatted number
 */
export function unformat(formattedValue, currencyFormat) {
    var decimalSeparator = _getCurrencyFormatInfo(currencyFormat).decimal,
        unformattedValue,
        transformedValue,
        regex;

    if (isNumber(formattedValue)) {
        unformattedValue = parseFloat(formattedValue.toString());
    } else {
        // Build regex to strip out everything except digits, decimal point and minus sign:
        regex = new RegExp(`[^0-9-${decimalSeparator}]`, 'g');
        transformedValue = parseFloat(
            formattedValue
                .toString()
                .replace(constants.BRACKET_REGEX, '-$1')
                .replace(regex, '')
                .replace(decimalSeparator, constants.DEFAULT_DECIMAL_NOTATION),
        );
        unformattedValue = isNaN(transformedValue) ? 0 : transformedValue;
    }

    return unformattedValue;
}

/**
 * Implementation of toFixed() that treats floats more like decimals
 *
 * Fixes binary rounding issues (eg. (0.615).toFixed(2) === "0.61") that present
 * problems for accounting- and finance-related software.
 */
function _toFixed(value, precision) {
    var power = Math.pow(10, precision);

    // Multiply up by precision, round accurately, then divide and use native toFixed():
    return (Math.round(unformat(value) * power) / power).toFixed(precision);
}

/**
 * Based on the localized currency format of the page, default options for
 * _formatMoney() function, e.g:
 *
 *     _formatMoney(5212.4, getFormatMoneyOptions());
 *     $5,212.40
 *
 * @return {Object} Options to pass to Accounting.js's _formatMoney()
 */
function _getFormatMoneyOptions(format, curencyInfo) {
    var currencyFormat = _getCurrencyFormatInfo(format),
        symbolSpace = currencyFormat.symbolSpace,
        newFormat = `%s${symbolSpace}%v`;

    if (currencyFormat.isSymbolSuffix) {
        newFormat = `%v${symbolSpace}%s`;
    }

    return extend(
        {
            symbol: curencyInfo.symbol,
            format: newFormat,
        },
        pick(currencyFormat, constants.FORMAT_NUMBER_OPTIONS),
    );
}

/**
 * Parses a format string and returns format obj for use in rendering
 *
 * `format` is either a string with the default (positive) format
 * must contain '%v' (value) to be valid
 */
function _checkCurrencyFormat(format) {
    let currencyFormat = format;

    if (!currencyFormat || !currencyFormat.match('%v')) {
        currencyFormat = constants.DEFAULT_CURRENCY_SETTINGS.format;
    }

    return {
        pos: currencyFormat,
        neg: currencyFormat.replace('-', '').replace('%v', '-%v'),
        zero: currencyFormat,
    };
}

/**
 * Format a number, with comma-separated thousands and custom precision/decimal places
 *
 *     formatNumber(5212.4, '¤ #,##0.00');
 *     5,212.40
 *
 * @param {number|string} value The value to format
 * @param {string} [currencyFormat] Format of currency, e.g. '¤ #,##0.00'
 * @param {object} [options] Additional options to override currency format info
 * @return {string} Localized formatted money
 */
export function formatNumber(value, currencyFormat, options) {
    var unformattedNumber = unformat(value, currencyFormat),
        formatOptions = extend(_getCurrencyFormatInfo(currencyFormat), options),
        usePrecision = _checkPrecision(formatOptions.precision),
        negative = unformattedNumber < 0 ? '-' : '',
        base = parseInt(
            _toFixed(Math.abs(unformattedNumber), usePrecision),
            10,
        ).toString(),
        mod = base.length > 3 ? base.length % 3 : 0,
        formattedNumber = negative;

    if (mod) {
        formattedNumber += base.substr(0, mod) + formatOptions.thousand;
    }

    formattedNumber += base
        .substr(mod)
        .replace(
            constants.THOUSANDS_GROUP_GLOBAL_REGEX,
            `$1${formatOptions.thousand}`,
        );

    if (usePrecision) {
        formattedNumber +=
            formatOptions.decimal +
            _toFixed(Math.abs(unformattedNumber), usePrecision).split('.')[1];
    }

    return formattedNumber;
}

/**
 * Format a (minor) value into currency. Localize by overriding the symbol, precision, thousand / decimal separators and format
 * currency format for the page. Defaults: (0, '$', 2, ',', '.', '%s%v')
 *
 *     _formatMoney(5212.4);
 *     $5,212.40
 *
 * @param {number} minorValue The value to format
 * @param {object} options The currency format settings
 * @return {string} Localized formatted money
 */
function _formatMoney(minorValue, options) {
    var unformattedNumber = unformat(minorValue),
        formats = _checkCurrencyFormat(options.format),
        useFormat = formats.zero,
        formatOptions;

    if (unformattedNumber > 0) {
        useFormat = formats.pos;
    } else if (unformattedNumber < 0) {
        useFormat = formats.neg;
    }

    formatOptions = extend({}, options, {
        precision: _checkPrecision(options.precision),
    });

    // Return with currency symbol added
    return useFormat
        .replace('%s', options.symbol)
        .replace(
            '%v',
            formatNumber(
                Math.abs(unformattedNumber),
                formatOptions.format,
                formatOptions,
            ),
        );
}

/**
 * Converts a major value (an int) into a minor value (decimal) using the currency divisor
 *
 *     majorToMinor(52124, 'USD');
 *     5,212.4
 *
 * @param {number} majorValue The numeric major value to format
 * @param {string} [currencyCode] The currency code used convert to minor value
 * @return {number} Minor value
 */
export function majorToMinor(majorValue, currencyCode) {
    var currencyInfo = _getCurrencyInfo(currencyCode);

    return majorValue / currencyInfo.divisor;
}

/**
 * Converts a minor value (decimal) into a major value (an int) using the currency divisor
 *
 *     minorToMajor(521.24, 'USD');
 *     52124
 *
 * @param {number} minorValue The numeric minor value to format
 * @param {string} [currencyCode] The currency code used convert to major value
 * @return {number} Minor value
 */
export function minorToMajor(minorValue, currencyCode) {
    var currencyInfo = _getCurrencyInfo(currencyCode);

    return minorValue * currencyInfo.divisor;
}

/**
 * Format a minor value into currency. Localize by overriding the symbol, precision, thousand / decimal separators and format
 * currency format for the page. Defaults: (0, '$', 2, ',', '.', '%s%v')
 *
 *     formatMinorMoney(5212.40, 'USD', '¤#,##0.00');
 *     $5,212.40
 *
 *     formatMinorMoney(5200.50, 'USD', '#.##0,00 ¤'); // DE currencyFormat
 *     5.200,50$
 *
 * @param {number} unformattedMinorMoney The numeric major value to format
 * @param {string} [currencyCode] The currency code is used to convert to minor value and provide currency symbol
 * @param {string} [currencyFormat] The currency format is used to determine thousands & decimal separators, and position the currency symbol
 * @return {string} Localized formatted money
 */
export function formatMinorMoney(
    unformattedMinorMoney,
    currencyCode,
    currencyFormat,
) {
    var currencyInfo = _getCurrencyInfo(currencyCode);

    return _formatMoney(
        unformattedMinorMoney,
        _getFormatMoneyOptions(currencyFormat, currencyInfo),
    );
}

/**
 * Format a major value into currency. Localize by specifying currencyFormat
 *
 *     formatMajorMoney(521240, 'USD', '¤#,##0.00');
 *     $5,212.40
 *
 *     formatMajorMoney(520050, 'USD', '#.##0,00 ¤'); // DE currencyFormat
 *     5.200,50$
 *
 * @param {number} unformattedMajorValue The numeric major value to format
 * @param {string} [currencyCode] The currency code is used to convert to minor value and provide currency symbol
 * @param {string} [currencyFormat] The currency format is used to determine thousands & decimal separators, and position the currency symbol
 * @return {string} Localized formatted money
 */
export function formatMajorMoney(
    unformattedMajorValue,
    currencyCode,
    currencyFormat,
) {
    return formatMinorMoney(
        majorToMinor(unformattedMajorValue, currencyCode),
        currencyCode,
        currencyFormat,
    );
}

/**
 * Gets the currency symbol for the specified currency
 * @param {string} currencyCode The currency code (i.e. USD, EUR, etc)
 * @returns {string} The currency symbol (empty string if not found)
 */
export const getCurrencySymbol = (currencyCode) =>
    constants.CURRENCY_MAP[currencyCode]
        ? constants.CURRENCY_MAP[currencyCode].symbol
        : '';

/**
 * Gets the short currency symbol for the specified currency
 * @param {string} currencyCode The currency code (i.e. USD, EUR, etc)
 * @returns {string} The short currency symbol (empty string if not found)
 */
export const getAbbreviatedCurrencySymbol = (currencyCode) =>
    constants.CURRENCY_MAP[currencyCode]
        ? constants.CURRENCY_MAP[currencyCode].shortSymbol
        : '';

/**
 * Gets whether or not the currency symbol for the currency code is displayed as a suffix
 * @param {string} currencyCode The currency code (i.e. USD, EUR, etc)
 * @param {string} locale The locale name (i.e. en_US, fr_CA, etc)
 * @returns {boolean} Whether or not the currency symbol is a suffix
 */
export const isCurrencySymbolSuffix = (currencyCode, locale) => {
    if (currencyCode === 'EUR') {
        return locale in constants.CURRENCY_SYMBOL_IS_SUFFIX_FOR_LOCALE;
    }

    return (constants.CURRENCY_MAP[currencyCode] || { isSuffix: false })
        .isSuffix;
};

/**
 * Gets the precision of currency given the specified currency Code
 * @param {string} currencyCode The currency code (i.e. USD, EUR, etc)
 * @returns {number} The precision (number of decimal places after the decimal)
 */
export const getCurrencyPrecision = (currencyCode) => {
    let precision = 2;

    if (constants.CURRENCY_MAP[currencyCode]) {
        const { divisor } = constants.CURRENCY_MAP[currencyCode];

        // We need log10(divisor) but Math.log10 was added in ES2015
        // So we can use regular Math.log and divide by log(10)
        precision = Math.log(divisor) / Math.LN10;
    }

    return precision;
};
