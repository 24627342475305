import momentTZ from 'moment-timezone';

import { moment } from '@eventbrite/date';
import { gettext, ngettext } from '@eventbrite/i18n';
import { simpleStringHash } from '@eventbrite/string-utils';
import { formatMajorMoney } from '@eventbrite/intl';

import { FALLBACK_IMAGE_LIST } from './_internal/constants';

import {
    FREE_FLAG_TEXT,
    MOMENT_DATE_AND_TIME_FORMAT_STRING,
    MOMENT_DATE_FORMAT_STRING,
    MOMENT_DATE_FORMAT_STRING_ONLINE,
    MOMENT_DATE_AND_TIME_FORMAT_STRING_ONLINE,
    MOMENT_DATE_AND_TIME_FORMAT_STRING_ONLINE_OFFSET,
} from './constants';

/**
 * Format a date and time for display.  'Today' and 'Tomorrow' are displayed when appropriate.
 * @param {string} startDate - YYYY-MM-DD formatted date string
 * @param {string} startTime - HH:MM:SS formatted time string
 * @param {*} instanceCount - Number of upcoming repeating event instances
 */
export const getEventTimeString = (
    startDate,
    startTime,
    instanceCount,
    isOnlineEvent,
    timezone,
    userTimezone,
    shouldConvertToUserTimezone,
) => {
    let dateString = moment(startDate).calendar(null, {
        nextWeek: MOMENT_DATE_FORMAT_STRING,
        lastDay: MOMENT_DATE_FORMAT_STRING,
        lastWeek: MOMENT_DATE_FORMAT_STRING,
        sameElse: MOMENT_DATE_FORMAT_STRING,
    });

    if (startTime) {
        dateString = moment(`${startDate} ${startTime}`).calendar(null, {
            nextWeek: MOMENT_DATE_AND_TIME_FORMAT_STRING,
            lastDay: MOMENT_DATE_AND_TIME_FORMAT_STRING,
            lastWeek: MOMENT_DATE_AND_TIME_FORMAT_STRING,
            sameElse: MOMENT_DATE_AND_TIME_FORMAT_STRING,
        });
    }

    // Format for online events with a timezone. Note that unlike physical events we don't call .calendar(). This is because
    // we use .calendar() to display relative time information (like showing "Today" or "Tomorrow" instead of the actual date)
    // Since .calendar() doesn't work with timezones, we can't use it here.
    if (timezone && isOnlineEvent) {
        dateString = momentTZ
            .tz(startDate, timezone)
            .format(MOMENT_DATE_FORMAT_STRING_ONLINE);

        const willConvertToUserTimezone =
            shouldConvertToUserTimezone && userTimezone;

        if (willConvertToUserTimezone) {
            dateString = momentTZ
                .tz(startDate, timezone)
                .clone()
                .tz(userTimezone)
                .format(MOMENT_DATE_FORMAT_STRING_ONLINE);
        }

        if (startTime) {
            dateString = momentTZ
                .tz(`${startDate} ${startTime}`, timezone)
                .format(MOMENT_DATE_AND_TIME_FORMAT_STRING_ONLINE_OFFSET);

            if (willConvertToUserTimezone) {
                dateString = momentTZ
                    .tz(`${startDate} ${startTime}`, timezone)
                    .clone()
                    .tz(userTimezone)
                    .format(MOMENT_DATE_AND_TIME_FORMAT_STRING_ONLINE);
            }
        }
    }

    if (instanceCount) {
        //Translators: Sun, Dec 12th + more events
        //Translators: Sun, Dec 12th, 7:00pm + 10 more events
        //Translators: Sun, Dec 12th, 4:00 + 4 more events
        dateString = ngettext(
            '%(dateString)s + %(instanceCount)s more event',
            '%(dateString)s + %(instanceCount)s more events',
            instanceCount,
            { dateString, instanceCount },
        );
    }

    return dateString;
};

/*
@param {object} minPrice - Object containing the data for properly formatting price string
@param {bool} isFree - Flag which sets if the event is a free event or not
*/
export const getPriceInfo = (minPrice = {}, isFree) => {
    const { minPriceValue, currency, currencyFormat } = minPrice;

    let priceString;

    if (minPriceValue <= 0 && !isFree) {
        return priceString;
    }

    if (minPriceValue && currency) {
        const formattedMoney = formatMajorMoney(
            minPriceValue,
            currency,
            currencyFormat,
        );

        priceString = gettext('Starts at %(valueString)s', {
            valueString: formattedMoney,
        });
    }

    if (isFree) {
        priceString = FREE_FLAG_TEXT;
    }

    return priceString;
};

/*
@param {string} minPrice - Object containing the data for properly formatting price string
@param {bool} isFree - Flag which sets if the event is a free event or not
@param {string} ticketsBy - The source of the ticket
*/
export const getFormattedPriceString = (minPrice, isFree, ticketsBy) => {
    const priceString = getPriceInfo(minPrice, isFree);

    let formattedPriceString;
    let formattedTicketsByString;

    if (priceString) {
        formattedPriceString = priceString;

        if (ticketsBy && ticketsBy !== 'Eventbrite') {
            formattedTicketsByString = gettext('on %(ticketsBy)s', {
                ticketsBy,
            });
        }
    } else if (ticketsBy && ticketsBy !== 'Eventbrite') {
        formattedTicketsByString = gettext('On %(ticketsBy)s', { ticketsBy });
    }

    if (priceString && formattedTicketsByString) {
        formattedPriceString = gettext(
            '%(priceString)s • %(formattedTicketsByString)s',
            { priceString, formattedTicketsByString },
        );
    }

    if (!priceString && formattedTicketsByString) {
        formattedPriceString = formattedTicketsByString;
    }

    return formattedPriceString;
};

/*
@param {string} id - Event ID
Returns a random fallback image randomized on the given events ID
*/
export const getRandomFallbackImage = (id) =>
    FALLBACK_IMAGE_LIST[simpleStringHash(id) % FALLBACK_IMAGE_LIST.length];
