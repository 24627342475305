import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

export interface StepperButtonProps {
    ariaLabel?: string;
    dataTestid?: string;
    onClick: () => void;
    isAppRebrandActive?: boolean;
    isDisabled?: boolean;
    children: React.ReactNode;
    disabledCallback?: () => void;
}
/**
 * This component draw a button that can be enabled or disabled
 */
export const StepperButton: FunctionComponent<StepperButtonProps> = ({
    ariaLabel,
    children,
    dataTestid,
    isAppRebrandActive = false,
    isDisabled = false,
    onClick,
    disabledCallback,
}) => {
    const cssClasses = classNames('eds-stepper-button', {
        'eds-stepper-button--disabled': isDisabled,
        'rebranding--active': isAppRebrandActive,
    });

    const handleOnClick = () =>
        isDisabled ? disabledCallback && disabledCallback() : onClick();

    return (
        <button
            aria-disabled={isDisabled}
            disabled={disabledCallback ? false : isDisabled}
            aria-label={ariaLabel}
            data-testid={dataTestid}
            type="button"
            className={cssClasses}
            onClick={handleOnClick}
        >
            {children}
        </button>
    );
};
