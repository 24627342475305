import { Badge, Typography } from '@eventbrite/marmalade';
import { logEvent } from '@eventbrite/statsig';
import classNames from 'classnames';
import React from 'react';
import { LISTING_HEAP_IDS } from '../../../../../constants';
import { STATSIG_EVENT_NAMES } from '../../../../../experimentation';
import styles from './DateCard.module.scss';
import { SliderCard } from './SliderCard';

type DatesToDisplay = {
    day: string;
    month: string;
    monthAbbr: string;
    time: string;
    weekDay: string;
    weekDayAbbr: string;
    year: string;
    fullDate: string;
};

interface Props {
    isSoldOut?: boolean;
    url: string;
    datesToDisplay: DatesToDisplay;
    selected: boolean;
    eventId: string;
    onClick?: () => void;
    isTimedEntryEnabled?: boolean;
}

export const DateCard: React.FC<Props> = ({
    isSoldOut = false,
    selected,
    datesToDisplay,
    onClick,
    isTimedEntryEnabled,
}) => {
    const { fullDate } = datesToDisplay;

    return (
        <SliderCard selected={selected} disabled={isSoldOut}>
            {isSoldOut ? (
                <DateCardDisplay
                    isSoldOut={isSoldOut}
                    dateToDisplay={datesToDisplay}
                    selected={selected}
                    isTimedEntryEnabled={isTimedEntryEnabled}
                />
            ) : (
                <ClickableElement
                    ariaLabel={fullDate}
                    ariaCurrent={
                        selected ? 'date' : (false as boolean | 'date')
                    }
                    onClick={onClick}
                >
                    <DateCardDisplay
                        isSoldOut={isSoldOut}
                        dateToDisplay={datesToDisplay}
                        selected={selected}
                    />
                </ClickableElement>
            )}
        </SliderCard>
    );
};

type ClickableElementProps = {
    children: React.ReactNode;
    ariaLabel: string;
    ariaCurrent: boolean | 'date';
    onClick?: () => void;
};

const ClickableElement: React.FC<ClickableElementProps> = ({
    children,
    ariaLabel,
    ariaCurrent,
    onClick,
}) => {
    const commonProps = {
        'aria-label': ariaLabel,
        'aria-current': ariaCurrent,
        'data-heap-id': LISTING_HEAP_IDS.CAROUSEL_CARD,
        className: styles.root,
    };

    return (
        <button
            onClick={() => {
                onClick && onClick();
                logEvent(STATSIG_EVENT_NAMES.CAROUSEL_CARD);
            }}
            {...commonProps}
        >
            {children}
        </button>
    );
};

type DateCardDisplayProps = {
    isSoldOut: boolean;
    dateToDisplay: DatesToDisplay;
    selected: boolean;
    isTimedEntryEnabled?: boolean;
};

const DateCardDisplay: React.FC<DateCardDisplayProps> = ({
    isSoldOut,
    dateToDisplay,
    selected,
    isTimedEntryEnabled,
}) => {
    const {
        fullDate,
        day,
        month,
        monthAbbr,
        time,
        weekDay,
        weekDayAbbr,
        year,
    } = dateToDisplay;

    const formattedMonth =
        new Date().getFullYear().toString() === year
            ? month
            : `${monthAbbr} ${year}`;

    const formattedWeekDay = weekDay.length <= 9 ? weekDay : weekDayAbbr;
    const colourForSoldOut = isTimedEntryEnabled
        ? 'neutral-400'
        : 'neutral-800';
    let header;
    const dateCardWithPaddingClassname = `${styles.root} ${
        !isSoldOut && styles.dateCardPadding
    }`;

    if (isSoldOut) {
        header = (
            <Badge
                className={styles.soldOutBadge}
                variant={'info'}
                bordered={false}
            >
                <Typography as="p" color="neutral-900" variant="body-md-bold">
                    {'Sold out'}{' '}
                </Typography>
            </Badge>
        );
    } else {
        header = (
            <Typography as="span" color="neutral-900" variant="body-md-bold">
                {formattedWeekDay}{' '}
            </Typography>
        );
    }
    return (
        <div
            aria-label={fullDate}
            aria-current={selected ? 'date' : false}
            className={dateCardWithPaddingClassname}
            data-heap-id={LISTING_HEAP_IDS.CAROUSEL_CARD}
        >
            <time dateTime={fullDate}>
                <div className={`${styles.dateCardSection} ${styles.weekDay}`}>
                    {header}
                </div>
                {!isSoldOut && <hr className={styles.divider} />}
                <div className={styles.dateCardSection}>
                    <Typography as="span" color="neutral-700" variant="body-md">
                        {formattedMonth}{' '}
                    </Typography>
                </div>
                <div className={styles.dateCardSection}>
                    <div
                        className={classNames(styles.day, {
                            [styles.daySelected]: selected,
                            [styles.dayDisabled]: isSoldOut,
                        })}
                    >
                        <Typography
                            as="span"
                            color={selected ? 'white' : `${colourForSoldOut}`}
                            variant="body-lg"
                        >
                            {day}{' '}
                        </Typography>
                    </div>
                </div>
                <div className={styles.dateCardSection}>
                    <div>
                        <Typography
                            as="span"
                            color="neutral-700"
                            variant="body-md"
                        >
                            {time}
                        </Typography>
                    </div>
                </div>
            </time>
        </div>
    );
};
