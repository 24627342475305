import React from 'react';
import { ReadMoreProvider } from './context';
import { ReadMoreProps } from './types';

export { ReadMoreContent } from './ReadMoreContent';
export { ReadMoreToggle } from './ReadMoreToggle';

export const ReadMore: React.FunctionComponent<ReadMoreProps> = ({
    children,
    initialIsExpanded,
    ariaLabel,
}) => {
    return (
        <ReadMoreProvider isExpanded={initialIsExpanded} ariaLabel={ariaLabel}>
            {children}
        </ReadMoreProvider>
    );
};
