export interface Host {
    name: string;
    image?: {
        id?: string;
        url?: string;
    };
}

export interface Slot {
    title: string;
    startTime: string;
    endTime: string;
    hosts: Host[];
    description: string;
}

export interface Tab {
    name: string;
    slots: Slot[];
}

export interface AgendaWidget {
    tabs: Tab[];
}

export enum AnimationDirection {
    right,
    left,
}
