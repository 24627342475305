import React from 'react';
import type { Event, EventHeroScopedProps } from '../../../types';
import './EventHero.scss';
import { HeroCarousel } from './HeroCarousel';

export type EventHeroProps = {
    eventName: Event['name'];
} & EventHeroScopedProps &
    Pick<Event, 'hasCroppedLogo'>;

export const EventHero = ({
    mainBodyBackground: { croppedLogoUrl50 },
    items = [],
    eventName,
    featuredVideo,
    nativeVideo,
}: EventHeroProps) => {
    const shouldShowEventHero =
        items.length > 0 || featuredVideo || nativeVideo;
    const backgroundImage =
        croppedLogoUrl50 ||
        nativeVideo?.thumbnail ||
        featuredVideo?.thumbnail?.small?.url;

    if (!shouldShowEventHero) {
        return null;
    }

    return (
        <div className="event-hero-wrapper" data-testid="event-hero">
            <div className="event-hero">
                <div
                    className="event-hero__background"
                    style={{
                        backgroundImage: `url("${backgroundImage}")`,
                    }}
                />
                <HeroCarousel
                    eventName={eventName}
                    items={items}
                    featuredVideo={featuredVideo}
                    nativeVideo={nativeVideo}
                />
            </div>
        </div>
    );
};
