import React from 'react';
import { gettext } from '@eventbrite/i18n';
import noop from 'lodash/noop';
import { CopyField } from '@eventbrite/eds-copy-field';
import { ShareBox } from '@eventbrite/eds-share-box';
import { SHARE_BY_COPY } from './constants';
import { formatUrl } from 'url-lib';
import { keysCamelToSnake } from '@eventbrite/transformation-utils';
export class ShareModalContent extends React.Component {
    // TODO: Add prop types

    render() {
        const { shareOptions } = this.props;
        const { eventUrl, eventId, utmOptions, onClick = noop } = shareOptions;

        return (
            <section className="eds-g-cell eds-g-cell-1-1 eds-g-cell--has-overflow">
                <div className="eds-align--center-horizontal eds-l-mar-top-8">
                    <ShareBox {...shareOptions} />
                </div>
                <div className="eds-l-mar-top-8">
                    <CopyField
                        inputFieldId={`event-url-field-${eventId}`}
                        inputFieldStyle="static"
                        label={gettext('Event URL')}
                        value={formatUrl(
                            eventUrl,
                            keysCamelToSnake({
                                ...utmOptions,
                                aff: 'ebdsshcopyurl',
                            }),
                        )}
                        eventId={eventId}
                        onCopyClick={() => {
                            onClick(SHARE_BY_COPY);
                        }}
                    />
                </div>
            </section>
        );
    }
}

export default ShareModalContent;
