import { sdkRequest } from '@eventbrite/http';

// See here all the possible email names
// https://github.com/eventbrite/core/blob/master/django/src/www/eventbrite/ebapps/email/__init__.py#L281
const LIKED_EVENT_REMINDERS_EMAIL = 'liked_event_reminders_email';
const REMIND_ME_EMAIL = 'remind_me_email';
const emailToEmailName = {
    remind_me: REMIND_ME_EMAIL,
    liked_event: LIKED_EVENT_REMINDERS_EMAIL,
};

const emailPreferencesRootURL = (userId) =>
    `/api/v3/user/${userId}/email_preferences/`;
const emailPreferenceGetURL = (email, userId) =>
    `${emailPreferencesRootURL(userId)}?email_names=${emailToEmailName[email]}`;

export const followOrganizerEmailChannelId = '23';
export const followOrganizerNotificationChannelId = '10004';

export const followCollectionEmailChannelId = '26';
export const followCollectionNotificationChannelId = '30004';

export const newsletterEmailChannelId = '3';

export const followOrganizerSource = 'follow-organizer-gpdr-modal';
export const followCollectionSource = 'follow-collection-gdpr-modal';
export const newsletterSource = 'onboarding-newsletter';

export const commSettingsRootURL = (userId) =>
    `/api/v3/message/users/${userId}/comm_settings/`;

export const followOrganizerChannelsGetURL = (userId) =>
    `${commSettingsRootURL(
        userId,
    )}?channel_ids=${followOrganizerEmailChannelId},${followOrganizerNotificationChannelId}&source=${followOrganizerSource}`;

export const followCollectionChannelsGetURL = (userId) =>
    `${commSettingsRootURL(
        userId,
    )}?channel_ids=${followCollectionEmailChannelId},${followCollectionNotificationChannelId}&source=${followCollectionSource}`;

export const getFollowOrganizerStatus = (userId) =>
    sdkRequest(followOrganizerChannelsGetURL(userId));

export const setFollowOrganizerStatus = (userId, newState) =>
    setFollowOrganizerCommSettings(userId, newState); //  POST A API COMM SETTINGS

// If the user has explicitly opted in or out for receiving notifications from an organizer
const explicitOptIn = 1;
const explicitOptOut = 2;

export const setFollowOrganizerCommSettings = (userId, newState) => {
    const globalPreference = newState ? explicitOptIn : explicitOptOut;
    return sdkRequest(commSettingsRootURL(userId), {
        method: 'POST',
        body: JSON.stringify({
            preferences: [
                {
                    channel_id: followOrganizerEmailChannelId,
                    global_preference: globalPreference,
                },
                {
                    channel_id: followOrganizerNotificationChannelId,
                    global_preference: globalPreference,
                },
            ],
            source: followOrganizerSource,
        }),
    });
};

export const setFollowCollectionCommSettings = (userId, newState) => {
    const globalPreference = newState ? explicitOptIn : explicitOptOut;
    return sdkRequest(commSettingsRootURL(userId), {
        method: 'POST',
        body: JSON.stringify({
            preferences: [
                {
                    channel_id: followCollectionEmailChannelId,
                    global_preference: globalPreference,
                },
                {
                    channel_id: followCollectionNotificationChannelId,
                    global_preference: globalPreference,
                },
            ],
            source: followCollectionSource,
        }),
    });
};
export const getFollowCollectionStatus = (userId) =>
    sdkRequest(followCollectionChannelsGetURL(userId));

export const setFollowCollectionStatus = (userId, newState) =>
    setFollowCollectionCommSettings(userId, newState); //  POST A API COMM SETTINGS

// Liked Events Reminder Email
export const getLikedEventStatus = (userId) =>
    sdkRequest(emailPreferenceGetURL('liked_event', userId));
export const setLikedEventStatus = (userId, newState) =>
    sdkRequest(emailPreferencesRootURL(userId), {
        method: 'POST',
        body: JSON.stringify({
            email_name: emailToEmailName['liked_event'],
            can_send: !!newState,
        }),
    });

// Remind Me Email
export const getRemindMeStatus = (userId) =>
    sdkRequest(emailPreferenceGetURL('remind_me', userId));
export const setRemindMeStatus = (userId, newState) =>
    sdkRequest(emailPreferencesRootURL(userId), {
        method: 'POST',
        body: JSON.stringify({
            email_name: emailToEmailName['remind_me'],
            can_send: !!newState,
        }),
    });

// Send Newsletter
export const setNewsletterStatus = (
    userId,
    newState,
    source = newsletterSource,
) => setNewsletterCommSettings(userId, newState, source); //  POST A API COMM SETTINGS
export const setNewsletterCommSettings = (userId, newState, source) => {
    const globalPreference = newState ? explicitOptIn : explicitOptOut;
    return sdkRequest(commSettingsRootURL(userId), {
        method: 'POST',
        body: JSON.stringify({
            preferences: [
                {
                    channel_id: newsletterEmailChannelId,
                    global_preference: globalPreference,
                },
            ],
            source,
        }),
    });
};

export const newsletterChannelsGetURL = (userId) =>
    `${commSettingsRootURL(
        userId,
    )}?channel_ids=${newsletterEmailChannelId}&source=${newsletterSource}`;

export const getNewsletterStatus = (userId) =>
    sdkRequest(newsletterChannelsGetURL(userId));
