import { gettext, ngettext } from '@eventbrite/i18n';
import { HighlightDurationValue } from './types';

// ngettext uses gettext and needs for it to be imported
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ignore = gettext;

export const formatDuration = (duration: HighlightDurationValue) => {
    const daysText = String(
        ngettext('%(days)s day', '%(days)s days', duration.days, {
            days: duration.days,
        }),
    );
    const hoursText = String(
        ngettext('%(hours)s hour', '%(hours)s hours', duration.hours, {
            hours: duration.hours,
        }),
    );
    const minutesText = String(
        ngettext(
            '%(minutes)s minute',
            '%(minutes)s minutes',
            duration.minutes,
            {
                minutes: duration.minutes,
            },
        ),
    );

    let durationText: string;

    if (duration.days) {
        if (duration.hours) {
            durationText = `${daysText} ${hoursText}`;
        } else {
            durationText = daysText;
        }
    } else if (duration.hours) {
        if (duration.minutes) {
            durationText = `${hoursText} ${minutesText}`;
        } else {
            durationText = hoursText;
        }
    } else {
        durationText = minutesText;
    }

    return gettext('Event lasts %(durationText)s', {
        durationText,
    });
};
