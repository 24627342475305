const ITERATOR_SYMBOL = typeof Symbol === 'function' && Symbol.iterator;
const FAUX_ITERATOR_SYMBOL = '@@iterator';

/**
 * GenericLazyString is a constructor function that will create instances of objects whose toString method will
 * resolve the provided 'translateFn'. It is intended to be used in situation where you declare a string, for example,
 * at the top level of an ES6 module, but you do not need its value until later in your application lifecyle. A good use
 * case of this, will be translations, where you might declare a literal string in a constants file but you won't know the
 * language the user prefers until later in the loading process of your application.
 *
 * @param {Function} translateFn a function without arguments to be called whenever toString of a GenericLazyString instance is called.
 */
export class GenericLazyString extends String {
    static isValid(obj: any) {
        return obj !== 'undefined' && typeof obj.__msgid !== 'undefined';
    }

    translateFn: () => string;
    __msgid: string;
    _called: boolean | null = null;

    constructor(translateFn: () => string) {
        super();

        this.translateFn = translateFn;
        this.__msgid = this.toString();
    }

    toString() {
        return this.translateFn();
    }

    /**
     * We save the msgid here so that Jest's snapshot tests can assert on the string passed in.
     * Also, removing this would be a breaking change given this is looked at in many tests.
     *
     * Lastly, this is used to ensure that we are a valid LazyString
     */

    // EB-64381: We need to override the iterator because React thinks that the lazy string object
    // is an array-like object and would render each string individually
    // https://github.com/facebook/react/blob/master/src/renderers/shared/stack/reconciler/traverseStackChildren.js
    // React uses `FAUX_ITERATOR_SYMBOL` when `Symbol.iterator` doesn't exist (IE) so we
    // should be covered in all environments.
    // https://github.com/facebook/react/blob/e932ad68bed656eed5295b61ba74e5d0857902ed/src/isomorphic/children/ReactChildren.js#L22-L23
    [ITERATOR_SYMBOL || FAUX_ITERATOR_SYMBOL]() {
        this._called = false;

        return {
            next: () => {
                if (!this._called) {
                    this._called = true;
                    return { value: this.toString() };
                }

                return { done: true };
            },
        };
    }
}
