import React, { RefObject } from 'react';

export const useIntersecting = ({
    domNode,
    ref,
    callback,
    rootMargin = '400px',
}: {
    domNode?: HTMLElement | null;
    ref?: RefObject<HTMLElement>;
    rootMargin?: string;
    callback?: () => HTMLElement | null;
}) => {
    const [isIntersecting, setIsIntersecting] = React.useState(false);

    React.useEffect(() => {
        const element = callback?.() || domNode || ref?.current;
        let observerNode: IntersectionObserver;

        const loadIntersectionObserver = async () => {
            if (element) {
                const onIntersection = (
                    entries: IntersectionObserverEntry[],
                ) => {
                    if (entries[0].intersectionRatio <= 0) return;

                    setIsIntersecting(true);
                    observerNode.disconnect();
                };
                observerNode = new IntersectionObserver(onIntersection, {
                    rootMargin,
                });

                observerNode.observe(element);
            }
        };

        loadIntersectionObserver();

        return () => {
            observerNode?.disconnect();
        };
    }, [domNode, ref, isIntersecting, rootMargin, callback]);

    return [isIntersecting];
};
