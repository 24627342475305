import * as React from 'react';

const DefaultAbstractProfileThreeSvg = (
    props: React.SVGProps<SVGSVGElement>,
) => (
    <svg viewBox="0 0 40 40" fill="none" {...props}>
        <circle cx={20} cy={20} r={20} fill="#F7B9C1" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.607 1.043c8.735 7.228 17.84 15.145 18.306 16.197 1.08 2.444-8.125.263-14.381-1.219-2.326-.55-4.244-1.005-5.076-1.09-2.275-.232-.055 4.856 1.986 9.535 1.855 4.254 3.563 8.17 1.616 7.447A220.99 220.99 0 01.786 25.57 20.009 20.009 0 010 20C0 11.19 5.697 3.71 13.607 1.043z"
            fill="#E95B6E"
        />
    </svg>
);

DefaultAbstractProfileThreeSvg.displayName = 'DefaultAbstractProfileThreeSvg';
export default DefaultAbstractProfileThreeSvg;
