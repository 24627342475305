import { HAS_WINDOW } from '@eventbrite/feature-detection';

export const DescriptionDefault = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a nisi dui. Praesent orci urna, sagittis id pellentesque eu, volutpat sit amet dolor. Quisque ut mauris cursus ante congue eleifend a et lacus. Integer risus massa, euismod eget egestas eget, tempus et magna. Donec vitae tortor pretium, varius turpis in, rutrum odio. Sed blandit, metus non iaculis aliquam, elit nisi dapibus quam, sit amet mattis massa turpis quis dolor. Vestibulum id egestas felis. Aenean pretium imperdiet enim sit amet finibus. Morbi id lorem in felis fringilla tincidunt a in ex. Pellentesque dapibus molestie tortor, at commodo metus euismod sed.`;

export const CLICK_ACTIVATION_TRIAL_SUBSCRIPTION_ACTION =
    'Clicked Subscription';
export const ON_TRIAL_ACTIVATION_SUBSCRIPTION_BANNER_FEATURE = '';
export const CATEGORY_TRIAL_ACTIVATION_SUBSCRIPTION = 'Subscription activation';
export const domainUrl = HAS_WINDOW
    ? window.location.origin
    : 'https://www.eventbrite.com';
