import { Button } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { trackEvent } from '../analytics/analytics';
import {
    AMPLITUDE_GA_TRACKING_CATEGORY,
    CLICK_SUBSCRIPTION_BANNER_SUBSCRIPTION_ACTION,
    ON_TRIAL_SUBSCRIPTION_BANNER_FEATURE,
} from '../constants';
import '../MarketingHubTrialBanner/MarketingHubTrialBanner.scss';

export const TrialTitle = (trialEndDays: number) =>
    gettext('Your free trial ends in %(trialEndDays)s', {
        trialEndDays,
    }).toString();
export const TrialDescription = gettext(
    'Subscribe to Boost to increase your ticket sales and keep your audience engaged.',
).toString();

const onSubscriptionClick = (history: RouteComponentProps['history']) => {
    trackEvent({
        category: AMPLITUDE_GA_TRACKING_CATEGORY,
        action: CLICK_SUBSCRIPTION_BANNER_SUBSCRIPTION_ACTION,
        path: history.location.pathname,
        feature: ON_TRIAL_SUBSCRIPTION_BANNER_FEATURE,
    });
    history.push('/settings/billing/subscribe?returnUrl=true');
};

export interface Buttons {
    history: RouteComponentProps['history'];
}

export const TrialButtons = (props: Buttons) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-evenly',
            }}
        >
            <Button
                style="link"
                __containerClassName={
                    'eds-text-bm eds-text-weight--heavy marketing-hub-footer-banner__btn eds-l-pad-all-4'
                }
                onClick={() => onSubscriptionClick(props.history)}
            >
                {gettext('Subscribe now')}
            </Button>
        </div>
    );
};

export const TrialBannerIcon = (
    <svg
        width="53"
        height="53"
        viewBox="0 0 53 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="26.5" cy="26.5" r="26.5" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.9993 14L17 32.2084H27V42L37 23.8084L26.9993 23.8V14Z"
            fill="#3659E3"
        />
    </svg>
);
