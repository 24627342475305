import React from 'react';
import { FollowButtonContext } from './context';
import './FollowButton.scss';

export const useFollowButtonModal = () => {
    const followButtonProps = React.useContext(FollowButtonContext);
    return {
        shouldShowLoginModal: followButtonProps?.shouldShowLoginModal || false,
        closeLoginModal: followButtonProps?.closeLoginModal,
        shouldShowGDPRModal: followButtonProps?.shouldShowGDPRModal || false,
        closeGDPRModal: followButtonProps?.closeGDPRModal,
        userId: followButtonProps?.userId,
    };
};
