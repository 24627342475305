import { Button } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import React, { useContext } from 'react';
import { ReadMoreContext } from './context';
import './ReadMore.scss';
import { ReadMoreToggleProps } from './types';

const handleOnClick = (state: boolean, callback?: Function) => {
    callback?.(state);
};

const defaultLabels = {
    labels: {
        hide: gettext('View less'),
        show: gettext('View more'),
    },
};

export const ReadMoreToggle: React.FunctionComponent<ReadMoreToggleProps> = (
    props,
) => {
    const { isExpanded, setExpanded, isClamped } = useContext(ReadMoreContext);

    if (!isClamped) {
        return null;
    }

    return (
        <div className="read-more__toggle">
            <Button
                style="none"
                data-testid="read-more-toggle"
                onClick={() => {
                    handleOnClick(!isExpanded, setExpanded);
                }}
            >
                <span className="eds-link">
                    {isExpanded ? props.labels?.hide : props.labels?.show}
                </span>
            </Button>
        </div>
    );
};

ReadMoreToggle.defaultProps = defaultLabels;
