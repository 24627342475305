import { getWindowObject } from '@eventbrite/feature-detection';
import { SetEventURLAction } from '../actions';
import { EventBasicInformation } from '../types';

const getURLPath = (url: string) => {
    const urlObject = new URL(url);

    const location = getWindowObject('location');
    return urlObject.pathname + location?.search;
};

export const setEventURL = (
    event: EventBasicInformation,
    { payload: { url } }: SetEventURLAction,
): EventBasicInformation => {
    const history = getWindowObject('history');

    if (history && history.replaceState) {
        history.replaceState(history.state, 'null', getURLPath(url));
    }

    return event;
};
