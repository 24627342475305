import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { GACodes } from '../types';

export type ShareType =
    | 'facebook'
    | 'linkedin'
    | 'whatsapp'
    | 'facebookMessenger'
    | 'twitter'
    | 'email';

export const shareMapping = {
    facebook: 'fb',
    linkedin: 'li',
    whatsapp: 'wa',
    facebookMessenger: 'fbm',
    twitter: 'tw',
    email: 'strongmail',
};

export const label = (id: ShareType): string => shareMapping[id];

export const trackShareAction = (
    label: string,
    category: string = GACodes.GA_CATEGORY_LISTING,
) =>
    trackAnalyticsEvent({
        action: GACodes.GA_ACTION_SHARE_EVENT,
        category: category,
        label,
    });
