import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ComponentPropsWithoutRef } from 'react';

export const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    touch-action: none;
`;

export const SliderWrapper = styled.div`
    display: flex;
    position: relative;
    touch-action: none;
    width: 100%;
`;

export const SliderContentWrapper = styled.div`
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const SliderContent = styled.div`
    display: flex;

    & > * {
        flex-shrink: 0;
        flex-grow: 1;
    }
`;

interface SliderNavProps extends ComponentPropsWithoutRef<'div'> {
    position: 'left' | 'right';
}

export const SliderNav = styled.div<SliderNavProps>`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    width: 75px;
    z-index: 1;

    ${({ position }) => {
        if (position === 'left') {
            return css`
                background: rgb(255, 255, 255);
                background: linear-gradient(
                    90deg,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 0) 100%
                );
                justify-content: left;
                left: 0;
            `;
        }
        if (position === 'right') {
            return css`
                background: rgb(255, 255, 255);
                background: linear-gradient(
                    90deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%
                );
                justify-content: right;
                right: 0;
            `;
        }
    }}
`;
