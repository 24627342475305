import { Icon } from '@eventbrite/eds-icon';
import { MinusChunky, PlusChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React, {
    FunctionComponent,
    KeyboardEvent,
    useEffect,
    useRef,
    useState,
} from 'react';
import { getIconColor } from './helpers';
import './stepper.scss';
import { StepperButton } from './StepperButton';
import { StepperTotalQuantity } from './StepperTotalQuantity';

export interface StepperProps {
    collapsedWhenMinimum?: boolean;
    decreaseButtonContentNode?: React.ReactNode;
    increaseButtonContentNode?: React.ReactNode;
    isAppRebrandActive?: boolean;
    isDisabled?: boolean;
    maxValue: number;
    minQuantitySelectable: number;
    minValue: number;
    onDecreaseOneStep: () => void;
    onIncreaseOneStep: () => void;
    onSetQuantity: (value: number) => void;
    shouldAnimate?: boolean;
    totalQuantity: number;
}

/**
 * This component draw a 2 buttons for manage increase and decrease quantitys, also a quantity display
 * can be enabled or disabled
 * It require 2 methods to handle this actions (the quantity need to be part of the state)
 *
 * TODO: add accesibility: https://eventbrite.atlassian.net/browse/EB-200213
 */
export const Stepper: FunctionComponent<StepperProps> = ({
    collapsedWhenMinimum = false,
    decreaseButtonContentNode,
    increaseButtonContentNode,
    isAppRebrandActive = false,
    isDisabled = false,
    maxValue,
    minQuantitySelectable,
    minValue,
    onDecreaseOneStep,
    onIncreaseOneStep,
    onSetQuantity,
    totalQuantity,
    shouldAnimate = false,
}) => {
    const [quantityJumping, setQuantityJumping] = useState(false);
    const [quantityShaking, setQuantityShaking] = useState(false);
    const [fadingOut, setFadingOut] = useState(false);
    const [fadingIn, setFadingIn] = useState(false);
    const [decreaseBtnVisible, setDecreaseBtnVisible] = useState(true);
    const [decreasingAnimClass, setDecreasingAnimClass] = useState(
        'eds-stepper-quantity-initial-pos',
    );

    const animatedAndCollapsed = collapsedWhenMinimum && shouldAnimate;
    const collapsedAndAtMinValue =
        collapsedWhenMinimum && totalQuantity === minValue;

    const quantityGoesDownCssClassTimeout = useRef<NodeJS.Timeout | null>(null);
    const decreasingQuantityTimeout = useRef<NodeJS.Timeout | null>(null);
    const quantityWaitsUpCssClassTimeout = useRef<NodeJS.Timeout | null>(null);
    const quantityGoesToInitialPosCssClassTimeout =
        useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        return () => {
            clearAnimationTimeouts();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (collapsedWhenMinimum) {
            setDecreaseBtnVisible(false);
            setFadingIn(shouldAnimate);
        }
    }, [collapsedWhenMinimum, shouldAnimate]);

    useEffect(() => {
        // When the quantity is not at the minimum, we ensure the decrease button
        // is displayed
        if (totalQuantity > minValue) {
            setDecreaseBtnVisible(true);
            return;
        }

        // When the stepper is not animated but the decrease button and the quantity
        // have to be collapsed at zero, we ensure that they are hidden
        if (collapsedAndAtMinValue && !shouldAnimate) {
            setDecreaseBtnVisible(false);
        }
    }, [collapsedAndAtMinValue, minValue, shouldAnimate, totalQuantity]);

    const isAddButtonDisabled = isDisabled || totalQuantity >= maxValue;
    const isRemoveButtonDisabled = isDisabled || totalQuantity <= minValue;

    const addIconColor = getIconColor(isAddButtonDisabled, isAppRebrandActive);
    const removeIconColor = getIconColor(
        isRemoveButtonDisabled,
        isAppRebrandActive,
    );

    const fadeInOutClasses = classNames({
        'eds-stepper-fade-in': animatedAndCollapsed && fadingIn,
        'eds-stepper-fade-out': animatedAndCollapsed && fadingOut,
    });

    const stepperClassname = classNames(
        'eds-stepper eds-align--center-vertical',
    );

    const shouldJump = shouldAnimate && quantityJumping;
    const shouldFadeOut = shouldAnimate && animatedAndCollapsed && fadingOut;
    const shouldShake = shouldAnimate && quantityShaking;

    const addIcon = increaseButtonContentNode ? (
        increaseButtonContentNode
    ) : (
        <Icon type={<PlusChunky />} color={addIconColor} />
    );
    const removeIcon = decreaseButtonContentNode ? (
        decreaseButtonContentNode
    ) : (
        <Icon type={<MinusChunky />} color={removeIconColor} />
    );

    const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        switch (e.key) {
            case 'ArrowRight':
                return handleOnIncreaseOneStep();
            case 'ArrowLeft':
                return handleOnDecreaseOneStep();
            case 'Home':
                return onSetQuantity(minValue);
            case 'End':
                return onSetQuantity(maxValue);
            default:
                break;
        }
    };

    const clearAnimationTimeouts = () => {
        for (const ref of [
            quantityGoesDownCssClassTimeout,
            decreasingQuantityTimeout,
            quantityWaitsUpCssClassTimeout,
            quantityGoesToInitialPosCssClassTimeout,
        ]) {
            if (ref.current) {
                clearTimeout(ref.current);
            }
        }
    };

    const runDecreasingAnimation = () => {
        clearAnimationTimeouts();
        quantityGoesDownCssClassTimeout.current = setTimeout(
            () => setDecreasingAnimClass('eds-stepper-quantity-goes-down'),
            0,
        );
        decreasingQuantityTimeout.current = setTimeout(onDecreaseOneStep, 100);
        quantityWaitsUpCssClassTimeout.current = setTimeout(
            () => setDecreasingAnimClass('eds-stepper-quantity-waits-up'),
            100,
        );
        quantityGoesToInitialPosCssClassTimeout.current = setTimeout(
            () => setDecreasingAnimClass('eds-stepper-quantity-initial-pos'),
            100,
        );
    };

    const handleOnDecreaseOneStep = () => {
        const quantityAtMinimumSelectable =
            totalQuantity === minQuantitySelectable;
        const quantityAtMinValue = totalQuantity === minValue;

        if (collapsedWhenMinimum) {
            setFadingOut(quantityAtMinimumSelectable);

            if (quantityAtMinimumSelectable) {
                setFadingIn(false);
            } else if (quantityAtMinValue) {
                setFadingIn(true);
            }
        }

        if (shouldAnimate && totalQuantity > minValue) {
            runDecreasingAnimation();
        } else {
            onDecreaseOneStep();
        }
    };

    const handleOnDecreaseBtnAnimationEnd = () => {
        // When decreasing and we are at zero, we need to ensure that if collapsed
        // the fade out animation is disabled and the decrease button is hidden
        if (collapsedAndAtMinValue) {
            setDecreaseBtnVisible(false);
            setFadingOut(false);
            setFadingIn(true);
        }
    };

    const handleQuantityAnimationEnd = () => {
        setQuantityJumping(false);
        setQuantityShaking(false);
    };

    const handleOnIncreaseOneStep = () => {
        if (shouldAnimate) {
            setQuantityJumping(true);
        }

        onIncreaseOneStep();
    };

    const handleOnClickAtMaximum = () => {
        setQuantityShaking(true);
    };

    const decreaseBtnAndQuantity = decreaseBtnVisible ? (
        <>
            <div
                className={fadeInOutClasses}
                data-testid="eds-stepper-decrease-button-wrapper"
                onAnimationEnd={handleOnDecreaseBtnAnimationEnd}
            >
                <StepperButton
                    ariaLabel={gettext('Decrease').toString()}
                    dataTestid="eds-stepper-decrease-button"
                    isAppRebrandActive={isAppRebrandActive}
                    isDisabled={isRemoveButtonDisabled}
                    onClick={handleOnDecreaseOneStep}
                >
                    {removeIcon}
                </StepperButton>
            </div>

            <StepperTotalQuantity
                animationClasses={decreasingAnimClass}
                handleQuantityAnimationEnd={handleQuantityAnimationEnd}
                isAppRebrandActive={isAppRebrandActive}
                isDisabled={isDisabled}
                minValue={minValue}
                shouldJump={shouldJump}
                shouldFadeOut={shouldFadeOut}
                shouldShake={shouldShake}
                totalQuantity={totalQuantity}
            />
        </>
    ) : null;

    return (
        <div
            className={stepperClassname}
            data-testid="eds-stepper"
            onKeyDown={handleOnKeyDown}
        >
            {decreaseBtnAndQuantity}

            <StepperButton
                ariaLabel={gettext('Increase').toString()}
                dataTestid="eds-stepper-increase-button"
                disabledCallback={handleOnClickAtMaximum}
                isAppRebrandActive={isAppRebrandActive}
                isDisabled={isAddButtonDisabled}
                onClick={handleOnIncreaseOneStep}
            >
                {addIcon}
            </StepperButton>
        </div>
    );
};
