import * as React from 'react';

const MailFillChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="mail-fill-chunky_svg__eds-icon--mail-fill-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="mail-fill-chunky_svg__eds-icon--mail-fill-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 14.2L4 8.4V18h16V8.4l-8 5.8z"
        />
        <path
            id="mail-fill-chunky_svg__eds-icon--mail-fill-chunky_top"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.1 6l7.9 5.8L19.9 6z"
        />
    </svg>
);

MailFillChunkySvg.displayName = 'MailFillChunkySvg';
export default MailFillChunkySvg;
