import { CopyField } from '@eventbrite/eds-copy-field';
import { IconButton } from '@eventbrite/eds-icon-button';
import { ShareIosChunky } from '@eventbrite/eds-iconography';
import { Modal } from '@eventbrite/eds-modal';
import { withTooltip } from '@eventbrite/eds-tooltip';
import {
    HEAP_SHARE_EVENT_ACTION,
    HEAP_SHARE_EVENT_CLOSE_MODAL,
    HEAP_SHARE_EVENT_METHOD,
    reTrackHeapEvent,
} from '@eventbrite/eds-utils';
import { GenericLazyString } from '@eventbrite/i18n';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { trackShareAction } from '../../tracking';

interface UseDialog {
    closeDialog: Function;
    isDialogOpen: boolean;
    openDialog: (event: React.MouseEvent) => void;
}

const useDialog = (initialMode = false): UseDialog => {
    const [isDialogOpen, setDialogOpen] = useState(initialMode);
    const openDialog = (): void => setDialogOpen(true);
    const closeDialog = (): void => setDialogOpen(false);

    return {
        closeDialog,
        isDialogOpen,
        openDialog,
    };
};

interface DataWebShareApi {
    text?: string;
    title?: string;
    url: string;
}

export interface Props {
    title: string | GenericLazyString;
    inputLabel: string | GenericLazyString;
    url: string;
    tooltipTitle: GenericLazyString;
    tooltipText: GenericLazyString;
    trackingCategory: string;
    isMobile: boolean;
    container: React.MutableRefObject<HTMLDivElement | null>;
    useWebShareApi?: boolean;
    dataWebShareApi?: DataWebShareApi;
    eventId?: string;
    affCode?: string | undefined;
}

const shareUsingApi = async (
    dataWebShareApi: DataWebShareApi,
    trackingCategory: string,
) => {
    try {
        await navigator.share({ ...dataWebShareApi });
        trackShareAction({
            label: 'share-api-processed',
            category: trackingCategory,
        });
    } catch (err) {
        trackShareAction({
            label: 'share-api-cancelled',
            category: trackingCategory,
        });
    }
};

const openModal = async (
    e: React.MouseEvent,
    webShareApiEnabled: boolean,
    dataWebShareApi: DataWebShareApi | undefined,
    trackingCategory: string,
    openDialog: (e: React.MouseEvent) => void,
) => {
    if (webShareApiEnabled && dataWebShareApi?.url) {
        await shareUsingApi(dataWebShareApi, trackingCategory);
        trackShareAction({ label: 'share-api', category: trackingCategory });
    } else {
        openDialog(e);
        trackShareAction({ label: 'open-modal', category: trackingCategory });
    }
};

export const BaseShareModal: React.FunctionComponent<
    Pick<Props, 'container' | 'title' | 'inputLabel' | 'url'> & {
        isDialogOpen: boolean;
        closeDialog: Function;
        eventId?: string;
        affCode?: string | undefined;
    }
> = ({
    isDialogOpen,
    container,
    title,
    closeDialog,
    inputLabel,
    url,
    children,
    eventId,
    affCode,
}) => {
    if (!isDialogOpen || container.current === null) {
        return null;
    }
    return ReactDOM.createPortal(
        <Modal
            title={title}
            onClose={() => {
                if (eventId) {
                    reTrackHeapEvent({
                        affCode,
                        eventId,
                        heapEventName: HEAP_SHARE_EVENT_CLOSE_MODAL,
                    });
                }
                closeDialog();
            }}
            isShown={isDialogOpen}
            noMinHeight={true}
            roleIsDialog={true}
            eventId={eventId}
        >
            <section className="eds-align--center eds-l-pad-vert-8">
                <div className="eds-g-cell-8-12">
                    <div className="eds-align--center eds-l-pad-bot-8">
                        {children}
                    </div>
                    <CopyField
                        inputFieldId="shareUrlField"
                        label={inputLabel}
                        value={url}
                        eventId={eventId}
                        onCopyClick={() => {
                            if (eventId) {
                                reTrackHeapEvent({
                                    affCode,
                                    eventId,
                                    heapEventName: HEAP_SHARE_EVENT_METHOD,
                                    shareMethod: 'copy',
                                });
                            }
                        }}
                    />
                </div>
            </section>
        </Modal>,
        container.current,
    );
};

type ButtonProps = {
    tooltipDisabled: boolean;
    tooltipTitle: GenericLazyString;
    tooltipText: GenericLazyString;
    eventId?: string;
    affCode?: string | undefined;
    onClick: (e: React.MouseEvent) => void;
};

const Button: React.FunctionComponent<ButtonProps> = ({
    tooltipDisabled,
    tooltipTitle,
    tooltipText,
    onClick,
}) => {
    if (tooltipDisabled) {
        return (
            <IconButton
                iconType={<ShareIosChunky />}
                onClick={onClick}
                title={tooltipTitle}
            />
        );
    } else {
        const IconWithTooltip = withTooltip(IconButton);
        return (
            <IconWithTooltip
                iconType={<ShareIosChunky />}
                onClick={onClick}
                title={tooltipTitle}
                tooltipId="tooltip-share-modal-button"
                tooltipText={tooltipText}
            />
        );
    }
};

export const ShareModal: React.FunctionComponent<Props> = ({
    title,
    inputLabel,
    url,
    tooltipTitle,
    tooltipText,
    trackingCategory,
    isMobile,
    children,
    container,
    useWebShareApi,
    dataWebShareApi,
    eventId,
    affCode,
}) => {
    const { openDialog, closeDialog, isDialogOpen } = useDialog();
    const [isTouchableDevice, setIsTouchableDevice] = useState(false);
    const [isWebShareApiEnabled, setIsWebShareApiEnabled] = useState(false);
    const [tooltipDisabled, setTooltipDisabled] = useState(false);

    useEffect(() => {
        setIsTouchableDevice(
            document.documentElement?.ontouchstart !== undefined,
        );
    }, []);

    useEffect(() => {
        setIsWebShareApiEnabled(
            isTouchableDevice && navigator.share !== undefined,
        );
    }, [isTouchableDevice]);

    useEffect(() => {
        setTooltipDisabled(useWebShareApi ? isTouchableDevice : isMobile);
    }, [useWebShareApi, isTouchableDevice, isMobile]);

    return (
        <>
            <Button
                tooltipDisabled={tooltipDisabled}
                tooltipTitle={tooltipTitle}
                tooltipText={tooltipText}
                eventId={eventId}
                onClick={(e: React.MouseEvent) => {
                    openModal(
                        e,
                        isWebShareApiEnabled,
                        dataWebShareApi,
                        trackingCategory,
                        openDialog,
                    );
                    if (eventId) {
                        reTrackHeapEvent({
                            affCode,
                            eventId,
                            heapEventName: HEAP_SHARE_EVENT_ACTION,
                        });
                    }
                }}
                affCode={affCode}
            />
            <BaseShareModal
                isDialogOpen={isDialogOpen}
                container={container}
                title={title}
                closeDialog={closeDialog}
                inputLabel={inputLabel}
                url={url}
                eventId={eventId}
                affCode={affCode}
            >
                {children}
            </BaseShareModal>
        </>
    );
};
