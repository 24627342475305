/**
 * Truncates a string with an ellipsis at the end if its too long
 */
export const truncateString = (string: string, length: number) => {
    if (string.length > length) {
        return `${string.slice(0, length)}...`;
    }

    return string;
};
