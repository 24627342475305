import { gettext } from '@eventbrite/i18n';
import { Button, CalendarOffIcon, InlineAlert } from '@eventbrite/marmalade';
import React from 'react';

export type EnhancedExpiredBadgeProps = {
    onViewDetailsClick?: () => void;
    isEventDetailsPage: boolean;
    salesMessage: string;
};

export const EnhancedExpiredEventsBadge: React.FunctionComponent<
    EnhancedExpiredBadgeProps
> = ({ onViewDetailsClick, isEventDetailsPage, salesMessage }) => {
    const cta = !isEventDetailsPage ? (
        <Button variant="ghost" onClick={onViewDetailsClick}>
            {gettext('View event')}
        </Button>
    ) : undefined;

    return (
        <InlineAlert
            className="enhanced-expired-badge"
            variant="warning"
            description={gettext('%(salesMessage)s', { salesMessage })}
            icon={
                <CalendarOffIcon
                    color="#3A3247"
                    className="enhanced-expired-badge__icon"
                />
            }
            cta={cta}
        />
    );
};
