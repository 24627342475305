import { Avatar } from '@eventbrite/eds-avatar';
import { Button } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import React, { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import './FollowButton.scss';
import { useFollowButtonModal } from './hooks';
import { redirectToSignIn, saveOrganizerInfoInCookie } from './utils';

type FollowButtonLoginModalProps = {
    organizerId: string;
    organizerName: string;
    organizerAvatar?: string;
};

export const followLoginModalTitleText = (organizerName: string) =>
    gettext('Sign in to follow %(organizerName)s', { organizerName });
export const FOLLOW_LOGIN_MODAL_SUBTITLE_TEXT = gettext(
    'Stay up on the latest from your favorite event organizers',
);
export const GET_STARTED_TEXT = gettext('Get Started');

const _handleRedirectToSignIn = (organizerId: string) => {
    saveOrganizerInfoInCookie(organizerId);
    redirectToSignIn();
};

export const FollowButtonLoginModal = ({
    organizerName,
    organizerAvatar,
    organizerId,
}: FollowButtonLoginModalProps) => {
    const { shouldShowLoginModal, closeLoginModal } = useFollowButtonModal();

    const [isClientSideRendered, setIsClientSideRendered] = useState(false);

    useEffect(() => setIsClientSideRendered(true), []);

    return isClientSideRendered ? (
        <Modal
            visible={shouldShowLoginModal}
            type="simple"
            noMinHeight={true}
            onClose={closeLoginModal}
        >
            <section className="eds-g-cell eds-g-cell-1-1 eds-g-cell--has-overflow">
                {organizerAvatar && (
                    <div className="eds-align--center-horizontal eds-l-mar-top-8">
                        <Avatar
                            imageUrl={organizerAvatar}
                            backgroundColor="grey-100"
                        />
                    </div>
                )}
                <div className="eds-text--center eds-l-mar-top-8">
                    <span className="eds-text-hs">
                        {followLoginModalTitleText(organizerName)}
                    </span>
                </div>
                <div className="eds-text--center eds-l-mar-top-8">
                    <span>{FOLLOW_LOGIN_MODAL_SUBTITLE_TEXT}</span>
                </div>
                <div className="eds-align--center-horizontal eds-l-mar-vert-8">
                    <Button
                        style="fill"
                        type="button"
                        onClick={() => _handleRedirectToSignIn(organizerId)}
                    >
                        {GET_STARTED_TEXT}
                    </Button>
                </div>
            </section>
        </Modal>
    ) : null;
};
