import { gettext } from '@eventbrite/i18n';
import { formatMajorMoney } from '@eventbrite/intl';
import { TicketCost } from './types';

export const hasCost = (cost?: TicketCost): boolean =>
    !!cost && typeof cost.value === 'number' && cost.value > 0;

export const formatPrice = (value: number, currency: string) =>
    formatMajorMoney(value, currency, '¤#,##0.00');

export const renderPrice = (price: TicketCost) => {
    return formatPrice(price.value, price.currency);
};

export const renderFeeText = (fee: TicketCost, allInclusive = false) => {
    const displayFee = formatPrice(fee.value, fee.currency);
    if (!allInclusive) {
        return gettext('+%(displayFee)s Fee', { displayFee });
    }

    return gettext('incl. %(displayFee)s Fee', { displayFee });
};

export const renderTaxText = (tax: TicketCost, allInclusive = false) => {
    const displayTax = formatPrice(tax.value, tax.currency);
    if (!allInclusive) {
        return gettext('+%(displayTax)s Tax', { displayTax });
    }

    return gettext('incl. %(displayTax)s Tax', { displayTax });
};
