import { sdkRequest } from '@eventbrite/http';
import { FormState as FormStateInterface } from '../context';

const URL = '/organizers/';

interface ContactOrganizerBody {
    from_name: string;
    from_email: string;
    message: string;
    reason: string;
    captchaResponse?: string;
    event_id?: string;
    collection_id?: string;
}

interface OrganizerContactApi {
    formState: FormStateInterface;
    organizerId: string;
    eventId?: string;
    collectionId?: string;
}

const formatForPost = (
    formState: FormStateInterface,
    event_id?: string,
    collection_id?: string,
): ContactOrganizerBody => {
    return {
        from_name: formState.name.val || '',
        from_email: formState.email.val || '',
        message: formState.message.val || '',
        reason: formState.reason.val || '',
        captchaResponse: formState?.captcha?.val || '',
        event_id,
        collection_id,
    };
};

export const postOrganizerContact = async (data: OrganizerContactApi) => {
    const { formState, organizerId, eventId, collectionId } = data;
    const formattedUrl = `${URL}${organizerId}/contact/`;

    const formattedPostBody = formatForPost(formState, eventId, collectionId);
    const fetchOptions = {
        method: 'POST',
        body: JSON.stringify(formattedPostBody),
    };

    return sdkRequest(formattedUrl, fetchOptions).then((response: any) => {
        return {
            ...response,
            success: true,
        };
    });
};

/**
 * Get current logged in user
 */
export const getUser = async () => {
    return sdkRequest('/users/me/').then((useData: any) => useData);
};
