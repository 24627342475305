import { css, cx } from 'emotion';
import React from 'react';

type PageTitleProps = {
    pageTitle: string;
    hideStartDate: boolean;
};

const titleCustomStyle = (hideStartDate: boolean) => css`
    ${hideStartDate &&
    `
    @media (max-width: 960px) {
        width: 80%;
    }
    `}
`;

export const PageTitle = ({ pageTitle, hideStartDate }: PageTitleProps) => {
    if (!pageTitle) {
        return null;
    }
    return (
        <h1 className={cx('event-title', titleCustomStyle(hideStartDate))}>
            {pageTitle}
        </h1>
    );
};
