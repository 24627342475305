import { initializeErrorReporting } from '@eventbrite/error-reporting';
import type { Event } from '@sentry/types';
import { isProd } from '../config';
import type { ServerEvent, User } from '../types';
import { initDatadogRUM } from './DatadogRUM';

const SENTRY_TRACES_SAMPLE_RATE = 0.5;

const ignoredErrors = [
    /Script error for "analyticsjs"/i,
    /Script error for "grylls"/i,
    /Script error for "grylls_config"/i,
    /Load timeout for modules: grylls/i,
    /heap.resetIdentity is not a function/i,
    /Error calling eventclick/i,
    /al_onPoststitialDismiss/i,
    /al_onAdViewRendered/i,
    /TypeError: Load failed/i,
    /SyntaxError: Unexpected token =/i, //Branch.io https://sentry.io/organizations/eventbrite-eng/issues/3428328229/?project=6240753
    /XDI connection to https:\/\/synctranscend.eventbrite.com/i, // https://eventbrite.atlassian.net/browse/PRIV-197
];

const ignoreError = (event: Event) => {
    const errorMessage = event?.exception?.values?.[0]?.value;

    if (errorMessage) {
        const isAnIgnoredEvent =
            ignoredErrors.findIndex((ignoredError) =>
                ignoredError.test(errorMessage),
            ) !== -1;

        return isAnIgnoredEvent;
    }

    return false;
};

export type TrackingInitParams = {
    event: ServerEvent;
    appName: string;
    appVersion: string;
    user: User;
    ebDomain: string;
    sentryDNS: string;
};

export const initializeTracking = ({
    event,
    appName,
    appVersion,
    user,
    ebDomain,
    sentryDNS,
}: TrackingInitParams) => {
    initializeErrorReporting(
        {
            dsn: sentryDNS,
            tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
            ignoreError,
            metaData: {
                tags: {
                    event_id: event.id,
                },
            },
        },
        undefined,
        ['https://cdntranscend.eventbrite.com/.*'],
    );
    captureUnhandledRejections();
    initDatadogRUM({
        app: {
            name: appName,
            version: appVersion,
        },
        user,
        env: isProd(ebDomain) ? 'prod' : 'qa',
        sampleValue: 0.15,
        featureFlags: {
            listings_is_expired: event.isExpiredEvent,
            listings_is_live: event.isLive,
            listings_has_date_selector: !!event.childEvents,
        },
    });
};

const captureUnhandledRejections = () => {
    window.onunhandledrejection = (e) => {
        e.preventDefault();
        console.error('Captured Unhandled Rejection:', e.reason);
    };
};
