import { CheckoutType } from '../types';

// Statsig's Events
export const STATSIG_EVENT_NAMES = {
    LISTING_APP_PAGE_VIEW: 'Listing - App - View - Page',
    LISTING_CONVERSION_BAR_CTA_CLICK: 'Listing - conversionBar - Click - CTA',
    LISTING_TICKET_SELECTION_VIEW:
        'Listing - Conversion Bar - Ticket Selection - View',
    LISTING_ALL_EVENT_DETAILS_VIEW:
        'Listing - All - Click - View All Event Details',
    LISTING_TICKET_SELECTION_CLICK:
        'Listing - Conversion Bar - Ticket Selection - Click',
    CHILD_EVENTS_DROPDOWN:
        'Listing - Select a date - Change - Child Events Dropdown',
    MORE_OPTIONS_BUTTON:
        'Listing - Select a date - Click - More Options Button',
    MORE_OPTIONS_CARD: 'Listing - Select a date - Click - More Options Card',
    CAROUSEL_CARD: 'Listing - Select a date - Click - Carousel card',
    LISTING_MORE_ORGANIZER_EVENT_CLICK:
        'Listing - All - Click - More Organizer Event',
    LISTING_MORE_ORGANIZER_EVENT_LIKE:
        'Listing - All - Click - More Organizer Event Like',
    LISTING_MORE_ORGANIZER_EVENT_SHARE:
        'Listing - All - Click - More Organizer Event Share',
    LISTING_RELATED_EVENT_CLICK: 'Listing - All - Click - Related Event',
    LISTING_RELATED_EVENT_LIKE: 'Listing - All - Click - Related Event Like',
    LISTING_RELATED_EVENT_SHARE: 'Listing - All - Click - Related Event Share',
    LISTING_SUBCATEGORY_CTA_CLICK:
        'Listing - Expired Event Page - Click - Category Browse Modal CTA',
    LISTING_FOLLOW_CLICK: 'Listing - All - Click - Follow',
    LISTING_UNFOLLOW_CLICK: 'Listing - All - Click - Unfollow',
    LISTING_MULTITICKET_ANCHOR_BUTTON_VIEW:
        'ListingWeb - Tickets Anchor Button - View',
    LISTING_MULTITICKET_ANCHOR_BUTTON_CLICK:
        'ListingWeb - Tickets Anchor Button - Click',
    LISTING_MULTITICKET_TICKET_SECTION_VIEW:
        'ListingWeb - Ticket Section - View',
    LISTING_MULTITICKET_TICKET_CONTROL_SECTION_VIEW:
        'ListingWeb - Ticket Section - Control View',
    LISTING_MULTITICKET_END_OF_DESCRIPTION_VIEW:
        'ListingWeb - "End of Description" Section - View',
    LISTING_MULTITICKET_CTA_CLICK: 'ListingWeb - Multiticket CTA - Click - CTA',
    LISTING_MULTITICKET_ERROR_VIEW: 'ListingWeb - Multiticket Error - View',
    LISTING_ERROR_BANNER_CLOSE_CLICK: 'ListingWeb - View Close Error - Click',
    LISTING_MULTITICKET_TICKET_SELECTED_CLICK:
        'ListingWeb - Ticket selected - Click',
    ABOUT_THIS_EVENT_VIEW: 'ListingWeb - Description - View',
    LISTING_ALL_SUCCESS_LIKE_EVENT: 'Listing - All - Success - Like Event',
    LISTING_NIGHTLIFE_EVENT_CLICK: 'Listing - All - Click - Nightlife Event',
    LISTING_NIGHTLIFE_EVENT_LIKE:
        'Listing - All - Click - Nightlife Event Like',
    LISTING_NIGHTLIFE_EVENT_SHARE:
        'Listing - All - Click - Nightlife Event Share',
    G2K_VIEW: 'ListingWeb - Good to know - ListingGoodToKnowView - View',
    G2K_VIEW_CARD:
        'ListingWeb - Good to know - ListingGoodToKnowViewCard - View',
    G2K_READ_MORE_CLICK:
        'ListingWeb - Good to know - ListingGoodToKnowReadMore - Click',
    G2K_VIEW_ALL_CLICK:
        'ListingWeb - Good to know - ListingGoodToKnowViewAll - Click',
    FAQ_VIEW: 'ListingWeb - Good to know - FAQ - View',
    HIGHLIGHTS_VIEW: 'ListingWeb - Good to know - Highlights - View',
    REFUND_POLICY_VIEW: 'ListingWeb - Good to know - RefundPolicy - View',
};

// Statsig's First Hierarchy Level Experiment for Listings Team
export const LISTING_SECOND_HIERARCHY_LEVEL_EXPERIMENT = {
    experimentName: 'listing_second_hierarchy_level',
    param: 'show_second_hierarchy_level',
    defaultValue: false,
};

// Statsig's First Hierarchy Level Experiment for Listings Team
export const LISTING_THIRD_HIERARCHY_LEVEL_EXPERIMENT = {
    experimentName: 'listing_third_hierarchy_level',
    param: 'show_third_hierarchy_level',
    defaultValue: false,
};

// Statsig's First Hierarchy Level Experiment for Listings Team
export const LISTING_VIDEO_AUTOPLAY_EXPERIMENT = {
    experimentName: 'listing_video_autoplay',
    param: 'show_autoplay_video',
    defaultValue: false,
};

export const LISTING_TEST_AA_SSR_EXPERIMENT = {
    experimentName: 'test_ssr_experiment',
    param: 'test',
    defaultValue: false,
};

// Statsig's detach interests from user menu
export const DETACH_INTERESTS_FROM_USER_MENU = {
    experimentName: 'consumer_onboarding_detach_interests',
    param: 'detach_interests',
    defaultValue: false,
};

// Statsig's one ticket type experiment

export const ONE_TICKET_TYPE_EXPERIMENT = {
    experimentName: 'one_ticket_type_experiment',
    param: 'checkoutType',
    defaultValue: CheckoutType.Compact,
};

export const SURFACING_RATING_EXPERIMENT = {
    experimentName: 'listing_page_surfacing_rating_experiment',
    param: 'variant_type',
    defaultValue: 'control',
    eligibleOrganizerControlVariant: 'ListingPageEligibleControlVariant',
    experimentLoaded: 'SurfacingRatingExperimentLoaded',
    modalOpen: 'SurfacingRatingExperimentModalOpen',
    modalClose: 'SurfacingRatingExperimentModalClose',
    cardClick: 'SurfacingRatingExperimentCardClick',
};

export enum NIGHTLIFE_EXPERIMENT_VALUES {
    CONTROL = 'hidden',
    TEST = 'visible',
}

export const NIGHTLIFE_CAROUSEL_EXPERIMENT = {
    experimentName: 'nightlife_carousel_listings',
    param: 'nightlife_carousel',
    defaultValue: NIGHTLIFE_EXPERIMENT_VALUES.CONTROL,
};

export const G2K_EXPERIMENT = {
    experimentName: 'listings_good_to_know',
    param: 'g2k',
    defaultValue: false,
};

export const TIMED_ENTRY_SESSION_SELECTOR_EXPERIMENT = {
    experimentName: 'timed_entry_session_selector_experiment',
    param: 'hide_session_selector',
    defaultValue: false,
};

export const LISTING_TIMED_ENTRY_EXPERIMENT = {
    experimentName: 'listing_timed_entry_experiment',
    param: 'hide_session_selector',
    defaultValue: false,
};
