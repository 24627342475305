import React from 'react';
import { Tag, STYLE_PALE } from '@eventbrite/eds-tag';
import { gettext } from '@eventbrite/i18n';

import { CollectionMembershipView } from './CollectionMembershipView';

export const CollectionMembershipSignal = (props) => {
    const collections = props.signal.value;
    const [isCollectionViewActive, setIsCollectionViewActive] =
        React.useState(null);

    const handleSetIsCollectionViewActive = () => {
        props.onIsCollectionViewActive?.(!isCollectionViewActive);

        setIsCollectionViewActive(!isCollectionViewActive);
    };

    return (
        <div
            className="eds-event-card__sub-content--signal__collection"
            data-testid="collection-signal"
            data-subcontent-key="collection"
            style={{ position: 'relative' }}
        >
            <Tag
                style={STYLE_PALE}
                onActiveToggle={() =>
                    handleSetIsCollectionViewActive(!isCollectionViewActive)
                }
                initialIsActive={isCollectionViewActive}
                __containerClassName="ignore-react-onclickoutside"
            >
                <span style={{ fontSize: '12px', lineHeight: '16px' }}>
                    {collections.objectCount > 1
                        ? gettext(
                              'Featured in %(collectionCount)s collections',
                              {
                                  collectionCount: collections.objectCount,
                              },
                          )
                        : gettext('Featured in a collection')}
                </span>
            </Tag>
            {isCollectionViewActive && (
                <CollectionMembershipView
                    isCollectionViewActive={isCollectionViewActive}
                    collections={collections}
                    setIsCollectionViewActive={() =>
                        handleSetIsCollectionViewActive()
                    }
                    onCollectionClick={props.onCollectionClick}
                />
            )}
        </div>
    );
};
