import { ChildEventProps } from '../../types';
import { findFirstPurchasableEvent, getEventChildById } from '../../utils';
import { EventBasicInformation } from './types';

export const getEventBasicInfo = (
    eventInfo: EventBasicInformation,
): EventBasicInformation => {
    const { isSeriesParent } = eventInfo;

    if (isSeriesParent) {
        return getParentEventBasicInfo(eventInfo);
    }

    const url =
        eventInfo.childEvents && eventInfo.childEvents.length > 0
            ? (
                  getEventChildById(
                      eventInfo.childEvents,
                      eventInfo.id,
                  ) as ChildEventProps
              ).url
            : eventInfo.url;

    return { ...eventInfo, url };
};

export const getParentEventBasicInfo = (
    eventBasicInfo: EventBasicInformation,
): EventBasicInformation => {
    const firstAvailableEventInSeries =
        findFirstPurchasableEvent(eventBasicInfo.childEvents) ?? null;
    const isSeriesParentWithAvailableChildEvents =
        eventBasicInfo.isSeriesParent && firstAvailableEventInSeries;

    if (!isSeriesParentWithAvailableChildEvents) {
        return {
            ...eventBasicInfo,
            salesStatus: 'sold_out',
        };
    }

    return {
        ...eventBasicInfo,
        id: firstAvailableEventInSeries.id,
        url: firstAvailableEventInSeries.url,
        start: firstAvailableEventInSeries.start,
        end: firstAvailableEventInSeries.end,
    };
};
