export enum TabTrackerAction {
    request = 'request-open-external',
    ack = 'ack-open-external',
    confirm = 'confirm-open-external',
}

export type TabTrackerMessagePayload =
    | TabTrackerRequestPayload
    | TabTrackerAckPayload
    | TabTrackerConfirmPayload;

type TabTrackerRequestPayload = {
    action: TabTrackerAction.request;
    eventId: string;
};

type TabTrackerAckPayload = {
    action: TabTrackerAction.ack;
    eventId: string;
    uuid: string;
};

type TabTrackerConfirmPayload = {
    action: TabTrackerAction.confirm;
    eventId: string;
    uuid: string;
};

export const listingsBroadcastChannel = 'listings-broadcast-channel';
