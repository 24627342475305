export const getFocalPoint = (
    imageUrl: string,
): { x: number; y: number } | null => {
    if (!imageUrl) {
        return null;
    }

    let imageUrlObject;
    try {
        imageUrlObject = new URL(imageUrl);
    } catch (e) {
        console.error('Error parsing image URL', e);
        return null;
    }

    if (!imageUrlObject.searchParams.has('fp-x')) {
        return null;
    }

    return {
        x: parseFloat(imageUrlObject.searchParams.get('fp-x') || '0.5'),
        y: parseFloat(imageUrlObject.searchParams.get('fp-y') || '0.5'),
    };
};
