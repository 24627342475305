import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import React, { useEffect, useState } from 'react';
import {
    GA_ACTION_FORM_DISMISSED,
    GA_CONTACT_ORGANIZER_CATEGORY,
    SUCCESS_VIEW,
} from '../constants';

const defaultConfig: OverlayConfigOptions = {
    title: gettext('Contact the organizer'),
};

interface OverlayConfigOptions {
    title: GenericLazyString;
    onPrimaryAction?: Function;
    primaryText?: string;
    primaryType?: string;
    onSecondaryAction?: Function;
    secondaryText?: string;
    secondaryType?: string;
}

interface OverlayConfig {
    overlayConfig: OverlayConfigOptions;
    setOverlayConfig: Function;
}

interface OverlayVisible {
    isVisible: boolean;
    setVisible: Function;
    setCurrentView: Function;
}

export const OverlayConfigContext = React.createContext<Partial<OverlayConfig>>(
    {},
);
export const OverlayVisibilityContext = React.createContext<
    Partial<OverlayVisible>
>({});

interface OverlayProviderProps {
    isVisible: boolean;
    onClose: Function;
    showOverlay?: Function;
}

export const OverlayProvider: React.FunctionComponent<OverlayProviderProps> = (
    props,
) => {
    const [isVisible, setVisible] = useState(props.isVisible);
    const [currentView, setCurrentView] = useState<string | null>(null);
    const [overlayConfig, setOverlayConfig] = useState(defaultConfig);

    useEffect(() => {
        setVisible(props.isVisible);
    }, [props.isVisible]);

    const handleSetVisible = ({
        value = false,
        noAnalyticsTrack = false,
    } = {}) => {
        setVisible(value);

        if (!value && props.onClose) {
            props.onClose();
        }

        if (!value && currentView !== SUCCESS_VIEW && !noAnalyticsTrack) {
            trackAnalyticsEvent({
                action: GA_ACTION_FORM_DISMISSED,
                category: GA_CONTACT_ORGANIZER_CATEGORY,
                label: currentView,
            });
        }
    };

    return (
        <OverlayConfigContext.Provider
            value={{ overlayConfig, setOverlayConfig }}
        >
            <OverlayVisibilityContext.Provider
                value={{
                    isVisible,
                    setVisible: handleSetVisible,
                    setCurrentView: (view: string) => setCurrentView(view),
                }}
            >
                {props.children}
            </OverlayVisibilityContext.Provider>
        </OverlayConfigContext.Provider>
    );
};
