import { Icon } from '@eventbrite/eds-icon';
import {
    ChevronDownChunky,
    ChevronUpChunky,
} from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import { MapPinIcon, VideoIcon } from '@eventbrite/marmalade';
import React from 'react';
import { LISTING_HEAP_IDS } from '../../../../constants';
import { EVENT_LOCATION_MAP_ID } from '../../../_shared/constants';
import {
    Detail,
    DetailContent,
    DetailInner,
} from '../../../_shared/Detail/Detail';
import { EventDetailsSectionTitle } from '../../EventDetails';
import './Location.scss';

export type LocationProps = {
    hasAddress?: boolean;
    hasMap?: boolean;
    venueMultilineAddress?: string[];
    venueName?: string;
    isOnlineEvent: boolean;
    onlineEventUrl?: string;
    localityPlaceId?: string;
};

export type MapToggleButtonProps = {
    onToggleButtonMap?: (isMapExpanded: boolean) => void;
    isMapExpanded?: boolean;
};

const ButtonContent: React.FC<Pick<MapToggleButtonProps, 'isMapExpanded'>> = ({
    isMapExpanded,
}) => {
    const HIDE_MAP_TEXT = gettext('Hide map');
    const SHOW_MAP_TEXT = gettext('Show map');
    const iconType = isMapExpanded ? (
        <ChevronUpChunky />
    ) : (
        <ChevronDownChunky />
    );
    const text = isMapExpanded ? HIDE_MAP_TEXT : SHOW_MAP_TEXT;

    return (
        <>
            {text}
            <Icon type={iconType} size="small" color="ui-blue--hover" />
        </>
    );
};

export const MapToggleButton = ({
    onToggleButtonMap,
    isMapExpanded,
}: MapToggleButtonProps) => {
    const _handleToggleMap = () => {
        if (onToggleButtonMap) {
            onToggleButtonMap(!isMapExpanded);
        }
    };

    return (
        <div className="map-button-toggle">
            <button
                className="eds-btn eds-btn--link"
                onClick={_handleToggleMap}
                aria-expanded={isMapExpanded}
                aria-controls={EVENT_LOCATION_MAP_ID}
                data-heap-id={
                    isMapExpanded
                        ? LISTING_HEAP_IDS.HIDE_MAP
                        : LISTING_HEAP_IDS.SHOW_MAP
                }
            >
                <ButtonContent isMapExpanded={isMapExpanded} />
            </button>
        </div>
    );
};

export const Location = ({
    isOnlineEvent,
    onlineEventUrl,
    hasAddress,
    hasMap,
    venueMultilineAddress,
    venueName,
    onToggleButtonMap,
    isMapExpanded,
}: LocationProps & MapToggleButtonProps) => {
    const OnlineEvent = () => {
        return (
            <div className="location-info">
                <VideoIcon className="location-info__icon" />
                <div>
                    {
                        <p className="location-info__address-text">
                            {gettext('Online')}
                        </p>
                    }
                    {onlineEventUrl && (
                        <a
                            className="eds-link"
                            href={onlineEventUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-heap-id={
                                LISTING_HEAP_IDS.ONLINE_EVENT_ACCESS_BUTTON
                            }
                        >
                            {gettext('Access link')}
                        </a>
                    )}
                </div>
            </div>
        );
    };

    const InPersonEvent = () => {
        const address = venueMultilineAddress?.join(' ');

        return (
            <div className="location-info">
                <MapPinIcon className="location-info__icon" />
                <div className="location-info__address">
                    {venueName && (
                        <p className="location-info__address-text">
                            {venueName}
                        </p>
                    )}
                    {venueMultilineAddress && ` ${address}`}
                    {hasMap && (
                        <MapToggleButton
                            onToggleButtonMap={onToggleButtonMap}
                            isMapExpanded={isMapExpanded}
                        />
                    )}
                </div>
            </div>
        );
    };

    const LABEL_ID = 'location-heading';

    return (
        <section aria-labelledby={LABEL_ID}>
            <Detail>
                <DetailInner>
                    <DetailContent>
                        <EventDetailsSectionTitle>
                            <h2 id={LABEL_ID}>{gettext('Location')}</h2>
                        </EventDetailsSectionTitle>
                        {isOnlineEvent && <OnlineEvent />}
                        {hasAddress && <InPersonEvent />}
                        {!hasAddress &&
                            !isOnlineEvent &&
                            gettext('To be announced')}
                    </DetailContent>
                </DetailInner>
            </Detail>
        </section>
    );
};
