import { AgendaWidget } from './Agenda';
import { FaqWidget } from './Faq';
import { Widget, WidgetType } from './types';

type ParsedWidgets = Partial<Record<WidgetType, AgendaWidget | FaqWidget>>;

export function parseWidgets(widgets: Widget[]) {
    const transformedWidgets: ParsedWidgets = {};

    widgets.forEach((widget) => {
        transformedWidgets[widget.type] = widget.data;
    });

    return transformedWidgets;
}
