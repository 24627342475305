export const GA_CONTACT_ORGANIZER_CATEGORY = 'ContactOrganizer';

export const GA_ACTION_LANDING_SHOWN = 'LandingShown';
export const GA_ACTION_FORM_SHOWN = 'FormShown';
export const GA_ACTION_REASON_SELECTED = 'ReasonSelected';
export const GA_ACTION_FORM_CONFIRMATION_SHOWN = 'FormConfirmationShown';
export const GA_ACTION_FORM_SUBMITTED = 'FormSubmitted';
export const GA_ACTION_FORM_SUBMISSION_ERROR = 'FormSubmissionError';
export const GA_ACTION_FORM_DISMISSED = 'FormDismissed';

export const LANDING_VIEW = 'LandingView';
export const FORM_VIEW = 'FormView';
export const FORM_CONFIRMATION_VIEW = 'FormConfirmationView';
export const SUCCESS_VIEW = 'SuccessView';
