import * as React from 'react';

const DefaultAbstractProfileFourSvg = (
    props: React.SVGProps<SVGSVGElement>,
) => (
    <svg viewBox="0 0 40 40" fill="none" {...props}>
        <circle cx={20} cy={20} r={20} fill="#C9C5CF" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.423 34.685A19.946 19.946 0 010 20 19.94 19.94 0 016.054 5.664C19.14 6.973 32 10.202 32 15.244c0 3.448-2.69 4.614-5.505 5.833-1.379.597-2.788 1.208-3.924 2.106-1.13.894-.63 1.665-.067 2.533.552.85 1.164 1.792.365 3.033-2.281 3.542-9.228 5.27-16.446 5.936z"
            fill="#6E627F"
        />
    </svg>
);

DefaultAbstractProfileFourSvg.displayName = 'DefaultAbstractProfileFourSvg';
export default DefaultAbstractProfileFourSvg;
