import { constants } from '@eventbrite/datetime-fns';
import type {
    TimedEntryCalenderEventProps,
    TimedEntryNextConfirmedEventsProps,
} from '../../types';

export const _getTimedEntryCalendarApiUrl = (
    parentEventId: string,
    year: string,
    month: string,
    timezone: string,
) =>
    `/attendee/event-listing/v1/events/${parentEventId}/sessions/month/${year}/${month}/dates?tzIdentifier=${timezone}`;

export const _getNextConfirmedEventsSessionsApiUrl = (
    parentEventId: string,
    fromDateTime: string,
) =>
    `/attendee/event-listing/v1/events/${parentEventId}/sessions?fromDateTime=${fromDateTime}`;

export const getTimedEntryCalendarData = async (
    parentEventId: string,
    year: string,
    month: string,
    timezone?: string | null,
) => {
    const apiUrl = _getTimedEntryCalendarApiUrl(
        parentEventId,
        year,
        month,
        timezone || constants.DEFAULT_TIMEZONE,
    );
    const response: Response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const responseData: TimedEntryCalenderEventProps = await response.json();
    return responseData;
};

export const getNextConfirmedEventsSessions = async (
    parentEventId: string,
    fromDateTime: string,
) => {
    const apiUrl = _getNextConfirmedEventsSessionsApiUrl(
        parentEventId,
        fromDateTime,
    );
    const response: Response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const responseData: TimedEntryNextConfirmedEventsProps =
        await response.json();
    return responseData;
};
