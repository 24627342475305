import {
    constants as dateTimeConstants,
    getDateFnsLocale,
} from '@eventbrite/datetime-fns';
import { getAttendeeTimezone } from '@eventbrite/event-datetime';
import { format } from 'date-fns-tz';
import { LocalizedDateTime } from '../../../types';

type GetFormattedStartDateParams = {
    eventStartDateTime: LocalizedDateTime;
    isOnlineEvent: boolean;
    locale: string;
};

export const getFormattedStartDate = ({
    eventStartDateTime,
    isOnlineEvent,
    locale,
}: GetFormattedStartDateParams) => {
    const date = isOnlineEvent
        ? eventStartDateTime.utc
        : eventStartDateTime.local;
    const dateObj = new Date(date);
    const timeZone = isOnlineEvent
        ? getAttendeeTimezone() ?? eventStartDateTime.timezone
        : eventStartDateTime.timezone;

    const { DATE_FORMATS } = dateTimeConstants;

    const shortDate = format(dateObj, DATE_FORMATS[locale] || 'EEEE, MMMM d', {
        timeZone,
        locale: getDateFnsLocale(locale),
    });
    const formattedDateTime = shortDate;

    const dateTime = format(dateObj, 'yyy-MM-dd', {
        timeZone,
    });

    return { formattedDateTime, dateTime };
};
