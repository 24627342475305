import {
    EventCardActions,
    EventCardBadge,
    EventCardDate,
    EventCardDetails,
    EventCardImage,
    EventCardLink,
    EventCardLocation,
    EventCardOrganizer,
    EventCardPrice,
    EventCardPromoted,
    EventCardTitle,
    EventCardTracking,
    EventCardUrgencySignals,
    FormattedEvent,
    getEventCardLinkProps,
    getFormattedSalesStatus,
    HORIZONTAL_LEFT_CARD_BADGE_STYLE,
    useFormattedLocationString,
    useGetFormattedEventDateTimeString,
    useMinPriceString,
    VerticalEventCard,
    VERTICAL_EVENT_CARD_IMAGE_CONFIG,
} from '@eventbrite/event-renderer';
import React from 'react';
import styles from './ListingsEventCard.module.scss';

interface ListingsEventCardProps {
    actions?: React.ReactNode;
    event: FormattedEvent;
    locale: string;
    isLazyImage?: boolean;
    affCode?: string;
    isRaised?: boolean;
    statsigLocationString?: string;
    'data-testid'?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    bucketLabel?: string;
}

export const ListingsEventCard: React.FunctionComponent<
    ListingsEventCardProps
> = ({
    event,
    locale,
    isRaised,
    statsigLocationString,
    actions,
    affCode,
    onClick,
    isLazyImage,
    bucketLabel,
}) => {
    const { urgencySignals } = event;
    const linkProps = getEventCardLinkProps({
        affCode: affCode || '',
        event,
        onClick,
    });

    const formattedDateString = useGetFormattedEventDateTimeString({
        startDate: event.startDate,
        startTime: event.startTime,
        timezone: event.timezone,
        repeatingInstanceCount: event.repeatingInstanceCount,
        locale: locale,
        useUserTimezone: event.isOnlineEvent,
        dateTimeDelimeter: '•',
    });

    const formattedPriceString = useMinPriceString({
        price: event.minPrice,
        locale: locale,
    });

    const formattedLocationString = useFormattedLocationString({
        venue: event.venue,
        style: 'venue',
    });
    const formattedSalesStatus = getFormattedSalesStatus(event);
    const shouldShowUrgencySignals = !!urgencySignals && !formattedSalesStatus;

    return (
        <VerticalEventCard isRaised={isRaised}>
            <EventCardTracking
                statsigLocationString={statsigLocationString}
                event={event}
                eventBucketLabel={bucketLabel}
            >
                <EventCardBadge style={HORIZONTAL_LEFT_CARD_BADGE_STYLE}>
                    {formattedSalesStatus}
                </EventCardBadge>
                <EventCardLink {...linkProps}>
                    <EventCardImage
                        name={event.name}
                        loading={isLazyImage ? 'lazy' : 'eager'}
                        tokens={{
                            ...VERTICAL_EVENT_CARD_IMAGE_CONFIG,
                            '--image-background-color': event.edgeColor,
                        }}
                        src={event.imageUrl}
                    />
                </EventCardLink>
                <EventCardDetails
                    space="xs"
                    tokens={{
                        '--EventCardDetailsPadding': '16px 8px 12px 12px',
                        '--EventCardDetailsFlexGrow': '2',
                        '--EventCardDetailsPosition': 'relative',
                    }}
                >
                    {shouldShowUrgencySignals && (
                        <EventCardUrgencySignals
                            className={styles.listingsEventCardUrgencySignals}
                            urgencySignals={urgencySignals}
                        />
                    )}
                    <EventCardLink {...linkProps}>
                        <EventCardTitle
                            className="event-card__clamp-line--two eds-text-color--ui-800"
                            title={event.name}
                        />
                    </EventCardLink>
                    <EventCardDate
                        className="eds-text-color--primary-brand"
                        formattedDateString={formattedDateString}
                        variant="body-md-bold"
                    />

                    <EventCardLocation
                        className="event-card__clamp-line--one eds-text-color--ui-600"
                        formattedLocationString={formattedLocationString}
                        variant="body-md"
                    />
                    <EventCardPrice
                        isFree={event.isFree}
                        formattedPriceString={formattedPriceString}
                        className="eds-text-color--ui-600"
                        variant="body-md"
                    />
                    <EventCardOrganizer
                        organizerName={event.organizerName}
                        variantStyle="body-md-bold"
                        className="eds-text-color--ui-800"
                    />
                    <EventCardPromoted
                        className={styles.promotedLabel}
                        isPromoted={event.isPromoted}
                    />
                    {actions && (
                        <EventCardActions
                            tokens={{
                                '--EventCardActionsPosition': 'absolute',
                                '--EventCardActionsTop': '-22px',
                                '--EventCardActionsRight': '8px',
                                '--EventCardActionsGap': '8px',
                            }}
                            actionStyleOverride="outline"
                        >
                            {actions}
                        </EventCardActions>
                    )}
                </EventCardDetails>
            </EventCardTracking>
        </VerticalEventCard>
    );
};
