import { Button, STYLE_ANCHOR } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import React, { useState } from 'react';
import { EventDetailsSection, EventDetailsSectionTitle } from '../EventDetails';
import './Performers.scss';

type Artist = {
    name: string;
};

type ArtistList = Artist[];

export type PerformersProps = {
    headliners?: ArtistList;
    supporters?: ArtistList;
};

export type PerformersListProps = PerformersProps & {
    shouldShowHeadlinersTitle: boolean;
    shouldRenderSupportersTitle: boolean;
};

const PerformersList = ({
    headliners = [],
    supporters = [],
    shouldShowHeadlinersTitle,
    shouldRenderSupportersTitle,
}: PerformersListProps) => {
    return (
        <>
            {headliners.length > 0 && (
                <>
                    <h3
                        className={`performers__title ${
                            shouldShowHeadlinersTitle
                                ? ''
                                : 'eds-is-hidden-accesible'
                        }`}
                    >
                        {gettext('Headliners')}
                    </h3>

                    <ul className="performers__list">
                        {headliners.map(({ name }, i) => (
                            <li key={i} className="headliners">
                                {name}
                            </li>
                        ))}
                    </ul>
                </>
            )}

            {supporters.length > 0 && (
                <>
                    {shouldRenderSupportersTitle && (
                        <h3 className="performers__title">
                            {gettext('More Performers')}
                        </h3>
                    )}

                    <ul className="performers__list">
                        {supporters.map(({ name }, i) => (
                            <li key={i} className="supporters">
                                {name}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </>
    );
};

const PERFORMERS_PREVIEW_THRESHOLD = 15;

const PerformersPreview = ({
    headliners = [],
    supporters = [],
}: PerformersProps) => {
    const filteredHeadliners = headliners.slice(
        0,
        PERFORMERS_PREVIEW_THRESHOLD,
    );
    const filteredSupporters =
        filteredHeadliners.length < PERFORMERS_PREVIEW_THRESHOLD
            ? supporters.slice(
                  0,
                  PERFORMERS_PREVIEW_THRESHOLD - headliners.length,
              )
            : [];

    return (
        <div aria-hidden="true" data-testid="performers-preview">
            <PerformersList
                headliners={filteredHeadliners}
                supporters={filteredSupporters}
                shouldShowHeadlinersTitle={supporters.length > 0}
                shouldRenderSupportersTitle={headliners.length > 0}
            />
        </div>
    );
};

export const Performers = ({
    headliners = [],
    supporters = [],
}: PerformersProps) => {
    const hasPreview =
        headliners.length + supporters.length > PERFORMERS_PREVIEW_THRESHOLD;
    const [isExpanded, setIsExpanded] = useState(!hasPreview);

    if (!headliners.length && !supporters.length) {
        return null;
    }

    const shouldShowPreview = hasPreview && !isExpanded;

    return (
        <EventDetailsSection ariaLabelledBy="performers">
            <EventDetailsSectionTitle>
                <h2>{gettext('Performers')}</h2>
            </EventDetailsSectionTitle>
            <>
                {shouldShowPreview && (
                    <PerformersPreview
                        headliners={headliners}
                        supporters={supporters}
                    />
                )}
                <div
                    className={
                        shouldShowPreview ? 'eds-is-hidden-accessible' : ''
                    }
                    data-testid="performers-full-list"
                >
                    <PerformersList
                        headliners={headliners}
                        supporters={supporters}
                        shouldShowHeadlinersTitle={supporters.length > 0}
                        shouldRenderSupportersTitle={headliners.length > 0}
                    />
                </div>
                {hasPreview && (
                    <Button
                        style={STYLE_ANCHOR}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {isExpanded
                            ? gettext('Hide all performers')
                            : gettext('Show all performers')}
                    </Button>
                )}
            </>
        </EventDetailsSection>
    );
};
