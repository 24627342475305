import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { EventAction } from './actions';
import { eventReducer } from './reducers';
import { EventBasicInformation } from './types';
import { getEventBasicInfo } from './utils';

export const useEventBasicInformationContext = () => {
    const value = useContext(EventBasicInformationContext);

    if (!value) {
        throw new Error(
            'useEventBasicInformationContext must be used within EventBasicInformationContext',
        );
    }

    return value;
};

export const useEventDispatchContext = () => {
    const dispatch = useContext(EventDispatchContext);

    if (!dispatch) {
        throw new Error(
            'useEventDispatchContext must be used within EventDispatchContext',
        );
    }

    return dispatch;
};

export const EventBasicInformationProvider: React.FC<{
    value: EventBasicInformation;
}> = ({ children, value }) => {
    const [state, dispatch] = useReducer(
        eventReducer,
        getEventBasicInfo(value),
    );

    return (
        <EventBasicInformationContext.Provider value={state}>
            <EventDispatchContext.Provider value={dispatch}>
                {children}
            </EventDispatchContext.Provider>
        </EventBasicInformationContext.Provider>
    );
};

export const EventBasicInformationContext =
    createContext<EventBasicInformation | null>(null);
EventBasicInformationContext.displayName = 'EventBasicInformationContext';

export const EventDispatchContext = createContext<Dispatch<EventAction> | null>(
    null,
);
EventDispatchContext.displayName = 'EventDispatchContext';
