import { displayJourneyUsingMetatags } from '@eventbrite/branchio-utils';
import { TYPE_ERROR, TYPE_INFO } from '@eventbrite/eds-notification';
import { sdkRequest } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { logEvent } from '@eventbrite/statsig';
import loadable from '@loadable/component';
import Cookies from 'cookies-js';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context';
import { useNotificationsContext } from '../../contexts';
import {
    EventBasicInformation,
    EventBasicInformationContext,
} from '../../contexts/EventContext';
import { STATSIG_EVENT_NAMES } from '../../experimentation/constants';
import { GACodes, NotificationBarProps } from '../../types';
import { dynaMount } from '../utils/mountComponent';
import Bookmark, { BookmarkProps } from './Bookmark';

type BookmarkRootProps = {
    isBookmarked: boolean;
    queryParams: string;
};

const BOOKMARK_DESKTOP_ROOT_ID = 'listings-root__bookmark-desktop';
const BOOKMARK_REMOVE_ACTION = 'unsave';
const BOOKMARK_ADD_ACTION = 'save';

const BookmarkDesktopMount = (
    bookmarkProps: BookmarkProps,
): JSX.Element | React.ReactPortal => {
    return dynaMount(
        {
            Component: Bookmark,
            containerId: BOOKMARK_DESKTOP_ROOT_ID,
        },
        bookmarkProps,
    );
};

const trackAnalytics = (action: string) => {
    trackAnalyticsEvent({
        action: action,
        category: GACodes.GA_CATEGORY_LISTING,
    });
};

const LazyBookmarkReminderModal = loadable(
    () => import('./BookmarkReminderModal'),
);

const BookmarkRoot = (props: BookmarkRootProps): JSX.Element | null => {
    const { id: eventId, childEvents } = useContext(
        EventBasicInformationContext,
    ) as EventBasicInformation;
    const { isBookmarked, queryParams } = props;
    const [isBookmarkedEvent, setIsBookmarkedEvent] = useState(isBookmarked);
    const { isAuthenticated, id: userId } = useContext(UserContext);
    const [isFetching, setIsFetching] = useState(false);
    const [shouldShowReminderModal, setShouldShowReminderModal] =
        useState(false);
    const { setNotificationState } = useNotificationsContext();
    const favoritesUrl = `/u/${userId}/favorites/events`;
    const action = isBookmarkedEvent
        ? BOOKMARK_REMOVE_ACTION
        : BOOKMARK_ADD_ACTION;

    useEffect(() => {
        if (!!childEvents && childEvents.length > 0 && isAuthenticated) {
            sdkRequest(`/users/${userId}/bookmarks/`, {
                method: 'GET',
            }).then((response: any) => {
                const isCurrentlyBookmarked = !!response.events.find(
                    (event: any) => event.id == eventId,
                );
                setIsBookmarkedEvent(isCurrentlyBookmarked);
            });
        }
    }, [eventId, childEvents, userId, isAuthenticated]);

    const errorNotificationState: NotificationBarProps = {
        isHidden: false,
        type: TYPE_ERROR,
        hasCloseButton: true,
        children: gettext(
            'We were unable to complete your request. Please try again.',
        ),
    };

    const bookmarkQueryParam = queryParams.includes(`bookmarkEvent=${eventId}`);

    const getSuccessNotificationMessage = () => {
        const text =
            action === BOOKMARK_ADD_ACTION
                ? gettext('Event added to')
                : gettext('Event removed from');
        const gettextWithMarkup = {
            __html: gettext(
                '%(text)s <a href=%(favoritesUrl)s target="_blank"}>Likes</a>',
                { text, favoritesUrl },
            ).toString(),
        };

        return (
            <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={gettextWithMarkup}
            />
        );
    };

    const successNotificationState: NotificationBarProps = {
        isHidden: false,
        type: TYPE_INFO,
        hasCloseButton: true,
        children: getSuccessNotificationMessage(),
    };

    const setBookmark = () => {
        const analyticsAction = isBookmarkedEvent
            ? GACodes?.GA_ACTION_BOOKMARK_REMOVE
            : GACodes?.GA_ACTION_BOOKMARK_ADD;

        trackAnalytics(analyticsAction);
        setIsFetching(true);

        sdkRequest(`/users/${userId}/bookmarks/${action}/`, {
            method: 'POST',
            body: JSON.stringify({
                event_id: eventId,
            }),
        })
            .then((response: { created?: boolean; deleted?: boolean }) => {
                if (response.created || response.deleted) {
                    setIsBookmarkedEvent(!isBookmarkedEvent);
                    if (!shouldShowReminderModal) {
                        setNotificationState({
                            ...successNotificationState,
                            children: getSuccessNotificationMessage(),
                        });
                    }
                    if (response.created) {
                        logEvent(
                            STATSIG_EVENT_NAMES.LISTING_ALL_SUCCESS_LIKE_EVENT,
                        );
                    }
                } else {
                    setNotificationState({
                        ...errorNotificationState,
                    });
                }
            })
            .catch(() => {
                setNotificationState({
                    ...errorNotificationState,
                });
            })
            .finally(() => {
                setIsFetching(false);
                if (bookmarkQueryParam) removeBookmarkQueryParam();
            });
    };

    useEffect(() => {
        if (bookmarkQueryParam && isAuthenticated && !isBookmarkedEvent) {
            const hasCookie = Cookies.get('ebLikesReminders');

            setShouldShowReminderModal(!hasCookie);
            setBookmark();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const removeBookmarkQueryParam = () => {
        // Remove bookmarkEvent from the URL
        // for older browsers, leaves a # behind
        window.location.hash = '';
        if (history && history.pushState) {
            history.pushState('', document.title, window.location.pathname);
        }
    };

    const handleClick = () => {
        if (!isAuthenticated || isFetching) {
            return false;
        }
        setShouldShowReminderModal(
            !isBookmarkedEvent && !Cookies.get('ebLikesReminders'),
        );

        displayJourneyUsingMetatags({
            name: 'SaveClicked',
            content: '1',
            extraData: {
                customActionParams: {
                    EventId: eventId,
                },
            },
        }); // Add metatag to display branchIO journeys
        setBookmark();
    };

    const onModalClose = () => {
        Cookies.set('ebLikesReminders', true, { expires: Infinity });
        setShouldShowReminderModal(false);
    };

    const onErrorModal = () => {
        setNotificationState({
            ...errorNotificationState,
            children: gettext(
                'We were unable to complete your settings request. Please try again.',
            ),
        });
    };

    return (
        <>
            {shouldShowReminderModal && (
                <LazyBookmarkReminderModal
                    onClose={onModalClose}
                    onError={onErrorModal}
                />
            )}
            <BookmarkDesktopMount
                isBookmarkedEvent={isBookmarkedEvent}
                eventId={eventId}
                onClickLoggedIn={handleClick}
            />
        </>
    );
};

export default BookmarkRoot;
