import {
    CONSUMER_CARD_DEFAULTS,
    DiscoveryEvent,
    EventCard,
    EventClient,
    EventClientProvider,
    FormattedEvent,
    transformDiscoveryEvent,
} from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { logEvent } from '@eventbrite/statsig';
import React, { useMemo } from 'react';
import { LISTING_HEAP_IDS } from '../../constants';
import { STATSIG_EVENT_NAMES } from '../../experimentation';
import { AffiliateCodes } from '../../types';
import { ShareProps } from '../ShareEvent/ShareEventRoot';
import { getSortedEvents } from '../_shared/getSortedEvents';
import { Trackable } from './ExpiredEventsTracking';

// Is this component still used? Seems completely deprecated
export function ExpiredEventsList({
    relatedEvents,
    promotedEvents,
    isAuthenticated,
    shareComponentProps,
    areMetricsEnabled,
}: {
    relatedEvents: DiscoveryEvent[];
    promotedEvents: FormattedEvent[];
    isAuthenticated: boolean;
    shareComponentProps: ShareProps;
    areMetricsEnabled: boolean;
}): JSX.Element {
    const affCode = AffiliateCodes.AFF_RELATED_EVENTS_EXPIRED;
    const eventClient = new EventClient();

    const allEvents = useMemo(
        () => getSortedEvents(relatedEvents as any, promotedEvents),
        [promotedEvents, relatedEvents],
    );

    return (
        <Trackable
            className="expired-wrapper expired-wrapper--bg-gray"
            events={allEvents}
        >
            <div className="expired-events__container">
                <div>
                    <h2 className="related-events__heading">
                        {gettext('Events you might like')}
                    </h2>
                </div>
                <ul className="expired-events-grid">
                    <EventClientProvider client={eventClient}>
                        {allEvents.map((event: any, i: number) => {
                            const shareEventProps = {
                                eventId: event.id,
                                eventName: event.name,
                                eventUrl: event.url,
                                ...shareComponentProps,
                            };
                            return (
                                <li
                                    key={i}
                                    className="expired-events-grid__item"
                                >
                                    <EventCard
                                        bucketLabel={
                                            LISTING_HEAP_IDS.RELATED_EVENTS_STRING
                                        }
                                        {...CONSUMER_CARD_DEFAULTS}
                                        eventData={
                                            event.isPromoted
                                                ? event
                                                : transformDiscoveryEvent(event)
                                        }
                                        heapLocationString={
                                            event.isPromoted
                                                ? 'Related Events - Event Expired Page - Promoted'
                                                : undefined
                                        }
                                        affCode={affCode}
                                        key={event.id}
                                        gaCategory="expired-events"
                                        isAuthenticated={isAuthenticated}
                                        shareOptions={shareEventProps}
                                        onWillShareClick={() =>
                                            areMetricsEnabled &&
                                            logEvent(
                                                STATSIG_EVENT_NAMES.LISTING_RELATED_EVENT_SHARE,
                                            )
                                        }
                                        onClick={() =>
                                            areMetricsEnabled &&
                                            logEvent(
                                                STATSIG_EVENT_NAMES.LISTING_RELATED_EVENT_CLICK,
                                            )
                                        }
                                        onSave={(saved) =>
                                            areMetricsEnabled &&
                                            // bug exists where saved doesn't update before it is passed, so checking for opposite of true
                                            !saved &&
                                            logEvent(
                                                STATSIG_EVENT_NAMES.LISTING_RELATED_EVENT_LIKE,
                                            )
                                        }
                                    />
                                </li>
                            );
                        })}
                    </EventClientProvider>
                </ul>
            </div>
        </Trackable>
    );
}
