import * as React from 'react';

const IdChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 8h12v8H6V8zM4 18V6h16v12H4zm5.5-6c.863 0 1.5-.637 1.5-1.5S10.363 9 9.5 9 8 9.637 8 10.5 8.636 12 9.5 12zm4.5-2h3v1.429h-3V10zm.75 2.857H17v1.429h-2.25v-1.429zm-2.178 1.812c0-.982-1.256-1.99-2.786-1.99-1.529 0-2.786 1.008-2.786 1.99V15h5.572v-.331z"
            fill="#010101"
        />
    </svg>
);

IdChunkySvg.displayName = 'IdChunkySvg';
export default IdChunkySvg;
