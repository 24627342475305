import { LanguageCode, __parentLanguageLookup } from './constants';
/**
 * Get correct parent locale which will have a corresponding file in the ./i18n/translations folder
 *
 * @param locale country code to correlate
 */
export function getParentLocale(locale: string | LanguageCode) {
    // Fallback to US if we don't find it in the lookup table
    return __parentLanguageLookup[locale as LanguageCode] || 'en_US';
}
