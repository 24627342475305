import {
    listingsBroadcastChannel,
    TabTrackerAction,
    TabTrackerMessagePayload,
} from './channels';

export function emitEventForExternalTab(eventId: string) {
    // If Broadcast channel is not supported just ignore these events
    // If no channelId is provided, ignore this
    if (typeof BroadcastChannel === 'undefined') {
        return undefined;
    }

    const channel = new BroadcastChannel(listingsBroadcastChannel);

    channel.postMessage({
        action: TabTrackerAction.request,
        eventId: eventId,
    });

    channel.onmessage = (
        broadcastMessage: MessageEvent<TabTrackerMessagePayload>,
    ) => {
        switch (broadcastMessage.data.action) {
            case TabTrackerAction.ack:
                channel.postMessage({
                    ...broadcastMessage.data,
                    action: TabTrackerAction.confirm,
                });
                channel.close();
                break;
        }
    };

    return channel;
}
