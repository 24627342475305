import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import {
    BOOKMARK_GA_ACTION,
    COLLECTION_SIGNAL_CLICKED,
    CREATOR_COLLECTION_CLICKED,
    EVENTCARD_CATEGORY,
    UNBOOKMARK_GA_ACTION,
    USER_VIEWS_HISTORY,
    VIEW_EVENT_GA_ACTION,
} from './constants';

export const trackUserViewedEvent = (eventId: string) => {
    const viewedEventsFromStorage = localStorage.getItem(USER_VIEWS_HISTORY);
    let eventsToStore: string[] = [];

    if (viewedEventsFromStorage === null) {
        eventsToStore = [eventId];
    } else {
        const storedEvents = viewedEventsFromStorage.split(',');
        eventsToStore = [eventId, ...storedEvents].slice(0, 10);
    }
    const dedupedEventsToStore = [...new Set(eventsToStore)];
    localStorage.setItem(USER_VIEWS_HISTORY, dedupedEventsToStore.toString());
};

export const trackCollectionSignalClick = (eventId: string) => {
    trackAnalyticsEvent({
        action: COLLECTION_SIGNAL_CLICKED,
        category: EVENTCARD_CATEGORY,
        label: eventId,
    });
};

export const trackCollectionClick = (collectionId: string) => {
    trackAnalyticsEvent({
        action: CREATOR_COLLECTION_CLICKED,
        category: EVENTCARD_CATEGORY,
        label: collectionId,
    });
};

export const trackEventClick = (
    category: string,
    id: string,
    organizerFollowerCount?: number,
    customLabel?: string,
) =>
    trackAnalyticsEvent({
        action: VIEW_EVENT_GA_ACTION,
        category,
        label: customLabel ? customLabel : id,
        metrics: {
            organizerFollowerCount: organizerFollowerCount || 0,
        },
    });

export const trackSaveEvent = (category: string, saved: boolean, id: string) =>
    trackAnalyticsEvent({
        category,
        action: saved ? BOOKMARK_GA_ACTION : UNBOOKMARK_GA_ACTION,
        dimensions: {
            eventId: id,
        },
    });

// Holding as placeholder until this package
// re-supports the share functionality.
export const trackDidShareEvent = (category: string, eventId: string) => {
    trackAnalyticsEvent({
        category,
        // action: SHARE_EVENT_GA_ACTION,
        label: eventId,
    });
};

// Holding as placeholder until this package
// re-supports the share functionality.
export const trackWillShareEvent = (category: string, eventId: string) => {
    trackAnalyticsEvent({
        category,
        //action: WILL_SHARE_GA_ACTION,
        label: eventId,
    });
};
