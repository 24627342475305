import {
    QueryClient,
    QueryClientProvider,
    useQueryClient,
} from '@tanstack/react-query';

// In case the implementation changes down the line,
// export wrappers around the clients.
export const EventClient = QueryClient;
export const EventClientProvider = QueryClientProvider;
export const useEventClient = useQueryClient;
