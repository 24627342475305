import { Icon } from '@eventbrite/eds-icon';
import {
    ChevronDownChunky,
    ChevronUpChunky,
} from '@eventbrite/eds-iconography';
import React, { useEffect } from 'react';
import {
    AccordionContentRoot,
    AccordionHeaderRoot,
    AccordionHeaderTitle,
    AccordionRoot,
    HiddenAccordionContent,
} from './Accordion.styles';

export type AccordionContextProps = {
    isExpanded: boolean;
    toggle: (event: React.SyntheticEvent, isExpanded: boolean) => void;
};

export type AccordionRootProps = {
    noBorder?: boolean;
};

export type AccordionHeaderRootProps = {
    noPadding?: boolean;
};

export const AccordionContext = React.createContext<AccordionContextProps>({
    isExpanded: false,
    // eslint-disable-next-line
    toggle: () => {},
});

export interface AccordionProps {
    children: React.ReactNode;
    isExpanded?: boolean;
    onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void;
    noBorder?: boolean;
    noPadding?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
    children,
    isExpanded: isDefaultExpanded = false,
    onChange,
    noBorder,
}) => {
    const [isExpanded, setIsExpanded] = React.useState(isDefaultExpanded);

    useEffect(() => {
        setIsExpanded(isDefaultExpanded);
    }, [isDefaultExpanded]);

    const handleChange = React.useCallback(
        (event: React.SyntheticEvent) => {
            setIsExpanded(!isExpanded);
            if (onChange) {
                onChange(event, !isExpanded);
            }
        },
        [isExpanded, onChange],
    );

    const contextValue = React.useMemo(
        () => ({
            isExpanded: isExpanded,
            toggle: handleChange,
        }),
        [isExpanded, handleChange],
    );

    return (
        <AccordionContext.Provider value={contextValue}>
            <AccordionRoot noBorder={noBorder}>{children}</AccordionRoot>
        </AccordionContext.Provider>
    );
};

export const AccordionHeader: React.FC<AccordionProps> = ({
    children,
    noPadding,
}) => {
    const { isExpanded, toggle } = React.useContext(AccordionContext);

    const handleChange = (event: React.SyntheticEvent) => {
        if (toggle) {
            toggle(event, isExpanded);
        }
    };

    return (
        <AccordionHeaderRoot onClick={handleChange} noPadding={noPadding}>
            <AccordionHeaderTitle>{children}</AccordionHeaderTitle>
            <div>
                <Icon
                    type={
                        isExpanded ? <ChevronUpChunky /> : <ChevronDownChunky />
                    }
                    size="small"
                    color="grey-700"
                />
            </div>
        </AccordionHeaderRoot>
    );
};

export const AccordionContent: React.FC = ({ children }) => {
    const { isExpanded } = React.useContext(AccordionContext);

    return isExpanded ? (
        <AccordionContentRoot data-testid="visible-accordion-content">
            {children}
        </AccordionContentRoot>
    ) : (
        <HiddenAccordionContent data-testid="hidden-accordion-content">
            {children}
        </HiddenAccordionContent>
    );
};
