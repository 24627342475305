import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { LISTING_HEAP_IDS } from '../constant';
import { READ_MORE_BUTTON_ID, READ_MORE_CONTENT_ID } from './constants';
import {
    TicketActionType,
    useTicketContext,
    useTicketDispatchContext,
} from './context';
import styles from './TicketCard.module.scss';

export const ReadMoreButton: React.FC = () => {
    const { expanded, description, displayFlags, id = '' } = useTicketContext();
    const dispatch = useTicketDispatchContext();
    const { showSalesEndDate, showRemaining } = displayFlags;
    const shouldShowReadMore = showSalesEndDate || showRemaining || description;
    const literal = {
        readMore: gettext('Read more'),
        close: gettext('Close'),
    };

    if (!shouldShowReadMore) {
        return null;
    }

    return (
        <button
            aria-expanded={!!expanded}
            aria-controls={`${READ_MORE_CONTENT_ID}-${id}`}
            data-heap-id={LISTING_HEAP_IDS.TICKET_READ_MORE_CLICK}
            id={`${READ_MORE_BUTTON_ID}-${id}`}
            className={styles.readMore}
            onClick={() =>
                dispatch({
                    type: TicketActionType.setExpanded,
                    payload: {
                        expanded: !expanded,
                    },
                })
            }
        >
            <Typography as="span" variant="body-sm-bold" color="control">
                {expanded ? literal.close : literal.readMore}
            </Typography>
        </button>
    );
};
