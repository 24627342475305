import { gettext } from '@eventbrite/i18n';

export const getTooltipContent = ({
    id,
    showLikesTooltip,
}: {
    id: string;
    showLikesTooltip: boolean;
}) => {
    const tooltipContent = {
        disableBeacon: true,
        offset: -20,
        placement: 'auto',
    };
    if (showLikesTooltip) {
        return {
            ...tooltipContent,
            content: gettext(
                'Like events to help us give you better event recommendations.',
            ),
            target: `.eds-event-card__actions-id__${id}__favorite`,
            title: gettext('Find more events you love'),
        };
    }

    // else return 'remove' tooltip content
    return {
        ...tooltipContent,
        content: gettext(
            'View more of what you love by hiding events you aren’t interested in.',
        ),
        target: `.eds-event-card-actions-id__${id}__remove-action`,
        title: gettext('Remove events from your feed'),
    };
};
