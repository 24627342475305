import { Button } from '@eventbrite/marmalade';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import { LISTING_HEAP_IDS } from '../../../../constants';
import { useScrollDispatch } from '../../../../contexts/ScrollContext';
import { STATSIG_EVENT_NAMES } from '../../../../experimentation';

export type ExploreSimilarEventsButtonProps = {
    handleClick?: Function;
};

export const ExploreSimilarEventsButton: React.FC<
    ExploreSimilarEventsButtonProps
> = ({ handleClick }) => {
    const label = 'Explore similar events';
    const dispatch = useScrollDispatch();

    return (
        <Button
            data-testid="explore-similar-events-button"
            variant="primary"
            data-tracking-label={label}
            onClick={() => {
                logEvent(STATSIG_EVENT_NAMES.LISTING_CONVERSION_BAR_CTA_CLICK);
                dispatch({ action: 'scroll', target: 'relatedEvents' });
                handleClick?.();
            }}
            data-heap-id={LISTING_HEAP_IDS.EXPLORE_SIMILAR_EVENTS}
        >
            {label}
        </Button>
    );
};
