import * as React from 'react';

const ThumbsDownChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="thumbs-down-chunky_svg__eds-icon--thumbs-down-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <g>
            <path d="M17.79 4h2l-.88 10h-2.15zm-2.07 10l-6.99 6a2.54 2.54 0 01-2.91-2.08A2.49 2.49 0 016.27 16l1.43-2H5a1 1 0 01-1-1 1.11 1.11 0 010-.19l1.26-6.35A3 3 0 018.2 4h6.31a2 2 0 012 2 1.21 1.21 0 010 .19zm-1.21-8H8.2a1 1 0 00-1 .8L6.21 12h5.39l-3.71 5.16a.52.52 0 00-.09.4.48.48 0 00.25.36L13.81 13z" />
        </g>
    </svg>
);

ThumbsDownChunkySvg.displayName = 'ThumbsDownChunkySvg';
export default ThumbsDownChunkySvg;
