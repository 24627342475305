import { CoreApplicationContext } from '@eventbrite/context-gen';

/**
 *
 * @param additionalTags - Tags that get added to the metadata for Sentry errors
 * @returns - A configuration object for initializing error reporting
 */
export const buildServerErrorContext = (additionalTags: any) => {
    if (window?.errorReportingContext) return window.errorReportingContext;
    const serverData =
        (window?.__SERVER_DATA__ as CoreApplicationContext) ?? undefined;
    if (!serverData) return;
    const user = serverData?.user;
    const request = serverData?.request;
    const localeInfo = serverData?.env?.localeInfo;

    return {
        appName: serverData?.app_name,
        appVersion: serverData?.app_version,
        environment: request?.lifecycle,
        sessionTrackingEnabled: true,
        shouldLogErrors: request?.lifecycle !== 'dev' ? true : false,
        metaData: {
            tags: {
                serverName: request?.serverName,
                ...additionalTags,
            },
        },
        user: {
            session: request?.session_id,
            authenticated: user?.isAuthenticated,
            id: user?.publicId,
            email: user?.email,
            locale: localeInfo?.locale,
            lang: localeInfo?.language_code,
            attendee: user?.isAttendee,
            has_any_global_permission: user?.hasGlobalPermissions,
        },
    };
};
