import { IconButton } from '@eventbrite/eds-icon-button';
import { HeartChunky, HeartFillChunky } from '@eventbrite/eds-iconography';
import { withTooltip } from '@eventbrite/eds-tooltip';
import { setWindowLocation } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context';
import {
    EventBasicInformation,
    EventBasicInformationContext,
} from '../../contexts/EventContext';
import './Bookmark.scss';

export type BookmarkProps = {
    eventId?: string;
    isBookmarkedEvent: boolean;
    onClickLoggedIn: () => void;
};

const STYLE_BRAND = 'brand';
const STYLE_NEUTRAL = 'neutral';

const bookmarkedEventLookup = (isBookmarked: boolean) =>
    isBookmarked
        ? {
              className: 'bookmark is-active',
              iconType: <HeartFillChunky />,
              iconStyle: STYLE_BRAND,
          }
        : {
              className: 'bookmark',
              iconType: <HeartChunky />,
              iconStyle: STYLE_NEUTRAL,
          };

export const Bookmark = (props: BookmarkProps) => {
    const { id: eventId } = useContext(
        EventBasicInformationContext,
    ) as EventBasicInformation;
    const { isBookmarkedEvent, onClickLoggedIn } = props;
    const { isAuthenticated } = useContext(UserContext);
    const isBookmarkedEventProps = bookmarkedEventLookup(isBookmarkedEvent);
    const [referrerPath, setReferrerPath] = useState('');
    const IconButtonWithTooltip = withTooltip(IconButton);

    useEffect(() => {
        const referrerURL = new URL(window.location.href);
        referrerURL.searchParams.set('bookmarkEvent', eventId);
        const pathWithQuery = encodeURIComponent(
            referrerURL.pathname + referrerURL.search,
        );
        setReferrerPath(pathWithQuery);
    }, [eventId]);

    const handleClickLoggedOut = () => {
        redirectToLogin();
    };

    const redirectToLogin = useCallback(() => {
        const loginWithReferrerPath = `/login/?referrer=${referrerPath}`;
        return setWindowLocation(loginWithReferrerPath);
    }, [referrerPath]);

    return (
        <>
            <div className={isBookmarkedEventProps.className}>
                <IconButtonWithTooltip
                    aria-pressed={isAuthenticated && isBookmarkedEvent}
                    iconType={isBookmarkedEventProps.iconType}
                    tooltipId="tooltip-bookmark"
                    tooltipStyle="basic"
                    title={gettext('Like Event')}
                    tooltipText={gettext('Like Event')}
                    style={
                        isAuthenticated
                            ? isBookmarkedEventProps.iconStyle
                            : STYLE_NEUTRAL
                    }
                    onClick={
                        isAuthenticated ? onClickLoggedIn : handleClickLoggedOut
                    }
                />
            </div>
        </>
    );
};

export default Bookmark;
