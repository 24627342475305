import { gettext } from '@eventbrite/i18n';
import { Button } from '@eventbrite/marmalade';
import React from 'react';
import { useScrollDispatch } from '../../../../contexts';

import { logEvent } from '@eventbrite/statsig';
import { LISTING_HEAP_IDS } from '../../../../constants';
import { STATSIG_EVENT_NAMES } from '../../../../experimentation';
import { useTrackComponent } from '../../../hooks/useTrackComponent';
import './GoToTicketsButton.scss';

export const GoToTicketsButton = () => {
    const scrollTo = useScrollDispatch();

    const goToTicketsRef = React.useRef<HTMLDivElement>(null);

    useTrackComponent({
        ref: goToTicketsRef,
        statsig: {
            eventName:
                STATSIG_EVENT_NAMES.LISTING_MULTITICKET_ANCHOR_BUTTON_VIEW,
        },
    });

    return (
        <div className="GoToTicketButton__container" ref={goToTicketsRef}>
            <Button
                onClick={() => {
                    logEvent(
                        STATSIG_EVENT_NAMES.LISTING_MULTITICKET_ANCHOR_BUTTON_CLICK,
                    );
                    scrollTo({ action: 'scroll', target: 'tickets' });
                }}
                variant="primary"
                size="block"
                aria-label={gettext('Select tickets')}
                data-heap-id={LISTING_HEAP_IDS.TICKETS_ANCHOR_BUTTON_CLICK}
            >
                {gettext('Select tickets')}
            </Button>
        </div>
    );
};
