import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import React, { useContext, useEffect, useState } from 'react';
import {
    GA_ACTION_REASON_SELECTED,
    GA_CONTACT_ORGANIZER_CATEGORY,
} from '../constants';
import { User, UserContext } from './User';

export interface FormField {
    val?: string;
    err?: string;
}

export interface FormState {
    [fieldName: string]: FormField;
}

export interface ContactForm {
    formState: FormState;
    setFormState: Function;
}

export const FormContext = React.createContext<Partial<ContactForm>>({});

const getFormDataFromUser = (
    user: User | null,
): { name: FormField; email: FormField } | {} => {
    if (user) {
        const email = user.emails ? user.emails[0]?.email : '';
        const name = user.name;

        return { email: { val: email }, name: { val: name } };
    }

    return {};
};

interface FormProviderProps {
    initialState?: FormState;
}

export const FormProvider: React.FunctionComponent<FormProviderProps> = (
    props,
) => {
    const [formState, setFormState] = useState(props.initialState || {});
    const user = useContext(UserContext);

    const handleSetFormState = (newState: FormState) => {
        setFormState({ ...formState, ...newState });

        if (newState.reason?.val != formState.reason?.val) {
            trackAnalyticsEvent({
                action: GA_ACTION_REASON_SELECTED,
                category: GA_CONTACT_ORGANIZER_CATEGORY,
                label: newState.reason.val,
            });
        }
    };

    useEffect(() => {
        return function cleanUp() {
            setFormState && setFormState({});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Include the logged in users email/name
    // if the current values are undefined
    const userFormData = getFormDataFromUser(user as User);

    return (
        <FormContext.Provider
            value={{
                formState: { ...userFormData, ...formState },
                setFormState: handleSetFormState,
            }}
        >
            {props.children}
        </FormContext.Provider>
    );
};
