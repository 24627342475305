import { gettext } from '@eventbrite/i18n';
import { InlineAlert } from '@eventbrite/marmalade';
import React from 'react';
import type { ErrorBannerProps } from './types';

import './ErrorBanner.scss';

export const ErrorBanner = ({
    title,
    description,
    closeText,
    onCloseClick,
}: ErrorBannerProps) => {
    const errorBannerRef = React.useRef<HTMLDivElement>(null);
    const defaultErrorDescription = gettext(
        'Something went wrong. Please try again.',
    );

    return (
        <div
            className="error-banner__container"
            data-heap-id="error-banner-multiticket"
            ref={errorBannerRef}
        >
            <div className="error-banner__inner-container">
                <InlineAlert
                    title={title}
                    description={description || defaultErrorDescription}
                    variant="danger"
                    closeOnClick={onCloseClick}
                    closeTitle={closeText || gettext('Close')}
                    className={'error-banner__alert'}
                />
            </div>
        </div>
    );
};
