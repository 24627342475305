import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

// withMainControls is a HOC used to wrap a component.
// It adds function props to the component which is used in tandem with
// `addMainControls` to show global UI elements w/in the main content
const withMainControls = (Component) =>
    class WithMainControlsWrapper extends PureComponent {
        static contextTypes = {
            showMainBottomBar: PropTypes.func,
            closeMainBottomBar: PropTypes.func,
            addMainNotification: PropTypes.func,
            hideMainNotification: PropTypes.func,
        };

        // Read functions out of context and add it as a props to the component
        render() {
            const {
                showMainBottomBar,
                closeMainBottomBar,
                addMainNotification,
                hideMainNotification,
            } = this.context;

            return (
                <Component
                    showMainBottomBar={showMainBottomBar}
                    closeMainBottomBar={closeMainBottomBar}
                    addMainNotification={addMainNotification}
                    hideMainNotification={hideMainNotification}
                    {...this.props}
                />
            );
        }
    };

export default withMainControls;
