import { format } from 'date-fns';
import {
    ChildEventProps,
    SALES_STATUS,
    UNDETERMINED_SALES_STATUSES,
} from '../../../../types';
import {
    eventIsOnSale,
    eventIsSalesEnded,
    eventIsSoldOut,
} from '../../../../utils';

export const isChildAvailable =
    ({
        isSoldOut,
        isTimedEntryEnabled,
    }: {
        isSoldOut?: boolean;
        isTimedEntryEnabled?: boolean;
    }) =>
    (event: ChildEventProps) => {
        if (
            eventIsOnSale(event) ||
            UNDETERMINED_SALES_STATUSES.includes(
                event?.sales_status as SALES_STATUS,
            )
        ) {
            return true;
        }

        return (
            (isTimedEntryEnabled && !isSoldOut && eventIsSoldOut(event)) ||
            eventIsSalesEnded(event)
        );
    };

export const shouldShowMoreOptions = (
    childEventsOnSlider: ChildEventProps[],
    isTimedEntryEnabled?: boolean,
    isSoldOut?: boolean,
) => {
    const slicedEventDates = isTimedEntryEnabled ? 4 : 5;
    const availableFollowingEvents = childEventsOnSlider
        .slice(slicedEventDates)
        .filter(isChildAvailable({ isSoldOut, isTimedEntryEnabled }));
    return (
        childEventsOnSlider.length > slicedEventDates &&
        availableFollowingEvents.length > 0
    );
};

export const getMonthNameAndYear = (
    dateString: string,
): {
    year: string;
    month: string;
} => {
    const month = dateString.substring(5, 7);
    const year = dateString.substring(0, 4);
    return { year, month };
};

export const formatDateToString = (
    date: Date,
    formatString = 'yyyy-MM-dd',
): string => {
    return format(date, formatString);
};
