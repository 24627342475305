import { gettext } from '@eventbrite/i18n';
import loadable from '@loadable/component';
import React, { useState } from 'react';
import { AppProps } from '../../types';
import { useIntersecting } from '../hooks/useIntersecting';
import { dynaMount } from '../utils/mountComponent';
import { CollectionCards } from '../_shared/CollectionCards';
import { LoadMoreCollections } from '../_shared/LoadMoreCollections';
import { Modal } from '../_shared/Modal';
import { useEventCollections } from '../_shared/useEventCollections';
import {
    COLLECTIONS_PAGE_SIZE,
    CREATOR_COLLECTIONS_LINK_ID,
    CREATOR_COLLECTIONS_LINK_MOBILE_ID,
    MODAL_LAYOUT_COLUMNS,
    RELATED_COLLECTIONS_ID,
} from './constants';
import { CreatorCollectionsLink } from './CreatorCollectionsLink';

export const CreatorCollectionsLinkMount = (props: {
    onViewMoreCollections: (isOpen: boolean) => void;
    collection?: any[];
    count: number;
    // This prop `isMobile` is because of https://eventbrite.atlassian.net/browse/EB-176094 and should be removed when the new design of the Hero section is done
    isMobile?: boolean;
}): JSX.Element | React.ReactPortal | null => {
    const containerId = props.isMobile
        ? CREATOR_COLLECTIONS_LINK_ID
        : CREATOR_COLLECTIONS_LINK_MOBILE_ID;

    return dynaMount(
        {
            Component: (props) => (
                <CreatorCollectionsLink
                    viewMoreCollections={props.onViewMoreCollections}
                    count={props.count}
                    collection={props.collection || undefined}
                />
            ),
            containerId,
        },
        { ...props },
    );
};

const getCreatorCollectionsProps = (props: AppProps) => {
    const {
        event: { id: eventId },
    } = props;

    return {
        eventId,
    };
};

const filterCollections = (collections: any[], collectionAmount: number) => {
    return collections.slice(0, collectionAmount);
};

export const LazyMoreRelatedCollections = loadable(
    () => import('./MoreRelatedCollections'),
);

const CreatorCollectionsRoot = (props: AppProps) => {
    const [opened, setOpened] = useState(false);
    const moreRelatedCollectionsContainer = document.getElementById(
        RELATED_COLLECTIONS_ID,
    );
    const [isIntersecting] = useIntersecting({
        domNode: moreRelatedCollectionsContainer,
    });

    const creatorCollectionsProps = {
        ...getCreatorCollectionsProps(props),
        onViewMoreCollections: setOpened,
    };

    const {
        collections,
        count,
        loadNextPage,
        hasMoreCollections,
        areCollectionsLoading,
    } = useEventCollections(
        creatorCollectionsProps.eventId,
        COLLECTIONS_PAGE_SIZE,
    );

    const collectionsLinkProps = {
        count,
        collection: count === 1 ? filterCollections(collections, 1) : undefined,
        onViewMoreCollections: setOpened,
    };

    const hasSeveralCreatorCollections = count > 1;

    return (
        <>
            <CreatorCollectionsLinkMount {...collectionsLinkProps} />
            {/* This component is because of https://eventbrite.atlassian.net/browse/EB-176094 and should be removed when the new design of the Hero section is done */}
            <CreatorCollectionsLinkMount
                {...{
                    ...collectionsLinkProps,
                    isMobile: true,
                }}
            />
            {hasSeveralCreatorCollections && (
                <>
                    {isIntersecting ? (
                        <LazyMoreRelatedCollections
                            {...{
                                ...creatorCollectionsProps,
                                collections: filterCollections(collections, 3),
                                count,
                            }}
                        />
                    ) : null}
                    <Modal
                        title={gettext('View collections').toString()}
                        visible={opened}
                        noMinHeight={true}
                        onClose={() => setOpened(false)}
                    >
                        <CollectionCards
                            collections={collections}
                            columns={MODAL_LAYOUT_COLUMNS}
                        />
                        <LoadMoreCollections
                            areCollectionsLoading={areCollectionsLoading}
                            loadNextPage={loadNextPage}
                            hasMoreCollections={hasMoreCollections}
                        />
                    </Modal>
                </>
            )}
        </>
    );
};

export default CreatorCollectionsRoot;
