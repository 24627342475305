import {
    DefaultAbstractProfileFour,
    DefaultAbstractProfileOne,
    DefaultAbstractProfileThree,
    DefaultAbstractProfileTwo,
} from '@eventbrite/eds-iconography';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import {
    CalendarAgendaIcon,
    HeartIcon,
    MegaphoneIcon,
} from '@eventbrite/marmalade';
import React from 'react';

import { numberFormatter } from '../../utils/utils';

import { TOTAL_RATING_THRESHOLD } from '../constants';

import type {
    CreatorBadgeResponse,
    CreatorSignalQualityResponse,
    Hypothesis,
    HypothesisAttendeesHosted,
    HypothesisEventsHosted,
    HypothesisTenureInMonths,
    HypothesisValue1,
    IRatingResponse,
} from '../../RelatedEvents/types/api';

type HypothesisStringGenerator = (value: any) => GenericLazyString | null;
function getHypothesis1String(
    eventCount: number,
    monthCount: number,
): GenericLazyString {
    let returnString: GenericLazyString;
    if (eventCount <= 1) {
        returnString = gettext('New to Eventbrite');
    }

    if (monthCount <= 1) {
        returnString = gettext(
            '<strong>%(eventCount)s</strong> events in the last month',
            { eventCount },
        );
    } else if (monthCount <= 11) {
        returnString = gettext(
            '<strong>%(eventCount)s</strong> events in  last %(monthCount)s months',
            { eventCount, monthCount },
        );
    } else if (monthCount < 18) {
        returnString = gettext(
            '<strong>%(eventCount)s</strong> events in 1 year',
            {
                eventCount,
            },
        );
    } else {
        returnString = gettext(
            '<strong>%(eventCount)s</strong> events in %(year)s years',
            {
                eventCount,
                year: Math.floor(monthCount / 12),
            },
        );
    }
    return returnString;
}
export let selectedIcon: React.ReactNode = null;

const hypothesisStringGenerators: Record<string, HypothesisStringGenerator> = {
    'hypothesis-1': (value: HypothesisValue1) => {
        const noOfEvents = Number(value.noOfEvents);
        const monthCount = Number(value.timeInMonths);
        selectedIcon = <CalendarAgendaIcon />;
        return getHypothesis1String(noOfEvents, monthCount);
    },
    eventsHosted: (value: HypothesisEventsHosted) => {
        const eventCount = Number(value.eventsHosted);
        selectedIcon = <CalendarAgendaIcon />;
        return gettext('<strong>%(count)s</strong> events hosted', {
            count: numberFormatter(eventCount),
        });
    },
    tenureInMonths: (value: HypothesisTenureInMonths) => {
        const months = Number(value.tenureInMonths);
        selectedIcon = <CalendarAgendaIcon />;
        if (months < 9) {
            return gettext('New to Eventbrite');
        } else if (months >= 9 && months < 24) {
            return gettext('<strong>1 year</strong> on Eventbrite');
        } else {
            return gettext('<strong>%(year)s years</strong> on Eventbrite', {
                year: Math.floor(months / 12),
            });
        }
    },
    attendeesHosted: (value: HypothesisAttendeesHosted) => {
        const attendiesCount = Number(value.attendeesHosted);
        selectedIcon = <MegaphoneIcon />;
        return gettext('<strong>%(attendies)s</strong> attendees hosted', {
            attendies: numberFormatter(attendiesCount),
        });
    },
    repeatAttendees: () => {
        selectedIcon = <HeartIcon />;
        return gettext('Lots of repeat customers');
    },
};

export function getTrustSignalText(
    creatorSignal: CreatorSignalQualityResponse | undefined,
): React.ReactNode {
    if (!creatorSignal) {
        return null;
    }

    const selectedHypothesisIndex = getSelectedHypothesisIndex(creatorSignal);

    if (
        !isValidHypothesisIndex(
            creatorSignal.hypothesis,
            selectedHypothesisIndex,
        )
    ) {
        return null;
    }

    const selectedHypothesis =
        creatorSignal.hypothesis[selectedHypothesisIndex];
    const hypothesisString = getHypothesisStrings(selectedHypothesis);

    if (!isValidHypothesisStrings(hypothesisString)) {
        return null;
    }

    return (
        <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: String(hypothesisString) }}
        ></span>
    );
}

function getSelectedHypothesisIndex(
    creatorSignal: CreatorSignalQualityResponse,
): number {
    return Number(creatorSignal.selectedHypothesis[0]);
}

function isValidHypothesisIndex(
    hypothesis: Hypothesis[],
    index: number,
): boolean {
    return hypothesis && index >= 0 && index < hypothesis.length;
}

function getHypothesisStrings(
    selectedHypothesis: Hypothesis,
): GenericLazyString | null {
    const hypothesisStringGenerator =
        hypothesisStringGenerators[selectedHypothesis.name];

    if (!hypothesisStringGenerator) {
        return null;
    }

    return hypothesisStringGenerator(selectedHypothesis.value);
}

function isValidHypothesisStrings(
    hypothesisStrings: GenericLazyString | null,
): hypothesisStrings is GenericLazyString {
    return hypothesisStrings !== null;
}

export function removeHtmlTags(input: string) {
    const textWithoutHtml = input.replace(/<[^>]*>/g, '');
    return textWithoutHtml.replace(/\s+/g, ' ').trim();
}

export const calculateTopOffset = () => {
    let isMobile = false;
    if (typeof window !== 'undefined') {
        isMobile = window?.innerWidth <= 768;
    }
    return isMobile;
};

export function extractInitials(organizerName: string) {
    const initials =
        organizerName &&
        organizerName
            .trim()
            .split(' ')
            .map((word, index) => {
                if (index == 0 || index == 1) {
                    return word
                        .split('')
                        .find((char) => char.match(/[a-zA-Z]/));
                }
                return null;
            })
            .filter((word) => word != null)
            .join('');

    return initials.length > 0
        ? initials.toUpperCase()
        : organizerName[0].toUpperCase();
}

export function pickDefaultAvatarIcon(organizerId: string) {
    const iconsList = [
        DefaultAbstractProfileOne,
        DefaultAbstractProfileTwo,
        DefaultAbstractProfileThree,
        DefaultAbstractProfileFour,
    ];
    const indexForIcon = Number(organizerId) % (iconsList.length - 1);

    const SelectedIcon = iconsList[indexForIcon];

    return <SelectedIcon />;
}

export function checkForOrganiserNameCondition(
    organizerName: string | null | undefined,
) {
    const conditionForOrganiserName =
        organizerName && organizerName?.trim().length > 0;
    return conditionForOrganiserName;
}

export function getSignalTypeText(
    creatorSignal: CreatorSignalQualityResponse | undefined,
): string {
    if (!creatorSignal) {
        return '';
    }

    const selectedHypothesisIndex = getSelectedHypothesisIndex(creatorSignal);

    if (
        !isValidHypothesisIndex(
            creatorSignal.hypothesis,
            selectedHypothesisIndex,
        )
    ) {
        return '';
    }

    const selectedHypothesis =
        creatorSignal.hypothesis[selectedHypothesisIndex];

    return selectedHypothesis.name;
}

export function getCreatorBadgeText(
    creatorBadgeData?: CreatorBadgeResponse,
): boolean {
    return creatorBadgeData ? true : false;
}

export function isRatingsCriteriaMatching(
    ratingsData: IRatingResponse['ratings'],
) {
    return (
        Number(ratingsData.overall_experience.total ?? 0) >
        TOTAL_RATING_THRESHOLD
    );
}
