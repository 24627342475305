import loadable from '@loadable/component';
import React from 'react';
import { EnhancedAppProps, SALES_STATUS } from '../../types';
import { useIntersecting } from '../hooks/useIntersecting';
import { getShareComponentProps } from '../ShareEvent/ShareEventRoot';
import { MORE_EVENTS_FROM_ID } from './constants';
import { RelatedEventsProps } from './types';

const RelatedEvents = loadable(() => import('./RelatedEvents'));

export const getRelatedEventsProps = (
    props: EnhancedAppProps,
): RelatedEventsProps => {
    const {
        event: {
            id: eventId,
            salesStatus: { sales_status },
        },
        user: { isAuthenticated },
        organizer: { id: organizerId },
        env: {
            localeInfo: { locale },
        },
        creatorCollections,
    } = props;

    const shareComponentProps = getShareComponentProps(props);

    return {
        eventId,
        isAuthenticated,
        shareComponentProps,
        organizerId,
        creatorCollections,
        locale,
        areMetricsEnabled:
            sales_status === SALES_STATUS.SALES_ENDED ||
            sales_status === SALES_STATUS.SOLD_OUT,
    };
};

const RelatedEventsRoot = (props: EnhancedAppProps): JSX.Element | null => {
    // TODO: We should replace this part to use
    // enable_more_events_from_this_organizer_on_listings FF from the mako template
    // https://github.com/eventbrite/core/blob/master/django/templates/event/event_page/listing/_event_listing_body.html#L30
    const [isIntersecting] = useIntersecting({
        callback: () => document.getElementById(MORE_EVENTS_FROM_ID),
    });

    const relatedEventsProps = getRelatedEventsProps(props);

    return isIntersecting ? <RelatedEvents {...relatedEventsProps} /> : null;
};

export default RelatedEventsRoot;
