import styled from '@emotion/styled';
import { day as EDSColors } from '@eventbrite/eds-settings';
import { AccordionHeaderRootProps, AccordionRootProps } from './Accordion';

export const AccordionRoot = styled.div`
    border-bottom: ${(props: AccordionRootProps) =>
        props.noBorder ? 'none' : `1px solid ${EDSColors['ui-200']}`};
`;
AccordionRoot.displayName = 'AccordionRoot';

export const AccordionHeaderRoot = styled.div`
    align-items: left;
    color: ${EDSColors['ui-900']};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    gap: 20px;
    line-height: 20px;
    margin: 0;
    padding: ${(props: AccordionHeaderRootProps) =>
        props.noPadding ? 'none' : '22px 16px'};
`;
AccordionHeaderRoot.displayName = 'AccordionHeaderRoot';

export const AccordionHeaderTitle = styled.div`
    flex-basis: 100%;
`;
AccordionHeaderTitle.displayName = 'AccordionHeaderTitle';

export const AccordionContentRoot = styled.div`
    margin: 8px 0 16px;
`;
AccordionContentRoot.displayName = 'AccordionContentRoot';

export const HiddenAccordionContent = styled.div`
    display: none;
`;
HiddenAccordionContent.displayName = 'HiddenAccordionContent';
