import { getWindowObject } from '@eventbrite/feature-detection';
import { getDeviceType, UADeviceType } from '@eventbrite/user-agent-parser';
import {
    StatsigDeviceData,
    StatsigDeviceType,
} from '../models/statsigDeviceData';

const deviceData: StatsigDeviceData = {};

/**
 * Utility that will provide the caller with a config object
 * which includes `bucketedDeviceWidth` and `deviceType`
 * metrics. Addtionally, consumers can opt into `deviceWidth` with
 * no additional massaging applied.
 *
 * @param userAgent
 * @returns
 */
export function getDeviceData({
    userAgent = '',
    includeRawDeviceWidth = false,
} = {}): StatsigDeviceData {
    const deviceWidth = getDeviceWidthRounded();
    if (deviceWidth) {
        deviceData.bucketedDeviceWidth = getBucketedDeviceWidth(deviceWidth);
    }

    if (includeRawDeviceWidth) {
        deviceData.deviceWidth = `${deviceWidth}`;
    }

    //Attempt to parse device type off the user agent
    if (deviceData.deviceType === undefined) {
        const ua = userAgent
            ? userAgent
            : getWindowObject('navigator')?.userAgent;

        if (ua) {
            const device = getDeviceType(ua);
            const type = parseDeviceTypeForStatsig(device);
            deviceData.deviceType = type;
        }
    }

    return deviceData;
}

/**
 * Returns a string representation of what the current
 * device width closest device representation is.
 *
 * This allows us to segment specific metrics by
 * this piece of metadata and understand impact
 * across experiences.
 *
 * The values are pulled from EDS Breakpoints
 * with `small` representing mobile and `medium`
 * representing tablet widths.
 *
 * https://eventbrite.atlassian.net/wiki/spaces/FRONTEND/pages/15500640332/EDS+Breakpoints
 *
 * @param deviceWidth
 * @returns 'mobile' | 'tablet' | 'desktop'
 */
export function getBucketedDeviceWidth(deviceWidth: number): StatsigDeviceType {
    //FIXME: Use centralized breakpoints
    if (deviceWidth < 481) {
        return 'mobile';
    }
    if (deviceWidth < 960) {
        return 'tablet';
    }
    return 'desktop';
}

function getDeviceWidthRounded(): number | null {
    const innerWidth = getWindowObject('innerWidth');

    if (typeof innerWidth !== 'number') {
        return null;
    }

    const roundedInnerWidth = Math.round(innerWidth / 10) * 10;
    return roundedInnerWidth;
}

function parseDeviceTypeForStatsig({ type }: UADeviceType): StatsigDeviceType {
    if (type === 'desktop') return type;
    if (type === 'mobile') return type;
    if (type === 'tablet') return type;

    return 'other';
}
