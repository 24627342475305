import { ChoiceCard } from '@eventbrite/marmalade';
import React from 'react';
import { LISTING_HEAP_IDS } from '../constant';
import { TicketContextProvider } from './context';
import { useTrackComponent } from './hooks/useTrackComponent';
import { QuantityRemaining } from './QuantityRemaining';
import { ReadMoreButton } from './ReadMoreButton';
import { ReadMoreContent } from './ReadMoreContent';
import styles from './TicketCard.module.scss';
import { TicketCosts } from './TicketCosts';
import { TicketDescription } from './TicketDescription';
import { TicketHeading } from './TicketHeading';
import {
    SalesEndStatus,
    SoldOutStatus,
    TicketSalesStatus,
} from './TicketSalesStatus';
import { TicketStepper } from './TicketStepper';
import type { TicketCardType } from './types';

export const TicketCard: React.FC<TicketCardType> = ({
    onSaleStatus,
    minimumQuantity,
    maximumQuantityPerOrder,
    displayFlags,
    description,
    ...props
}) => {
    const { showSalesEndDate, showRemaining } = displayFlags;
    const readMoreRef = React.useRef<HTMLDivElement>(null);

    useTrackComponent({
        ref: readMoreRef,
        eventName: LISTING_HEAP_IDS.TICKET_READ_MORE_VIEW,
        eventBucketLabel: 'Views on Read more/ ticket',
    });
    return (
        <TicketContextProvider
            value={{
                onSaleStatus,
                minimumQuantity,
                maximumQuantityPerOrder,
                displayFlags,
                description,
                ...props,
            }}
        >
            <ChoiceCard className={styles.root}>
                <div className={styles.information}>
                    <div className={styles.header}>
                        <div className={styles.headerLeft}>
                            <SoldOutStatus />
                            <TicketHeading />
                        </div>
                        <div className={styles.headerRight}>
                            {!onSaleStatus.notOnSale && (
                                <TicketStepper
                                    minimumQuantity={minimumQuantity}
                                    maximumQuantityPerOrder={
                                        maximumQuantityPerOrder
                                    }
                                />
                            )}
                            <TicketSalesStatus />
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.bodyRow} ref={readMoreRef}>
                            <TicketCosts />
                            <ReadMoreButton />
                        </div>
                        <ReadMoreContent>
                            <>
                                {(showSalesEndDate || showRemaining) && (
                                    <div className={styles.bodyRow}>
                                        <SalesEndStatus />
                                        <QuantityRemaining />
                                    </div>
                                )}
                                {description && (
                                    <div className={styles.bodyRow}>
                                        <TicketDescription />
                                    </div>
                                )}
                            </>
                        </ReadMoreContent>
                    </div>
                </div>
            </ChoiceCard>
        </TicketContextProvider>
    );
};
