import { EventInteraction, useTracking } from '@eventbrite/ads';
import { ContactOrganizerButton } from '@eventbrite/contact-organizer-button';
import { Avatar } from '@eventbrite/eds-avatar';
import { gettext } from '@eventbrite/i18n';
import React, { useContext } from 'react';
import { LISTING_HEAP_PAGE_AREA_IDS } from '../../../../constants';
import { TrackingContext } from '../../../../contexts';
import { SocialLinksStyle } from '../../../../types';
import { EventDetailsSectionTitle } from '../../../EventDetails/EventDetails';
import { OrganizerPanelLayoutProps } from '../../../OrganizerPanel/OrganizerPanelLayout';
import { SocialLinks } from '../../../SocialLinks';
import { FollowButton, useFollowCount } from '../../FollowButton/FollowButton';
import {
    OrganizerStats,
    StyleTypes,
} from '../../OrganizerStats/OrganizerStats';
import { ReadMore, ReadMoreContent, ReadMoreToggle } from '../../ReadMore';
import { DescriptiveOrganizerInfoProps } from '../DescriptiveOrganizerInfo';
import { calculateTopOffset, removeHtmlTags } from '../helperFunctions';
import {
    ABOUT_ORGANIZER_SUBINTERFACE,
    aggregateContext,
    LISTINGS_EXPIRED_INTERFACE,
    LISTINGS_LIVE_INTERFACE,
} from '../utils';
import './DescriptiveOrganizerPanelView.scss';

export type TDescriptiveOrganizerPanelViewProps = OrganizerPanelLayoutProps &
    DescriptiveOrganizerInfoProps;

export const DescriptiveOrganizerPanelView = ({
    captchaKey,
    eventName,
    gaCategory,
    isAuthenticated,
    organizerAvatar,
    organizerDescription,
    organizerFacebook,
    organizerId,
    organizerName,
    organizerTwitter,
    organizerUrl,
    organizerWebsite,
    pageArea,
    trustSignal,
    eventId,
}: TDescriptiveOrganizerPanelViewProps) => {
    const followersCount = useFollowCount();
    const isMobile = calculateTopOffset();

    const orgainiserDescriptionTextOnly =
        organizerDescription && removeHtmlTags(organizerDescription);

    const context = useContext(TrackingContext);
    const getTrackingContext = ({ id, event }: EventInteraction) =>
        aggregateContext({
            id,
            event: { ...event, id },
            context,
            listingsType: context.event?.isExpiredEvent
                ? LISTINGS_EXPIRED_INTERFACE
                : LISTINGS_LIVE_INTERFACE,
            subInterface: ABOUT_ORGANIZER_SUBINTERFACE,
        });
    const { ref } = useTracking<HTMLDivElement>(getTrackingContext);

    const renderOrganiserStats = () => (
        <>
            <OrganizerStats
                style={StyleTypes.CONDENSED_FULL_WIDTH}
                organizer={{ id: organizerId }}
                followersCount={followersCount || null}
            />

            <div className="descriptive-organizer-info-mobile_event-number">
                {trustSignal}
            </div>
        </>
    );

    const renderFollowButtonSection = () => (
        <li>
            <FollowButton
                pageArea={
                    pageArea || LISTING_HEAP_PAGE_AREA_IDS.SIMPLIFIED_ORGANIZER
                }
            />
        </li>
    );

    const renderContactSection = () => (
        <li>
            <ContactOrganizerButton
                {...{
                    organizerId: organizerId,
                    organizerName: organizerName,
                    captchaKey: captchaKey,
                    isVisible: false,
                    isAuthenticated: isAuthenticated,
                    eventName: eventName,
                    eventId: eventId,
                    gaCategory: gaCategory,
                    domNodeId: 'event-page',
                }}
            />
        </li>
    );

    const renderReadMoreSection = () => (
        <>
            {orgainiserDescriptionTextOnly &&
                orgainiserDescriptionTextOnly.length > 0 && (
                    <ReadMore
                        ariaLabel={gettext('Organizer description')}
                        initialIsExpanded={false}
                    >
                        <div className="descriptive-organizer-info-mobile__description">
                            <ReadMoreContent
                                previewContentType="line"
                                previewLineCount={{
                                    mobile: 5,
                                    tablet: 5,
                                    desktop: 3,
                                }}
                            >
                                <div
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: organizerDescription,
                                    }}
                                ></div>
                            </ReadMoreContent>
                            <ReadMoreToggle />
                        </div>
                    </ReadMore>
                )}
        </>
    );

    return (
        <section aria-label="Organizer profile" ref={ref}>
            <EventDetailsSectionTitle>
                <h2>
                    {isMobile
                        ? gettext('About the organizer')
                        : gettext('Organized by')}
                </h2>
            </EventDetailsSectionTitle>

            <div className="descriptive-organizer-info-mobile">
                {organizerAvatar && (
                    <div className="descriptive-organizer-info-mobile__avatar">
                        <Avatar imageUrl={organizerAvatar} />
                    </div>
                )}
                <div className="descriptive-organizer-info-heading-signal-container">
                    {organizerName && (
                        <div
                            className="descriptive-organizer-info-mobile__name"
                            data-testid="organizer-name"
                        >
                            <a
                                href={organizerUrl}
                                className="descriptive-organizer-info-mobile__name-link"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {organizerName}
                            </a>
                        </div>
                    )}

                    <div className="descriptive-organizer-info-mobile__followers-stats">
                        {renderOrganiserStats()}
                    </div>
                </div>
                <ul
                    className={
                        organizerName
                            ? 'descriptive-organizer-info__actions__variantB'
                            : 'descriptive-organizer-info__remove-flex'
                    }
                >
                    {renderContactSection()}
                    {organizerName && renderFollowButtonSection()}
                </ul>
                {renderReadMoreSection()}
                {(organizerWebsite ||
                    organizerTwitter ||
                    organizerFacebook) && (
                    <div className="descriptive-organizer-info-mobile__social-links">
                        <SocialLinks
                            orgWebsite={organizerWebsite}
                            orgTwitter={organizerTwitter}
                            orgFacebook={organizerFacebook}
                            style={SocialLinksStyle.rounded}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};
