import { Modal as EdsModal } from '@eventbrite/eds-modal';
import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

import './Modal.scss';

interface Props {
    visible: boolean;
    title?: string;
    type?: string;
    illustration?: JSX.Element;
    noPadding?: boolean;
    dangerouslySetContainerClassName?: { __className: string };
    noMinHeight?: boolean;
    onClose?: () => void;
    __containerClassName?: string;
}

export const Modal: React.FC<Props> = ({
    visible,
    title,
    children,
    type = 'complex',
    illustration,
    noPadding,
    dangerouslySetContainerClassName,
    noMinHeight = true,
    onClose: notifyClosed,
    __containerClassName,
}) => {
    const modalDiv = useMemo(() => document.createElement('div'), []);

    useEffect(() => {
        document.body.appendChild(modalDiv);

        return () => {
            modalDiv.remove();
        };
    }, [modalDiv]);

    if (!visible) return null;

    return ReactDOM.createPortal(
        <EdsModal
            title={title}
            isShown={visible}
            onClose={notifyClosed}
            noMinHeight={noMinHeight}
            type={type}
            illustration={illustration}
            noPadding={noPadding}
            dangerouslySetContainerClassName={dangerouslySetContainerClassName}
            __containerClassName={__containerClassName}
        >
            {children}
        </EdsModal>,
        modalDiv,
    );
};
