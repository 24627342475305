import * as React from 'react';

const PlaceholderSvg2Svg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 400 200" {...props}>
        <g>
            <path
                d="M0 100v100l97.231-.05c86.246-.044 97.237-.084 97.281-.35.094-.558.871-3.546 1.794-6.9.182-.66.542-2.01.799-3a692.754 692.754 0 011.692-6.4c1.173-4.336 3.86-15.4 5.707-23.5 2.294-10.057 4.44-20.549 5.605-27.4.178-1.045.445-2.53.594-3.3.364-1.881.823-4.571 1.193-7 .167-1.1.527-3.44.8-5.2 1.132-7.319 2.485-19.268 3.193-28.2 1.687-21.279.583-45.545-2.77-60.9-.156-.715-.44-2.065-.631-3-.19-.935-.565-2.555-.833-3.6s-.564-2.265-.658-2.712c-.662-3.145-3.481-11.979-5.102-15.988-.4-.99-.751-1.935-.78-2.1-.047-.266-11.629-.306-102.58-.35L.005 0v100M341.765.106c-.058.059.105 1.071.363 2.25.788 3.6 1.04 4.851 1.458 7.244.222 1.265.579 3.29.795 4.5.215 1.21.45 2.695.521 3.3s.209 1.595.306 2.2c.518 3.209 1.033 7.53 1.405 11.8.111 1.265.245 2.57.298 2.9.054.33.185 2.292.292 4.36.191 3.708.317 4.458.705 4.181.268-.192 1.361-1.563 3.491-4.38a341.18 341.18 0 012.746-3.587c.528-.674 1.964-2.519 3.191-4.1a719.342 719.342 0 0110.189-12.786c1.639-2.011 3.377-4.158 3.862-4.772s1.432-1.746 2.106-2.516c.673-.77 1.624-1.895 2.113-2.5s1.391-1.685 2.003-2.4c.613-.715 1.728-2.02 2.478-2.9s1.579-1.847 1.841-2.148c.262-.302.477-.594.477-.65 0-.136-40.504-.132-40.64.004m55.854 175.05c-1.148 1.838-2.912 4.691-3.92 6.341s-2.715 4.44-3.793 6.2c-3.804 6.214-7.101 11.851-7.101 12.142 0 .087 3.87.158 8.6.158h8.6v-14.1c0-7.755-.067-14.096-.15-14.091-.082.005-1.089 1.513-2.236 3.35"
                fill="#fbfcfc"
            />
            <path
                d="M205.1.35c.041.192.402 1.16.801 2.15 1.629 4.041 4.439 12.854 5.099 15.988.094.447.39 1.667.658 2.712s.643 2.665.833 3.6c.191.935.475 2.285.631 3 4.83 22.118 4.669 56.176-.423 89.1-.273 1.76-.633 4.1-.8 5.2-.37 2.429-.829 5.119-1.193 7-.149.77-.416 2.255-.594 3.3-1.165 6.851-3.311 17.343-5.605 27.4-1.847 8.1-4.534 19.164-5.707 23.5-.47 1.736-.959 3.588-1.692 6.4-.257.99-.617 2.34-.799 3-.87 3.159-1.639 6.113-1.811 6.95-.069.333 4.519.35 94.116.35 51.804 0 94.189-.071 94.189-.158 0-.256 3.313-5.98 5.698-9.842.577-.935 2.2-3.59 3.606-5.9 3.575-5.873 7.561-12.187 7.743-12.267.084-.036.153-38.714.153-85.95V0h-8.727c-8.309 0-8.742.018-9.05.378l-1.823 2.145c-.825.971-2.041 2.398-2.701 3.172-.661.773-1.604 1.9-2.095 2.505s-1.443 1.73-2.116 2.5c-.674.77-1.621 1.902-2.106 2.516s-2.223 2.761-3.862 4.772a719.342 719.342 0 00-10.189 12.786 1604.565 1604.565 0 01-3.191 4.1 341.18 341.18 0 00-2.746 3.587c-2.13 2.817-3.223 4.188-3.491 4.38-.388.277-.514-.473-.705-4.181-.107-2.068-.238-4.03-.292-4.36-.053-.33-.187-1.635-.298-2.9-.372-4.27-.887-8.591-1.405-11.8-.097-.605-.235-1.595-.306-2.2s-.306-2.09-.521-3.3a952.35 952.35 0 01-.687-3.9c-.445-2.562-.659-3.644-1.312-6.622-.329-1.497-.612-2.915-.63-3.15L341.718 0h-68.346C208.385 0 205.03.017 205.1.35"
                fill="#ececf3"
            />
        </g>
    </svg>
);

PlaceholderSvg2Svg.displayName = 'PlaceholderSvg2Svg';
export default PlaceholderSvg2Svg;
