import { InlineAlert } from '@eventbrite/marmalade';
import React, { type ReactElement } from 'react';

import './InlineBanner.scss';

type InlineBannerProps = Omit<
    React.ComponentProps<typeof InlineAlert>,
    'description'
> & {
    textAlign?: 'start' | 'center' | 'end';
};

const InlineBanner: React.FC<InlineBannerProps> = ({
    children,
    textAlign = 'start',
    ...props
}) => {
    return (
        <div className="InlineBanner__wrapper">
            <InlineAlert
                {...props}
                className={`InlineBanner ${
                    textAlign ? `InlineBanner--align-${textAlign}` : ''
                }`}
                description={children as ReactElement | string}
            />
        </div>
    );
};

export default InlineBanner;
