import { track } from '@eventbrite/datalayer-library';
import { RefObject, useState } from 'react';
import { useIntersecting } from './useIntersecting';

export const useTrackComponent = ({
    domNode,
    ref,
    eventName,
    eventBucketLabel,
    rootMargin = '0px',
}: {
    domNode?: HTMLElement | null;
    ref?: RefObject<HTMLElement>;
    eventName: string;
    eventBucketLabel?: string;
    rootMargin?: string;
}) => {
    const [viewed, setViewed] = useState(false);

    const [isIntersecting] = useIntersecting({
        domNode: domNode,
        ref: ref,
        rootMargin: rootMargin,
    });

    if (isIntersecting && !viewed) {
        setViewed(true);
        track({
            eventName: eventName,
            eventData: {
                eventBucketLabel: eventBucketLabel,
            },
        });
    }
};
