import { css } from '@emotion/core';
import styled from '@emotion/styled';

interface PreviewComponentWithIndexProps
    extends React.ComponentPropsWithoutRef<'div'> {
    slotIndex: number;
}

const SLOTS_COLORS = {
    background: ['#FFF7F6', '#F5F6F9', '#F5F9F7', '#FFF7FA', '#F3F6F7'],
    border: ['#FAAFA0', '#324B8C', '#CDDFD6', '#FC86BC', '#044F60'],
};

export const PreviewWrapper = styled.div<PreviewComponentWithIndexProps>`
    position: relative;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 16px auto;
    padding: 16px;
    background-color: ${({ slotIndex }) =>
        SLOTS_COLORS.background[slotIndex % 5]};

    &:hover > .slot-preview__edit-button {
        display: block;
    }
`;
PreviewWrapper.displayName = 'PreviewWrapper';

export const EditButtonWrapper = styled.div`
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
`;
EditButtonWrapper.displayName = 'EditButtonWrapper';

export const InnerWrapper = styled.div<PreviewComponentWithIndexProps>`
    box-sizing: border-box;
    border-left: ${({ slotIndex }) =>
        `2px solid ${SLOTS_COLORS.border[slotIndex % 5]}`};
    padding-left: 24px;
`;
InnerWrapper.displayName = 'InnerWrapper';

export const TimeDisplay = styled.p`
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #4b4d63;
`;
TimeDisplay.displayName = 'TimeDisplay';

export const TitleDisplay = styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #39364f;
`;
TitleDisplay.displayName = 'TitleDisplay';

export const HostsArea = styled.div<{ breakLine: boolean }>`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 16px;
    gap: 8px;
    width: 100%;

    @media all and (min-width: 640px) {
        flex-direction: row;
        flex-wrap: wrap;
        width: auto;
    }

    ${({ breakLine }) =>
        breakLine &&
        css`
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 16px;
        `}
`;
HostsArea.displayName = 'HostsArea';

export const Divider = styled.hr`
    border: none;
    border-top: 1px solid #dbdae3;
    margin: 16px auto 8px;
`;

export const DescriptionDisplay = styled.div`
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4b4d63;
    word-break: break-word;
`;
DescriptionDisplay.displayName = 'DescriptionDisplay';

export const ViewMoreButton = styled.button`
    background: none;
    border: none;
    color: #3659e3;
    margin-top: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
`;
ViewMoreButton.displayName = 'ViewMoreButton';

export const PlusOneDay = styled.span`
    font-size: 12px;
`;
PlusOneDay.displayName = 'PlusOneDay';
